/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Location } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function LocationUpdateForm(props) {
  const {
    id: idProp,
    location: locationModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    LocationName: "",
    Description: "",
    LocationID: "",
    FacilityOrSite: "",
    Latitude: "",
    Longitude: "",
    AddressLine1: "",
    AddressLine2: "",
    AddressLine3: "",
    AddressPostCode: "",
    AddressCity: "",
    AddressCountry: "",
  };
  const [LocationName, setLocationName] = React.useState(
    initialValues.LocationName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [LocationID, setLocationID] = React.useState(initialValues.LocationID);
  const [FacilityOrSite, setFacilityOrSite] = React.useState(
    initialValues.FacilityOrSite
  );
  const [Latitude, setLatitude] = React.useState(initialValues.Latitude);
  const [Longitude, setLongitude] = React.useState(initialValues.Longitude);
  const [AddressLine1, setAddressLine1] = React.useState(
    initialValues.AddressLine1
  );
  const [AddressLine2, setAddressLine2] = React.useState(
    initialValues.AddressLine2
  );
  const [AddressLine3, setAddressLine3] = React.useState(
    initialValues.AddressLine3
  );
  const [AddressPostCode, setAddressPostCode] = React.useState(
    initialValues.AddressPostCode
  );
  const [AddressCity, setAddressCity] = React.useState(
    initialValues.AddressCity
  );
  const [AddressCountry, setAddressCountry] = React.useState(
    initialValues.AddressCountry
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = locationRecord
      ? { ...initialValues, ...locationRecord }
      : initialValues;
    setLocationName(cleanValues.LocationName);
    setDescription(cleanValues.Description);
    setLocationID(cleanValues.LocationID);
    setFacilityOrSite(cleanValues.FacilityOrSite);
    setLatitude(cleanValues.Latitude);
    setLongitude(cleanValues.Longitude);
    setAddressLine1(cleanValues.AddressLine1);
    setAddressLine2(cleanValues.AddressLine2);
    setAddressLine3(cleanValues.AddressLine3);
    setAddressPostCode(cleanValues.AddressPostCode);
    setAddressCity(cleanValues.AddressCity);
    setAddressCountry(cleanValues.AddressCountry);
    setErrors({});
  };
  const [locationRecord, setLocationRecord] = React.useState(locationModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Location, idProp)
        : locationModelProp;
      setLocationRecord(record);
    };
    queryData();
  }, [idProp, locationModelProp]);
  React.useEffect(resetStateValues, [locationRecord]);
  const validations = {
    LocationName: [],
    Description: [],
    LocationID: [],
    FacilityOrSite: [],
    Latitude: [],
    Longitude: [],
    AddressLine1: [],
    AddressLine2: [],
    AddressLine3: [],
    AddressPostCode: [],
    AddressCity: [],
    AddressCountry: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          LocationName,
          Description,
          LocationID,
          FacilityOrSite,
          Latitude,
          Longitude,
          AddressLine1,
          AddressLine2,
          AddressLine3,
          AddressPostCode,
          AddressCity,
          AddressCountry,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Location.copyOf(locationRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "LocationUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Location"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update this record of a location of business activities, assets and facilities. This information is used to compute analytics and visualisations (e.g. assets at risk), but is not used in a formal climate-related disclosure."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Location name"
        isRequired={false}
        isReadOnly={false}
        value={LocationName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              LocationName: value,
              Description,
              LocationID,
              FacilityOrSite,
              Latitude,
              Longitude,
              AddressLine1,
              AddressLine2,
              AddressLine3,
              AddressPostCode,
              AddressCity,
              AddressCountry,
            };
            const result = onChange(modelFields);
            value = result?.LocationName ?? value;
          }
          if (errors.LocationName?.hasError) {
            runValidationTasks("LocationName", value);
          }
          setLocationName(value);
        }}
        onBlur={() => runValidationTasks("LocationName", LocationName)}
        errorMessage={errors.LocationName?.errorMessage}
        hasError={errors.LocationName?.hasError}
        {...getOverrideProps(overrides, "LocationName")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              LocationName,
              Description: value,
              LocationID,
              FacilityOrSite,
              Latitude,
              Longitude,
              AddressLine1,
              AddressLine2,
              AddressLine3,
              AddressPostCode,
              AddressCity,
              AddressCountry,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Location id"
          isRequired={false}
          isReadOnly={false}
          value={LocationID}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID: value,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.LocationID ?? value;
            }
            if (errors.LocationID?.hasError) {
              runValidationTasks("LocationID", value);
            }
            setLocationID(value);
          }}
          onBlur={() => runValidationTasks("LocationID", LocationID)}
          errorMessage={errors.LocationID?.errorMessage}
          hasError={errors.LocationID?.hasError}
          {...getOverrideProps(overrides, "LocationID")}
        ></TextField>
        <SelectField
          label="Facility or site"
          placeholder="Please select an option"
          isDisabled={false}
          value={FacilityOrSite}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite: value,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.FacilityOrSite ?? value;
            }
            if (errors.FacilityOrSite?.hasError) {
              runValidationTasks("FacilityOrSite", value);
            }
            setFacilityOrSite(value);
          }}
          onBlur={() => runValidationTasks("FacilityOrSite", FacilityOrSite)}
          errorMessage={errors.FacilityOrSite?.errorMessage}
          hasError={errors.FacilityOrSite?.hasError}
          {...getOverrideProps(overrides, "FacilityOrSite")}
        >
          <option
            children="Facility"
            value="Facility"
            {...getOverrideProps(overrides, "FacilityOrSiteoption0")}
          ></option>
          <option
            children="Site"
            value="Site"
            {...getOverrideProps(overrides, "FacilityOrSiteoption1")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Latitude"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Latitude}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude: value,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.Latitude ?? value;
            }
            if (errors.Latitude?.hasError) {
              runValidationTasks("Latitude", value);
            }
            setLatitude(value);
          }}
          onBlur={() => runValidationTasks("Latitude", Latitude)}
          errorMessage={errors.Latitude?.errorMessage}
          hasError={errors.Latitude?.hasError}
          {...getOverrideProps(overrides, "Latitude")}
        ></TextField>
        <TextField
          label="Longitude"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Longitude}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude: value,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.Longitude ?? value;
            }
            if (errors.Longitude?.hasError) {
              runValidationTasks("Longitude", value);
            }
            setLongitude(value);
          }}
          onBlur={() => runValidationTasks("Longitude", Longitude)}
          errorMessage={errors.Longitude?.errorMessage}
          hasError={errors.Longitude?.hasError}
          {...getOverrideProps(overrides, "Longitude")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Address line 1"
          isRequired={false}
          isReadOnly={false}
          value={AddressLine1}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1: value,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.AddressLine1 ?? value;
            }
            if (errors.AddressLine1?.hasError) {
              runValidationTasks("AddressLine1", value);
            }
            setAddressLine1(value);
          }}
          onBlur={() => runValidationTasks("AddressLine1", AddressLine1)}
          errorMessage={errors.AddressLine1?.errorMessage}
          hasError={errors.AddressLine1?.hasError}
          {...getOverrideProps(overrides, "AddressLine1")}
        ></TextField>
        <TextField
          label="Address line 2"
          isRequired={false}
          isReadOnly={false}
          value={AddressLine2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2: value,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.AddressLine2 ?? value;
            }
            if (errors.AddressLine2?.hasError) {
              runValidationTasks("AddressLine2", value);
            }
            setAddressLine2(value);
          }}
          onBlur={() => runValidationTasks("AddressLine2", AddressLine2)}
          errorMessage={errors.AddressLine2?.errorMessage}
          hasError={errors.AddressLine2?.hasError}
          {...getOverrideProps(overrides, "AddressLine2")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Address line 3"
          isRequired={false}
          isReadOnly={false}
          value={AddressLine3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3: value,
                AddressPostCode,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.AddressLine3 ?? value;
            }
            if (errors.AddressLine3?.hasError) {
              runValidationTasks("AddressLine3", value);
            }
            setAddressLine3(value);
          }}
          onBlur={() => runValidationTasks("AddressLine3", AddressLine3)}
          errorMessage={errors.AddressLine3?.errorMessage}
          hasError={errors.AddressLine3?.hasError}
          {...getOverrideProps(overrides, "AddressLine3")}
        ></TextField>
        <TextField
          label="Address post code"
          isRequired={false}
          isReadOnly={false}
          value={AddressPostCode}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode: value,
                AddressCity,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.AddressPostCode ?? value;
            }
            if (errors.AddressPostCode?.hasError) {
              runValidationTasks("AddressPostCode", value);
            }
            setAddressPostCode(value);
          }}
          onBlur={() => runValidationTasks("AddressPostCode", AddressPostCode)}
          errorMessage={errors.AddressPostCode?.errorMessage}
          hasError={errors.AddressPostCode?.hasError}
          {...getOverrideProps(overrides, "AddressPostCode")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Address city"
          isRequired={false}
          isReadOnly={false}
          value={AddressCity}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity: value,
                AddressCountry,
              };
              const result = onChange(modelFields);
              value = result?.AddressCity ?? value;
            }
            if (errors.AddressCity?.hasError) {
              runValidationTasks("AddressCity", value);
            }
            setAddressCity(value);
          }}
          onBlur={() => runValidationTasks("AddressCity", AddressCity)}
          errorMessage={errors.AddressCity?.errorMessage}
          hasError={errors.AddressCity?.hasError}
          {...getOverrideProps(overrides, "AddressCity")}
        ></TextField>
        <SelectField
          label="Address country"
          placeholder="Please select an option"
          isDisabled={false}
          value={AddressCountry}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                Description,
                LocationID,
                FacilityOrSite,
                Latitude,
                Longitude,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                AddressPostCode,
                AddressCity,
                AddressCountry: value,
              };
              const result = onChange(modelFields);
              value = result?.AddressCountry ?? value;
            }
            if (errors.AddressCountry?.hasError) {
              runValidationTasks("AddressCountry", value);
            }
            setAddressCountry(value);
          }}
          onBlur={() => runValidationTasks("AddressCountry", AddressCountry)}
          errorMessage={errors.AddressCountry?.errorMessage}
          hasError={errors.AddressCountry?.hasError}
          {...getOverrideProps(overrides, "AddressCountry")}
        >
          <option
            children="Australia"
            value="Australia"
            {...getOverrideProps(overrides, "AddressCountryoption0")}
          ></option>
          <option
            children="Canada"
            value="Canada"
            {...getOverrideProps(overrides, "AddressCountryoption1")}
          ></option>
          <option
            children="China"
            value="China"
            {...getOverrideProps(overrides, "AddressCountryoption2")}
          ></option>
          <option
            children="Hong kong"
            value="HongKong"
            {...getOverrideProps(overrides, "AddressCountryoption3")}
          ></option>
          <option
            children="New zealand"
            value="NewZealand"
            {...getOverrideProps(overrides, "AddressCountryoption4")}
          ></option>
          <option
            children="Singapore"
            value="Singapore"
            {...getOverrideProps(overrides, "AddressCountryoption5")}
          ></option>
          <option
            children="United kingdom"
            value="UnitedKingdom"
            {...getOverrideProps(overrides, "AddressCountryoption6")}
          ></option>
          <option
            children="United states"
            value="UnitedStates"
            {...getOverrideProps(overrides, "AddressCountryoption7")}
          ></option>
        </SelectField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || locationModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || locationModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
