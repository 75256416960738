/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Icon,
  ScrollView,
  SelectField,
  Text,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { ClimateRelatedMetrics } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ClimateRelatedMetricsCrossIndustryMetricsCreateForm(
  props
) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    MetricDataSetName: "",
    AbsoluteGrossScope1Emissions: "",
    AbsoluteGrossScope2Emissions: "",
    AbsoluteGrossScope3Emissions: "",
    TotalAbsoluteGrossAllScopesEmissions: "",
    GHGMeasurementStandardUsed: "",
    MeasurementApproach: "",
    MeasurementInputs: "",
    MeasurementAssumptions: "",
    ReasonForApproach: "",
    ChangesInApproachFromPreviousPeriod: "",
    Scope1EmissionsByConsolidatedAcctGrp: "",
    Scope2EmissionsByConsolidatedAcctGrp: "",
    Scope1EmissionsOtherInvestees: "",
    Scope2EmissionsOtherInvestees: "",
    Scope2LocationBasedEmissions: "",
    ContracturalInstrumentsInformingScope2: "",
    Scope3EmissionsCategoriesIncluded: [],
    AdditionalCategory15Information: "",
  };
  const [MetricDataSetName, setMetricDataSetName] = React.useState(
    initialValues.MetricDataSetName
  );
  const [AbsoluteGrossScope1Emissions, setAbsoluteGrossScope1Emissions] =
    React.useState(initialValues.AbsoluteGrossScope1Emissions);
  const [AbsoluteGrossScope2Emissions, setAbsoluteGrossScope2Emissions] =
    React.useState(initialValues.AbsoluteGrossScope2Emissions);
  const [AbsoluteGrossScope3Emissions, setAbsoluteGrossScope3Emissions] =
    React.useState(initialValues.AbsoluteGrossScope3Emissions);
  const [
    TotalAbsoluteGrossAllScopesEmissions,
    setTotalAbsoluteGrossAllScopesEmissions,
  ] = React.useState(initialValues.TotalAbsoluteGrossAllScopesEmissions);
  const [GHGMeasurementStandardUsed, setGHGMeasurementStandardUsed] =
    React.useState(initialValues.GHGMeasurementStandardUsed);
  const [MeasurementApproach, setMeasurementApproach] = React.useState(
    initialValues.MeasurementApproach
  );
  const [MeasurementInputs, setMeasurementInputs] = React.useState(
    initialValues.MeasurementInputs
  );
  const [MeasurementAssumptions, setMeasurementAssumptions] = React.useState(
    initialValues.MeasurementAssumptions
  );
  const [ReasonForApproach, setReasonForApproach] = React.useState(
    initialValues.ReasonForApproach
  );
  const [
    ChangesInApproachFromPreviousPeriod,
    setChangesInApproachFromPreviousPeriod,
  ] = React.useState(initialValues.ChangesInApproachFromPreviousPeriod);
  const [
    Scope1EmissionsByConsolidatedAcctGrp,
    setScope1EmissionsByConsolidatedAcctGrp,
  ] = React.useState(initialValues.Scope1EmissionsByConsolidatedAcctGrp);
  const [
    Scope2EmissionsByConsolidatedAcctGrp,
    setScope2EmissionsByConsolidatedAcctGrp,
  ] = React.useState(initialValues.Scope2EmissionsByConsolidatedAcctGrp);
  const [Scope1EmissionsOtherInvestees, setScope1EmissionsOtherInvestees] =
    React.useState(initialValues.Scope1EmissionsOtherInvestees);
  const [Scope2EmissionsOtherInvestees, setScope2EmissionsOtherInvestees] =
    React.useState(initialValues.Scope2EmissionsOtherInvestees);
  const [Scope2LocationBasedEmissions, setScope2LocationBasedEmissions] =
    React.useState(initialValues.Scope2LocationBasedEmissions);
  const [
    ContracturalInstrumentsInformingScope2,
    setContracturalInstrumentsInformingScope2,
  ] = React.useState(initialValues.ContracturalInstrumentsInformingScope2);
  const [
    Scope3EmissionsCategoriesIncluded,
    setScope3EmissionsCategoriesIncluded,
  ] = React.useState(initialValues.Scope3EmissionsCategoriesIncluded);
  const [AdditionalCategory15Information, setAdditionalCategory15Information] =
    React.useState(initialValues.AdditionalCategory15Information);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setMetricDataSetName(initialValues.MetricDataSetName);
    setAbsoluteGrossScope1Emissions(initialValues.AbsoluteGrossScope1Emissions);
    setAbsoluteGrossScope2Emissions(initialValues.AbsoluteGrossScope2Emissions);
    setAbsoluteGrossScope3Emissions(initialValues.AbsoluteGrossScope3Emissions);
    setTotalAbsoluteGrossAllScopesEmissions(
      initialValues.TotalAbsoluteGrossAllScopesEmissions
    );
    setGHGMeasurementStandardUsed(initialValues.GHGMeasurementStandardUsed);
    setMeasurementApproach(initialValues.MeasurementApproach);
    setMeasurementInputs(initialValues.MeasurementInputs);
    setMeasurementAssumptions(initialValues.MeasurementAssumptions);
    setReasonForApproach(initialValues.ReasonForApproach);
    setChangesInApproachFromPreviousPeriod(
      initialValues.ChangesInApproachFromPreviousPeriod
    );
    setScope1EmissionsByConsolidatedAcctGrp(
      initialValues.Scope1EmissionsByConsolidatedAcctGrp
    );
    setScope2EmissionsByConsolidatedAcctGrp(
      initialValues.Scope2EmissionsByConsolidatedAcctGrp
    );
    setScope1EmissionsOtherInvestees(
      initialValues.Scope1EmissionsOtherInvestees
    );
    setScope2EmissionsOtherInvestees(
      initialValues.Scope2EmissionsOtherInvestees
    );
    setScope2LocationBasedEmissions(initialValues.Scope2LocationBasedEmissions);
    setContracturalInstrumentsInformingScope2(
      initialValues.ContracturalInstrumentsInformingScope2
    );
    setScope3EmissionsCategoriesIncluded(
      initialValues.Scope3EmissionsCategoriesIncluded
    );
    setCurrentScope3EmissionsCategoriesIncludedValue("");
    setAdditionalCategory15Information(
      initialValues.AdditionalCategory15Information
    );
    setErrors({});
  };
  const [
    currentScope3EmissionsCategoriesIncludedValue,
    setCurrentScope3EmissionsCategoriesIncludedValue,
  ] = React.useState("");
  const Scope3EmissionsCategoriesIncludedRef = React.createRef();
  const getDisplayValue = {
    Scope3EmissionsCategoriesIncluded: (r) => {
      const enumDisplayValueMap = {
        Category1: "Category1",
        Category2: "Category2",
        Category3: "Category3",
        Category4: "Category4",
        Category5: "Category5",
        Category6: "Category6",
        Category7: "Category7",
        Category8: "Category8",
        Category9: "Category9",
        Category10: "Category10",
        Category11: "Category11",
        Category12: "Category12",
        Category13: "Category13",
        Category14: "Category14",
        Category15: "Category15",
      };
      return enumDisplayValueMap[r];
    },
  };
  const validations = {
    MetricDataSetName: [],
    AbsoluteGrossScope1Emissions: [],
    AbsoluteGrossScope2Emissions: [],
    AbsoluteGrossScope3Emissions: [],
    TotalAbsoluteGrossAllScopesEmissions: [],
    GHGMeasurementStandardUsed: [],
    MeasurementApproach: [],
    MeasurementInputs: [],
    MeasurementAssumptions: [],
    ReasonForApproach: [],
    ChangesInApproachFromPreviousPeriod: [],
    Scope1EmissionsByConsolidatedAcctGrp: [],
    Scope2EmissionsByConsolidatedAcctGrp: [],
    Scope1EmissionsOtherInvestees: [],
    Scope2EmissionsOtherInvestees: [],
    Scope2LocationBasedEmissions: [],
    ContracturalInstrumentsInformingScope2: [],
    Scope3EmissionsCategoriesIncluded: [],
    AdditionalCategory15Information: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          MetricDataSetName,
          AbsoluteGrossScope1Emissions,
          AbsoluteGrossScope2Emissions,
          AbsoluteGrossScope3Emissions,
          TotalAbsoluteGrossAllScopesEmissions,
          GHGMeasurementStandardUsed,
          MeasurementApproach,
          MeasurementInputs,
          MeasurementAssumptions,
          ReasonForApproach,
          ChangesInApproachFromPreviousPeriod,
          Scope1EmissionsByConsolidatedAcctGrp,
          Scope2EmissionsByConsolidatedAcctGrp,
          Scope1EmissionsOtherInvestees,
          Scope2EmissionsOtherInvestees,
          Scope2LocationBasedEmissions,
          ContracturalInstrumentsInformingScope2,
          Scope3EmissionsCategoriesIncluded,
          AdditionalCategory15Information,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateRelatedMetrics(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedMetricsCrossIndustryMetricsCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Metrics - Greenhouse Gases"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 29 a i-vi."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Create a record describing greenhouse gas metrics relevant to all industries."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Greenhouse gas metrics record name (e.g. 2025 greenhouse gas metrics)"
        isRequired={false}
        isReadOnly={false}
        value={MetricDataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName: value,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
            };
            const result = onChange(modelFields);
            value = result?.MetricDataSetName ?? value;
          }
          if (errors.MetricDataSetName?.hasError) {
            runValidationTasks("MetricDataSetName", value);
          }
          setMetricDataSetName(value);
        }}
        onBlur={() =>
          runValidationTasks("MetricDataSetName", MetricDataSetName)
        }
        errorMessage={errors.MetricDataSetName?.errorMessage}
        hasError={errors.MetricDataSetName?.hasError}
        {...getOverrideProps(overrides, "MetricDataSetName")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Absolute gross scope 1 emissions (metric tonnes CO2 equivalent)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={AbsoluteGrossScope1Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions: value,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.AbsoluteGrossScope1Emissions ?? value;
            }
            if (errors.AbsoluteGrossScope1Emissions?.hasError) {
              runValidationTasks("AbsoluteGrossScope1Emissions", value);
            }
            setAbsoluteGrossScope1Emissions(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "AbsoluteGrossScope1Emissions",
              AbsoluteGrossScope1Emissions
            )
          }
          errorMessage={errors.AbsoluteGrossScope1Emissions?.errorMessage}
          hasError={errors.AbsoluteGrossScope1Emissions?.hasError}
          {...getOverrideProps(overrides, "AbsoluteGrossScope1Emissions")}
        ></TextField>
        <TextField
          label="Absolute gross scope 2 emissions (metric tonnes CO2 equivalent)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={AbsoluteGrossScope2Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions: value,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.AbsoluteGrossScope2Emissions ?? value;
            }
            if (errors.AbsoluteGrossScope2Emissions?.hasError) {
              runValidationTasks("AbsoluteGrossScope2Emissions", value);
            }
            setAbsoluteGrossScope2Emissions(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "AbsoluteGrossScope2Emissions",
              AbsoluteGrossScope2Emissions
            )
          }
          errorMessage={errors.AbsoluteGrossScope2Emissions?.errorMessage}
          hasError={errors.AbsoluteGrossScope2Emissions?.hasError}
          {...getOverrideProps(overrides, "AbsoluteGrossScope2Emissions")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Absolute gross scope 3 emissions (metric tonnes CO2 equivalent)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={AbsoluteGrossScope3Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions: value,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.AbsoluteGrossScope3Emissions ?? value;
            }
            if (errors.AbsoluteGrossScope3Emissions?.hasError) {
              runValidationTasks("AbsoluteGrossScope3Emissions", value);
            }
            setAbsoluteGrossScope3Emissions(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "AbsoluteGrossScope3Emissions",
              AbsoluteGrossScope3Emissions
            )
          }
          errorMessage={errors.AbsoluteGrossScope3Emissions?.errorMessage}
          hasError={errors.AbsoluteGrossScope3Emissions?.hasError}
          {...getOverrideProps(overrides, "AbsoluteGrossScope3Emissions")}
        ></TextField>
        <TextField
          label="Total absolute gross all scopes emissions (metric tonnes CO2 equivalent)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={TotalAbsoluteGrossAllScopesEmissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions: value,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.TotalAbsoluteGrossAllScopesEmissions ?? value;
            }
            if (errors.TotalAbsoluteGrossAllScopesEmissions?.hasError) {
              runValidationTasks("TotalAbsoluteGrossAllScopesEmissions", value);
            }
            setTotalAbsoluteGrossAllScopesEmissions(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "TotalAbsoluteGrossAllScopesEmissions",
              TotalAbsoluteGrossAllScopesEmissions
            )
          }
          errorMessage={
            errors.TotalAbsoluteGrossAllScopesEmissions?.errorMessage
          }
          hasError={errors.TotalAbsoluteGrossAllScopesEmissions?.hasError}
          {...getOverrideProps(
            overrides,
            "TotalAbsoluteGrossAllScopesEmissions"
          )}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Greenhouse Gas measurement standard used"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed: value,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
            };
            const result = onChange(modelFields);
            value = result?.GHGMeasurementStandardUsed ?? value;
          }
          if (errors.GHGMeasurementStandardUsed?.hasError) {
            runValidationTasks("GHGMeasurementStandardUsed", value);
          }
          setGHGMeasurementStandardUsed(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GHGMeasurementStandardUsed",
            GHGMeasurementStandardUsed
          )
        }
        errorMessage={errors.GHGMeasurementStandardUsed?.errorMessage}
        hasError={errors.GHGMeasurementStandardUsed?.hasError}
        	value={GHGMeasurementStandardUsed} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "GHGMeasurementStandardUsed")}
      ></TextAreaField>
      <TextAreaField
        label="Measurement approach"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach: value,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
            };
            const result = onChange(modelFields);
            value = result?.MeasurementApproach ?? value;
          }
          if (errors.MeasurementApproach?.hasError) {
            runValidationTasks("MeasurementApproach", value);
          }
          setMeasurementApproach(value);
        }}
        onBlur={() =>
          runValidationTasks("MeasurementApproach", MeasurementApproach)
        }
        errorMessage={errors.MeasurementApproach?.errorMessage}
        hasError={errors.MeasurementApproach?.hasError}
        	value={MeasurementApproach} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "MeasurementApproach")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextAreaField
          label="Measurement inputs"
          isRequired={false}
          isReadOnly={false}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs: value,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.MeasurementInputs ?? value;
            }
            if (errors.MeasurementInputs?.hasError) {
              runValidationTasks("MeasurementInputs", value);
            }
            setMeasurementInputs(value);
          }}
          onBlur={() =>
            runValidationTasks("MeasurementInputs", MeasurementInputs)
          }
          errorMessage={errors.MeasurementInputs?.errorMessage}
          hasError={errors.MeasurementInputs?.hasError}
          	value={MeasurementInputs} // GRL_MODIFY: correct value prop
          {...getOverrideProps(overrides, "MeasurementInputs")}
        ></TextAreaField>
        <TextAreaField
          label="Measurement assumptions"
          isRequired={false}
          isReadOnly={false}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions: value,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.MeasurementAssumptions ?? value;
            }
            if (errors.MeasurementAssumptions?.hasError) {
              runValidationTasks("MeasurementAssumptions", value);
            }
            setMeasurementAssumptions(value);
          }}
          onBlur={() =>
            runValidationTasks("MeasurementAssumptions", MeasurementAssumptions)
          }
          errorMessage={errors.MeasurementAssumptions?.errorMessage}
          hasError={errors.MeasurementAssumptions?.hasError}
          	value={MeasurementAssumptions} // GRL_MODIFY: correct value prop
          {...getOverrideProps(overrides, "MeasurementAssumptions")}
        ></TextAreaField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextAreaField
          label="Reason for approach"
          isRequired={false}
          isReadOnly={false}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach: value,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.ReasonForApproach ?? value;
            }
            if (errors.ReasonForApproach?.hasError) {
              runValidationTasks("ReasonForApproach", value);
            }
            setReasonForApproach(value);
          }}
          onBlur={() =>
            runValidationTasks("ReasonForApproach", ReasonForApproach)
          }
          errorMessage={errors.ReasonForApproach?.errorMessage}
          hasError={errors.ReasonForApproach?.hasError}
          	value={ReasonForApproach} // GRL_MODIFY: correct value prop
          {...getOverrideProps(overrides, "ReasonForApproach")}
        ></TextAreaField>
        <TextAreaField
          label="Changes in approach from previous period"
          isRequired={false}
          isReadOnly={false}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod: value,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.ChangesInApproachFromPreviousPeriod ?? value;
            }
            if (errors.ChangesInApproachFromPreviousPeriod?.hasError) {
              runValidationTasks("ChangesInApproachFromPreviousPeriod", value);
            }
            setChangesInApproachFromPreviousPeriod(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ChangesInApproachFromPreviousPeriod",
              ChangesInApproachFromPreviousPeriod
            )
          }
          errorMessage={
            errors.ChangesInApproachFromPreviousPeriod?.errorMessage
          }
          hasError={errors.ChangesInApproachFromPreviousPeriod?.hasError}
          {...getOverrideProps(
            overrides,
            "ChangesInApproachFromPreviousPeriod"
          )}
        ></TextAreaField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <TextField
          label="Scope 1 emissions by consolidated accounting group (e.g. parent and subsidiaries)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope1EmissionsByConsolidatedAcctGrp}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp: value,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.Scope1EmissionsByConsolidatedAcctGrp ?? value;
            }
            if (errors.Scope1EmissionsByConsolidatedAcctGrp?.hasError) {
              runValidationTasks("Scope1EmissionsByConsolidatedAcctGrp", value);
            }
            setScope1EmissionsByConsolidatedAcctGrp(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "Scope1EmissionsByConsolidatedAcctGrp",
              Scope1EmissionsByConsolidatedAcctGrp
            )
          }
          errorMessage={
            errors.Scope1EmissionsByConsolidatedAcctGrp?.errorMessage
          }
          hasError={errors.Scope1EmissionsByConsolidatedAcctGrp?.hasError}
          {...getOverrideProps(
            overrides,
            "Scope1EmissionsByConsolidatedAcctGrp"
          )}
        ></TextField>
        <TextField
          label="Scope 2 emissions by consolidated accounting group (e.g. parent and subsidiaries)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope2EmissionsByConsolidatedAcctGrp}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp: value,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.Scope2EmissionsByConsolidatedAcctGrp ?? value;
            }
            if (errors.Scope2EmissionsByConsolidatedAcctGrp?.hasError) {
              runValidationTasks("Scope2EmissionsByConsolidatedAcctGrp", value);
            }
            setScope2EmissionsByConsolidatedAcctGrp(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "Scope2EmissionsByConsolidatedAcctGrp",
              Scope2EmissionsByConsolidatedAcctGrp
            )
          }
          errorMessage={
            errors.Scope2EmissionsByConsolidatedAcctGrp?.errorMessage
          }
          hasError={errors.Scope2EmissionsByConsolidatedAcctGrp?.hasError}
          {...getOverrideProps(
            overrides,
            "Scope2EmissionsByConsolidatedAcctGrp"
          )}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid12")}
      >
        <TextField
          label="Scope 1 emissions other investees"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope1EmissionsOtherInvestees}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees: value,
                Scope2EmissionsOtherInvestees,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.Scope1EmissionsOtherInvestees ?? value;
            }
            if (errors.Scope1EmissionsOtherInvestees?.hasError) {
              runValidationTasks("Scope1EmissionsOtherInvestees", value);
            }
            setScope1EmissionsOtherInvestees(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "Scope1EmissionsOtherInvestees",
              Scope1EmissionsOtherInvestees
            )
          }
          errorMessage={errors.Scope1EmissionsOtherInvestees?.errorMessage}
          hasError={errors.Scope1EmissionsOtherInvestees?.hasError}
          {...getOverrideProps(overrides, "Scope1EmissionsOtherInvestees")}
        ></TextField>
        <TextField
          label="Scope 2 emissions other investees"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope2EmissionsOtherInvestees}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                AbsoluteGrossScope1Emissions,
                AbsoluteGrossScope2Emissions,
                AbsoluteGrossScope3Emissions,
                TotalAbsoluteGrossAllScopesEmissions,
                GHGMeasurementStandardUsed,
                MeasurementApproach,
                MeasurementInputs,
                MeasurementAssumptions,
                ReasonForApproach,
                ChangesInApproachFromPreviousPeriod,
                Scope1EmissionsByConsolidatedAcctGrp,
                Scope2EmissionsByConsolidatedAcctGrp,
                Scope1EmissionsOtherInvestees,
                Scope2EmissionsOtherInvestees: value,
                Scope2LocationBasedEmissions,
                ContracturalInstrumentsInformingScope2,
                Scope3EmissionsCategoriesIncluded,
                AdditionalCategory15Information,
              };
              const result = onChange(modelFields);
              value = result?.Scope2EmissionsOtherInvestees ?? value;
            }
            if (errors.Scope2EmissionsOtherInvestees?.hasError) {
              runValidationTasks("Scope2EmissionsOtherInvestees", value);
            }
            setScope2EmissionsOtherInvestees(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "Scope2EmissionsOtherInvestees",
              Scope2EmissionsOtherInvestees
            )
          }
          errorMessage={errors.Scope2EmissionsOtherInvestees?.errorMessage}
          hasError={errors.Scope2EmissionsOtherInvestees?.hasError}
          {...getOverrideProps(overrides, "Scope2EmissionsOtherInvestees")}
        ></TextField>
      </Grid>
      <TextField
        label="Scope 2 location based emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Scope2LocationBasedEmissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions: value,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
            };
            const result = onChange(modelFields);
            value = result?.Scope2LocationBasedEmissions ?? value;
          }
          if (errors.Scope2LocationBasedEmissions?.hasError) {
            runValidationTasks("Scope2LocationBasedEmissions", value);
          }
          setScope2LocationBasedEmissions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "Scope2LocationBasedEmissions",
            Scope2LocationBasedEmissions
          )
        }
        errorMessage={errors.Scope2LocationBasedEmissions?.errorMessage}
        hasError={errors.Scope2LocationBasedEmissions?.hasError}
        {...getOverrideProps(overrides, "Scope2LocationBasedEmissions")}
      ></TextField>
      <TextAreaField
        label="Contractural instruments informing scope 2"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2: value,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
            };
            const result = onChange(modelFields);
            value = result?.ContracturalInstrumentsInformingScope2 ?? value;
          }
          if (errors.ContracturalInstrumentsInformingScope2?.hasError) {
            runValidationTasks("ContracturalInstrumentsInformingScope2", value);
          }
          setContracturalInstrumentsInformingScope2(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ContracturalInstrumentsInformingScope2",
            ContracturalInstrumentsInformingScope2
          )
        }
        errorMessage={
          errors.ContracturalInstrumentsInformingScope2?.errorMessage
        }
        hasError={errors.ContracturalInstrumentsInformingScope2?.hasError}
        {...getOverrideProps(
          overrides,
          "ContracturalInstrumentsInformingScope2"
        )}
      ></TextAreaField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded: values,
              AdditionalCategory15Information,
            };
            const result = onChange(modelFields);
            values = result?.Scope3EmissionsCategoriesIncluded ?? values;
          }
          setScope3EmissionsCategoriesIncluded(values);
          setCurrentScope3EmissionsCategoriesIncludedValue("");
        }}
        currentFieldValue={currentScope3EmissionsCategoriesIncludedValue}
        label={"Scope 3 emissions categories included"}
        items={Scope3EmissionsCategoriesIncluded}
        hasError={errors?.Scope3EmissionsCategoriesIncluded?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "Scope3EmissionsCategoriesIncluded",
            currentScope3EmissionsCategoriesIncludedValue
          )
        }
        errorMessage={errors?.Scope3EmissionsCategoriesIncluded?.errorMessage}
        getBadgeText={getDisplayValue.Scope3EmissionsCategoriesIncluded}
        setFieldValue={setCurrentScope3EmissionsCategoriesIncludedValue}
        inputFieldRef={Scope3EmissionsCategoriesIncludedRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Scope 3 emissions categories included"
          placeholder="Please select an option"
          isDisabled={false}
          value={currentScope3EmissionsCategoriesIncludedValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.Scope3EmissionsCategoriesIncluded?.hasError) {
              runValidationTasks("Scope3EmissionsCategoriesIncluded", value);
            }
            setCurrentScope3EmissionsCategoriesIncludedValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "Scope3EmissionsCategoriesIncluded",
              currentScope3EmissionsCategoriesIncludedValue
            )
          }
          errorMessage={errors.Scope3EmissionsCategoriesIncluded?.errorMessage}
          hasError={errors.Scope3EmissionsCategoriesIncluded?.hasError}
          ref={Scope3EmissionsCategoriesIncludedRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Scope3EmissionsCategoriesIncluded")}
        >
          <option
            children="Category1"
            value="Category1"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption0"
            )}
          ></option>
          <option
            children="Category2"
            value="Category2"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption1"
            )}
          ></option>
          <option
            children="Category3"
            value="Category3"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption2"
            )}
          ></option>
          <option
            children="Category4"
            value="Category4"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption3"
            )}
          ></option>
          <option
            children="Category5"
            value="Category5"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption4"
            )}
          ></option>
          <option
            children="Category6"
            value="Category6"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption5"
            )}
          ></option>
          <option
            children="Category7"
            value="Category7"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption6"
            )}
          ></option>
          <option
            children="Category8"
            value="Category8"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption7"
            )}
          ></option>
          <option
            children="Category9"
            value="Category9"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption8"
            )}
          ></option>
          <option
            children="Category10"
            value="Category10"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption9"
            )}
          ></option>
          <option
            children="Category11"
            value="Category11"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption10"
            )}
          ></option>
          <option
            children="Category12"
            value="Category12"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption11"
            )}
          ></option>
          <option
            children="Category13"
            value="Category13"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption12"
            )}
          ></option>
          <option
            children="Category14"
            value="Category14"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption13"
            )}
          ></option>
          <option
            children="Category15"
            value="Category15"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption14"
            )}
          ></option>
        </SelectField>
      </ArrayField>
      <TextAreaField
        label="Additional information about your category 15 greenhouse gas emissions associated with your investments (financed emissions), if your activities include asset management, commercial banking or insurance"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information: value,
            };
            const result = onChange(modelFields);
            value = result?.AdditionalCategory15Information ?? value;
          }
          if (errors.AdditionalCategory15Information?.hasError) {
            runValidationTasks("AdditionalCategory15Information", value);
          }
          setAdditionalCategory15Information(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AdditionalCategory15Information",
            AdditionalCategory15Information
          )
        }
        errorMessage={errors.AdditionalCategory15Information?.errorMessage}
        hasError={errors.AdditionalCategory15Information?.hasError}
        	value={AdditionalCategory15Information} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "AdditionalCategory15Information")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
