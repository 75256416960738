/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { AggregatedFinancialEffect } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function AggregatedFinancialEffectCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    WhyNoQuanitativeFinancialData: "",
    QualitativeDescription: "",
    QuantitativeDescription: "",
    FinancialEffectValue: "",
    FinancialEffectUnit: "",
    FinancialEffectValueMin: "",
    FinancialEffectValueMax: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [WhyNoQuanitativeFinancialData, setWhyNoQuanitativeFinancialData] =
    React.useState(initialValues.WhyNoQuanitativeFinancialData);
  const [QualitativeDescription, setQualitativeDescription] = React.useState(
    initialValues.QualitativeDescription
  );
  const [QuantitativeDescription, setQuantitativeDescription] = React.useState(
    initialValues.QuantitativeDescription
  );
  const [FinancialEffectValue, setFinancialEffectValue] = React.useState(
    initialValues.FinancialEffectValue
  );
  const [FinancialEffectUnit, setFinancialEffectUnit] = React.useState(
    initialValues.FinancialEffectUnit
  );
  const [FinancialEffectValueMin, setFinancialEffectValueMin] = React.useState(
    initialValues.FinancialEffectValueMin
  );
  const [FinancialEffectValueMax, setFinancialEffectValueMax] = React.useState(
    initialValues.FinancialEffectValueMax
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setWhyNoQuanitativeFinancialData(
      initialValues.WhyNoQuanitativeFinancialData
    );
    setQualitativeDescription(initialValues.QualitativeDescription);
    setQuantitativeDescription(initialValues.QuantitativeDescription);
    setFinancialEffectValue(initialValues.FinancialEffectValue);
    setFinancialEffectUnit(initialValues.FinancialEffectUnit);
    setFinancialEffectValueMin(initialValues.FinancialEffectValueMin);
    setFinancialEffectValueMax(initialValues.FinancialEffectValueMax);
    setErrors({});
  };
  const validations = {
    WhyNoQuanitativeFinancialData: [],
    QualitativeDescription: [],
    QuantitativeDescription: [],
    FinancialEffectValue: [],
    FinancialEffectUnit: [],
    FinancialEffectValueMin: [],
    FinancialEffectValueMax: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          WhyNoQuanitativeFinancialData,
          QualitativeDescription,
          QuantitativeDescription,
          FinancialEffectValue,
          FinancialEffectUnit,
          FinancialEffectValueMin,
          FinancialEffectValueMax,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new AggregatedFinancialEffect(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AggregatedFinancialEffectCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Aggregated Financial Effect"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 21 c."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record that defines aggregated current and anticipated financial effects of your climate-related risks or opportunities."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Text
        children="Note: enter this data only if you determined that quantitative information about current or future financial effects for individual risks and opportunities would not be useful, and you wish to report a single value aggregated across all risks and opportunities."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextAreaField
        label="Describe why you have not provided quantitative data about the current or anticipated financial effects of your individual climate-related risks or opportunities"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              WhyNoQuanitativeFinancialData: value,
              QualitativeDescription,
              QuantitativeDescription,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
            };
            const result = onChange(modelFields);
            value = result?.WhyNoQuanitativeFinancialData ?? value;
          }
          if (errors.WhyNoQuanitativeFinancialData?.hasError) {
            runValidationTasks("WhyNoQuanitativeFinancialData", value);
          }
          setWhyNoQuanitativeFinancialData(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "WhyNoQuanitativeFinancialData",
            WhyNoQuanitativeFinancialData
          )
        }
        errorMessage={errors.WhyNoQuanitativeFinancialData?.errorMessage}
        hasError={errors.WhyNoQuanitativeFinancialData?.hasError}
        	value={WhyNoQuanitativeFinancialData} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "WhyNoQuanitativeFinancialData")}
      ></TextAreaField>
      <TextAreaField
        label="Qualitative description of aggregated financial effects"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              WhyNoQuanitativeFinancialData,
              QualitativeDescription: value,
              QuantitativeDescription,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
            };
            const result = onChange(modelFields);
            value = result?.QualitativeDescription ?? value;
          }
          if (errors.QualitativeDescription?.hasError) {
            runValidationTasks("QualitativeDescription", value);
          }
          setQualitativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("QualitativeDescription", QualitativeDescription)
        }
        errorMessage={errors.QualitativeDescription?.errorMessage}
        hasError={errors.QualitativeDescription?.hasError}
        	value={QualitativeDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "QualitativeDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Quantitative description of aggregated financial effects"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              WhyNoQuanitativeFinancialData,
              QualitativeDescription,
              QuantitativeDescription: value,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
            };
            const result = onChange(modelFields);
            value = result?.QuantitativeDescription ?? value;
          }
          if (errors.QuantitativeDescription?.hasError) {
            runValidationTasks("QuantitativeDescription", value);
          }
          setQuantitativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("QuantitativeDescription", QuantitativeDescription)
        }
        errorMessage={errors.QuantitativeDescription?.errorMessage}
        hasError={errors.QuantitativeDescription?.hasError}
        	value={QuantitativeDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "QuantitativeDescription")}
      ></TextAreaField>
      <Text
        children="Provide quantitative information about the combined financial effects of climate-related risks or opportunities  unless you determine that quantitative information about the combined financial effects would not be useful. You can also report a range of values (min-max)."
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Value of aggregated financial effects"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={FinancialEffectValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                WhyNoQuanitativeFinancialData,
                QualitativeDescription,
                QuantitativeDescription,
                FinancialEffectValue: value,
                FinancialEffectUnit,
                FinancialEffectValueMin,
                FinancialEffectValueMax,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValue ?? value;
            }
            if (errors.FinancialEffectValue?.hasError) {
              runValidationTasks("FinancialEffectValue", value);
            }
            setFinancialEffectValue(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectValue", FinancialEffectValue)
          }
          errorMessage={errors.FinancialEffectValue?.errorMessage}
          hasError={errors.FinancialEffectValue?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValue")}
        ></TextField>
        <TextField
          label="Unit of measure (e.g. EBITDA, profit)"
          isRequired={false}
          isReadOnly={false}
          value={FinancialEffectUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                WhyNoQuanitativeFinancialData,
                QualitativeDescription,
                QuantitativeDescription,
                FinancialEffectValue,
                FinancialEffectUnit: value,
                FinancialEffectValueMin,
                FinancialEffectValueMax,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectUnit ?? value;
            }
            if (errors.FinancialEffectUnit?.hasError) {
              runValidationTasks("FinancialEffectUnit", value);
            }
            setFinancialEffectUnit(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectUnit", FinancialEffectUnit)
          }
          errorMessage={errors.FinancialEffectUnit?.errorMessage}
          hasError={errors.FinancialEffectUnit?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectUnit")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Value of aggregated financial effects (min)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={FinancialEffectValueMin}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                WhyNoQuanitativeFinancialData,
                QualitativeDescription,
                QuantitativeDescription,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialEffectValueMin: value,
                FinancialEffectValueMax,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValueMin ?? value;
            }
            if (errors.FinancialEffectValueMin?.hasError) {
              runValidationTasks("FinancialEffectValueMin", value);
            }
            setFinancialEffectValueMin(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "FinancialEffectValueMin",
              FinancialEffectValueMin
            )
          }
          errorMessage={errors.FinancialEffectValueMin?.errorMessage}
          hasError={errors.FinancialEffectValueMin?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValueMin")}
        ></TextField>
        <TextField
          label="Value of aggregated financial effects (max)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={FinancialEffectValueMax}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                WhyNoQuanitativeFinancialData,
                QualitativeDescription,
                QuantitativeDescription,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialEffectValueMin,
                FinancialEffectValueMax: value,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValueMax ?? value;
            }
            if (errors.FinancialEffectValueMax?.hasError) {
              runValidationTasks("FinancialEffectValueMax", value);
            }
            setFinancialEffectValueMax(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "FinancialEffectValueMax",
              FinancialEffectValueMax
            )
          }
          errorMessage={errors.FinancialEffectValueMax?.errorMessage}
          hasError={errors.FinancialEffectValueMax?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValueMax")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
