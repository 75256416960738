import HelpBox from "../../shared/content/HelpBox";

export default function ClimateResilienceHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Resilience Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes the Climate Resilience of your organization's strategy and business model to climate-related changes, developments and uncertainties, considering your identified climate-related risks and opportunities.
        </p>
        <p>Follow these steps to define a climate resilience record:</p>
        <ol>
          <li>
            Enter a name and description for the climate resilience record.
          </li>
          <li>
            Select or create and select records for each of the tables in the middle of the window covering: <b>Climate-related Resilience Assessment</b>, <b>Climate-related Scenario Analysis</b>, and <b>Scenario Analysis Assumptions</b>.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
