import HelpBox from "./HelpBox";

export default function AvoidedEmissionAcountEntryHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Avoided Emission Transaction Help</HelpBox.Header>
            <HelpBox.Content>
                <p>This form allows you to create a record of a transaction that avoids creating emissions that would otherwise have occurred.</p>
                <p>Avoided emission transactions have a lifecycle that can be used for planning purposes when deciding how to reduce emissions, as follows:</p>
                <ul>
                    <li><b>Investigating</b> - currently looking at options for an avoided emission transaction, for example conducting feasibility studies into changing a manufacturing process to use fewer CO2e emitting resources.</li>
                    <li><b>Planning</b> - planning has started for implementing the avoided emission transaction. Data captured at this point can be used in transistion planning tradeoff analysis, to determine cost-effective ways to avoid emissions, and reduce overall scoped emissions over time.</li>
                    <li><b>Implementing</b> - the avoided emission transaction is now implementing but not yet complete, and some preliminary data may be available on how many emissions it avoids. This can help fine tune emission reduction analyses and transition planning.</li>
                    <li><b>Implemented</b> - the avoided emission transaction has been implemented, and full data can be recorded on the emissions it avoided. The reduced emissions should be factored in to the scoped emissions recorded as part of a climate-related disclosure.</li>
                </ul>
                <p>Follow these steps to create an <i>Avoided Emission Transaction Entry</i> transaction:</p>
                <ol>
                    <li>Complete all fields in the form on the left hand side of the screen</li>
                    <li>Use the status drop-down to record an avoided emission transaction as it moves across a lifecycle</li>
                    <li>Once the record is created you can edit it, and upload any evidence documenting the record</li>
                    <li>Once all data is entered, click the Create or Update button at the bottom left</li>
                    <li>You can also click Reset to clear any data entered into the form.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
