import HelpBox from "../../shared/content/HelpBox";

export default function ResourcingStrategyDecisionMakingTargetsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Resourcing of Strategy, Decision Making and Targets Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes your organization's plan for resourcing of climate-related Strategy and Decision Making (including Business Model changes, Mitigation and Adaptation efforts, and Transition Plans), and Target achievement plan.
        </p>
        <p>Follow these steps to define your resourcing plan:</p>
        <ol>
          <li>
            Enter a name for the resourcing plan.
          </li>
          <li>
            Enter a description of how you current resource your climate-related strategy and decision making, and target achievement plan.
          </li>
          <li>
            Enter a description of your future resourcing plan for your climate-related strategy and decision making, and target achievement plan.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
