import HelpBox from "../../shared/content/HelpBox";

export default function RisksOpportunitiesHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Risks / Opportunity Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes a specific climate-related risk or opportunity.
        </p>
        <p>Follow these steps to define your risk / opportunity:</p>
        <ol>
          <li>
            Enter/update a name in the left hand side of the window, select from the <i>Risk or opportunity type</i> dropdown menu to define whether this is a risk or opportunity, then provide a description.
          </li>
          <li>
            You can also define the <i>Likelihood of occurring</i> and <i>Priority</i> for the risk/opportunity, which will be used for planning and assessment of effects.
          </li>
          <li>
            Select an option from the <i>Risk or opportunity category</i> dropdown menu to define the nature of the risk or opportunity, then select the <i>Time horizon</i> it will occur within.
          </li>
          <li>
            Once these fields have been completed, you need to specify the financial effects caused by your risk or opportunity. Select one or more records from the <i>Current and Anticipated Financial Effects</i> table in the middle of the screen. You can also create a new effect by clicking the <b>New</b> button in this table.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, select <b>Create</b> or <b>Update </b> at the bottom right of the window to save or update your data.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
