/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { MitigationAdaptationChange } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function MitigationAdaptationChangeUpdateForm(props) {
  const {
    id: idProp,
    mitigationAdaptationChange: mitigationAdaptationChangeModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    CurrentDirectMitigationAdaptationEffort: "",
    AnticipatedDirectMitigationAdaptationEffort: "",
    CurrentIndirectMitigationAdaptationEffort: "",
    AnticipatedIndirectMitigationAdaptationEffort: "",
  };
  const [
    CurrentDirectMitigationAdaptationEffort,
    setCurrentDirectMitigationAdaptationEffort,
  ] = React.useState(initialValues.CurrentDirectMitigationAdaptationEffort);
  const [
    AnticipatedDirectMitigationAdaptationEffort,
    setAnticipatedDirectMitigationAdaptationEffort,
  ] = React.useState(initialValues.AnticipatedDirectMitigationAdaptationEffort);
  const [
    CurrentIndirectMitigationAdaptationEffort,
    setCurrentIndirectMitigationAdaptationEffort,
  ] = React.useState(initialValues.CurrentIndirectMitigationAdaptationEffort);
  const [
    AnticipatedIndirectMitigationAdaptationEffort,
    setAnticipatedIndirectMitigationAdaptationEffort,
  ] = React.useState(
    initialValues.AnticipatedIndirectMitigationAdaptationEffort
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = mitigationAdaptationChangeRecord
      ? { ...initialValues, ...mitigationAdaptationChangeRecord }
      : initialValues;
    setCurrentDirectMitigationAdaptationEffort(
      cleanValues.CurrentDirectMitigationAdaptationEffort
    );
    setAnticipatedDirectMitigationAdaptationEffort(
      cleanValues.AnticipatedDirectMitigationAdaptationEffort
    );
    setCurrentIndirectMitigationAdaptationEffort(
      cleanValues.CurrentIndirectMitigationAdaptationEffort
    );
    setAnticipatedIndirectMitigationAdaptationEffort(
      cleanValues.AnticipatedIndirectMitigationAdaptationEffort
    );
    setErrors({});
  };
  const [
    mitigationAdaptationChangeRecord,
    setMitigationAdaptationChangeRecord,
  ] = React.useState(mitigationAdaptationChangeModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(MitigationAdaptationChange, idProp)
        : mitigationAdaptationChangeModelProp;
      setMitigationAdaptationChangeRecord(record);
    };
    queryData();
  }, [idProp, mitigationAdaptationChangeModelProp]);
  React.useEffect(resetStateValues, [mitigationAdaptationChangeRecord]);
  const validations = {
    CurrentDirectMitigationAdaptationEffort: [],
    AnticipatedDirectMitigationAdaptationEffort: [],
    CurrentIndirectMitigationAdaptationEffort: [],
    AnticipatedIndirectMitigationAdaptationEffort: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CurrentDirectMitigationAdaptationEffort,
          AnticipatedDirectMitigationAdaptationEffort,
          CurrentIndirectMitigationAdaptationEffort,
          AnticipatedIndirectMitigationAdaptationEffort,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            MitigationAdaptationChange.copyOf(
              mitigationAdaptationChangeRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "MitigationAdaptationChangeUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Mitigation and Adaptation"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 14 a ii-iii."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Update this record that defines your current and anticipated direct and indirect mitigation and adaptation efforts."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextAreaField
        label="Current direct mitigation and adaptation efforts"
        isRequired={false}
        isReadOnly={false}
        value={CurrentDirectMitigationAdaptationEffort}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentDirectMitigationAdaptationEffort: value,
              AnticipatedDirectMitigationAdaptationEffort,
              CurrentIndirectMitigationAdaptationEffort,
              AnticipatedIndirectMitigationAdaptationEffort,
            };
            const result = onChange(modelFields);
            value = result?.CurrentDirectMitigationAdaptationEffort ?? value;
          }
          if (errors.CurrentDirectMitigationAdaptationEffort?.hasError) {
            runValidationTasks(
              "CurrentDirectMitigationAdaptationEffort",
              value
            );
          }
          setCurrentDirectMitigationAdaptationEffort(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "CurrentDirectMitigationAdaptationEffort",
            CurrentDirectMitigationAdaptationEffort
          )
        }
        errorMessage={
          errors.CurrentDirectMitigationAdaptationEffort?.errorMessage
        }
        hasError={errors.CurrentDirectMitigationAdaptationEffort?.hasError}
        {...getOverrideProps(
          overrides,
          "CurrentDirectMitigationAdaptationEffort"
        )}
      ></TextAreaField>
      <TextAreaField
        label="Anticipated direct and mitigation adaptation efforts"
        isRequired={false}
        isReadOnly={false}
        value={AnticipatedDirectMitigationAdaptationEffort}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentDirectMitigationAdaptationEffort,
              AnticipatedDirectMitigationAdaptationEffort: value,
              CurrentIndirectMitigationAdaptationEffort,
              AnticipatedIndirectMitigationAdaptationEffort,
            };
            const result = onChange(modelFields);
            value =
              result?.AnticipatedDirectMitigationAdaptationEffort ?? value;
          }
          if (errors.AnticipatedDirectMitigationAdaptationEffort?.hasError) {
            runValidationTasks(
              "AnticipatedDirectMitigationAdaptationEffort",
              value
            );
          }
          setAnticipatedDirectMitigationAdaptationEffort(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AnticipatedDirectMitigationAdaptationEffort",
            AnticipatedDirectMitigationAdaptationEffort
          )
        }
        errorMessage={
          errors.AnticipatedDirectMitigationAdaptationEffort?.errorMessage
        }
        hasError={errors.AnticipatedDirectMitigationAdaptationEffort?.hasError}
        {...getOverrideProps(
          overrides,
          "AnticipatedDirectMitigationAdaptationEffort"
        )}
      ></TextAreaField>
      <TextAreaField
        label="Current indirect mitigation and adaptation efforts"
        isRequired={false}
        isReadOnly={false}
        value={CurrentIndirectMitigationAdaptationEffort}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentDirectMitigationAdaptationEffort,
              AnticipatedDirectMitigationAdaptationEffort,
              CurrentIndirectMitigationAdaptationEffort: value,
              AnticipatedIndirectMitigationAdaptationEffort,
            };
            const result = onChange(modelFields);
            value = result?.CurrentIndirectMitigationAdaptationEffort ?? value;
          }
          if (errors.CurrentIndirectMitigationAdaptationEffort?.hasError) {
            runValidationTasks(
              "CurrentIndirectMitigationAdaptationEffort",
              value
            );
          }
          setCurrentIndirectMitigationAdaptationEffort(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "CurrentIndirectMitigationAdaptationEffort",
            CurrentIndirectMitigationAdaptationEffort
          )
        }
        errorMessage={
          errors.CurrentIndirectMitigationAdaptationEffort?.errorMessage
        }
        hasError={errors.CurrentIndirectMitigationAdaptationEffort?.hasError}
        {...getOverrideProps(
          overrides,
          "CurrentIndirectMitigationAdaptationEffort"
        )}
      ></TextAreaField>
      <TextAreaField
        label="Anticipated indirect mitigation and adaptation efforts"
        isRequired={false}
        isReadOnly={false}
        value={AnticipatedIndirectMitigationAdaptationEffort}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentDirectMitigationAdaptationEffort,
              AnticipatedDirectMitigationAdaptationEffort,
              CurrentIndirectMitigationAdaptationEffort,
              AnticipatedIndirectMitigationAdaptationEffort: value,
            };
            const result = onChange(modelFields);
            value =
              result?.AnticipatedIndirectMitigationAdaptationEffort ?? value;
          }
          if (errors.AnticipatedIndirectMitigationAdaptationEffort?.hasError) {
            runValidationTasks(
              "AnticipatedIndirectMitigationAdaptationEffort",
              value
            );
          }
          setAnticipatedIndirectMitigationAdaptationEffort(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AnticipatedIndirectMitigationAdaptationEffort",
            AnticipatedIndirectMitigationAdaptationEffort
          )
        }
        errorMessage={
          errors.AnticipatedIndirectMitigationAdaptationEffort?.errorMessage
        }
        hasError={
          errors.AnticipatedIndirectMitigationAdaptationEffort?.hasError
        }
        {...getOverrideProps(
          overrides,
          "AnticipatedIndirectMitigationAdaptationEffort"
        )}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || mitigationAdaptationChangeModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || mitigationAdaptationChangeModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
