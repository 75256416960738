/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedTargets } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedTargetsGreenhouseGasEmissionTargetDataUpdateForm(
  props
) {
  const {
    id: idProp,
    climateRelatedTargets: climateRelatedTargetsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GHGCoveredByTarget: "",
    TargetEmissionScope: "",
    TargetEmissionType: "",
    AssociatedGrossGHGEmissionTarget: "",
    SectoralDecarbonisationApproach: "",
    ExtentRelianceOnCarbonCreditsDescription: "",
    ExtentRelianceOnCarbonCredits: "",
    RelianceonOffsetsType: "",
    OtherCarbonCreditFactorsDescription: "",
  };
  const [GHGCoveredByTarget, setGHGCoveredByTarget] = React.useState(
    initialValues.GHGCoveredByTarget
  );
  const [TargetEmissionScope, setTargetEmissionScope] = React.useState(
    initialValues.TargetEmissionScope
  );
  const [TargetEmissionType, setTargetEmissionType] = React.useState(
    initialValues.TargetEmissionType
  );
  const [
    AssociatedGrossGHGEmissionTarget,
    setAssociatedGrossGHGEmissionTarget,
  ] = React.useState(initialValues.AssociatedGrossGHGEmissionTarget);
  const [SectoralDecarbonisationApproach, setSectoralDecarbonisationApproach] =
    React.useState(initialValues.SectoralDecarbonisationApproach);
  const [
    ExtentRelianceOnCarbonCreditsDescription,
    setExtentRelianceOnCarbonCreditsDescription,
  ] = React.useState(initialValues.ExtentRelianceOnCarbonCreditsDescription);
  const [ExtentRelianceOnCarbonCredits, setExtentRelianceOnCarbonCredits] =
    React.useState(initialValues.ExtentRelianceOnCarbonCredits);
  const [RelianceonOffsetsType, setRelianceonOffsetsType] = React.useState(
    initialValues.RelianceonOffsetsType
  );
  const [
    OtherCarbonCreditFactorsDescription,
    setOtherCarbonCreditFactorsDescription,
  ] = React.useState(initialValues.OtherCarbonCreditFactorsDescription);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = climateRelatedTargetsRecord
      ? { ...initialValues, ...climateRelatedTargetsRecord }
      : initialValues;
    setGHGCoveredByTarget(cleanValues.GHGCoveredByTarget);
    setTargetEmissionScope(cleanValues.TargetEmissionScope);
    setTargetEmissionType(cleanValues.TargetEmissionType);
    setAssociatedGrossGHGEmissionTarget(
      cleanValues.AssociatedGrossGHGEmissionTarget
    );
    setSectoralDecarbonisationApproach(
      cleanValues.SectoralDecarbonisationApproach
    );
    setExtentRelianceOnCarbonCreditsDescription(
      cleanValues.ExtentRelianceOnCarbonCreditsDescription
    );
    setExtentRelianceOnCarbonCredits(cleanValues.ExtentRelianceOnCarbonCredits);
    setRelianceonOffsetsType(cleanValues.RelianceonOffsetsType);
    setOtherCarbonCreditFactorsDescription(
      cleanValues.OtherCarbonCreditFactorsDescription
    );
    setErrors({});
  };
  const [climateRelatedTargetsRecord, setClimateRelatedTargetsRecord] =
    React.useState(climateRelatedTargetsModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(ClimateRelatedTargets, idProp)
        : climateRelatedTargetsModelProp;
      setClimateRelatedTargetsRecord(record);
    };
    queryData();
  }, [idProp, climateRelatedTargetsModelProp]);
  React.useEffect(resetStateValues, [climateRelatedTargetsRecord]);
  const validations = {
    GHGCoveredByTarget: [],
    TargetEmissionScope: [],
    TargetEmissionType: [],
    AssociatedGrossGHGEmissionTarget: [],
    SectoralDecarbonisationApproach: [],
    ExtentRelianceOnCarbonCreditsDescription: [],
    ExtentRelianceOnCarbonCredits: [],
    RelianceonOffsetsType: [],
    OtherCarbonCreditFactorsDescription: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GHGCoveredByTarget,
          TargetEmissionScope,
          TargetEmissionType,
          AssociatedGrossGHGEmissionTarget,
          SectoralDecarbonisationApproach,
          ExtentRelianceOnCarbonCreditsDescription,
          ExtentRelianceOnCarbonCredits,
          RelianceonOffsetsType,
          OtherCarbonCreditFactorsDescription,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            ClimateRelatedTargets.copyOf(
              climateRelatedTargetsRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedTargetsGreenhouseGasEmissionTargetDataUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Targets - Greenhouse Gas Target Data"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 36 a-e."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update greenhouse gas data about this target."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <SelectField
        label="Which greenhouse gases are covered by this target?"
        placeholder="Please select an option"
        isDisabled={false}
        value={GHGCoveredByTarget}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget: value,
              TargetEmissionScope,
              TargetEmissionType,
              AssociatedGrossGHGEmissionTarget,
              SectoralDecarbonisationApproach,
              ExtentRelianceOnCarbonCreditsDescription,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription,
            };
            const result = onChange(modelFields);
            value = result?.GHGCoveredByTarget ?? value;
          }
          if (errors.GHGCoveredByTarget?.hasError) {
            runValidationTasks("GHGCoveredByTarget", value);
          }
          setGHGCoveredByTarget(value);
        }}
        onBlur={() =>
          runValidationTasks("GHGCoveredByTarget", GHGCoveredByTarget)
        }
        errorMessage={errors.GHGCoveredByTarget?.errorMessage}
        hasError={errors.GHGCoveredByTarget?.hasError}
        {...getOverrideProps(overrides, "GHGCoveredByTarget")}
      >
        <option
          children="Carbon dioxide"
          value="CarbonDioxide"
          {...getOverrideProps(overrides, "GHGCoveredByTargetoption0")}
        ></option>
        <option
          children="Methane"
          value="Methane"
          {...getOverrideProps(overrides, "GHGCoveredByTargetoption1")}
        ></option>
        <option
          children="Nitrous oxide"
          value="NitrousOxide"
          {...getOverrideProps(overrides, "GHGCoveredByTargetoption2")}
        ></option>
        <option
          children="Hydrofluorocarbons"
          value="Hydrofluorocarbons"
          {...getOverrideProps(overrides, "GHGCoveredByTargetoption3")}
        ></option>
        <option
          children="Perfluorocarbons"
          value="Perfluorocarbons"
          {...getOverrideProps(overrides, "GHGCoveredByTargetoption4")}
        ></option>
        <option
          children="Sulphur hexafluoride"
          value="SulphurHexafluoride"
          {...getOverrideProps(overrides, "GHGCoveredByTargetoption5")}
        ></option>
      </SelectField>
      <SelectField
        label="Select whether Scope 1, Scope 2 or Scope 3 greenhouse gas emissions are covered by this target"
        placeholder="Please select an option"
        isDisabled={false}
        value={TargetEmissionScope}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget,
              TargetEmissionScope: value,
              TargetEmissionType,
              AssociatedGrossGHGEmissionTarget,
              SectoralDecarbonisationApproach,
              ExtentRelianceOnCarbonCreditsDescription,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription,
            };
            const result = onChange(modelFields);
            value = result?.TargetEmissionScope ?? value;
          }
          if (errors.TargetEmissionScope?.hasError) {
            runValidationTasks("TargetEmissionScope", value);
          }
          setTargetEmissionScope(value);
        }}
        onBlur={() =>
          runValidationTasks("TargetEmissionScope", TargetEmissionScope)
        }
        errorMessage={errors.TargetEmissionScope?.errorMessage}
        hasError={errors.TargetEmissionScope?.hasError}
        {...getOverrideProps(overrides, "TargetEmissionScope")}
      >
        <option
          children="Scope 1"
          value="Scope_1"
          {...getOverrideProps(overrides, "TargetEmissionScopeoption0")}
        ></option>
        <option
          children="Scope 2"
          value="Scope_2"
          {...getOverrideProps(overrides, "TargetEmissionScopeoption1")}
        ></option>
        <option
          children="Scope 3"
          value="Scope_3"
          {...getOverrideProps(overrides, "TargetEmissionScopeoption2")}
        ></option>
      </SelectField>
      <SelectField
        label="Select whether this target is a gross greenhouse gas emissions target or net greenhouse gas emissions target"
        placeholder="Please select an option"
        isDisabled={false}
        value={TargetEmissionType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget,
              TargetEmissionScope,
              TargetEmissionType: value,
              AssociatedGrossGHGEmissionTarget,
              SectoralDecarbonisationApproach,
              ExtentRelianceOnCarbonCreditsDescription,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription,
            };
            const result = onChange(modelFields);
            value = result?.TargetEmissionType ?? value;
          }
          if (errors.TargetEmissionType?.hasError) {
            runValidationTasks("TargetEmissionType", value);
          }
          setTargetEmissionType(value);
        }}
        onBlur={() =>
          runValidationTasks("TargetEmissionType", TargetEmissionType)
        }
        errorMessage={errors.TargetEmissionType?.errorMessage}
        hasError={errors.TargetEmissionType?.hasError}
        {...getOverrideProps(overrides, "TargetEmissionType")}
      >
        <option
          children="Gross GHG emission target"
          value="GrossGhgEmissionTarget"
          {...getOverrideProps(overrides, "TargetEmissionTypeoption0")}
        ></option>
        <option
          children="Net GHG emission target"
          value="NetGhgEmissionTarget"
          {...getOverrideProps(overrides, "TargetEmissionTypeoption1")}
        ></option>
      </SelectField>
      <TextAreaField
        label="If this target is a net greenhouse gas emissions target, also disclose the associated gross greenhouse gas emissions target"
        isRequired={false}
        isReadOnly={false}
        value={AssociatedGrossGHGEmissionTarget}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget,
              TargetEmissionScope,
              TargetEmissionType,
              AssociatedGrossGHGEmissionTarget: value,
              SectoralDecarbonisationApproach,
              ExtentRelianceOnCarbonCreditsDescription,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription,
            };
            const result = onChange(modelFields);
            value = result?.AssociatedGrossGHGEmissionTarget ?? value;
          }
          if (errors.AssociatedGrossGHGEmissionTarget?.hasError) {
            runValidationTasks("AssociatedGrossGHGEmissionTarget", value);
          }
          setAssociatedGrossGHGEmissionTarget(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AssociatedGrossGHGEmissionTarget",
            AssociatedGrossGHGEmissionTarget
          )
        }
        errorMessage={errors.AssociatedGrossGHGEmissionTarget?.errorMessage}
        hasError={errors.AssociatedGrossGHGEmissionTarget?.hasError}
        {...getOverrideProps(overrides, "AssociatedGrossGHGEmissionTarget")}
      ></TextAreaField>
      <SelectField
        label="Select whether this target was derived using a sectoral decarbonisation approach"
        placeholder="Please select an option"
        isDisabled={false}
        value={SectoralDecarbonisationApproach}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget,
              TargetEmissionScope,
              TargetEmissionType,
              AssociatedGrossGHGEmissionTarget,
              SectoralDecarbonisationApproach: value,
              ExtentRelianceOnCarbonCreditsDescription,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription,
            };
            const result = onChange(modelFields);
            value = result?.SectoralDecarbonisationApproach ?? value;
          }
          if (errors.SectoralDecarbonisationApproach?.hasError) {
            runValidationTasks("SectoralDecarbonisationApproach", value);
          }
          setSectoralDecarbonisationApproach(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "SectoralDecarbonisationApproach",
            SectoralDecarbonisationApproach
          )
        }
        errorMessage={errors.SectoralDecarbonisationApproach?.errorMessage}
        hasError={errors.SectoralDecarbonisationApproach?.hasError}
        {...getOverrideProps(overrides, "SectoralDecarbonisationApproach")}
      >
        <option
          children="Uses sectoral decarbonisation approach"
          value="UsesSectoralDecarbonisationApproach"
          {...getOverrideProps(
            overrides,
            "SectoralDecarbonisationApproachoption0"
          )}
        ></option>
        <option
          children="Doesnt use sectoral decarbonisation approach"
          value="DoesntUseSectoralDecarbonisationApproach"
          {...getOverrideProps(
            overrides,
            "SectoralDecarbonisationApproachoption1"
          )}
        ></option>
      </SelectField>
      <Text
        children="If you plan to use carbon credits to offset greenhouse gas emissions to achieve any net greenhouse gas emissions target, enter the following information"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <TextAreaField
        label="Describe the extent to which, and how achieving any net greenhouse gas emissions target relies on the use of carbon credits"
        isRequired={false}
        isReadOnly={false}
        value={ExtentRelianceOnCarbonCreditsDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget,
              TargetEmissionScope,
              TargetEmissionType,
              AssociatedGrossGHGEmissionTarget,
              SectoralDecarbonisationApproach,
              ExtentRelianceOnCarbonCreditsDescription: value,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription,
            };
            const result = onChange(modelFields);
            value = result?.ExtentRelianceOnCarbonCreditsDescription ?? value;
          }
          if (errors.ExtentRelianceOnCarbonCreditsDescription?.hasError) {
            runValidationTasks(
              "ExtentRelianceOnCarbonCreditsDescription",
              value
            );
          }
          setExtentRelianceOnCarbonCreditsDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ExtentRelianceOnCarbonCreditsDescription",
            ExtentRelianceOnCarbonCreditsDescription
          )
        }
        errorMessage={
          errors.ExtentRelianceOnCarbonCreditsDescription?.errorMessage
        }
        hasError={errors.ExtentRelianceOnCarbonCreditsDescription?.hasError}
        {...getOverrideProps(
          overrides,
          "ExtentRelianceOnCarbonCreditsDescription"
        )}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <TextField
          label="Amount of carbon credit (tonnes CO2e) to achieve net greenhouse gas emissions target"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={ExtentRelianceOnCarbonCredits}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                GHGCoveredByTarget,
                TargetEmissionScope,
                TargetEmissionType,
                AssociatedGrossGHGEmissionTarget,
                SectoralDecarbonisationApproach,
                ExtentRelianceOnCarbonCreditsDescription,
                ExtentRelianceOnCarbonCredits: value,
                RelianceonOffsetsType,
                OtherCarbonCreditFactorsDescription,
              };
              const result = onChange(modelFields);
              value = result?.ExtentRelianceOnCarbonCredits ?? value;
            }
            if (errors.ExtentRelianceOnCarbonCredits?.hasError) {
              runValidationTasks("ExtentRelianceOnCarbonCredits", value);
            }
            setExtentRelianceOnCarbonCredits(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ExtentRelianceOnCarbonCredits",
              ExtentRelianceOnCarbonCredits
            )
          }
          errorMessage={errors.ExtentRelianceOnCarbonCredits?.errorMessage}
          hasError={errors.ExtentRelianceOnCarbonCredits?.hasError}
          {...getOverrideProps(overrides, "ExtentRelianceOnCarbonCredits")}
        ></TextField>
        <SelectField
          label="Percent or amount of carbon credit"
          placeholder="Please select an option"
          isDisabled={false}
          value={RelianceonOffsetsType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                GHGCoveredByTarget,
                TargetEmissionScope,
                TargetEmissionType,
                AssociatedGrossGHGEmissionTarget,
                SectoralDecarbonisationApproach,
                ExtentRelianceOnCarbonCreditsDescription,
                ExtentRelianceOnCarbonCredits,
                RelianceonOffsetsType: value,
                OtherCarbonCreditFactorsDescription,
              };
              const result = onChange(modelFields);
              value = result?.RelianceonOffsetsType ?? value;
            }
            if (errors.RelianceonOffsetsType?.hasError) {
              runValidationTasks("RelianceonOffsetsType", value);
            }
            setRelianceonOffsetsType(value);
          }}
          onBlur={() =>
            runValidationTasks("RelianceonOffsetsType", RelianceonOffsetsType)
          }
          errorMessage={errors.RelianceonOffsetsType?.errorMessage}
          hasError={errors.RelianceonOffsetsType?.hasError}
          {...getOverrideProps(overrides, "RelianceonOffsetsType")}
        >
          <option
            children="Percentage"
            value="Percentage"
            {...getOverrideProps(overrides, "RelianceonOffsetsTypeoption0")}
          ></option>
          <option
            children="Value"
            value="Value"
            {...getOverrideProps(overrides, "RelianceonOffsetsTypeoption1")}
          ></option>
          <option
            children="None"
            value="None"
            {...getOverrideProps(overrides, "RelianceonOffsetsTypeoption2")}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Describe any other factors necessary to understand the credibility and integrity of the carbon credits you plan to use"
        isRequired={false}
        isReadOnly={false}
        value={OtherCarbonCreditFactorsDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGCoveredByTarget,
              TargetEmissionScope,
              TargetEmissionType,
              AssociatedGrossGHGEmissionTarget,
              SectoralDecarbonisationApproach,
              ExtentRelianceOnCarbonCreditsDescription,
              ExtentRelianceOnCarbonCredits,
              RelianceonOffsetsType,
              OtherCarbonCreditFactorsDescription: value,
            };
            const result = onChange(modelFields);
            value = result?.OtherCarbonCreditFactorsDescription ?? value;
          }
          if (errors.OtherCarbonCreditFactorsDescription?.hasError) {
            runValidationTasks("OtherCarbonCreditFactorsDescription", value);
          }
          setOtherCarbonCreditFactorsDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "OtherCarbonCreditFactorsDescription",
            OtherCarbonCreditFactorsDescription
          )
        }
        errorMessage={errors.OtherCarbonCreditFactorsDescription?.errorMessage}
        hasError={errors.OtherCarbonCreditFactorsDescription?.hasError}
        {...getOverrideProps(overrides, "OtherCarbonCreditFactorsDescription")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || climateRelatedTargetsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || climateRelatedTargetsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
