import HelpBox from "../../shared/content/HelpBox";

export default function ClimateScenarioAnalysisHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Scenario Analysis Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes the Climate-related Scenario Analyses, which inform your response to climate-related risks and opportunities.
        </p>
        <p>Follow these steps to define a climate-related scenario analysis record:</p>
        <ol>
          <li>Enter a name and description for the scenario.
          </li>
          <li>
            Select the analysis method used to create the scenario, and the temperature alignment the scenario is targetting.
          </li>
          <li>
            Describe the data sources used to construct the scenario.
          </li>
          <li>
            Select an option from the <i>Association with risks</i> dropdown menu to show whether the climate-related scenario is associated with climate-related transition risks or climate-related physical risks.
          </li>
          <li>
            Describe whether the scenario is aligned with the latest international agreement on climate change.
          </li>
          <li>
            Describe whether the scenario is relevant to assessing your organization's resilience to climate-related changes, developments or uncertainties.
          </li>
          <li>
            Select a time horizon from the <i>Climate planning horizon</i> dropdown menu, and then select the scope of operations used in the analysis from the <i>Scope of operations</i> dropdown menu.
          </li>
          <li>
            If required, you can add extra detail on the scope of operations in the field below.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
