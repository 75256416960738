import HelpBox from "../../shared/content/HelpBox";

export default function ManagementRoleInClimateGovernanceHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Management Role in Climate Governance Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes the role that Management plays in climate governance, which includes the governance processes, controls and procedures used to monitor, manage and oversee climate-related risks and opportunities.
        </p>
        <p>
          Follow these steps to define the Management Role in Climate Governance:
        </p>
        <ol>
          <li>
            Enter a name and description for the management governance role.
          </li>
          <li>
            Describe how management's role in climate-related governance is delegated.
          </li>
          <li>
            If used in your organization, describe the controls and procedures management uses to support oversight of climate-related risks and opportunities.
          </li>
          <li>
            If management uses controls and procedures, describe how they are integrated with other internal functions.
          </li>
          <li>
            Enter data into the table in the middle of the window to define process(es) used for oversight over management's role in climate-related governance.
          </li>
          <li>You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
