import HelpBox from "../../shared/content/HelpBox";

export default function TargetAchievementPlanHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Target Achievement Plan Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter and update data on your organization's Target Achievement Plan, describing how to achieve any climate-related targets, including any greenhouse gas emissions targets.
        </p>
        <p>Follow these steps to define your target achievement plan:</p>
        <ol>
          <li>
            Enter a name and description for the target achievement plan.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
