/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { EmissionAccountEntry } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function EmissionAccountEntryCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    EmissionScope: "",
    EmissionCategory: "",
    EmissionGroup: "",
    DataSource: "",
    EmissionDescription: "",
    EmissionUnit: "",
    EmissionUsageValue: "",
    EmissionConversionFactor: "",
    GrossEmission: "",
    EmissionDate: "",
    EmissionDateRangeStart: "",
    EmissionDateRangeEnd: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [EmissionScope, setEmissionScope] = React.useState(
    initialValues.EmissionScope
  );
  const [EmissionCategory, setEmissionCategory] = React.useState(
    initialValues.EmissionCategory
  );
  const [EmissionGroup, setEmissionGroup] = React.useState(
    initialValues.EmissionGroup
  );
  const [DataSource, setDataSource] = React.useState(initialValues.DataSource);
  const [EmissionDescription, setEmissionDescription] = React.useState(
    initialValues.EmissionDescription
  );
  const [EmissionUnit, setEmissionUnit] = React.useState(
    initialValues.EmissionUnit
  );
  const [EmissionUsageValue, setEmissionUsageValue] = React.useState(
    initialValues.EmissionUsageValue
  );
  const [EmissionConversionFactor, setEmissionConversionFactor] =
    React.useState(initialValues.EmissionConversionFactor);
  const [GrossEmission, setGrossEmission] = React.useState(
    initialValues.GrossEmission
  );
  const [EmissionDate, setEmissionDate] = React.useState(
    initialValues.EmissionDate
  );
  const [EmissionDateRangeStart, setEmissionDateRangeStart] = React.useState(
    initialValues.EmissionDateRangeStart
  );
  const [EmissionDateRangeEnd, setEmissionDateRangeEnd] = React.useState(
    initialValues.EmissionDateRangeEnd
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setEmissionScope(initialValues.EmissionScope);
    setEmissionCategory(initialValues.EmissionCategory);
    setEmissionGroup(initialValues.EmissionGroup);
    setDataSource(initialValues.DataSource);
    setEmissionDescription(initialValues.EmissionDescription);
    setEmissionUnit(initialValues.EmissionUnit);
    setEmissionUsageValue(initialValues.EmissionUsageValue);
    setEmissionConversionFactor(initialValues.EmissionConversionFactor);
    setGrossEmission(initialValues.GrossEmission);
    setEmissionDate(initialValues.EmissionDate);
    setEmissionDateRangeStart(initialValues.EmissionDateRangeStart);
    setEmissionDateRangeEnd(initialValues.EmissionDateRangeEnd);
    setErrors({});
  };
  const validations = {
    EmissionScope: [],
    EmissionCategory: [],
    EmissionGroup: [],
    DataSource: [],
    EmissionDescription: [],
    EmissionUnit: [],
    EmissionUsageValue: [],
    EmissionConversionFactor: [],
    GrossEmission: [],
    EmissionDate: [],
    EmissionDateRangeStart: [],
    EmissionDateRangeEnd: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          EmissionScope,
          EmissionCategory,
          EmissionGroup,
          DataSource,
          EmissionDescription,
          EmissionUnit,
          EmissionUsageValue,
          EmissionConversionFactor,
          GrossEmission,
          EmissionDate,
          EmissionDateRangeStart,
          EmissionDateRangeEnd,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new EmissionAccountEntry(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "EmissionAccountEntryCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Greenhouse Gas Emission Transaction"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Create a record of a Greenhouse Gas emission transaction you are responsible for."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <SelectField
          label="Emission scope"
          placeholder="Please select an option"
          isDisabled={false}
          value={EmissionScope}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope: value,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionScope ?? value;
            }
            if (errors.EmissionScope?.hasError) {
              runValidationTasks("EmissionScope", value);
            }
            setEmissionScope(value);
          }}
          onBlur={() => runValidationTasks("EmissionScope", EmissionScope)}
          errorMessage={errors.EmissionScope?.errorMessage}
          hasError={errors.EmissionScope?.hasError}
          {...getOverrideProps(overrides, "EmissionScope")}
        >
          <option
            children="Scope 1"
            value="Scope_1"
            {...getOverrideProps(overrides, "EmissionScopeoption0")}
          ></option>
          <option
            children="Scope 2"
            value="Scope_2"
            {...getOverrideProps(overrides, "EmissionScopeoption1")}
          ></option>
          <option
            children="Scope 3"
            value="Scope_3"
            {...getOverrideProps(overrides, "EmissionScopeoption2")}
          ></option>
        </SelectField>
        <SelectField
          label="Emission category"
          placeholder="Please select an option"
          isDisabled={false}
          value={EmissionCategory}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory: value,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionCategory ?? value;
            }
            if (errors.EmissionCategory?.hasError) {
              runValidationTasks("EmissionCategory", value);
            }
            setEmissionCategory(value);
          }}
          onBlur={() =>
            runValidationTasks("EmissionCategory", EmissionCategory)
          }
          errorMessage={errors.EmissionCategory?.errorMessage}
          hasError={errors.EmissionCategory?.hasError}
          {...getOverrideProps(overrides, "EmissionCategory")}
        >
          <option
            children="Category1"
            value="Category1"
            {...getOverrideProps(overrides, "EmissionCategoryoption0")}
          ></option>
          <option
            children="Category2"
            value="Category2"
            {...getOverrideProps(overrides, "EmissionCategoryoption1")}
          ></option>
          <option
            children="Category3"
            value="Category3"
            {...getOverrideProps(overrides, "EmissionCategoryoption2")}
          ></option>
          <option
            children="Category4"
            value="Category4"
            {...getOverrideProps(overrides, "EmissionCategoryoption3")}
          ></option>
          <option
            children="Category5"
            value="Category5"
            {...getOverrideProps(overrides, "EmissionCategoryoption4")}
          ></option>
          <option
            children="Category6"
            value="Category6"
            {...getOverrideProps(overrides, "EmissionCategoryoption5")}
          ></option>
          <option
            children="Category7"
            value="Category7"
            {...getOverrideProps(overrides, "EmissionCategoryoption6")}
          ></option>
          <option
            children="Category8"
            value="Category8"
            {...getOverrideProps(overrides, "EmissionCategoryoption7")}
          ></option>
          <option
            children="Category9"
            value="Category9"
            {...getOverrideProps(overrides, "EmissionCategoryoption8")}
          ></option>
          <option
            children="Category10"
            value="Category10"
            {...getOverrideProps(overrides, "EmissionCategoryoption9")}
          ></option>
          <option
            children="Category11"
            value="Category11"
            {...getOverrideProps(overrides, "EmissionCategoryoption10")}
          ></option>
          <option
            children="Category12"
            value="Category12"
            {...getOverrideProps(overrides, "EmissionCategoryoption11")}
          ></option>
          <option
            children="Category13"
            value="Category13"
            {...getOverrideProps(overrides, "EmissionCategoryoption12")}
          ></option>
          <option
            children="Category14"
            value="Category14"
            {...getOverrideProps(overrides, "EmissionCategoryoption13")}
          ></option>
          <option
            children="Category15"
            value="Category15"
            {...getOverrideProps(overrides, "EmissionCategoryoption14")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Emission group"
          isRequired={false}
          isReadOnly={false}
          value={EmissionGroup}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup: value,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionGroup ?? value;
            }
            if (errors.EmissionGroup?.hasError) {
              runValidationTasks("EmissionGroup", value);
            }
            setEmissionGroup(value);
          }}
          onBlur={() => runValidationTasks("EmissionGroup", EmissionGroup)}
          errorMessage={errors.EmissionGroup?.errorMessage}
          hasError={errors.EmissionGroup?.hasError}
          {...getOverrideProps(overrides, "EmissionGroup")}
        ></TextField>
        <TextField
          label="Data source"
          isRequired={false}
          isReadOnly={false}
          value={DataSource}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource: value,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.DataSource ?? value;
            }
            if (errors.DataSource?.hasError) {
              runValidationTasks("DataSource", value);
            }
            setDataSource(value);
          }}
          onBlur={() => runValidationTasks("DataSource", DataSource)}
          errorMessage={errors.DataSource?.errorMessage}
          hasError={errors.DataSource?.hasError}
          {...getOverrideProps(overrides, "DataSource")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Emission description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              EmissionScope,
              EmissionCategory,
              EmissionGroup,
              DataSource,
              EmissionDescription: value,
              EmissionUnit,
              EmissionUsageValue,
              EmissionConversionFactor,
              GrossEmission,
              EmissionDate,
              EmissionDateRangeStart,
              EmissionDateRangeEnd,
            };
            const result = onChange(modelFields);
            value = result?.EmissionDescription ?? value;
          }
          if (errors.EmissionDescription?.hasError) {
            runValidationTasks("EmissionDescription", value);
          }
          setEmissionDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("EmissionDescription", EmissionDescription)
        }
        errorMessage={errors.EmissionDescription?.errorMessage}
        hasError={errors.EmissionDescription?.hasError}
        	value={EmissionDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "EmissionDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Emission unit"
          placeholder="Please select an option"
          isDisabled={false}
          value={EmissionUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit: value,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionUnit ?? value;
            }
            if (errors.EmissionUnit?.hasError) {
              runValidationTasks("EmissionUnit", value);
            }
            setEmissionUnit(value);
          }}
          onBlur={() => runValidationTasks("EmissionUnit", EmissionUnit)}
          errorMessage={errors.EmissionUnit?.errorMessage}
          hasError={errors.EmissionUnit?.hasError}
          {...getOverrideProps(overrides, "EmissionUnit")}
        >
          <option
            children="Employees"
            value="Employees"
            {...getOverrideProps(overrides, "EmissionUnitoption0")}
          ></option>
          <option
            children="Kg"
            value="Kg"
            {...getOverrideProps(overrides, "EmissionUnitoption1")}
          ></option>
          <option
            children="Kw"
            value="Kw"
            {...getOverrideProps(overrides, "EmissionUnitoption2")}
          ></option>
          <option
            children="Kwh"
            value="Kwh"
            {...getOverrideProps(overrides, "EmissionUnitoption3")}
          ></option>
          <option
            children="Litres"
            value="Litres"
            {...getOverrideProps(overrides, "EmissionUnitoption4")}
          ></option>
          <option
            children="Metres cubed"
            value="MetresCubed"
            {...getOverrideProps(overrides, "EmissionUnitoption5")}
          ></option>
          <option
            children="Million dollars revenue"
            value="MillionDollarsRevenue"
            {...getOverrideProps(overrides, "EmissionUnitoption6")}
          ></option>
          <option
            children="Nights"
            value="Nights"
            {...getOverrideProps(overrides, "EmissionUnitoption7")}
          ></option>
          <option
            children="Passenger per km"
            value="PassengerPerKm"
            {...getOverrideProps(overrides, "EmissionUnitoption8")}
          ></option>
          <option
            children="Per capita"
            value="PerCapita"
            {...getOverrideProps(overrides, "EmissionUnitoption9")}
          ></option>
          <option
            children="Percentage"
            value="Percentage"
            {...getOverrideProps(overrides, "EmissionUnitoption10")}
          ></option>
          <option
            children="Quantity"
            value="Quantity"
            {...getOverrideProps(overrides, "EmissionUnitoption11")}
          ></option>
          <option
            children="Tonnes"
            value="Tonnes"
            {...getOverrideProps(overrides, "EmissionUnitoption12")}
          ></option>
          <option
            children="Tonnes per km"
            value="TonnesPerKm"
            {...getOverrideProps(overrides, "EmissionUnitoption13")}
          ></option>
        </SelectField>
        <TextField
          label="Emission usage value (e.g. how many litres of fuel used)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={EmissionUsageValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue: value,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionUsageValue ?? value;
            }
            if (errors.EmissionUsageValue?.hasError) {
              runValidationTasks("EmissionUsageValue", value);
            }
            setEmissionUsageValue(value);
          }}
          onBlur={() =>
            runValidationTasks("EmissionUsageValue", EmissionUsageValue)
          }
          errorMessage={errors.EmissionUsageValue?.errorMessage}
          hasError={errors.EmissionUsageValue?.hasError}
          {...getOverrideProps(overrides, "EmissionUsageValue")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Emission conversion factor"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={EmissionConversionFactor}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor: value,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionConversionFactor ?? value;
            }
            if (errors.EmissionConversionFactor?.hasError) {
              runValidationTasks("EmissionConversionFactor", value);
            }
            setEmissionConversionFactor(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "EmissionConversionFactor",
              EmissionConversionFactor
            )
          }
          errorMessage={errors.EmissionConversionFactor?.errorMessage}
          hasError={errors.EmissionConversionFactor?.hasError}
          {...getOverrideProps(overrides, "EmissionConversionFactor")}
        ></TextField>
        <TextField
          label="Gross emission (tonnes CO2e)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={GrossEmission}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission: value,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.GrossEmission ?? value;
            }
            if (errors.GrossEmission?.hasError) {
              runValidationTasks("GrossEmission", value);
            }
            setGrossEmission(value);
          }}
          onBlur={() => runValidationTasks("GrossEmission", GrossEmission)}
          errorMessage={errors.GrossEmission?.errorMessage}
          hasError={errors.GrossEmission?.hasError}
          {...getOverrideProps(overrides, "GrossEmission")}
        ></TextField>
      </Grid>
      <TextField
        label="Emission date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={EmissionDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              EmissionScope,
              EmissionCategory,
              EmissionGroup,
              DataSource,
              EmissionDescription,
              EmissionUnit,
              EmissionUsageValue,
              EmissionConversionFactor,
              GrossEmission,
              EmissionDate: value,
              EmissionDateRangeStart,
              EmissionDateRangeEnd,
            };
            const result = onChange(modelFields);
            value = result?.EmissionDate ?? value;
          }
          if (errors.EmissionDate?.hasError) {
            runValidationTasks("EmissionDate", value);
          }
          setEmissionDate(value);
        }}
        onBlur={() => runValidationTasks("EmissionDate", EmissionDate)}
        errorMessage={errors.EmissionDate?.errorMessage}
        hasError={errors.EmissionDate?.hasError}
        {...getOverrideProps(overrides, "EmissionDate")}
      ></TextField>
      <Text
        children="If your emissions occurred over a date or is estimated over a period, use the date range fields below."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Emission date range start"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={EmissionDateRangeStart}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart: value,
                EmissionDateRangeEnd,
              };
              const result = onChange(modelFields);
              value = result?.EmissionDateRangeStart ?? value;
            }
            if (errors.EmissionDateRangeStart?.hasError) {
              runValidationTasks("EmissionDateRangeStart", value);
            }
            setEmissionDateRangeStart(value);
          }}
          onBlur={() =>
            runValidationTasks("EmissionDateRangeStart", EmissionDateRangeStart)
          }
          errorMessage={errors.EmissionDateRangeStart?.errorMessage}
          hasError={errors.EmissionDateRangeStart?.hasError}
          {...getOverrideProps(overrides, "EmissionDateRangeStart")}
        ></TextField>
        <TextField
          label="Emission date range end"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={EmissionDateRangeEnd}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                EmissionScope,
                EmissionCategory,
                EmissionGroup,
                DataSource,
                EmissionDescription,
                EmissionUnit,
                EmissionUsageValue,
                EmissionConversionFactor,
                GrossEmission,
                EmissionDate,
                EmissionDateRangeStart,
                EmissionDateRangeEnd: value,
              };
              const result = onChange(modelFields);
              value = result?.EmissionDateRangeEnd ?? value;
            }
            if (errors.EmissionDateRangeEnd?.hasError) {
              runValidationTasks("EmissionDateRangeEnd", value);
            }
            setEmissionDateRangeEnd(value);
          }}
          onBlur={() =>
            runValidationTasks("EmissionDateRangeEnd", EmissionDateRangeEnd)
          }
          errorMessage={errors.EmissionDateRangeEnd?.errorMessage}
          hasError={errors.EmissionDateRangeEnd?.hasError}
          {...getOverrideProps(overrides, "EmissionDateRangeEnd")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
