/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedMetrics } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedMetricsRiskOpportunityMetricsCreateForm(
  props
) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    MetricDataSetName: "",
    TransitionRisksAssetActivityType: "",
    TransitionRisksAmount: "",
    TransitionRisksPercentage: "",
    PhysicalRisksAssetActivityType: "",
    PhysicalRisksAmount: "",
    PhysicalRisksPercentage: "",
    OpportunitiesAssetActivityType: "",
    OpportunitiesAmount: "",
    OpportunitiesPercentage: "",
    CapitalDeploymentValue: "",
    InternalCarbonPriceDecisionExplanation: "",
    InternalCarbonPrice: "",
    RemunerationDescription: "",
    RemunerationPercentageValue: "",
  };
  const [MetricDataSetName, setMetricDataSetName] = React.useState(
    initialValues.MetricDataSetName
  );
  const [
    TransitionRisksAssetActivityType,
    setTransitionRisksAssetActivityType,
  ] = React.useState(initialValues.TransitionRisksAssetActivityType);
  const [TransitionRisksAmount, setTransitionRisksAmount] = React.useState(
    initialValues.TransitionRisksAmount
  );
  const [TransitionRisksPercentage, setTransitionRisksPercentage] =
    React.useState(initialValues.TransitionRisksPercentage);
  const [PhysicalRisksAssetActivityType, setPhysicalRisksAssetActivityType] =
    React.useState(initialValues.PhysicalRisksAssetActivityType);
  const [PhysicalRisksAmount, setPhysicalRisksAmount] = React.useState(
    initialValues.PhysicalRisksAmount
  );
  const [PhysicalRisksPercentage, setPhysicalRisksPercentage] = React.useState(
    initialValues.PhysicalRisksPercentage
  );
  const [OpportunitiesAssetActivityType, setOpportunitiesAssetActivityType] =
    React.useState(initialValues.OpportunitiesAssetActivityType);
  const [OpportunitiesAmount, setOpportunitiesAmount] = React.useState(
    initialValues.OpportunitiesAmount
  );
  const [OpportunitiesPercentage, setOpportunitiesPercentage] = React.useState(
    initialValues.OpportunitiesPercentage
  );
  const [CapitalDeploymentValue, setCapitalDeploymentValue] = React.useState(
    initialValues.CapitalDeploymentValue
  );
  const [
    InternalCarbonPriceDecisionExplanation,
    setInternalCarbonPriceDecisionExplanation,
  ] = React.useState(initialValues.InternalCarbonPriceDecisionExplanation);
  const [InternalCarbonPrice, setInternalCarbonPrice] = React.useState(
    initialValues.InternalCarbonPrice
  );
  const [RemunerationDescription, setRemunerationDescription] = React.useState(
    initialValues.RemunerationDescription
  );
  const [RemunerationPercentageValue, setRemunerationPercentageValue] =
    React.useState(initialValues.RemunerationPercentageValue);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setMetricDataSetName(initialValues.MetricDataSetName);
    setTransitionRisksAssetActivityType(
      initialValues.TransitionRisksAssetActivityType
    );
    setTransitionRisksAmount(initialValues.TransitionRisksAmount);
    setTransitionRisksPercentage(initialValues.TransitionRisksPercentage);
    setPhysicalRisksAssetActivityType(
      initialValues.PhysicalRisksAssetActivityType
    );
    setPhysicalRisksAmount(initialValues.PhysicalRisksAmount);
    setPhysicalRisksPercentage(initialValues.PhysicalRisksPercentage);
    setOpportunitiesAssetActivityType(
      initialValues.OpportunitiesAssetActivityType
    );
    setOpportunitiesAmount(initialValues.OpportunitiesAmount);
    setOpportunitiesPercentage(initialValues.OpportunitiesPercentage);
    setCapitalDeploymentValue(initialValues.CapitalDeploymentValue);
    setInternalCarbonPriceDecisionExplanation(
      initialValues.InternalCarbonPriceDecisionExplanation
    );
    setInternalCarbonPrice(initialValues.InternalCarbonPrice);
    setRemunerationDescription(initialValues.RemunerationDescription);
    setRemunerationPercentageValue(initialValues.RemunerationPercentageValue);
    setErrors({});
  };
  const validations = {
    MetricDataSetName: [],
    TransitionRisksAssetActivityType: [],
    TransitionRisksAmount: [],
    TransitionRisksPercentage: [],
    PhysicalRisksAssetActivityType: [],
    PhysicalRisksAmount: [],
    PhysicalRisksPercentage: [],
    OpportunitiesAssetActivityType: [],
    OpportunitiesAmount: [],
    OpportunitiesPercentage: [],
    CapitalDeploymentValue: [],
    InternalCarbonPriceDecisionExplanation: [],
    InternalCarbonPrice: [],
    RemunerationDescription: [],
    RemunerationPercentageValue: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          MetricDataSetName,
          TransitionRisksAssetActivityType,
          TransitionRisksAmount,
          TransitionRisksPercentage,
          PhysicalRisksAssetActivityType,
          PhysicalRisksAmount,
          PhysicalRisksPercentage,
          OpportunitiesAssetActivityType,
          OpportunitiesAmount,
          OpportunitiesPercentage,
          CapitalDeploymentValue,
          InternalCarbonPriceDecisionExplanation,
          InternalCarbonPrice,
          RemunerationDescription,
          RemunerationPercentageValue,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateRelatedMetrics(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedMetricsRiskOpportunityMetricsCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Metrics - Risks, Opportunities, Capital Deployed, Carbon Price and Remuneration"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 29 b-g."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Create a record describing cross-industry metrics for climate-related risks, opportunities, capital deployment, carbon price and remuneration."
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <TextField
        label="Metric record name (e.g. 2025 risks, opportunities and capital metrics)"
        isRequired={false}
        isReadOnly={false}
        value={MetricDataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName: value,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.MetricDataSetName ?? value;
          }
          if (errors.MetricDataSetName?.hasError) {
            runValidationTasks("MetricDataSetName", value);
          }
          setMetricDataSetName(value);
        }}
        onBlur={() =>
          runValidationTasks("MetricDataSetName", MetricDataSetName)
        }
        errorMessage={errors.MetricDataSetName?.errorMessage}
        hasError={errors.MetricDataSetName?.hasError}
        {...getOverrideProps(overrides, "MetricDataSetName")}
      ></TextField>
      <SelectField
        label="Transition risks asset activity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={TransitionRisksAssetActivityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType: value,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.TransitionRisksAssetActivityType ?? value;
          }
          if (errors.TransitionRisksAssetActivityType?.hasError) {
            runValidationTasks("TransitionRisksAssetActivityType", value);
          }
          setTransitionRisksAssetActivityType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TransitionRisksAssetActivityType",
            TransitionRisksAssetActivityType
          )
        }
        errorMessage={errors.TransitionRisksAssetActivityType?.errorMessage}
        hasError={errors.TransitionRisksAssetActivityType?.hasError}
        {...getOverrideProps(overrides, "TransitionRisksAssetActivityType")}
      >
        <option
          children="Business activity"
          value="BusinessActivity"
          {...getOverrideProps(
            overrides,
            "TransitionRisksAssetActivityTypeoption0"
          )}
        ></option>
        <option
          children="Asset"
          value="Asset"
          {...getOverrideProps(
            overrides,
            "TransitionRisksAssetActivityTypeoption1"
          )}
        ></option>
      </SelectField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Amount of assets or business activities vulnerable to transition risks"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={TransitionRisksAmount}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                TransitionRisksAssetActivityType,
                TransitionRisksAmount: value,
                TransitionRisksPercentage,
                PhysicalRisksAssetActivityType,
                PhysicalRisksAmount,
                PhysicalRisksPercentage,
                OpportunitiesAssetActivityType,
                OpportunitiesAmount,
                OpportunitiesPercentage,
                CapitalDeploymentValue,
                InternalCarbonPriceDecisionExplanation,
                InternalCarbonPrice,
                RemunerationDescription,
                RemunerationPercentageValue,
              };
              const result = onChange(modelFields);
              value = result?.TransitionRisksAmount ?? value;
            }
            if (errors.TransitionRisksAmount?.hasError) {
              runValidationTasks("TransitionRisksAmount", value);
            }
            setTransitionRisksAmount(value);
          }}
          onBlur={() =>
            runValidationTasks("TransitionRisksAmount", TransitionRisksAmount)
          }
          errorMessage={errors.TransitionRisksAmount?.errorMessage}
          hasError={errors.TransitionRisksAmount?.hasError}
          {...getOverrideProps(overrides, "TransitionRisksAmount")}
        ></TextField>
        <TextField
          label="Percentage of assets or business activities vulnerable to transition risks"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={TransitionRisksPercentage}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                TransitionRisksAssetActivityType,
                TransitionRisksAmount,
                TransitionRisksPercentage: value,
                PhysicalRisksAssetActivityType,
                PhysicalRisksAmount,
                PhysicalRisksPercentage,
                OpportunitiesAssetActivityType,
                OpportunitiesAmount,
                OpportunitiesPercentage,
                CapitalDeploymentValue,
                InternalCarbonPriceDecisionExplanation,
                InternalCarbonPrice,
                RemunerationDescription,
                RemunerationPercentageValue,
              };
              const result = onChange(modelFields);
              value = result?.TransitionRisksPercentage ?? value;
            }
            if (errors.TransitionRisksPercentage?.hasError) {
              runValidationTasks("TransitionRisksPercentage", value);
            }
            setTransitionRisksPercentage(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "TransitionRisksPercentage",
              TransitionRisksPercentage
            )
          }
          errorMessage={errors.TransitionRisksPercentage?.errorMessage}
          hasError={errors.TransitionRisksPercentage?.hasError}
          {...getOverrideProps(overrides, "TransitionRisksPercentage")}
        ></TextField>
      </Grid>
      <SelectField
        label="Physical risks asset activity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={PhysicalRisksAssetActivityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType: value,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.PhysicalRisksAssetActivityType ?? value;
          }
          if (errors.PhysicalRisksAssetActivityType?.hasError) {
            runValidationTasks("PhysicalRisksAssetActivityType", value);
          }
          setPhysicalRisksAssetActivityType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "PhysicalRisksAssetActivityType",
            PhysicalRisksAssetActivityType
          )
        }
        errorMessage={errors.PhysicalRisksAssetActivityType?.errorMessage}
        hasError={errors.PhysicalRisksAssetActivityType?.hasError}
        {...getOverrideProps(overrides, "PhysicalRisksAssetActivityType")}
      >
        <option
          children="Business activity"
          value="BusinessActivity"
          {...getOverrideProps(
            overrides,
            "PhysicalRisksAssetActivityTypeoption0"
          )}
        ></option>
        <option
          children="Asset"
          value="Asset"
          {...getOverrideProps(
            overrides,
            "PhysicalRisksAssetActivityTypeoption1"
          )}
        ></option>
      </SelectField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Amount of assets or business activities vulnerable to physical risks  "
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={PhysicalRisksAmount}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                TransitionRisksAssetActivityType,
                TransitionRisksAmount,
                TransitionRisksPercentage,
                PhysicalRisksAssetActivityType,
                PhysicalRisksAmount: value,
                PhysicalRisksPercentage,
                OpportunitiesAssetActivityType,
                OpportunitiesAmount,
                OpportunitiesPercentage,
                CapitalDeploymentValue,
                InternalCarbonPriceDecisionExplanation,
                InternalCarbonPrice,
                RemunerationDescription,
                RemunerationPercentageValue,
              };
              const result = onChange(modelFields);
              value = result?.PhysicalRisksAmount ?? value;
            }
            if (errors.PhysicalRisksAmount?.hasError) {
              runValidationTasks("PhysicalRisksAmount", value);
            }
            setPhysicalRisksAmount(value);
          }}
          onBlur={() =>
            runValidationTasks("PhysicalRisksAmount", PhysicalRisksAmount)
          }
          errorMessage={errors.PhysicalRisksAmount?.errorMessage}
          hasError={errors.PhysicalRisksAmount?.hasError}
          {...getOverrideProps(overrides, "PhysicalRisksAmount")}
        ></TextField>
        <TextField
          label="Percentage of assets or business activities vulnerable to physical risks"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={PhysicalRisksPercentage}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                TransitionRisksAssetActivityType,
                TransitionRisksAmount,
                TransitionRisksPercentage,
                PhysicalRisksAssetActivityType,
                PhysicalRisksAmount,
                PhysicalRisksPercentage: value,
                OpportunitiesAssetActivityType,
                OpportunitiesAmount,
                OpportunitiesPercentage,
                CapitalDeploymentValue,
                InternalCarbonPriceDecisionExplanation,
                InternalCarbonPrice,
                RemunerationDescription,
                RemunerationPercentageValue,
              };
              const result = onChange(modelFields);
              value = result?.PhysicalRisksPercentage ?? value;
            }
            if (errors.PhysicalRisksPercentage?.hasError) {
              runValidationTasks("PhysicalRisksPercentage", value);
            }
            setPhysicalRisksPercentage(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "PhysicalRisksPercentage",
              PhysicalRisksPercentage
            )
          }
          errorMessage={errors.PhysicalRisksPercentage?.errorMessage}
          hasError={errors.PhysicalRisksPercentage?.hasError}
          {...getOverrideProps(overrides, "PhysicalRisksPercentage")}
        ></TextField>
      </Grid>
      <SelectField
        label="Opportunities asset activity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={OpportunitiesAssetActivityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType: value,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.OpportunitiesAssetActivityType ?? value;
          }
          if (errors.OpportunitiesAssetActivityType?.hasError) {
            runValidationTasks("OpportunitiesAssetActivityType", value);
          }
          setOpportunitiesAssetActivityType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "OpportunitiesAssetActivityType",
            OpportunitiesAssetActivityType
          )
        }
        errorMessage={errors.OpportunitiesAssetActivityType?.errorMessage}
        hasError={errors.OpportunitiesAssetActivityType?.hasError}
        {...getOverrideProps(overrides, "OpportunitiesAssetActivityType")}
      >
        <option
          children="Business activity"
          value="BusinessActivity"
          {...getOverrideProps(
            overrides,
            "OpportunitiesAssetActivityTypeoption0"
          )}
        ></option>
        <option
          children="Asset"
          value="Asset"
          {...getOverrideProps(
            overrides,
            "OpportunitiesAssetActivityTypeoption1"
          )}
        ></option>
      </SelectField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Amount of assets or business activities aligned with opportunities"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={OpportunitiesAmount}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                TransitionRisksAssetActivityType,
                TransitionRisksAmount,
                TransitionRisksPercentage,
                PhysicalRisksAssetActivityType,
                PhysicalRisksAmount,
                PhysicalRisksPercentage,
                OpportunitiesAssetActivityType,
                OpportunitiesAmount: value,
                OpportunitiesPercentage,
                CapitalDeploymentValue,
                InternalCarbonPriceDecisionExplanation,
                InternalCarbonPrice,
                RemunerationDescription,
                RemunerationPercentageValue,
              };
              const result = onChange(modelFields);
              value = result?.OpportunitiesAmount ?? value;
            }
            if (errors.OpportunitiesAmount?.hasError) {
              runValidationTasks("OpportunitiesAmount", value);
            }
            setOpportunitiesAmount(value);
          }}
          onBlur={() =>
            runValidationTasks("OpportunitiesAmount", OpportunitiesAmount)
          }
          errorMessage={errors.OpportunitiesAmount?.errorMessage}
          hasError={errors.OpportunitiesAmount?.hasError}
          {...getOverrideProps(overrides, "OpportunitiesAmount")}
        ></TextField>
        <TextField
          label="Percentage of assets or business activities aligned with opportunities"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={OpportunitiesPercentage}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                TransitionRisksAssetActivityType,
                TransitionRisksAmount,
                TransitionRisksPercentage,
                PhysicalRisksAssetActivityType,
                PhysicalRisksAmount,
                PhysicalRisksPercentage,
                OpportunitiesAssetActivityType,
                OpportunitiesAmount,
                OpportunitiesPercentage: value,
                CapitalDeploymentValue,
                InternalCarbonPriceDecisionExplanation,
                InternalCarbonPrice,
                RemunerationDescription,
                RemunerationPercentageValue,
              };
              const result = onChange(modelFields);
              value = result?.OpportunitiesPercentage ?? value;
            }
            if (errors.OpportunitiesPercentage?.hasError) {
              runValidationTasks("OpportunitiesPercentage", value);
            }
            setOpportunitiesPercentage(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OpportunitiesPercentage",
              OpportunitiesPercentage
            )
          }
          errorMessage={errors.OpportunitiesPercentage?.errorMessage}
          hasError={errors.OpportunitiesPercentage?.hasError}
          {...getOverrideProps(overrides, "OpportunitiesPercentage")}
        ></TextField>
      </Grid>
      <TextField
        label="Amount of capital expenditure, financing or investment deployed towards climate-related risks and opportunities"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={CapitalDeploymentValue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue: value,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.CapitalDeploymentValue ?? value;
          }
          if (errors.CapitalDeploymentValue?.hasError) {
            runValidationTasks("CapitalDeploymentValue", value);
          }
          setCapitalDeploymentValue(value);
        }}
        onBlur={() =>
          runValidationTasks("CapitalDeploymentValue", CapitalDeploymentValue)
        }
        errorMessage={errors.CapitalDeploymentValue?.errorMessage}
        hasError={errors.CapitalDeploymentValue?.hasError}
        {...getOverrideProps(overrides, "CapitalDeploymentValue")}
      ></TextField>
      <TextAreaField
        label="Explain whether and how you are applying a carbon price in decision-making"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation: value,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.InternalCarbonPriceDecisionExplanation ?? value;
          }
          if (errors.InternalCarbonPriceDecisionExplanation?.hasError) {
            runValidationTasks("InternalCarbonPriceDecisionExplanation", value);
          }
          setInternalCarbonPriceDecisionExplanation(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "InternalCarbonPriceDecisionExplanation",
            InternalCarbonPriceDecisionExplanation
          )
        }
        errorMessage={
          errors.InternalCarbonPriceDecisionExplanation?.errorMessage
        }
        hasError={errors.InternalCarbonPriceDecisionExplanation?.hasError}
        {...getOverrideProps(
          overrides,
          "InternalCarbonPriceDecisionExplanation"
        )}
      ></TextAreaField>
      <TextField
        label="Internal carbon price (per metric tonne of greenhouse gas emissions) used to assess the costs of your greenhouse gas emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={InternalCarbonPrice}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice: value,
              RemunerationDescription,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.InternalCarbonPrice ?? value;
          }
          if (errors.InternalCarbonPrice?.hasError) {
            runValidationTasks("InternalCarbonPrice", value);
          }
          setInternalCarbonPrice(value);
        }}
        onBlur={() =>
          runValidationTasks("InternalCarbonPrice", InternalCarbonPrice)
        }
        errorMessage={errors.InternalCarbonPrice?.errorMessage}
        hasError={errors.InternalCarbonPrice?.hasError}
        {...getOverrideProps(overrides, "InternalCarbonPrice")}
      ></TextField>
      <TextAreaField
        label="Describe whether and how climate-related considerations are factored into executive remuneration"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription: value,
              RemunerationPercentageValue,
            };
            const result = onChange(modelFields);
            value = result?.RemunerationDescription ?? value;
          }
          if (errors.RemunerationDescription?.hasError) {
            runValidationTasks("RemunerationDescription", value);
          }
          setRemunerationDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("RemunerationDescription", RemunerationDescription)
        }
        errorMessage={errors.RemunerationDescription?.errorMessage}
        hasError={errors.RemunerationDescription?.hasError}
        	value={RemunerationDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "RemunerationDescription")}
      ></TextAreaField>
      <TextField
        label="Percentage of executive management remuneration recognised in the current period that is linked to climate-related considerations"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={RemunerationPercentageValue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              TransitionRisksPercentage,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              PhysicalRisksPercentage,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              OpportunitiesPercentage,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue: value,
            };
            const result = onChange(modelFields);
            value = result?.RemunerationPercentageValue ?? value;
          }
          if (errors.RemunerationPercentageValue?.hasError) {
            runValidationTasks("RemunerationPercentageValue", value);
          }
          setRemunerationPercentageValue(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "RemunerationPercentageValue",
            RemunerationPercentageValue
          )
        }
        errorMessage={errors.RemunerationPercentageValue?.errorMessage}
        hasError={errors.RemunerationPercentageValue?.hasError}
        {...getOverrideProps(overrides, "RemunerationPercentageValue")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
