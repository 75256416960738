/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { OffsetAccountEntry } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function OffsetAccountEntryUpdateForm(props) {
  const {
    id: idProp,
    offsetAccountEntry: offsetAccountEntryModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    OffsetName: "",
    OffsetTransactionStatus: "",
    OffsetDescription: "",
    GrossOffset: "",
    OffsetPurchaseDate: "",
    OffsetDataSource: "",
    OffsetCertificationSchemeName: "",
    CarbonCreditType: "",
    OffsetAchievementType: "",
    OffsetValidityStartDate: "",
    OffsetValidityEndDate: "",
  };
  const [OffsetName, setOffsetName] = React.useState(initialValues.OffsetName);
  const [OffsetTransactionStatus, setOffsetTransactionStatus] = React.useState(
    initialValues.OffsetTransactionStatus
  );
  const [OffsetDescription, setOffsetDescription] = React.useState(
    initialValues.OffsetDescription
  );
  const [GrossOffset, setGrossOffset] = React.useState(
    initialValues.GrossOffset
  );
  const [OffsetPurchaseDate, setOffsetPurchaseDate] = React.useState(
    initialValues.OffsetPurchaseDate
  );
  const [OffsetDataSource, setOffsetDataSource] = React.useState(
    initialValues.OffsetDataSource
  );
  const [OffsetCertificationSchemeName, setOffsetCertificationSchemeName] =
    React.useState(initialValues.OffsetCertificationSchemeName);
  const [CarbonCreditType, setCarbonCreditType] = React.useState(
    initialValues.CarbonCreditType
  );
  const [OffsetAchievementType, setOffsetAchievementType] = React.useState(
    initialValues.OffsetAchievementType
  );
  const [OffsetValidityStartDate, setOffsetValidityStartDate] = React.useState(
    initialValues.OffsetValidityStartDate
  );
  const [OffsetValidityEndDate, setOffsetValidityEndDate] = React.useState(
    initialValues.OffsetValidityEndDate
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = offsetAccountEntryRecord
      ? { ...initialValues, ...offsetAccountEntryRecord }
      : initialValues;
    setOffsetName(cleanValues.OffsetName);
    setOffsetTransactionStatus(cleanValues.OffsetTransactionStatus);
    setOffsetDescription(cleanValues.OffsetDescription);
    setGrossOffset(cleanValues.GrossOffset);
    setOffsetPurchaseDate(cleanValues.OffsetPurchaseDate);
    setOffsetDataSource(cleanValues.OffsetDataSource);
    setOffsetCertificationSchemeName(cleanValues.OffsetCertificationSchemeName);
    setCarbonCreditType(cleanValues.CarbonCreditType);
    setOffsetAchievementType(cleanValues.OffsetAchievementType);
    setOffsetValidityStartDate(cleanValues.OffsetValidityStartDate);
    setOffsetValidityEndDate(cleanValues.OffsetValidityEndDate);
    setErrors({});
  };
  const [offsetAccountEntryRecord, setOffsetAccountEntryRecord] =
    React.useState(offsetAccountEntryModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(OffsetAccountEntry, idProp)
        : offsetAccountEntryModelProp;
      setOffsetAccountEntryRecord(record);
    };
    queryData();
  }, [idProp, offsetAccountEntryModelProp]);
  React.useEffect(resetStateValues, [offsetAccountEntryRecord]);
  const validations = {
    OffsetName: [],
    OffsetTransactionStatus: [],
    OffsetDescription: [],
    GrossOffset: [],
    OffsetPurchaseDate: [],
    OffsetDataSource: [],
    OffsetCertificationSchemeName: [],
    CarbonCreditType: [],
    OffsetAchievementType: [],
    OffsetValidityStartDate: [],
    OffsetValidityEndDate: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          OffsetName,
          OffsetTransactionStatus,
          OffsetDescription,
          GrossOffset,
          OffsetPurchaseDate,
          OffsetDataSource,
          OffsetCertificationSchemeName,
          CarbonCreditType,
          OffsetAchievementType,
          OffsetValidityStartDate,
          OffsetValidityEndDate,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            OffsetAccountEntry.copyOf(offsetAccountEntryRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "OffsetAccountEntryUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Greenhouse Gas Offset Transaction"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update this record of a Greenhouse Gas offset you have purchased."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Offset name"
          isRequired={false}
          isReadOnly={false}
          value={OffsetName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName: value,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetName ?? value;
            }
            if (errors.OffsetName?.hasError) {
              runValidationTasks("OffsetName", value);
            }
            setOffsetName(value);
          }}
          onBlur={() => runValidationTasks("OffsetName", OffsetName)}
          errorMessage={errors.OffsetName?.errorMessage}
          hasError={errors.OffsetName?.hasError}
          {...getOverrideProps(overrides, "OffsetName")}
        ></TextField>
        <SelectField
          label="Offset transaction status"
          placeholder="Please select an option"
          isDisabled={false}
          value={OffsetTransactionStatus}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus: value,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetTransactionStatus ?? value;
            }
            if (errors.OffsetTransactionStatus?.hasError) {
              runValidationTasks("OffsetTransactionStatus", value);
            }
            setOffsetTransactionStatus(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OffsetTransactionStatus",
              OffsetTransactionStatus
            )
          }
          errorMessage={errors.OffsetTransactionStatus?.errorMessage}
          hasError={errors.OffsetTransactionStatus?.hasError}
          {...getOverrideProps(overrides, "OffsetTransactionStatus")}
        >
          <option
            children="Investigating"
            value="Investigating"
            {...getOverrideProps(overrides, "OffsetTransactionStatusoption0")}
          ></option>
          <option
            children="Planning"
            value="Planning"
            {...getOverrideProps(overrides, "OffsetTransactionStatusoption1")}
          ></option>
          <option
            children="Implementing"
            value="Implementing"
            {...getOverrideProps(overrides, "OffsetTransactionStatusoption2")}
          ></option>
          <option
            children="Implemented"
            value="Implemented"
            {...getOverrideProps(overrides, "OffsetTransactionStatusoption3")}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Offset description"
        isRequired={false}
        isReadOnly={false}
        value={OffsetDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OffsetName,
              OffsetTransactionStatus,
              OffsetDescription: value,
              GrossOffset,
              OffsetPurchaseDate,
              OffsetDataSource,
              OffsetCertificationSchemeName,
              CarbonCreditType,
              OffsetAchievementType,
              OffsetValidityStartDate,
              OffsetValidityEndDate,
            };
            const result = onChange(modelFields);
            value = result?.OffsetDescription ?? value;
          }
          if (errors.OffsetDescription?.hasError) {
            runValidationTasks("OffsetDescription", value);
          }
          setOffsetDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("OffsetDescription", OffsetDescription)
        }
        errorMessage={errors.OffsetDescription?.errorMessage}
        hasError={errors.OffsetDescription?.hasError}
        {...getOverrideProps(overrides, "OffsetDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Gross offset (tonnes CO2e)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={GrossOffset}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset: value,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.GrossOffset ?? value;
            }
            if (errors.GrossOffset?.hasError) {
              runValidationTasks("GrossOffset", value);
            }
            setGrossOffset(value);
          }}
          onBlur={() => runValidationTasks("GrossOffset", GrossOffset)}
          errorMessage={errors.GrossOffset?.errorMessage}
          hasError={errors.GrossOffset?.hasError}
          {...getOverrideProps(overrides, "GrossOffset")}
        ></TextField>
        <TextField
          label="Offset purchase date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={OffsetPurchaseDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate: value,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetPurchaseDate ?? value;
            }
            if (errors.OffsetPurchaseDate?.hasError) {
              runValidationTasks("OffsetPurchaseDate", value);
            }
            setOffsetPurchaseDate(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetPurchaseDate", OffsetPurchaseDate)
          }
          errorMessage={errors.OffsetPurchaseDate?.errorMessage}
          hasError={errors.OffsetPurchaseDate?.hasError}
          {...getOverrideProps(overrides, "OffsetPurchaseDate")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Offset data source"
          isRequired={false}
          isReadOnly={false}
          value={OffsetDataSource}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource: value,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetDataSource ?? value;
            }
            if (errors.OffsetDataSource?.hasError) {
              runValidationTasks("OffsetDataSource", value);
            }
            setOffsetDataSource(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetDataSource", OffsetDataSource)
          }
          errorMessage={errors.OffsetDataSource?.errorMessage}
          hasError={errors.OffsetDataSource?.hasError}
          {...getOverrideProps(overrides, "OffsetDataSource")}
        ></TextField>
        <TextField
          label="Offset certification scheme name"
          isRequired={false}
          isReadOnly={false}
          value={OffsetCertificationSchemeName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName: value,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetCertificationSchemeName ?? value;
            }
            if (errors.OffsetCertificationSchemeName?.hasError) {
              runValidationTasks("OffsetCertificationSchemeName", value);
            }
            setOffsetCertificationSchemeName(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OffsetCertificationSchemeName",
              OffsetCertificationSchemeName
            )
          }
          errorMessage={errors.OffsetCertificationSchemeName?.errorMessage}
          hasError={errors.OffsetCertificationSchemeName?.hasError}
          {...getOverrideProps(overrides, "OffsetCertificationSchemeName")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <SelectField
          label="Carbon credit type"
          placeholder="Please select an option"
          isDisabled={false}
          value={CarbonCreditType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType: value,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.CarbonCreditType ?? value;
            }
            if (errors.CarbonCreditType?.hasError) {
              runValidationTasks("CarbonCreditType", value);
            }
            setCarbonCreditType(value);
          }}
          onBlur={() =>
            runValidationTasks("CarbonCreditType", CarbonCreditType)
          }
          errorMessage={errors.CarbonCreditType?.errorMessage}
          hasError={errors.CarbonCreditType?.hasError}
          {...getOverrideProps(overrides, "CarbonCreditType")}
        >
          <option
            children="Nature based"
            value="NatureBased"
            {...getOverrideProps(overrides, "CarbonCreditTypeoption0")}
          ></option>
          <option
            children="Technological based"
            value="TechnologicalBased"
            {...getOverrideProps(overrides, "CarbonCreditTypeoption1")}
          ></option>
        </SelectField>
        <SelectField
          label="Offset achievement type"
          placeholder="Please select an option"
          isDisabled={false}
          value={OffsetAchievementType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType: value,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetAchievementType ?? value;
            }
            if (errors.OffsetAchievementType?.hasError) {
              runValidationTasks("OffsetAchievementType", value);
            }
            setOffsetAchievementType(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetAchievementType", OffsetAchievementType)
          }
          errorMessage={errors.OffsetAchievementType?.errorMessage}
          hasError={errors.OffsetAchievementType?.hasError}
          {...getOverrideProps(overrides, "OffsetAchievementType")}
        >
          <option
            children="Carbon reduction"
            value="CarbonReduction"
            {...getOverrideProps(overrides, "OffsetAchievementTypeoption0")}
          ></option>
          <option
            children="Carbon removal"
            value="CarbonRemoval"
            {...getOverrideProps(overrides, "OffsetAchievementTypeoption1")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Offset validity start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={OffsetValidityStartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate: value,
                OffsetValidityEndDate,
              };
              const result = onChange(modelFields);
              value = result?.OffsetValidityStartDate ?? value;
            }
            if (errors.OffsetValidityStartDate?.hasError) {
              runValidationTasks("OffsetValidityStartDate", value);
            }
            setOffsetValidityStartDate(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OffsetValidityStartDate",
              OffsetValidityStartDate
            )
          }
          errorMessage={errors.OffsetValidityStartDate?.errorMessage}
          hasError={errors.OffsetValidityStartDate?.hasError}
          {...getOverrideProps(overrides, "OffsetValidityStartDate")}
        ></TextField>
        <TextField
          label="Offset validity end date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={OffsetValidityEndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetTransactionStatus,
                OffsetDescription,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetDataSource,
                OffsetCertificationSchemeName,
                CarbonCreditType,
                OffsetAchievementType,
                OffsetValidityStartDate,
                OffsetValidityEndDate: value,
              };
              const result = onChange(modelFields);
              value = result?.OffsetValidityEndDate ?? value;
            }
            if (errors.OffsetValidityEndDate?.hasError) {
              runValidationTasks("OffsetValidityEndDate", value);
            }
            setOffsetValidityEndDate(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetValidityEndDate", OffsetValidityEndDate)
          }
          errorMessage={errors.OffsetValidityEndDate?.errorMessage}
          hasError={errors.OffsetValidityEndDate?.hasError}
          {...getOverrideProps(overrides, "OffsetValidityEndDate")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || offsetAccountEntryModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || offsetAccountEntryModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
