/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { RiskManagementDataCollection } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskManagementDataCollectionUpdateForm(props) {
  const {
    id: idProp,
    riskManagementDataCollection: riskManagementDataCollectionModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    DataSetName: "",
    Description: "",
  };
  const [DataSetName, setDataSetName] = React.useState(
    initialValues.DataSetName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = riskManagementDataCollectionRecord
      ? { ...initialValues, ...riskManagementDataCollectionRecord }
      : initialValues;
    setDataSetName(cleanValues.DataSetName);
    setDescription(cleanValues.Description);
    setErrors({});
  };
  const [
    riskManagementDataCollectionRecord,
    setRiskManagementDataCollectionRecord,
  ] = React.useState(riskManagementDataCollectionModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(RiskManagementDataCollection, idProp)
        : riskManagementDataCollectionModelProp;
      setRiskManagementDataCollectionRecord(record);
    };
    queryData();
  }, [idProp, riskManagementDataCollectionModelProp]);
  React.useEffect(resetStateValues, [riskManagementDataCollectionRecord]);
  const validations = {
    DataSetName: [],
    Description: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DataSetName,
          Description,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            RiskManagementDataCollection.copyOf(
              riskManagementDataCollectionRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "RiskManagementDataCollectionUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Risk Management"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 24-26."
        {...getOverrideProps(overrides, "SectionalElement20")}
      ></Heading>
      <Text
        children="Update this record that collates disclosure responses about processes and policies around managing climate-related risks and opportunities, and their integration into overall enterprise risk management processes."
        {...getOverrideProps(overrides, "SectionalElement200")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <TextField
        label="Risk management record name (e.g. 2025 risk management records)"
        isRequired={false}
        isReadOnly={false}
        value={DataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName: value,
              Description,
            };
            const result = onChange(modelFields);
            value = result?.DataSetName ?? value;
          }
          if (errors.DataSetName?.hasError) {
            runValidationTasks("DataSetName", value);
          }
          setDataSetName(value);
        }}
        onBlur={() => runValidationTasks("DataSetName", DataSetName)}
        errorMessage={errors.DataSetName?.errorMessage}
        hasError={errors.DataSetName?.hasError}
        {...getOverrideProps(overrides, "DataSetName")}
      ></TextField>
      <TextAreaField
        label="Description (e.g. Climate-related risk management process disclosure data for the 2025 reporting period)"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description: value,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || riskManagementDataCollectionModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || riskManagementDataCollectionModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
