/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateResilienceAssessment } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateResilienceAssessmentCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    ResilienceAssessmentName: "",
    ClimateResilienceAssessmentType: "",
    Description: "",
    QuantitativeAssessmentValue: "",
    QuantitativeAssessmentUnit: "",
    QuantitativeAssessmentValueMin: "",
    QuantitativeAssessmentValueMax: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [ResilienceAssessmentName, setResilienceAssessmentName] =
    React.useState(initialValues.ResilienceAssessmentName);
  const [ClimateResilienceAssessmentType, setClimateResilienceAssessmentType] =
    React.useState(initialValues.ClimateResilienceAssessmentType);
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [QuantitativeAssessmentValue, setQuantitativeAssessmentValue] =
    React.useState(initialValues.QuantitativeAssessmentValue);
  const [QuantitativeAssessmentUnit, setQuantitativeAssessmentUnit] =
    React.useState(initialValues.QuantitativeAssessmentUnit);
  const [QuantitativeAssessmentValueMin, setQuantitativeAssessmentValueMin] =
    React.useState(initialValues.QuantitativeAssessmentValueMin);
  const [QuantitativeAssessmentValueMax, setQuantitativeAssessmentValueMax] =
    React.useState(initialValues.QuantitativeAssessmentValueMax);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setResilienceAssessmentName(initialValues.ResilienceAssessmentName);
    setClimateResilienceAssessmentType(
      initialValues.ClimateResilienceAssessmentType
    );
    setDescription(initialValues.Description);
    setQuantitativeAssessmentValue(initialValues.QuantitativeAssessmentValue);
    setQuantitativeAssessmentUnit(initialValues.QuantitativeAssessmentUnit);
    setQuantitativeAssessmentValueMin(
      initialValues.QuantitativeAssessmentValueMin
    );
    setQuantitativeAssessmentValueMax(
      initialValues.QuantitativeAssessmentValueMax
    );
    setErrors({});
  };
  const validations = {
    ResilienceAssessmentName: [],
    ClimateResilienceAssessmentType: [],
    Description: [],
    QuantitativeAssessmentValue: [],
    QuantitativeAssessmentUnit: [],
    QuantitativeAssessmentValueMin: [],
    QuantitativeAssessmentValueMax: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ResilienceAssessmentName,
          ClimateResilienceAssessmentType,
          Description,
          QuantitativeAssessmentValue,
          QuantitativeAssessmentUnit,
          QuantitativeAssessmentValueMin,
          QuantitativeAssessmentValueMax,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateResilienceAssessment(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClimateResilienceAssessmentCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Resilience Assessment"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 22 a i-iii."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Create a record that defines your assessment of your organisation’s climate resilience, at the reporting date."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Resilience assessment record name (e.g. 2027 strategy resilience assessment)"
        isRequired={false}
        isReadOnly={false}
        value={ResilienceAssessmentName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ResilienceAssessmentName: value,
              ClimateResilienceAssessmentType,
              Description,
              QuantitativeAssessmentValue,
              QuantitativeAssessmentUnit,
              QuantitativeAssessmentValueMin,
              QuantitativeAssessmentValueMax,
            };
            const result = onChange(modelFields);
            value = result?.ResilienceAssessmentName ?? value;
          }
          if (errors.ResilienceAssessmentName?.hasError) {
            runValidationTasks("ResilienceAssessmentName", value);
          }
          setResilienceAssessmentName(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ResilienceAssessmentName",
            ResilienceAssessmentName
          )
        }
        errorMessage={errors.ResilienceAssessmentName?.errorMessage}
        hasError={errors.ResilienceAssessmentName?.hasError}
        {...getOverrideProps(overrides, "ResilienceAssessmentName")}
      ></TextField>
      <SelectField
        label="Area of assessment"
        placeholder="Please select an option"
        isDisabled={false}
        value={ClimateResilienceAssessmentType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ResilienceAssessmentName,
              ClimateResilienceAssessmentType: value,
              Description,
              QuantitativeAssessmentValue,
              QuantitativeAssessmentUnit,
              QuantitativeAssessmentValueMin,
              QuantitativeAssessmentValueMax,
            };
            const result = onChange(modelFields);
            value = result?.ClimateResilienceAssessmentType ?? value;
          }
          if (errors.ClimateResilienceAssessmentType?.hasError) {
            runValidationTasks("ClimateResilienceAssessmentType", value);
          }
          setClimateResilienceAssessmentType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ClimateResilienceAssessmentType",
            ClimateResilienceAssessmentType
          )
        }
        errorMessage={errors.ClimateResilienceAssessmentType?.errorMessage}
        hasError={errors.ClimateResilienceAssessmentType?.hasError}
        {...getOverrideProps(overrides, "ClimateResilienceAssessmentType")}
      >
        <option
          children="Resilience assessment of strategy and business model"
          value="ResilienceAssessmentOfStrategyAndBusinessModel"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption0"
          )}
        ></option>
        <option
          children="Implications of resilience assessment"
          value="ImplicationsOfResilienceAssessment"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption1"
          )}
        ></option>
        <option
          children="Response to identified effects from climate related scenario analysis"
          value="ResponseToIdentifiedEffectsFromClimateRelatedScenarioAnalysis"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption2"
          )}
        ></option>
        <option
          children="Area of significant uncertainty"
          value="AreaOfSignificantUncertainty"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption3"
          )}
        ></option>
        <option
          children="Availability of financial resources"
          value="AvailabilityOfFinancialResources"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption4"
          )}
        ></option>
        <option
          children="Flexibility of financial resources"
          value="FlexibilityOfFinancialResources"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption5"
          )}
        ></option>
        <option
          children="Ability to modify existing asset usage"
          value="AbilityToModifyExistingAssetUsage"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption6"
          )}
        ></option>
        <option
          children="Effect of current investments"
          value="EffectOfCurrentInvestments"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption7"
          )}
        ></option>
        <option
          children="Effect of planned investments"
          value="EffectOfPlannedInvestments"
          {...getOverrideProps(
            overrides,
            "ClimateResilienceAssessmentTypeoption8"
          )}
        ></option>
      </SelectField>
      <TextAreaField
        label="Description of resilience assessment for this area"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ResilienceAssessmentName,
              ClimateResilienceAssessmentType,
              Description: value,
              QuantitativeAssessmentValue,
              QuantitativeAssessmentUnit,
              QuantitativeAssessmentValueMin,
              QuantitativeAssessmentValueMax,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        	value={Description} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Text
        children="For each assessment area, you can enter an assessment amount and unit of measure, or a range (min, max)"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Quantitative assessment amount"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={QuantitativeAssessmentValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ResilienceAssessmentName,
                ClimateResilienceAssessmentType,
                Description,
                QuantitativeAssessmentValue: value,
                QuantitativeAssessmentUnit,
                QuantitativeAssessmentValueMin,
                QuantitativeAssessmentValueMax,
              };
              const result = onChange(modelFields);
              value = result?.QuantitativeAssessmentValue ?? value;
            }
            if (errors.QuantitativeAssessmentValue?.hasError) {
              runValidationTasks("QuantitativeAssessmentValue", value);
            }
            setQuantitativeAssessmentValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "QuantitativeAssessmentValue",
              QuantitativeAssessmentValue
            )
          }
          errorMessage={errors.QuantitativeAssessmentValue?.errorMessage}
          hasError={errors.QuantitativeAssessmentValue?.hasError}
          {...getOverrideProps(overrides, "QuantitativeAssessmentValue")}
        ></TextField>
        <TextField
          label="Quantitative assessment unit"
          isRequired={false}
          isReadOnly={false}
          value={QuantitativeAssessmentUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ResilienceAssessmentName,
                ClimateResilienceAssessmentType,
                Description,
                QuantitativeAssessmentValue,
                QuantitativeAssessmentUnit: value,
                QuantitativeAssessmentValueMin,
                QuantitativeAssessmentValueMax,
              };
              const result = onChange(modelFields);
              value = result?.QuantitativeAssessmentUnit ?? value;
            }
            if (errors.QuantitativeAssessmentUnit?.hasError) {
              runValidationTasks("QuantitativeAssessmentUnit", value);
            }
            setQuantitativeAssessmentUnit(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "QuantitativeAssessmentUnit",
              QuantitativeAssessmentUnit
            )
          }
          errorMessage={errors.QuantitativeAssessmentUnit?.errorMessage}
          hasError={errors.QuantitativeAssessmentUnit?.hasError}
          {...getOverrideProps(overrides, "QuantitativeAssessmentUnit")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Quantitative assessment amount (min)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={QuantitativeAssessmentValueMin}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ResilienceAssessmentName,
                ClimateResilienceAssessmentType,
                Description,
                QuantitativeAssessmentValue,
                QuantitativeAssessmentUnit,
                QuantitativeAssessmentValueMin: value,
                QuantitativeAssessmentValueMax,
              };
              const result = onChange(modelFields);
              value = result?.QuantitativeAssessmentValueMin ?? value;
            }
            if (errors.QuantitativeAssessmentValueMin?.hasError) {
              runValidationTasks("QuantitativeAssessmentValueMin", value);
            }
            setQuantitativeAssessmentValueMin(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "QuantitativeAssessmentValueMin",
              QuantitativeAssessmentValueMin
            )
          }
          errorMessage={errors.QuantitativeAssessmentValueMin?.errorMessage}
          hasError={errors.QuantitativeAssessmentValueMin?.hasError}
          {...getOverrideProps(overrides, "QuantitativeAssessmentValueMin")}
        ></TextField>
        <TextField
          label="Quantitative assessment amount (max)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={QuantitativeAssessmentValueMax}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ResilienceAssessmentName,
                ClimateResilienceAssessmentType,
                Description,
                QuantitativeAssessmentValue,
                QuantitativeAssessmentUnit,
                QuantitativeAssessmentValueMin,
                QuantitativeAssessmentValueMax: value,
              };
              const result = onChange(modelFields);
              value = result?.QuantitativeAssessmentValueMax ?? value;
            }
            if (errors.QuantitativeAssessmentValueMax?.hasError) {
              runValidationTasks("QuantitativeAssessmentValueMax", value);
            }
            setQuantitativeAssessmentValueMax(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "QuantitativeAssessmentValueMax",
              QuantitativeAssessmentValueMax
            )
          }
          errorMessage={errors.QuantitativeAssessmentValueMax?.errorMessage}
          hasError={errors.QuantitativeAssessmentValueMax?.hasError}
          {...getOverrideProps(overrides, "QuantitativeAssessmentValueMax")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
