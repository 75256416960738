import HelpBox from "../../shared/content/HelpBox";
import { useHelpContext } from "../../shared/help/HelpContext";

export default function IndustryBasedMetricsHelp() {
  const { application, version, model, subtype, action, view } = useHelpContext();
  return (
    <HelpBox>
      <HelpBox.Header>Industry Based Metrics Help</HelpBox.Header>
      {view === "wizard" ? (
        <HelpBox.Content>
          <p>
            insert {application} {version} {action} {view} HELP for {model.name} subtype {subtype} HERE
          </p>
        </HelpBox.Content>
      ) : (
        <HelpBox.Content>
          <p>
            This form allows you to enter a record that contains data about industry based metrics relevant to participation in your
            industry.
          </p>
          <p>Follow these steps to create an Industry Based Metrics record:</p>
          <ol>
            <li>
              Create a name for the record in the <i>Industry Based Metrics record name</i> field.
            </li>
            <li>Add a description for the metric</li>
            <li>Add a value and unit of measure in the requried fields</li>
            <li>
              Once completed, choose <b>Create</b> or <b>Update</b>.
            </li>
          </ol>
        </HelpBox.Content>
      )}
    </HelpBox>
  );
}
