import HelpBox from "../../shared/content/HelpBox";

export default function ResilienceAssessmentHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate-related Resilience Assessment Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes your organization's Climate-related Resilience Assessment, including how to achieve any climate-related targets and any greenhouse gas emissions targets.
        </p>
        <p>Follow these steps to define your resilience assessment record:</p>
        <ol>
          <li>
            Enter a name for the data you report for each resilience assessment area. This allows you to provide separate assessments in each area, and provide updated assessments in subsequent reporting periods.
          </li>
          <li>
            Select an area of assessment and provide a description of the assessment results. You should aim to cover all nine resilience assessment areas as follows:
            <p> </p>
            <ol>
              <li><b>Resilience Assessment Of Strategy And Business Model</b> to climate-related changes, developments and uncertainties, taking into consideration the entity’s identified climate-related risks and opportunities</li>
              <li><b>Implications of resilience assessment</b> for your strategy and business model</li>
              <li><b>Response to identified scenario effects</b> identified in the climate-related scenario analysis</li>
              <li><b>Area of significant uncertainty</b> in the resilience assessment</li>
              <li><b>Availability of financial resources</b> to respond to the effects identified in the climate-related scenario analysis, including to address climate-related risks and to take advantage of climate-related opportunities</li>
              <li><b>Flexibility of financial resources</b> to respond to the effects identified in the climate-related scenario analysis, including to address climate-related risks and to take advantage of climate-related opportunities</li>
              <li><b>Ability to modify existing asset usage</b> including redeploying, repurposing, upgrading or decommissioning existing assets</li>
              <li><b>Effect of current investments</b> in climate-related mitigation, adaptation and opportunities for climate resilience</li>
              <li><b>Effect of planned investments</b> in climate-related mitigation, adaptation and opportunities for climate resilience</li>
            </ol>
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
