import HelpBox from "./HelpBox";

export default function CarbonAccountingLedgersHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Carbon Accounting Ledgers Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          Creating a climate disclosure and reporting to stakeholders requires an understanding of the emission activities occurring within your organization that give rise to, abate, or prevent emissions.
        </p>
        <p>
          ClimateTracker allows you to create multiple Carbon Accounting Ledgers, with each ledger used to group together related Carbon Accounts. For example, you may create separate ledgers for different subsidiaries operating in different countries, separate ledgers for different business units, or ledgers to group specific types of carbon accounts such as sequestration and avoided emissions accounts.
        </p>
        <p>
          This information can then be used to create detailed analysis and visualization of emission and reduction transaction data by ledger and account, for planning and communication with stakeholders.
        </p>
        <p>
          Once a ledger has been created, it can be linked to multiple carbon accounts to record GHG transactions. ClimateTracker supports five carbon account types, including:
        </p>
        <ol type="1">
          <li>
            <b>Emission Account</b> - track and manage transactions that generate Greenhouse Gas emissions
          </li>
          <li>
            <b>Reduction Account</b> - track and manage transactions that reduce emissions
          </li>
          <li>
            <b>Carbon Credits / Offsets Account</b> - track and manage transactions that offset emissions
          </li>
          <li>
            <b>Biogenic Sequestration Account</b> - track and manage transactions that sequester emissions
          </li>
          <li>
            <b>Avoided Emission Account</b> - track and manage transactions that prevent emissions from occurring
          </li>
        </ol>
        <p>Follow these steps to create a Carbon Accounting Ledger:</p>
        <ol>
          <li>
            Create a name for the ledger in the <i>Carbon accounting ledger name</i> field.
          </li>
          <li>Add a description for the ledger to describe the purpose of the ledger</li>
          <li>Select one or more Carbon Accounts from the tables in the middle of the screen</li>
          <li>
            Once completed, choose <b>Create</b> or <b>Update</b>.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
