import HelpBox from "../../shared/content/HelpBox";

export default function ClimateGovernanceBodyHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Governance Body Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes the Climate Governance Body which is responsible for oversight of your organizaion's response to climate-related risks and opportunities.
        </p>
        <p>Follow these steps to define your Climate Governance Body:</p>
        <ol>
          <li>
            Enter a name and description for the governance body, then describe whether and how appropriate skills and competencies are available or will be developed in this governance body.
          </li>
          <li>
            Note that this body may be comprised of a single individual, or multiple individuals.
          </li>
          <li>
            Select or create and select records for each of the tables in the middle of the window covering: <b>Governance artifacts</b>, <b>Climate-related oversight of organizational strategy</b>, <b>Inform Governance Body about climate-related risks and opportunities</b>, and <b>Climate-related governance of targets</b>.
          </li>
          <li>
            You should also attach evidence supporting this record(s), using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
