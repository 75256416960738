/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { AuditLog } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function AuditLogUpdateForm(props) {
  const {
    id: idProp,
    auditLog: auditLogModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    LogName: "",
    StartDate: "",
    EndDate: "",
  };
  const [LogName, setLogName] = React.useState(initialValues.LogName);
  const [StartDate, setStartDate] = React.useState(initialValues.StartDate);
  const [EndDate, setEndDate] = React.useState(initialValues.EndDate);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = auditLogRecord
      ? { ...initialValues, ...auditLogRecord }
      : initialValues;
    setLogName(cleanValues.LogName);
    setStartDate(cleanValues.StartDate);
    setEndDate(cleanValues.EndDate);
    setErrors({});
  };
  const [auditLogRecord, setAuditLogRecord] = React.useState(auditLogModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(AuditLog, idProp)
        : auditLogModelProp;
      setAuditLogRecord(record);
    };
    queryData();
  }, [idProp, auditLogModelProp]);
  React.useEffect(resetStateValues, [auditLogRecord]);
  const validations = {
    LogName: [],
    StartDate: [],
    EndDate: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          LogName,
          StartDate,
          EndDate,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            AuditLog.copyOf(auditLogRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AuditLogUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Audit Log"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update an audit log to capture all changes made in ClimateTracker."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Audit log name"
        isRequired={false}
        isReadOnly={false}
        value={LogName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              LogName: value,
              StartDate,
              EndDate,
            };
            const result = onChange(modelFields);
            value = result?.LogName ?? value;
          }
          if (errors.LogName?.hasError) {
            runValidationTasks("LogName", value);
          }
          setLogName(value);
        }}
        onBlur={() => runValidationTasks("LogName", LogName)}
        errorMessage={errors.LogName?.errorMessage}
        hasError={errors.LogName?.hasError}
        {...getOverrideProps(overrides, "LogName")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={StartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LogName,
                StartDate: value,
                EndDate,
              };
              const result = onChange(modelFields);
              value = result?.StartDate ?? value;
            }
            if (errors.StartDate?.hasError) {
              runValidationTasks("StartDate", value);
            }
            setStartDate(value);
          }}
          onBlur={() => runValidationTasks("StartDate", StartDate)}
          errorMessage={errors.StartDate?.errorMessage}
          hasError={errors.StartDate?.hasError}
          {...getOverrideProps(overrides, "StartDate")}
        ></TextField>
        <TextField
          label="End date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={EndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LogName,
                StartDate,
                EndDate: value,
              };
              const result = onChange(modelFields);
              value = result?.EndDate ?? value;
            }
            if (errors.EndDate?.hasError) {
              runValidationTasks("EndDate", value);
            }
            setEndDate(value);
          }}
          onBlur={() => runValidationTasks("EndDate", EndDate)}
          errorMessage={errors.EndDate?.errorMessage}
          hasError={errors.EndDate?.hasError}
          {...getOverrideProps(overrides, "EndDate")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || auditLogModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || auditLogModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
