/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { StrategyDecisionMakingData } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function StrategyDecisionMakingDataTransitionPlanUpdateForm(
  props
) {
  const {
    id: idProp,
    strategyDecisionMakingData: strategyDecisionMakingDataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    TransitionPlanName: "",
    TransitionPlanDescription: "",
    KeyAssumptions: "",
    Dependencies: "",
  };
  const [TransitionPlanName, setTransitionPlanName] = React.useState(
    initialValues.TransitionPlanName
  );
  const [TransitionPlanDescription, setTransitionPlanDescription] =
    React.useState(initialValues.TransitionPlanDescription);
  const [KeyAssumptions, setKeyAssumptions] = React.useState(
    initialValues.KeyAssumptions
  );
  const [Dependencies, setDependencies] = React.useState(
    initialValues.Dependencies
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = strategyDecisionMakingDataRecord
      ? { ...initialValues, ...strategyDecisionMakingDataRecord }
      : initialValues;
    setTransitionPlanName(cleanValues.TransitionPlanName);
    setTransitionPlanDescription(cleanValues.TransitionPlanDescription);
    setKeyAssumptions(cleanValues.KeyAssumptions);
    setDependencies(cleanValues.Dependencies);
    setErrors({});
  };
  const [
    strategyDecisionMakingDataRecord,
    setStrategyDecisionMakingDataRecord,
  ] = React.useState(strategyDecisionMakingDataModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(StrategyDecisionMakingData, idProp)
        : strategyDecisionMakingDataModelProp;
      setStrategyDecisionMakingDataRecord(record);
    };
    queryData();
  }, [idProp, strategyDecisionMakingDataModelProp]);
  React.useEffect(resetStateValues, [strategyDecisionMakingDataRecord]);
  const validations = {
    TransitionPlanName: [],
    TransitionPlanDescription: [],
    KeyAssumptions: [],
    Dependencies: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          TransitionPlanName,
          TransitionPlanDescription,
          KeyAssumptions,
          Dependencies,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            StrategyDecisionMakingData.copyOf(
              strategyDecisionMakingDataRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "StrategyDecisionMakingDataTransitionPlanUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Transition Plan"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 14 a iv."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update this record that defines your climate-related transition plan, including information about key assumptions used in developing your transition plan, and dependencies on which your transition plan relies."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Transition plan name"
        isRequired={false}
        isReadOnly={false}
        value={TransitionPlanName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TransitionPlanName: value,
              TransitionPlanDescription,
              KeyAssumptions,
              Dependencies,
            };
            const result = onChange(modelFields);
            value = result?.TransitionPlanName ?? value;
          }
          if (errors.TransitionPlanName?.hasError) {
            runValidationTasks("TransitionPlanName", value);
          }
          setTransitionPlanName(value);
        }}
        onBlur={() =>
          runValidationTasks("TransitionPlanName", TransitionPlanName)
        }
        errorMessage={errors.TransitionPlanName?.errorMessage}
        hasError={errors.TransitionPlanName?.hasError}
        {...getOverrideProps(overrides, "TransitionPlanName")}
      ></TextField>
      <TextAreaField
        label="Transition plan description"
        isRequired={false}
        isReadOnly={false}
        value={TransitionPlanDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TransitionPlanName,
              TransitionPlanDescription: value,
              KeyAssumptions,
              Dependencies,
            };
            const result = onChange(modelFields);
            value = result?.TransitionPlanDescription ?? value;
          }
          if (errors.TransitionPlanDescription?.hasError) {
            runValidationTasks("TransitionPlanDescription", value);
          }
          setTransitionPlanDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TransitionPlanDescription",
            TransitionPlanDescription
          )
        }
        errorMessage={errors.TransitionPlanDescription?.errorMessage}
        hasError={errors.TransitionPlanDescription?.hasError}
        {...getOverrideProps(overrides, "TransitionPlanDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Key assumptions used in developing transition plan"
        isRequired={false}
        isReadOnly={false}
        value={KeyAssumptions}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TransitionPlanName,
              TransitionPlanDescription,
              KeyAssumptions: value,
              Dependencies,
            };
            const result = onChange(modelFields);
            value = result?.KeyAssumptions ?? value;
          }
          if (errors.KeyAssumptions?.hasError) {
            runValidationTasks("KeyAssumptions", value);
          }
          setKeyAssumptions(value);
        }}
        onBlur={() => runValidationTasks("KeyAssumptions", KeyAssumptions)}
        errorMessage={errors.KeyAssumptions?.errorMessage}
        hasError={errors.KeyAssumptions?.hasError}
        {...getOverrideProps(overrides, "KeyAssumptions")}
      ></TextAreaField>
      <TextAreaField
        label="Dependencies the transition plan relies upon"
        isRequired={false}
        isReadOnly={false}
        value={Dependencies}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TransitionPlanName,
              TransitionPlanDescription,
              KeyAssumptions,
              Dependencies: value,
            };
            const result = onChange(modelFields);
            value = result?.Dependencies ?? value;
          }
          if (errors.Dependencies?.hasError) {
            runValidationTasks("Dependencies", value);
          }
          setDependencies(value);
        }}
        onBlur={() => runValidationTasks("Dependencies", Dependencies)}
        errorMessage={errors.Dependencies?.errorMessage}
        hasError={errors.Dependencies?.hasError}
        {...getOverrideProps(overrides, "Dependencies")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || strategyDecisionMakingDataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || strategyDecisionMakingDataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
