/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { PartyRelationship } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function PartyRelationshipCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    RelationshipName: "",
    RelationshipDescription: "",
    PartyRelationshipType: "",
    FromParty: "",
    FromRole: "",
    ToParty: "",
    ToRole: "",
    PartyRelationshipStartDate: "",
    PartyRelationshipEndDate: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [RelationshipName, setRelationshipName] = React.useState(
    initialValues.RelationshipName
  );
  const [RelationshipDescription, setRelationshipDescription] = React.useState(
    initialValues.RelationshipDescription
  );
  const [PartyRelationshipType, setPartyRelationshipType] = React.useState(
    initialValues.PartyRelationshipType
  );
  const [FromParty, setFromParty] = React.useState(initialValues.FromParty);
  const [FromRole, setFromRole] = React.useState(initialValues.FromRole);
  const [ToParty, setToParty] = React.useState(initialValues.ToParty);
  const [ToRole, setToRole] = React.useState(initialValues.ToRole);
  const [PartyRelationshipStartDate, setPartyRelationshipStartDate] =
    React.useState(initialValues.PartyRelationshipStartDate);
  const [PartyRelationshipEndDate, setPartyRelationshipEndDate] =
    React.useState(initialValues.PartyRelationshipEndDate);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setRelationshipName(initialValues.RelationshipName);
    setRelationshipDescription(initialValues.RelationshipDescription);
    setPartyRelationshipType(initialValues.PartyRelationshipType);
    setFromParty(initialValues.FromParty);
    setFromRole(initialValues.FromRole);
    setToParty(initialValues.ToParty);
    setToRole(initialValues.ToRole);
    setPartyRelationshipStartDate(initialValues.PartyRelationshipStartDate);
    setPartyRelationshipEndDate(initialValues.PartyRelationshipEndDate);
    setErrors({});
  };
  const validations = {
    RelationshipName: [],
    RelationshipDescription: [],
    PartyRelationshipType: [],
    FromParty: [],
    FromRole: [],
    ToParty: [],
    ToRole: [],
    PartyRelationshipStartDate: [],
    PartyRelationshipEndDate: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          RelationshipName,
          RelationshipDescription,
          PartyRelationshipType,
          FromParty,
          FromRole,
          ToParty,
          ToRole,
          PartyRelationshipStartDate,
          PartyRelationshipEndDate,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new PartyRelationship(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "PartyRelationshipCreateForm")}
      {...rest}
    >
      <TextField
        label="Relationship name"
        isRequired={false}
        isReadOnly={false}
        value={RelationshipName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName: value,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty,
              FromRole,
              ToParty,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.RelationshipName ?? value;
          }
          if (errors.RelationshipName?.hasError) {
            runValidationTasks("RelationshipName", value);
          }
          setRelationshipName(value);
        }}
        onBlur={() => runValidationTasks("RelationshipName", RelationshipName)}
        errorMessage={errors.RelationshipName?.errorMessage}
        hasError={errors.RelationshipName?.hasError}
        {...getOverrideProps(overrides, "RelationshipName")}
      ></TextField>
      <TextField
        label="Relationship description"
        isRequired={false}
        isReadOnly={false}
        value={RelationshipDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription: value,
              PartyRelationshipType,
              FromParty,
              FromRole,
              ToParty,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.RelationshipDescription ?? value;
          }
          if (errors.RelationshipDescription?.hasError) {
            runValidationTasks("RelationshipDescription", value);
          }
          setRelationshipDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("RelationshipDescription", RelationshipDescription)
        }
        errorMessage={errors.RelationshipDescription?.errorMessage}
        hasError={errors.RelationshipDescription?.hasError}
        {...getOverrideProps(overrides, "RelationshipDescription")}
      ></TextField>
      <SelectField
        label="Party relationship type"
        placeholder="Please select an option"
        isDisabled={false}
        value={PartyRelationshipType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType: value,
              FromParty,
              FromRole,
              ToParty,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.PartyRelationshipType ?? value;
          }
          if (errors.PartyRelationshipType?.hasError) {
            runValidationTasks("PartyRelationshipType", value);
          }
          setPartyRelationshipType(value);
        }}
        onBlur={() =>
          runValidationTasks("PartyRelationshipType", PartyRelationshipType)
        }
        errorMessage={errors.PartyRelationshipType?.errorMessage}
        hasError={errors.PartyRelationshipType?.hasError}
        {...getOverrideProps(overrides, "PartyRelationshipType")}
      >
        <option
          children="Organisation rollup"
          value="OrganisationRollup"
          {...getOverrideProps(overrides, "PartyRelationshipTypeoption0")}
        ></option>
        <option
          children="Target rollup"
          value="TargetRollup"
          {...getOverrideProps(overrides, "PartyRelationshipTypeoption1")}
        ></option>
      </SelectField>
      <TextField
        label="From party"
        isRequired={false}
        isReadOnly={false}
        value={FromParty}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty: value,
              FromRole,
              ToParty,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.FromParty ?? value;
          }
          if (errors.FromParty?.hasError) {
            runValidationTasks("FromParty", value);
          }
          setFromParty(value);
        }}
        onBlur={() => runValidationTasks("FromParty", FromParty)}
        errorMessage={errors.FromParty?.errorMessage}
        hasError={errors.FromParty?.hasError}
        {...getOverrideProps(overrides, "FromParty")}
      ></TextField>
      <SelectField
        label="From role"
        placeholder="Please select an option"
        isDisabled={false}
        value={FromRole}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty,
              FromRole: value,
              ToParty,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.FromRole ?? value;
          }
          if (errors.FromRole?.hasError) {
            runValidationTasks("FromRole", value);
          }
          setFromRole(value);
        }}
        onBlur={() => runValidationTasks("FromRole", FromRole)}
        errorMessage={errors.FromRole?.errorMessage}
        hasError={errors.FromRole?.hasError}
        {...getOverrideProps(overrides, "FromRole")}
      >
        <option
          children="Parent entity"
          value="ParentEntity"
          {...getOverrideProps(overrides, "FromRoleoption0")}
        ></option>
        <option
          children="Subsidiary"
          value="Subsidiary"
          {...getOverrideProps(overrides, "FromRoleoption1")}
        ></option>
        <option
          children="Supplier"
          value="Supplier"
          {...getOverrideProps(overrides, "FromRoleoption2")}
        ></option>
        <option
          children="Target"
          value="Target"
          {...getOverrideProps(overrides, "FromRoleoption3")}
        ></option>
        <option
          children="Milestone"
          value="Milestone"
          {...getOverrideProps(overrides, "FromRoleoption4")}
        ></option>
        <option
          children="Interim target"
          value="InterimTarget"
          {...getOverrideProps(overrides, "FromRoleoption5")}
        ></option>
      </SelectField>
      <TextField
        label="To party"
        isRequired={false}
        isReadOnly={false}
        value={ToParty}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty,
              FromRole,
              ToParty: value,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.ToParty ?? value;
          }
          if (errors.ToParty?.hasError) {
            runValidationTasks("ToParty", value);
          }
          setToParty(value);
        }}
        onBlur={() => runValidationTasks("ToParty", ToParty)}
        errorMessage={errors.ToParty?.errorMessage}
        hasError={errors.ToParty?.hasError}
        {...getOverrideProps(overrides, "ToParty")}
      ></TextField>
      <SelectField
        label="To role"
        placeholder="Please select an option"
        isDisabled={false}
        value={ToRole}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty,
              FromRole,
              ToParty,
              ToRole: value,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.ToRole ?? value;
          }
          if (errors.ToRole?.hasError) {
            runValidationTasks("ToRole", value);
          }
          setToRole(value);
        }}
        onBlur={() => runValidationTasks("ToRole", ToRole)}
        errorMessage={errors.ToRole?.errorMessage}
        hasError={errors.ToRole?.hasError}
        {...getOverrideProps(overrides, "ToRole")}
      >
        <option
          children="Parent entity"
          value="ParentEntity"
          {...getOverrideProps(overrides, "ToRoleoption0")}
        ></option>
        <option
          children="Subsidiary"
          value="Subsidiary"
          {...getOverrideProps(overrides, "ToRoleoption1")}
        ></option>
        <option
          children="Supplier"
          value="Supplier"
          {...getOverrideProps(overrides, "ToRoleoption2")}
        ></option>
        <option
          children="Target"
          value="Target"
          {...getOverrideProps(overrides, "ToRoleoption3")}
        ></option>
        <option
          children="Milestone"
          value="Milestone"
          {...getOverrideProps(overrides, "ToRoleoption4")}
        ></option>
        <option
          children="Interim target"
          value="InterimTarget"
          {...getOverrideProps(overrides, "ToRoleoption5")}
        ></option>
      </SelectField>
      <TextField
        label="Party relationship start date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={PartyRelationshipStartDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty,
              FromRole,
              ToParty,
              ToRole,
              PartyRelationshipStartDate: value,
              PartyRelationshipEndDate,
            };
            const result = onChange(modelFields);
            value = result?.PartyRelationshipStartDate ?? value;
          }
          if (errors.PartyRelationshipStartDate?.hasError) {
            runValidationTasks("PartyRelationshipStartDate", value);
          }
          setPartyRelationshipStartDate(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "PartyRelationshipStartDate",
            PartyRelationshipStartDate
          )
        }
        errorMessage={errors.PartyRelationshipStartDate?.errorMessage}
        hasError={errors.PartyRelationshipStartDate?.hasError}
        {...getOverrideProps(overrides, "PartyRelationshipStartDate")}
      ></TextField>
      <TextField
        label="Party relationship end date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={PartyRelationshipEndDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RelationshipName,
              RelationshipDescription,
              PartyRelationshipType,
              FromParty,
              FromRole,
              ToParty,
              ToRole,
              PartyRelationshipStartDate,
              PartyRelationshipEndDate: value,
            };
            const result = onChange(modelFields);
            value = result?.PartyRelationshipEndDate ?? value;
          }
          if (errors.PartyRelationshipEndDate?.hasError) {
            runValidationTasks("PartyRelationshipEndDate", value);
          }
          setPartyRelationshipEndDate(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "PartyRelationshipEndDate",
            PartyRelationshipEndDate
          )
        }
        errorMessage={errors.PartyRelationshipEndDate?.errorMessage}
        hasError={errors.PartyRelationshipEndDate?.hasError}
        {...getOverrideProps(overrides, "PartyRelationshipEndDate")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
