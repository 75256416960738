import HelpBox from "./HelpBox";

export default function AssetRegisterHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Asset Register</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Create or update an asset register, to group and manage assets that are subject to climate-related risks and opportunities.
                </p>
                <p>
                    You can create and maintain multiple asset registers for different purposes, such as grouping assets that belong to different businesses, business units or locations.
                </p>
                <p>
                    To create an asset register, enter a name and description, then click 'Create' at the top left of the window. Next, select one or more assets from the 'Assets' table on the right hand side to link to the asset register, and click 'Update'. You can create assets by clicking the 'New' button at the bottom of the 'Assets' table, and also 'Edit' and 'Delete' an existing asset using the buttons at the end of each Asset row in the 'Asset' table.
                </p>
                <p>
                    You can use the search box at the top left hand side to find an existing asset register. Click in the search box to see available registers, then select the appropriate register to load it into the window.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
