import HelpBox from "../../shared/content/HelpBox";

export default function RiskManagementDataSetHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Risk Management Record Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to collate records on Risk Management data required for a climate-related disclosure.
        </p>
        <p>
          The purpose of your Risk Management record is to identify, assess, prioritise and monitor climate-related risks and opportunities, and how these are integrated into your organization's risk management processes.
        </p>
        <p>Follow these steps to create a Risk Management record:</p>
        <ol>
          <li>
            Enter a name and description for the risk management data.
          </li>
          <li>
            Select or create and select records for each of the tables in the middle of the window covering: <i>Risk Management Processes and Policies</i> and <i>Supplemental Risk Management Data</i>.
          </li>
          <li>
            Once selected, choose <b>Create</b> or <b>Update</b>.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
