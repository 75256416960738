/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedTargets } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedTargetsTargetSettingReviewApproachCreateForm(
  props
) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    TargetMethodologyValidation: "",
    TargetReviewProcessName: "",
    TargetReviewProcessFrequency: "",
    TargetReviewProcessDescription: "",
    ProgressMonitoringMetric: "",
    TargetRevision: "",
    RevisionExplanation: "",
  };
  const [TargetMethodologyValidation, setTargetMethodologyValidation] =
    React.useState(initialValues.TargetMethodologyValidation);
  const [TargetReviewProcessName, setTargetReviewProcessName] = React.useState(
    initialValues.TargetReviewProcessName
  );
  const [TargetReviewProcessFrequency, setTargetReviewProcessFrequency] =
    React.useState(initialValues.TargetReviewProcessFrequency);
  const [TargetReviewProcessDescription, setTargetReviewProcessDescription] =
    React.useState(initialValues.TargetReviewProcessDescription);
  const [ProgressMonitoringMetric, setProgressMonitoringMetric] =
    React.useState(initialValues.ProgressMonitoringMetric);
  const [TargetRevision, setTargetRevision] = React.useState(
    initialValues.TargetRevision
  );
  const [RevisionExplanation, setRevisionExplanation] = React.useState(
    initialValues.RevisionExplanation
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setTargetMethodologyValidation(initialValues.TargetMethodologyValidation);
    setTargetReviewProcessName(initialValues.TargetReviewProcessName);
    setTargetReviewProcessFrequency(initialValues.TargetReviewProcessFrequency);
    setTargetReviewProcessDescription(
      initialValues.TargetReviewProcessDescription
    );
    setProgressMonitoringMetric(initialValues.ProgressMonitoringMetric);
    setTargetRevision(initialValues.TargetRevision);
    setRevisionExplanation(initialValues.RevisionExplanation);
    setErrors({});
  };
  const validations = {
    TargetMethodologyValidation: [],
    TargetReviewProcessName: [],
    TargetReviewProcessFrequency: [],
    TargetReviewProcessDescription: [],
    ProgressMonitoringMetric: [],
    TargetRevision: [],
    RevisionExplanation: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          TargetMethodologyValidation,
          TargetReviewProcessName,
          TargetReviewProcessFrequency,
          TargetReviewProcessDescription,
          ProgressMonitoringMetric,
          TargetRevision,
          RevisionExplanation,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateRelatedTargets(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedTargetsTargetSettingReviewApproachCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Targets - Target Setting and Review Approach"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 34 a-d."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Enter data to describe your approach to setting and reviewing this target, and how you monitor progress against this target."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <SelectField
        label="Select whether this target, and the methodology for setting this target, has been validated by a third party"
        placeholder="Please select a validation option"
        isDisabled={false}
        value={TargetMethodologyValidation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetMethodologyValidation: value,
              TargetReviewProcessName,
              TargetReviewProcessFrequency,
              TargetReviewProcessDescription,
              ProgressMonitoringMetric,
              TargetRevision,
              RevisionExplanation,
            };
            const result = onChange(modelFields);
            value = result?.TargetMethodologyValidation ?? value;
          }
          if (errors.TargetMethodologyValidation?.hasError) {
            runValidationTasks("TargetMethodologyValidation", value);
          }
          setTargetMethodologyValidation(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TargetMethodologyValidation",
            TargetMethodologyValidation
          )
        }
        errorMessage={errors.TargetMethodologyValidation?.errorMessage}
        hasError={errors.TargetMethodologyValidation?.hasError}
        {...getOverrideProps(overrides, "TargetMethodologyValidation")}
      >
        <option
          children="Validated by third party"
          value="ValidatedByThirdParty"
          {...getOverrideProps(overrides, "TargetMethodologyValidationoption0")}
        ></option>
        <option
          children="Not validated"
          value="NotValidated"
          {...getOverrideProps(overrides, "TargetMethodologyValidationoption1")}
        ></option>
      </SelectField>
      <Text
        children="Describe your processes for reviewing the target"
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Target review process name"
          isRequired={false}
          isReadOnly={false}
          value={TargetReviewProcessName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetMethodologyValidation,
                TargetReviewProcessName: value,
                TargetReviewProcessFrequency,
                TargetReviewProcessDescription,
                ProgressMonitoringMetric,
                TargetRevision,
                RevisionExplanation,
              };
              const result = onChange(modelFields);
              value = result?.TargetReviewProcessName ?? value;
            }
            if (errors.TargetReviewProcessName?.hasError) {
              runValidationTasks("TargetReviewProcessName", value);
            }
            setTargetReviewProcessName(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "TargetReviewProcessName",
              TargetReviewProcessName
            )
          }
          errorMessage={errors.TargetReviewProcessName?.errorMessage}
          hasError={errors.TargetReviewProcessName?.hasError}
          {...getOverrideProps(overrides, "TargetReviewProcessName")}
        ></TextField>
        <SelectField
          label="Target review process frequency"
          placeholder="Please select an option"
          isDisabled={false}
          value={TargetReviewProcessFrequency}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetMethodologyValidation,
                TargetReviewProcessName,
                TargetReviewProcessFrequency: value,
                TargetReviewProcessDescription,
                ProgressMonitoringMetric,
                TargetRevision,
                RevisionExplanation,
              };
              const result = onChange(modelFields);
              value = result?.TargetReviewProcessFrequency ?? value;
            }
            if (errors.TargetReviewProcessFrequency?.hasError) {
              runValidationTasks("TargetReviewProcessFrequency", value);
            }
            setTargetReviewProcessFrequency(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "TargetReviewProcessFrequency",
              TargetReviewProcessFrequency
            )
          }
          errorMessage={errors.TargetReviewProcessFrequency?.errorMessage}
          hasError={errors.TargetReviewProcessFrequency?.hasError}
          {...getOverrideProps(overrides, "TargetReviewProcessFrequency")}
        >
          <option
            children="Daily"
            value="Daily"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption0"
            )}
          ></option>
          <option
            children="Weekly"
            value="Weekly"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption1"
            )}
          ></option>
          <option
            children="Fortnightly"
            value="Fortnightly"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption2"
            )}
          ></option>
          <option
            children="Monthly"
            value="Monthly"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption3"
            )}
          ></option>
          <option
            children="Quarterly"
            value="Quarterly"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption4"
            )}
          ></option>
          <option
            children="Half yearly"
            value="HalfYearly"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption5"
            )}
          ></option>
          <option
            children="Yearly"
            value="Yearly"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption6"
            )}
          ></option>
          <option
            children="Every two years"
            value="EveryTwoYears"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption7"
            )}
          ></option>
          <option
            children="Every three years"
            value="EveryThreeYears"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption8"
            )}
          ></option>
          <option
            children="Adhoc"
            value="Adhoc"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption9"
            )}
          ></option>
          <option
            children="Never"
            value="Never"
            {...getOverrideProps(
              overrides,
              "TargetReviewProcessFrequencyoption10"
            )}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Target review process description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetMethodologyValidation,
              TargetReviewProcessName,
              TargetReviewProcessFrequency,
              TargetReviewProcessDescription: value,
              ProgressMonitoringMetric,
              TargetRevision,
              RevisionExplanation,
            };
            const result = onChange(modelFields);
            value = result?.TargetReviewProcessDescription ?? value;
          }
          if (errors.TargetReviewProcessDescription?.hasError) {
            runValidationTasks("TargetReviewProcessDescription", value);
          }
          setTargetReviewProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TargetReviewProcessDescription",
            TargetReviewProcessDescription
          )
        }
        errorMessage={errors.TargetReviewProcessDescription?.errorMessage}
        hasError={errors.TargetReviewProcessDescription?.hasError}
        	value={TargetReviewProcessDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "TargetReviewProcessDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Define the metrics used to monitor progress towards reaching this target (alternatively, link required metric(s) from the Climate Related Metrics table below)"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetMethodologyValidation,
              TargetReviewProcessName,
              TargetReviewProcessFrequency,
              TargetReviewProcessDescription,
              ProgressMonitoringMetric: value,
              TargetRevision,
              RevisionExplanation,
            };
            const result = onChange(modelFields);
            value = result?.ProgressMonitoringMetric ?? value;
          }
          if (errors.ProgressMonitoringMetric?.hasError) {
            runValidationTasks("ProgressMonitoringMetric", value);
          }
          setProgressMonitoringMetric(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ProgressMonitoringMetric",
            ProgressMonitoringMetric
          )
        }
        errorMessage={errors.ProgressMonitoringMetric?.errorMessage}
        hasError={errors.ProgressMonitoringMetric?.hasError}
        	value={ProgressMonitoringMetric} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "ProgressMonitoringMetric")}
      ></TextAreaField>
      <TextAreaField
        label="Describe any revisions to this target"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetMethodologyValidation,
              TargetReviewProcessName,
              TargetReviewProcessFrequency,
              TargetReviewProcessDescription,
              ProgressMonitoringMetric,
              TargetRevision: value,
              RevisionExplanation,
            };
            const result = onChange(modelFields);
            value = result?.TargetRevision ?? value;
          }
          if (errors.TargetRevision?.hasError) {
            runValidationTasks("TargetRevision", value);
          }
          setTargetRevision(value);
        }}
        onBlur={() => runValidationTasks("TargetRevision", TargetRevision)}
        errorMessage={errors.TargetRevision?.errorMessage}
        hasError={errors.TargetRevision?.hasError}
        	value={TargetRevision} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "TargetRevision")}
      ></TextAreaField>
      <TextAreaField
        label="Explain any revisions to this target"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetMethodologyValidation,
              TargetReviewProcessName,
              TargetReviewProcessFrequency,
              TargetReviewProcessDescription,
              ProgressMonitoringMetric,
              TargetRevision,
              RevisionExplanation: value,
            };
            const result = onChange(modelFields);
            value = result?.RevisionExplanation ?? value;
          }
          if (errors.RevisionExplanation?.hasError) {
            runValidationTasks("RevisionExplanation", value);
          }
          setRevisionExplanation(value);
        }}
        onBlur={() =>
          runValidationTasks("RevisionExplanation", RevisionExplanation)
        }
        errorMessage={errors.RevisionExplanation?.errorMessage}
        hasError={errors.RevisionExplanation?.hasError}
        	value={RevisionExplanation} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "RevisionExplanation")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
