export const schema = {
    "models": {
        "FinancialEffect": {
            "name": "FinancialEffect",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectName": {
                    "name": "FinancialEffectName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectType": {
                    "name": "FinancialEffectType",
                    "isArray": false,
                    "type": {
                        "enum": "FinancialEffectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "HorizonPeriodType": {
                    "name": "HorizonPeriodType",
                    "isArray": false,
                    "type": {
                        "enum": "ClimatePlanningHorizonsEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialPositionChangeType": {
                    "name": "FinancialPositionChangeType",
                    "isArray": false,
                    "type": {
                        "enum": "FinancialPositionChangeTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectQuantitativeDescription": {
                    "name": "FinancialEffectQuantitativeDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectValue": {
                    "name": "FinancialEffectValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectUnit": {
                    "name": "FinancialEffectUnit",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectValueMin": {
                    "name": "FinancialEffectValueMin",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectValueMax": {
                    "name": "FinancialEffectValueMax",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "WhyNoQuanitativeFinancialData": {
                    "name": "WhyNoQuanitativeFinancialData",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectQualitativeDescription": {
                    "name": "FinancialEffectQualitativeDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "riskopportunityID": {
                    "name": "riskopportunityID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "FinancialEffects",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRiskOpportunity",
                        "fields": [
                            "riskopportunityID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PartyRelationship": {
            "name": "PartyRelationship",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "RelationshipName": {
                    "name": "RelationshipName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RelationshipDescription": {
                    "name": "RelationshipDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PartyRelationshipType": {
                    "name": "PartyRelationshipType",
                    "isArray": false,
                    "type": {
                        "enum": "PartyRelationshipTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FromParty": {
                    "name": "FromParty",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FromRole": {
                    "name": "FromRole",
                    "isArray": false,
                    "type": {
                        "enum": "PartyRoleTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ToParty": {
                    "name": "ToParty",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ToRole": {
                    "name": "ToRole",
                    "isArray": false,
                    "type": {
                        "enum": "PartyRoleTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "PartyRelationshipStartDate": {
                    "name": "PartyRelationshipStartDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "PartyRelationshipEndDate": {
                    "name": "PartyRelationshipEndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PartyRelationships",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AuditLog": {
            "name": "AuditLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "LogName": {
                    "name": "LogName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "StartDate": {
                    "name": "StartDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "EndDate": {
                    "name": "EndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AuditLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Evidence": {
            "name": "Evidence",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetEntityModel": {
                    "name": "TargetEntityModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetEntityID": {
                    "name": "TargetEntityID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetSubtype": {
                    "name": "TargetSubtype",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AttachmentKey": {
                    "name": "AttachmentKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ContentType": {
                    "name": "ContentType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ValidFrom": {
                    "name": "ValidFrom",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "ValidTo": {
                    "name": "ValidTo",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "Size": {
                    "name": "Size",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Url": {
                    "name": "Url",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Evidences",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Comment": {
            "name": "Comment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetEntityModel": {
                    "name": "TargetEntityModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetEntityID": {
                    "name": "TargetEntityID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Comment": {
                    "name": "Comment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Comments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AvoidedEmissionAccountEntry": {
            "name": "AvoidedEmissionAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AvoidedEmissionTransactionName": {
                    "name": "AvoidedEmissionTransactionName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AvoidedEmissionTransactionDescription": {
                    "name": "AvoidedEmissionTransactionDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AvoidedEmissionTransactionDataSource": {
                    "name": "AvoidedEmissionTransactionDataSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AvoidedEmissionTransactionDate": {
                    "name": "AvoidedEmissionTransactionDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "GrossAmountCO2Avoided": {
                    "name": "GrossAmountCO2Avoided",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "ReportingPeriod": {
                    "name": "ReportingPeriod",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_carbonaccounts": {
                    "name": "_carbonaccounts",
                    "isArray": true,
                    "type": {
                        "model": "CarbonAccountAvoidedEmissionAccountEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "avoidedEmissionAccountEntry"
                        ]
                    }
                },
                "AvoidedEmissionTransactionStatus": {
                    "name": "AvoidedEmissionTransactionStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ReductionInitiativeStatusEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AvoidedEmissionAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BiogenicAccountEntry": {
            "name": "BiogenicAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "BiogenicTransactionName": {
                    "name": "BiogenicTransactionName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "BiogenicTransactionDescription": {
                    "name": "BiogenicTransactionDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "BiogenicTransactionDataSource": {
                    "name": "BiogenicTransactionDataSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "SequestrationDate": {
                    "name": "SequestrationDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "ReportingPeriod": {
                    "name": "ReportingPeriod",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GrossAmountCO2Sequestered": {
                    "name": "GrossAmountCO2Sequestered",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "_carbonaccounts": {
                    "name": "_carbonaccounts",
                    "isArray": true,
                    "type": {
                        "model": "CarbonAccountBiogenicAccountEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "biogenicAccountEntry"
                        ]
                    }
                },
                "BiogenicTransactionStatus": {
                    "name": "BiogenicTransactionStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ReductionInitiativeStatusEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "BiogenicAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Location": {
            "name": "Location",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LocationName": {
                    "name": "LocationName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LocationID": {
                    "name": "LocationID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FacilityOrSite": {
                    "name": "FacilityOrSite",
                    "isArray": false,
                    "type": {
                        "enum": "FacilityOrSiteEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AddressLine1": {
                    "name": "AddressLine1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AddressLine2": {
                    "name": "AddressLine2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AddressLine3": {
                    "name": "AddressLine3",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AddressPostCode": {
                    "name": "AddressPostCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AddressCity": {
                    "name": "AddressCity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AddressCountry": {
                    "name": "AddressCountry",
                    "isArray": false,
                    "type": {
                        "enum": "CountryEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Latitude": {
                    "name": "Latitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "Longitude": {
                    "name": "Longitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "_assets": {
                    "name": "_assets",
                    "isArray": true,
                    "type": {
                        "model": "AssetLocation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "location"
                        ]
                    }
                },
                "_reportingentitys": {
                    "name": "_reportingentitys",
                    "isArray": true,
                    "type": {
                        "model": "ReportingEntityLocation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "location"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Locations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Asset": {
            "name": "Asset",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "AssetID": {
                    "name": "AssetID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssetName": {
                    "name": "AssetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssetClass": {
                    "name": "AssetClass",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssetType": {
                    "name": "AssetType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssetComponent": {
                    "name": "AssetComponent",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssetDescription": {
                    "name": "AssetDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Quantity": {
                    "name": "Quantity",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CapitalisationDate": {
                    "name": "CapitalisationDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "ValueAtAcquisition": {
                    "name": "ValueAtAcquisition",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "ValueClimateAffected": {
                    "name": "ValueClimateAffected",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "ValueClimateAffectedType": {
                    "name": "ValueClimateAffectedType",
                    "isArray": false,
                    "type": {
                        "enum": "AmountPercentageEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentValue": {
                    "name": "CurrentValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "Condition": {
                    "name": "Condition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Lifespan": {
                    "name": "Lifespan",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "_assetregisters": {
                    "name": "_assetregisters",
                    "isArray": true,
                    "type": {
                        "model": "AssetAssetRegister"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "asset"
                        ]
                    }
                },
                "Locations": {
                    "name": "Locations",
                    "isArray": true,
                    "type": {
                        "model": "AssetLocation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "asset"
                        ]
                    }
                },
                "_climaterelatedmetricss": {
                    "name": "_climaterelatedmetricss",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedMetricsAsset"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "asset"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Assets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AssetRegister": {
            "name": "AssetRegister",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "RegisterName": {
                    "name": "RegisterName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Assets": {
                    "name": "Assets",
                    "isArray": true,
                    "type": {
                        "model": "AssetAssetRegister"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "assetRegister"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AssetRegisters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AppUsers": {
            "name": "AppUsers",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Email": {
                    "name": "Email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Enabled": {
                    "name": "Enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "GivenName": {
                    "name": "GivenName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FamilyName": {
                    "name": "FamilyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Phone": {
                    "name": "Phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Groups": {
                    "name": "Groups",
                    "isArray": true,
                    "type": {
                        "enum": "AppGroup"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Username": {
                    "name": "Username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AppUsers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "read",
                                    "update",
                                    "create",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "OffsetAccountEntry": {
            "name": "OffsetAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetName": {
                    "name": "OffsetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetDescription": {
                    "name": "OffsetDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetDataSource": {
                    "name": "OffsetDataSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetCertificationSchemeName": {
                    "name": "OffsetCertificationSchemeName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GrossOffset": {
                    "name": "GrossOffset",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetPurchaseDate": {
                    "name": "OffsetPurchaseDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetValidityStartDate": {
                    "name": "OffsetValidityStartDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetValidityEndDate": {
                    "name": "OffsetValidityEndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "CarbonCreditType": {
                    "name": "CarbonCreditType",
                    "isArray": false,
                    "type": {
                        "enum": "CarbonCreditTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetAchievementType": {
                    "name": "OffsetAchievementType",
                    "isArray": false,
                    "type": {
                        "enum": "OffsetAchievementTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "_carbonaccounts": {
                    "name": "_carbonaccounts",
                    "isArray": true,
                    "type": {
                        "model": "OffsetAccountEntryCarbonAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "offsetAccountEntry"
                        ]
                    }
                },
                "_climaterelatedtargetss": {
                    "name": "_climaterelatedtargetss",
                    "isArray": true,
                    "type": {
                        "model": "OffsetAccountEntryClimateRelatedTargets"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "offsetAccountEntry"
                        ]
                    }
                },
                "OffsetTransactionStatus": {
                    "name": "OffsetTransactionStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ReductionInitiativeStatusEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "OffsetAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EmissionAccountEntry": {
            "name": "EmissionAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionScope": {
                    "name": "EmissionScope",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionScopeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionCategory": {
                    "name": "EmissionCategory",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionCategoryEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionGroup": {
                    "name": "EmissionGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionDescription": {
                    "name": "EmissionDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSource": {
                    "name": "DataSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionDate": {
                    "name": "EmissionDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionUnit": {
                    "name": "EmissionUnit",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionUnitEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionUsageValue": {
                    "name": "EmissionUsageValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionConversionFactor": {
                    "name": "EmissionConversionFactor",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "GrossEmission": {
                    "name": "GrossEmission",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionDateRangeStart": {
                    "name": "EmissionDateRangeStart",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionDateRangeEnd": {
                    "name": "EmissionDateRangeEnd",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "_carbonaccounts": {
                    "name": "_carbonaccounts",
                    "isArray": true,
                    "type": {
                        "model": "EmissionAccountEntryCarbonAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "emissionAccountEntry"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "EmissionAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CarbonAccount": {
            "name": "CarbonAccount",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "CarbonAccountSelectType": {
                    "name": "CarbonAccountSelectType",
                    "isArray": false,
                    "type": {
                        "enum": "CarbonAccountSelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "AccountName": {
                    "name": "AccountName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionAccountEntries": {
                    "name": "EmissionAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "EmissionAccountEntryCarbonAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccount"
                        ]
                    }
                },
                "ReductionAccountEntries": {
                    "name": "ReductionAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "ReductionAccountEntryCarbonAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccount"
                        ]
                    }
                },
                "OffsetAccountEntries": {
                    "name": "OffsetAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "OffsetAccountEntryCarbonAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccount"
                        ]
                    }
                },
                "BiogenicAccountEntries": {
                    "name": "BiogenicAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "CarbonAccountBiogenicAccountEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccount"
                        ]
                    }
                },
                "AvoidedEmissionAccountEntries": {
                    "name": "AvoidedEmissionAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "CarbonAccountAvoidedEmissionAccountEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccount"
                        ]
                    }
                },
                "_carbonaccountledgers": {
                    "name": "_carbonaccountledgers",
                    "isArray": true,
                    "type": {
                        "model": "CarbonAccountCarbonAccountingLedger"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccount"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CarbonAccounts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CarbonAccountingLedger": {
            "name": "CarbonAccountingLedger",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "CarbonAccountingLedgerName": {
                    "name": "CarbonAccountingLedgerName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CarbonAccounts": {
                    "name": "CarbonAccounts",
                    "isArray": true,
                    "type": {
                        "model": "CarbonAccountCarbonAccountingLedger"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "carbonAccountingLedger"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CarbonAccountingLedgers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ReductionAccountEntry": {
            "name": "ReductionAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "_climaterelatedtargetss": {
                    "name": "_climaterelatedtargetss",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedTargetsReductionAccountEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "reductionAccountEntry"
                        ]
                    }
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "ReductionInitiativeName": {
                    "name": "ReductionInitiativeName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ReductionInitiativeStatus": {
                    "name": "ReductionInitiativeStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ReductionInitiativeStatusEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ReductionInitiativeDescription": {
                    "name": "ReductionInitiativeDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "EstimatedAnnualCO2Saving": {
                    "name": "EstimatedAnnualCO2Saving",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "StartDate": {
                    "name": "StartDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "EndDate": {
                    "name": "EndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionReductionUnit": {
                    "name": "EmissionReductionUnit",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionUnitEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "EmissionReductionValue": {
                    "name": "EmissionReductionValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "CO2SavingScope": {
                    "name": "CO2SavingScope",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionScopeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "AnnualMonetarySaving": {
                    "name": "AnnualMonetarySaving",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "PaybackPeriod": {
                    "name": "PaybackPeriod",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "RequiredInvestment": {
                    "name": "RequiredInvestment",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "EstimatedInitiativeLifetime": {
                    "name": "EstimatedInitiativeLifetime",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "GrossEmissionReductionAchieved": {
                    "name": "GrossEmissionReductionAchieved",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "DateAchieved": {
                    "name": "DateAchieved",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "_carbonaccounts": {
                    "name": "_carbonaccounts",
                    "isArray": true,
                    "type": {
                        "model": "ReductionAccountEntryCarbonAccount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "reductionAccountEntry"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ReductionAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedTargets": {
            "name": "ClimateRelatedTargets",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "_metricsandtargetsdatacollections": {
                    "name": "_metricsandtargetsdatacollections",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedTargetsMetricAndTargetDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedTargets"
                        ]
                    }
                },
                "TargetName": {
                    "name": "TargetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetDescription": {
                    "name": "TargetDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateRelatedMetrics": {
                    "name": "ClimateRelatedMetrics",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedMetricsClimateRelatedTargets"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedTargets"
                        ]
                    }
                },
                "TargetObjective": {
                    "name": "TargetObjective",
                    "isArray": false,
                    "type": {
                        "enum": "TargetObjectiveEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ApplicableEntityPart": {
                    "name": "ApplicableEntityPart",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetTimeframeStartDate": {
                    "name": "TargetTimeframeStartDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetTimeframeEndDate": {
                    "name": "TargetTimeframeEndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetBaseYear": {
                    "name": "TargetBaseYear",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetType": {
                    "name": "TargetType",
                    "isArray": false,
                    "type": {
                        "enum": "TargetTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TargetUnit": {
                    "name": "TargetUnit",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionUnitEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TargetValue": {
                    "name": "TargetValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "InformedByInternationalAgreement": {
                    "name": "InformedByInternationalAgreement",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetMethodologyValidation": {
                    "name": "TargetMethodologyValidation",
                    "isArray": false,
                    "type": {
                        "enum": "TargetMethodologyValidationEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ProgressMonitoringMetric": {
                    "name": "ProgressMonitoringMetric",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetRevision": {
                    "name": "TargetRevision",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RevisionExplanation": {
                    "name": "RevisionExplanation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PerformanceAgainstTarget": {
                    "name": "PerformanceAgainstTarget",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ReductionAccountEntries": {
                    "name": "ReductionAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedTargetsReductionAccountEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedTargets"
                        ]
                    }
                },
                "GHGCoveredByTarget": {
                    "name": "GHGCoveredByTarget",
                    "isArray": false,
                    "type": {
                        "enum": "GhgCoveredByTarget"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TargetEmissionScope": {
                    "name": "TargetEmissionScope",
                    "isArray": false,
                    "type": {
                        "enum": "EmissionScopeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TargetEmissionType": {
                    "name": "TargetEmissionType",
                    "isArray": false,
                    "type": {
                        "enum": "TargetEmissionTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SectoralDecarbonisationApproach": {
                    "name": "SectoralDecarbonisationApproach",
                    "isArray": false,
                    "type": {
                        "enum": "SectoralDecarbonisationApproachEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "RelianceonOffsetsType": {
                    "name": "RelianceonOffsetsType",
                    "isArray": false,
                    "type": {
                        "enum": "OffsetRelianceEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ExtentRelianceOnCarbonCredits": {
                    "name": "ExtentRelianceOnCarbonCredits",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtentRelianceOnCarbonCreditsDescription": {
                    "name": "ExtentRelianceOnCarbonCreditsDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "OtherCarbonCreditFactorsDescription": {
                    "name": "OtherCarbonCreditFactorsDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "OffsetAccountEntries": {
                    "name": "OffsetAccountEntries",
                    "isArray": true,
                    "type": {
                        "model": "OffsetAccountEntryClimateRelatedTargets"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedTargets"
                        ]
                    }
                },
                "AnalysisOfTrendsInPerformance": {
                    "name": "AnalysisOfTrendsInPerformance",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssociatedGrossGHGEmissionTarget": {
                    "name": "AssociatedGrossGHGEmissionTarget",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateRelatedTargetsSelectType": {
                    "name": "ClimateRelatedTargetsSelectType",
                    "isArray": false,
                    "type": {
                        "enum": "ClimateRelatedTargetsSelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TargetReviewProcessName": {
                    "name": "TargetReviewProcessName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetReviewProcessDescription": {
                    "name": "TargetReviewProcessDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetReviewProcessFrequency": {
                    "name": "TargetReviewProcessFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "ProcessFrequencyEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ParentTarget": {
                    "name": "ParentTarget",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedTargets"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "climateRelatedTargetsParentTargetId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "climateRelatedTargetsParentTargetId": {
                    "name": "climateRelatedTargetsParentTargetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedTargets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedMetrics": {
            "name": "ClimateRelatedMetrics",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "_metricsandtargetsdatacollections": {
                    "name": "_metricsandtargetsdatacollections",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedMetricsMetricAndTargetDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedMetrics"
                        ]
                    }
                },
                "MetricDataSetName": {
                    "name": "MetricDataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AbsoluteGrossScope1Emissions": {
                    "name": "AbsoluteGrossScope1Emissions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "AbsoluteGrossScope2Emissions": {
                    "name": "AbsoluteGrossScope2Emissions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "AbsoluteGrossScope3Emissions": {
                    "name": "AbsoluteGrossScope3Emissions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "TotalAbsoluteGrossAllScopesEmissions": {
                    "name": "TotalAbsoluteGrossAllScopesEmissions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "GHGMeasurementStandardUsed": {
                    "name": "GHGMeasurementStandardUsed",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MeasurementApproach": {
                    "name": "MeasurementApproach",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MeasurementInputs": {
                    "name": "MeasurementInputs",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MeasurementAssumptions": {
                    "name": "MeasurementAssumptions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ReasonForApproach": {
                    "name": "ReasonForApproach",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ChangesInApproachFromPreviousPeriod": {
                    "name": "ChangesInApproachFromPreviousPeriod",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Scope1EmissionsByConsolidatedAcctGrp": {
                    "name": "Scope1EmissionsByConsolidatedAcctGrp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "Scope2EmissionsByConsolidatedAcctGrp": {
                    "name": "Scope2EmissionsByConsolidatedAcctGrp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "Scope1EmissionsOtherInvestees": {
                    "name": "Scope1EmissionsOtherInvestees",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "Scope2EmissionsOtherInvestees": {
                    "name": "Scope2EmissionsOtherInvestees",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "Scope2LocationBasedEmissions": {
                    "name": "Scope2LocationBasedEmissions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "ContracturalInstrumentsInformingScope2": {
                    "name": "ContracturalInstrumentsInformingScope2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Scope3EmissionsCategoriesIncluded": {
                    "name": "Scope3EmissionsCategoriesIncluded",
                    "isArray": true,
                    "type": {
                        "enum": "EmissionCategoryEnum"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "AdditionalCategory15Information": {
                    "name": "AdditionalCategory15Information",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TransitionRisksAssetActivityType": {
                    "name": "TransitionRisksAssetActivityType",
                    "isArray": false,
                    "type": {
                        "enum": "AssetActivityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TransitionRisksAmount": {
                    "name": "TransitionRisksAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "PhysicalRisksAssetActivityType": {
                    "name": "PhysicalRisksAssetActivityType",
                    "isArray": false,
                    "type": {
                        "enum": "AssetActivityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "PhysicalRisksAmount": {
                    "name": "PhysicalRisksAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "OpportunitiesAssetActivityType": {
                    "name": "OpportunitiesAssetActivityType",
                    "isArray": false,
                    "type": {
                        "enum": "AssetActivityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OpportunitiesAmount": {
                    "name": "OpportunitiesAmount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "CapitalDeploymentValue": {
                    "name": "CapitalDeploymentValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "InternalCarbonPriceDecisionExplanation": {
                    "name": "InternalCarbonPriceDecisionExplanation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "InternalCarbonPrice": {
                    "name": "InternalCarbonPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "RemunerationDescription": {
                    "name": "RemunerationDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RemunerationPercentageValue": {
                    "name": "RemunerationPercentageValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "RemunerationAlternative": {
                    "name": "RemunerationAlternative",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DisclosureTopic": {
                    "name": "DisclosureTopic",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DisclosureCode": {
                    "name": "DisclosureCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Metric": {
                    "name": "Metric",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricCode": {
                    "name": "MetricCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DiscussionAnalysis": {
                    "name": "DiscussionAnalysis",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricValue": {
                    "name": "MetricValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricUnitOfMeasure": {
                    "name": "MetricUnitOfMeasure",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IndustryCode": {
                    "name": "IndustryCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_climaterelatedtargetss": {
                    "name": "_climaterelatedtargetss",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedMetricsClimateRelatedTargets"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedMetrics"
                        ]
                    }
                },
                "TransitionRisksPercentage": {
                    "name": "TransitionRisksPercentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "PhysicalRisksPercentage": {
                    "name": "PhysicalRisksPercentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "OpportunitiesPercentage": {
                    "name": "OpportunitiesPercentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateRelatedMetricsSelectType": {
                    "name": "ClimateRelatedMetricsSelectType",
                    "isArray": false,
                    "type": {
                        "enum": "ClimateRelatedMetricsSelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Assets": {
                    "name": "Assets",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedMetricsAsset"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedMetrics"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedMetrics",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RiskManagementProcessPolicy": {
            "name": "RiskManagementProcessPolicy",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "RiskOpportunityType": {
                    "name": "RiskOpportunityType",
                    "isArray": false,
                    "type": {
                        "enum": "RiskOpportunityTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessName": {
                    "name": "ProcessName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessType": {
                    "name": "ProcessType",
                    "isArray": false,
                    "type": {
                        "enum": "ProcessTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessDescription": {
                    "name": "ProcessDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Inputs": {
                    "name": "Inputs",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Parameters": {
                    "name": "Parameters",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UseOfScenariosInRiskIdentification": {
                    "name": "UseOfScenariosInRiskIdentification",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UseOfScenariosInOpportunityIdentification": {
                    "name": "UseOfScenariosInOpportunityIdentification",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HowAssessRiskEffects": {
                    "name": "HowAssessRiskEffects",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RelativeRiskPrioritisation": {
                    "name": "RelativeRiskPrioritisation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessChangesFromPreviousPeriod": {
                    "name": "ProcessChangesFromPreviousPeriod",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IntegrationWithEnterpriseRiskProcess": {
                    "name": "IntegrationWithEnterpriseRiskProcess",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_riskmanagementdatacollections": {
                    "name": "_riskmanagementdatacollections",
                    "isArray": true,
                    "type": {
                        "model": "RiskManagementProcessPolicyRiskManagementDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "riskManagementProcessPolicy"
                        ]
                    }
                },
                "RiskOpportunities": {
                    "name": "RiskOpportunities",
                    "isArray": true,
                    "type": {
                        "model": "RiskOpportunityRiskManagementProcessPolicy"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "riskManagementProcessPolicy"
                        ]
                    }
                },
                "RiskManagementProcessPolicySelectType": {
                    "name": "RiskManagementProcessPolicySelectType",
                    "isArray": false,
                    "type": {
                        "enum": "RiskManagementProcessPolicySelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RiskManagementProcessPolicies",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ScenarioAnalysisAssumptions": {
            "name": "ScenarioAnalysisAssumptions",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "PolicyAssumptions": {
                    "name": "PolicyAssumptions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MacroeconomicTrends": {
                    "name": "MacroeconomicTrends",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "NationalRegionalVariables": {
                    "name": "NationalRegionalVariables",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "EnergyUsageAndMix": {
                    "name": "EnergyUsageAndMix",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TechnologyDevelopments": {
                    "name": "TechnologyDevelopments",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_climateresiliences": {
                    "name": "_climateresiliences",
                    "isArray": true,
                    "type": {
                        "model": "ScenarioAnalysisAssumptionsClimateResilience"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "scenarioAnalysisAssumptions"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ScenarioAnalysisAssumptions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ScenarioAnalysis": {
            "name": "ScenarioAnalysis",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "_climateresiliences": {
                    "name": "_climateresiliences",
                    "isArray": true,
                    "type": {
                        "model": "ScenarioAnalysisClimateResilience"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "scenarioAnalysis"
                        ]
                    }
                },
                "ScenarioName": {
                    "name": "ScenarioName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ScenarioDescription": {
                    "name": "ScenarioDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ScenarioAnalysisMethod": {
                    "name": "ScenarioAnalysisMethod",
                    "isArray": false,
                    "type": {
                        "enum": "ScenarioAnalysisMethodEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ScenarioTemperatureAlignment": {
                    "name": "ScenarioTemperatureAlignment",
                    "isArray": false,
                    "type": {
                        "enum": "ScenarioTemperatureAlignmentEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ScenarioDataSource": {
                    "name": "ScenarioDataSource",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AssociationWithRisks": {
                    "name": "AssociationWithRisks",
                    "isArray": false,
                    "type": {
                        "enum": "TransitionPhysicalRiskEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "AlignmentWithInternationalAgreement": {
                    "name": "AlignmentWithInternationalAgreement",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ScenarioRelevanceDescription": {
                    "name": "ScenarioRelevanceDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TimeHorizon": {
                    "name": "TimeHorizon",
                    "isArray": false,
                    "type": {
                        "enum": "ClimatePlanningHorizonsEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ScopeOfOperations": {
                    "name": "ScopeOfOperations",
                    "isArray": false,
                    "type": {
                        "enum": "ScopeOfOperationsEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ScopeOfOperationsDetail": {
                    "name": "ScopeOfOperationsDetail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ReportingPeriods": {
                    "name": "ReportingPeriods",
                    "isArray": true,
                    "type": {
                        "model": "ReportingPeriodScenarioAnalysis"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "scenarioAnalysis"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ScenarioAnalyses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateResilienceAssessment": {
            "name": "ClimateResilienceAssessment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "_climateresiliences": {
                    "name": "_climateresiliences",
                    "isArray": true,
                    "type": {
                        "model": "ClimateResilienceClimateResilienceAssessment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateResilienceAssessment"
                        ]
                    }
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "ResilienceAssessmentName": {
                    "name": "ResilienceAssessmentName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateResilienceAssessmentType": {
                    "name": "ClimateResilienceAssessmentType",
                    "isArray": false,
                    "type": {
                        "enum": "ClimateResilienceAssessmentTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "QuantitativeAssessmentValue": {
                    "name": "QuantitativeAssessmentValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "QuantitativeAssessmentUnit": {
                    "name": "QuantitativeAssessmentUnit",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "QuantitativeAssessmentValueMin": {
                    "name": "QuantitativeAssessmentValueMin",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "QuantitativeAssessmentValueMax": {
                    "name": "QuantitativeAssessmentValueMax",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateResilienceAssessments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateResilience": {
            "name": "ClimateResilience",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateResilienceAssessments": {
                    "name": "ClimateResilienceAssessments",
                    "isArray": true,
                    "type": {
                        "model": "ClimateResilienceClimateResilienceAssessment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateResilience"
                        ]
                    }
                },
                "ScenarioAnalyses": {
                    "name": "ScenarioAnalyses",
                    "isArray": true,
                    "type": {
                        "model": "ScenarioAnalysisClimateResilience"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateResilience"
                        ]
                    }
                },
                "ScenarioAnalysisAssumptions": {
                    "name": "ScenarioAnalysisAssumptions",
                    "isArray": true,
                    "type": {
                        "model": "ScenarioAnalysisAssumptionsClimateResilience"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateResilience"
                        ]
                    }
                },
                "_strategydatacollections": {
                    "name": "_strategydatacollections",
                    "isArray": true,
                    "type": {
                        "model": "ClimateResilienceStrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateResilience"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateResiliences",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StrategyDecisionMakingData": {
            "name": "StrategyDecisionMakingData",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "StrategyDecisionMakingDataSelectType": {
                    "name": "StrategyDecisionMakingDataSelectType",
                    "isArray": false,
                    "type": {
                        "enum": "StrategyDecisionMakingDataSelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TransitionPlanName": {
                    "name": "TransitionPlanName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TransitionPlanDescription": {
                    "name": "TransitionPlanDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "KeyAssumptions": {
                    "name": "KeyAssumptions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Dependencies": {
                    "name": "Dependencies",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetAchievementPlanName": {
                    "name": "TargetAchievementPlanName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetAchievementPlanDescription": {
                    "name": "TargetAchievementPlanDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ResourcingPlanName": {
                    "name": "ResourcingPlanName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentResourcingDescription": {
                    "name": "CurrentResourcingDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FutureResourcingDescription": {
                    "name": "FutureResourcingDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PlanProgressName": {
                    "name": "PlanProgressName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "QualitativePlanProgressDescription": {
                    "name": "QualitativePlanProgressDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "QuantitativePlanProgressDescription": {
                    "name": "QuantitativePlanProgressDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "StrategyPlanProgressValue": {
                    "name": "StrategyPlanProgressValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "StrategyPlanProgressUnit": {
                    "name": "StrategyPlanProgressUnit",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_strategydecisionmakings": {
                    "name": "_strategydecisionmakings",
                    "isArray": true,
                    "type": {
                        "model": "StrategyDecisionMakingDataStrategyDecisionMaking"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDecisionMakingData"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StrategyDecisionMakingData",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MitigationAdaptationChange": {
            "name": "MitigationAdaptationChange",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentDirectMitigationAdaptationEffort": {
                    "name": "CurrentDirectMitigationAdaptationEffort",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AnticipatedDirectMitigationAdaptationEffort": {
                    "name": "AnticipatedDirectMitigationAdaptationEffort",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentIndirectMitigationAdaptationEffort": {
                    "name": "CurrentIndirectMitigationAdaptationEffort",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AnticipatedIndirectMitigationAdaptationEffort": {
                    "name": "AnticipatedIndirectMitigationAdaptationEffort",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_strategydecisionmakings": {
                    "name": "_strategydecisionmakings",
                    "isArray": true,
                    "type": {
                        "model": "MitigationAdaptationChangeStrategyDecisionMaking"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "mitigationAdaptationChange"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "MitigationAdaptationChanges",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BusinessModelChange": {
            "name": "BusinessModelChange",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentBusinessModelChange": {
                    "name": "CurrentBusinessModelChange",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AnticipatedBusinessModelChange": {
                    "name": "AnticipatedBusinessModelChange",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_strategydecisionmakings": {
                    "name": "_strategydecisionmakings",
                    "isArray": true,
                    "type": {
                        "model": "BusinessModelChangeStrategyDecisionMaking"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "businessModelChange"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "BusinessModelChanges",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StrategyDecisionMaking": {
            "name": "StrategyDecisionMaking",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_strategydatacollections": {
                    "name": "_strategydatacollections",
                    "isArray": true,
                    "type": {
                        "model": "StrategyDecisionMakingStrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDecisionMaking"
                        ]
                    }
                },
                "BusinessModelChanges": {
                    "name": "BusinessModelChanges",
                    "isArray": true,
                    "type": {
                        "model": "BusinessModelChangeStrategyDecisionMaking"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDecisionMaking"
                        ]
                    }
                },
                "MitigationAdaptationChanges": {
                    "name": "MitigationAdaptationChanges",
                    "isArray": true,
                    "type": {
                        "model": "MitigationAdaptationChangeStrategyDecisionMaking"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDecisionMaking"
                        ]
                    }
                },
                "StrategyDecisionMakingData": {
                    "name": "StrategyDecisionMakingData",
                    "isArray": true,
                    "type": {
                        "model": "StrategyDecisionMakingDataStrategyDecisionMaking"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDecisionMaking"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StrategyDecisionMakings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AggregatedFinancialEffect": {
            "name": "AggregatedFinancialEffect",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "WhyNoQuanitativeFinancialData": {
                    "name": "WhyNoQuanitativeFinancialData",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "QualitativeDescription": {
                    "name": "QualitativeDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "QuantitativeDescription": {
                    "name": "QuantitativeDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectValue": {
                    "name": "FinancialEffectValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectUnit": {
                    "name": "FinancialEffectUnit",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectValueMin": {
                    "name": "FinancialEffectValueMin",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "FinancialEffectValueMax": {
                    "name": "FinancialEffectValueMax",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "_climaterelatedrisksopportunitiess": {
                    "name": "_climaterelatedrisksopportunitiess",
                    "isArray": true,
                    "type": {
                        "model": "AggregatedFinancialEffectClimateRelatedRiskOpportunity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "aggregatedFinancialEffect"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AggregatedFinancialEffects",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RiskOpportunity": {
            "name": "RiskOpportunity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "RiskOpportunityType": {
                    "name": "RiskOpportunityType",
                    "isArray": false,
                    "type": {
                        "enum": "RiskOpportunityTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RiskOpportunityGroups": {
                    "name": "RiskOpportunityGroups",
                    "isArray": false,
                    "type": {
                        "enum": "RiskOpportunityGroupEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TimeHorizon": {
                    "name": "TimeHorizon",
                    "isArray": false,
                    "type": {
                        "enum": "ClimatePlanningHorizonsEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "LikelihoodOfOccurring": {
                    "name": "LikelihoodOfOccurring",
                    "isArray": false,
                    "type": {
                        "enum": "LikelihoodOfOccurringEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Priority": {
                    "name": "Priority",
                    "isArray": false,
                    "type": {
                        "enum": "PriorityEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "_climaterelatedrisksopportunitiess": {
                    "name": "_climaterelatedrisksopportunitiess",
                    "isArray": true,
                    "type": {
                        "model": "RiskOpportunityClimateRelatedRiskOpportunity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "riskOpportunity"
                        ]
                    }
                },
                "WhereConcentratedBusinessModel": {
                    "name": "WhereConcentratedBusinessModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "WhereConcentratedValueChain": {
                    "name": "WhereConcentratedValueChain",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentEffectOnBusinessModel": {
                    "name": "CurrentEffectOnBusinessModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrentEffectOnValueChain": {
                    "name": "CurrentEffectOnValueChain",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AnticipatedEffectOnBusinessModel": {
                    "name": "AnticipatedEffectOnBusinessModel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AnticipatedEffectOnValueChain": {
                    "name": "AnticipatedEffectOnValueChain",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_riskmanagementprocesspolicys": {
                    "name": "_riskmanagementprocesspolicys",
                    "isArray": true,
                    "type": {
                        "model": "RiskOpportunityRiskManagementProcessPolicy"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "riskOpportunity"
                        ]
                    }
                },
                "FinancialEffects": {
                    "name": "FinancialEffects",
                    "isArray": true,
                    "type": {
                        "model": "FinancialEffect"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "riskopportunityID"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RiskOpportunities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedRiskOpportunity": {
            "name": "ClimateRelatedRiskOpportunity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_strategydatacollections": {
                    "name": "_strategydatacollections",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedRiskOpportunityStrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedRiskOpportunity"
                        ]
                    }
                },
                "RiskOpportunities": {
                    "name": "RiskOpportunities",
                    "isArray": true,
                    "type": {
                        "model": "RiskOpportunityClimateRelatedRiskOpportunity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedRiskOpportunity"
                        ]
                    }
                },
                "AggregatedFinancialEffects": {
                    "name": "AggregatedFinancialEffects",
                    "isArray": true,
                    "type": {
                        "model": "AggregatedFinancialEffectClimateRelatedRiskOpportunity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedRiskOpportunity"
                        ]
                    }
                },
                "PlanningHorizons": {
                    "name": "PlanningHorizons",
                    "isArray": true,
                    "type": {
                        "model": "PlanningHorizonClimateRelatedRiskOpportunity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateRelatedRiskOpportunity"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedRiskOpportunities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateGovernanceProcess": {
            "name": "ClimateGovernanceProcess",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceProcessSelectType": {
                    "name": "GovernanceProcessSelectType",
                    "isArray": false,
                    "type": {
                        "enum": "GovernanceProcessSelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceProcessName": {
                    "name": "GovernanceProcessName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessDescription": {
                    "name": "ProcessDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessFrequency": {
                    "name": "ProcessFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "ProcessFrequencyEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessType": {
                    "name": "ProcessType",
                    "isArray": false,
                    "type": {
                        "enum": "ProcessTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ProcessTradeoffDescription": {
                    "name": "ProcessTradeoffDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UseRemunerationPerformanceMetrics": {
                    "name": "UseRemunerationPerformanceMetrics",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateOversightProcessType": {
                    "name": "ClimateOversightProcessType",
                    "isArray": false,
                    "type": {
                        "enum": "ClimateOversightProcessTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "_governancedatas": {
                    "name": "_governancedatas",
                    "isArray": true,
                    "type": {
                        "model": "ClimateGovernanceProcessGovernanceData"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climateGovernanceProcess"
                        ]
                    }
                },
                "GovernanceArtifactName": {
                    "name": "GovernanceArtifactName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceArtifactDescription": {
                    "name": "GovernanceArtifactDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateGovernanceProcesses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GovernanceData": {
            "name": "GovernanceData",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceDataSelectType": {
                    "name": "GovernanceDataSelectType",
                    "isArray": false,
                    "type": {
                        "enum": "GovernanceDataSelectTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceName": {
                    "name": "GovernanceName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceDescription": {
                    "name": "GovernanceDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "SkillCompetencyDetermination": {
                    "name": "SkillCompetencyDetermination",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ManagementDelegation": {
                    "name": "ManagementDelegation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ManagementControlsProceduresIntegration": {
                    "name": "ManagementControlsProceduresIntegration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "_governancedatacollections": {
                    "name": "_governancedatacollections",
                    "isArray": true,
                    "type": {
                        "model": "GovernanceDataGovernanceDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "governanceData"
                        ]
                    }
                },
                "GovernanceProcesses": {
                    "name": "GovernanceProcesses",
                    "isArray": true,
                    "type": {
                        "model": "ClimateGovernanceProcessGovernanceData"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "governanceData"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GovernanceData",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BusinessPlanningHorizon": {
            "name": "BusinessPlanningHorizon",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "BusinessPlanningHorizonName": {
                    "name": "BusinessPlanningHorizonName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "BusinessPlanningHorizonDescription": {
                    "name": "BusinessPlanningHorizonDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HorizonStart": {
                    "name": "HorizonStart",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "HorizonEnd": {
                    "name": "HorizonEnd",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "_climateplanninghorizons": {
                    "name": "_climateplanninghorizons",
                    "isArray": true,
                    "type": {
                        "model": "BusinessPlanningHorizonClimatePlanningHorizon"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "businessPlanningHorizon"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "BusinessPlanningHorizons",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimatePlanningHorizon": {
            "name": "ClimatePlanningHorizon",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "HorizonPeriodType": {
                    "name": "HorizonPeriodType",
                    "isArray": false,
                    "type": {
                        "enum": "ClimatePlanningHorizonsEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "HorizonStart": {
                    "name": "HorizonStart",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "HorizonEnd": {
                    "name": "HorizonEnd",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "_planninghorizons": {
                    "name": "_planninghorizons",
                    "isArray": true,
                    "type": {
                        "model": "ClimatePlanningHorizonPlanningHorizon"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climatePlanningHorizon"
                        ]
                    }
                },
                "BusinessPlanningHorizons": {
                    "name": "BusinessPlanningHorizons",
                    "isArray": true,
                    "type": {
                        "model": "BusinessPlanningHorizonClimatePlanningHorizon"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "climatePlanningHorizon"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimatePlanningHorizons",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PlanningHorizon": {
            "name": "PlanningHorizon",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimatePlanningHorizons": {
                    "name": "ClimatePlanningHorizons",
                    "isArray": true,
                    "type": {
                        "model": "ClimatePlanningHorizonPlanningHorizon"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "planningHorizon"
                        ]
                    }
                },
                "_climaterelatedriskopportunitys": {
                    "name": "_climaterelatedriskopportunitys",
                    "isArray": true,
                    "type": {
                        "model": "PlanningHorizonClimateRelatedRiskOpportunity"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "planningHorizon"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PlanningHorizons",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MetricAndTargetDataCollection": {
            "name": "MetricAndTargetDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateRelatedMetrics": {
                    "name": "ClimateRelatedMetrics",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedMetricsMetricAndTargetDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "metricAndTargetDataCollection"
                        ]
                    }
                },
                "ClimateRelatedTargets": {
                    "name": "ClimateRelatedTargets",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedTargetsMetricAndTargetDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "metricAndTargetDataCollection"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "MetricAndTargetDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RiskManagementDataCollection": {
            "name": "RiskManagementDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RiskManagementProcessesPolicies": {
                    "name": "RiskManagementProcessesPolicies",
                    "isArray": true,
                    "type": {
                        "model": "RiskManagementProcessPolicyRiskManagementDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "riskManagementDataCollection"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RiskManagementDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StrategyDataCollection": {
            "name": "StrategyDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSet": {
                    "name": "DataSet",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ClimateRelatedRisksOpportunities": {
                    "name": "ClimateRelatedRisksOpportunities",
                    "isArray": true,
                    "type": {
                        "model": "ClimateRelatedRiskOpportunityStrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDataCollection"
                        ]
                    }
                },
                "StrategyDecisionMakings": {
                    "name": "StrategyDecisionMakings",
                    "isArray": true,
                    "type": {
                        "model": "StrategyDecisionMakingStrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDataCollection"
                        ]
                    }
                },
                "ClimateResiliences": {
                    "name": "ClimateResiliences",
                    "isArray": true,
                    "type": {
                        "model": "ClimateResilienceStrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "strategyDataCollection"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StrategyDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GovernanceDataCollection": {
            "name": "GovernanceDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GovernanceData": {
                    "name": "GovernanceData",
                    "isArray": true,
                    "type": {
                        "model": "GovernanceDataGovernanceDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "governanceDataCollection"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GovernanceDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ReportingEntity": {
            "name": "ReportingEntity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PrimaryOperatingCountry": {
                    "name": "PrimaryOperatingCountry",
                    "isArray": false,
                    "type": {
                        "enum": "CountryEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Currency": {
                    "name": "Currency",
                    "isArray": false,
                    "type": {
                        "enum": "CurrencyEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OrganisationIdentifierType": {
                    "name": "OrganisationIdentifierType",
                    "isArray": false,
                    "type": {
                        "enum": "OrgIdTypeEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OrganisationIdentifier": {
                    "name": "OrganisationIdentifier",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "URL": {
                    "name": "URL",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondaryOperatingCountry": {
                    "name": "SecondaryOperatingCountry",
                    "isArray": false,
                    "type": {
                        "enum": "CountryEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Disclosures": {
                    "name": "Disclosures",
                    "isArray": true,
                    "type": {
                        "model": "Disclosure"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "reportingentityID"
                        ]
                    }
                },
                "Locations": {
                    "name": "Locations",
                    "isArray": true,
                    "type": {
                        "model": "ReportingEntityLocation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "reportingEntity"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ReportingEntities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ReportingPeriod": {
            "name": "ReportingPeriod",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "ReportingPeriodName": {
                    "name": "ReportingPeriodName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "StartDate": {
                    "name": "StartDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "EndDate": {
                    "name": "EndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "ReportingPeriodYear": {
                    "name": "ReportingPeriodYear",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "_scenarioanalysiss": {
                    "name": "_scenarioanalysiss",
                    "isArray": true,
                    "type": {
                        "model": "ReportingPeriodScenarioAnalysis"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "reportingPeriod"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ReportingPeriods",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Disclosure": {
            "name": "Disclosure",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CustomerID": {
                    "name": "CustomerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "DataSetName": {
                    "name": "DataSetName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Author": {
                    "name": "Author",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PublishedTo": {
                    "name": "PublishedTo",
                    "isArray": true,
                    "type": {
                        "enum": "PublishedToEnum"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Status": {
                    "name": "Status",
                    "isArray": false,
                    "type": {
                        "enum": "StatusEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ReportingPeriod": {
                    "name": "ReportingPeriod",
                    "isArray": false,
                    "type": {
                        "model": "ReportingPeriod"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "disclosureReportingPeriodId"
                        ]
                    }
                },
                "GovernanceDataCollection": {
                    "name": "GovernanceDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "GovernanceDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "disclosureGovernanceDataCollectionId"
                        ]
                    }
                },
                "StrategyDataCollection": {
                    "name": "StrategyDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "disclosureStrategyDataCollectionId"
                        ]
                    }
                },
                "RiskManagementDataCollection": {
                    "name": "RiskManagementDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "RiskManagementDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "disclosureRiskManagementDataCollectionId"
                        ]
                    }
                },
                "MetricsAndTargetsDataCollection": {
                    "name": "MetricsAndTargetsDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "MetricAndTargetDataCollection"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "disclosureMetricsAndTargetsDataCollectionId"
                        ]
                    }
                },
                "Notes": {
                    "name": "Notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "reportingentityID": {
                    "name": "reportingentityID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "disclosureReportingPeriodId": {
                    "name": "disclosureReportingPeriodId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "disclosureGovernanceDataCollectionId": {
                    "name": "disclosureGovernanceDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "disclosureStrategyDataCollectionId": {
                    "name": "disclosureStrategyDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "disclosureRiskManagementDataCollectionId": {
                    "name": "disclosureRiskManagementDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "disclosureMetricsAndTargetsDataCollectionId": {
                    "name": "disclosureMetricsAndTargetsDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Disclosures",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byReportingEntity",
                        "fields": [
                            "reportingentityID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CarbonAccountAvoidedEmissionAccountEntry": {
            "name": "CarbonAccountAvoidedEmissionAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "avoidedEmissionAccountEntryId": {
                    "name": "avoidedEmissionAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccountId": {
                    "name": "carbonAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "avoidedEmissionAccountEntry": {
                    "name": "avoidedEmissionAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "AvoidedEmissionAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "avoidedEmissionAccountEntryId"
                        ]
                    }
                },
                "carbonAccount": {
                    "name": "carbonAccount",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CarbonAccountAvoidedEmissionAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAvoidedEmissionAccountEntry",
                        "fields": [
                            "avoidedEmissionAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccount",
                        "fields": [
                            "carbonAccountId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CarbonAccountBiogenicAccountEntry": {
            "name": "CarbonAccountBiogenicAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "biogenicAccountEntryId": {
                    "name": "biogenicAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccountId": {
                    "name": "carbonAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "biogenicAccountEntry": {
                    "name": "biogenicAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "BiogenicAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "biogenicAccountEntryId"
                        ]
                    }
                },
                "carbonAccount": {
                    "name": "carbonAccount",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CarbonAccountBiogenicAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBiogenicAccountEntry",
                        "fields": [
                            "biogenicAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccount",
                        "fields": [
                            "carbonAccountId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AssetLocation": {
            "name": "AssetLocation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "locationId": {
                    "name": "locationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "assetId": {
                    "name": "assetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "model": "Location"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "locationId"
                        ]
                    }
                },
                "asset": {
                    "name": "asset",
                    "isArray": false,
                    "type": {
                        "model": "Asset"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "assetId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AssetLocations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLocation",
                        "fields": [
                            "locationId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAsset",
                        "fields": [
                            "assetId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ReportingEntityLocation": {
            "name": "ReportingEntityLocation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "locationId": {
                    "name": "locationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "reportingEntityId": {
                    "name": "reportingEntityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "model": "Location"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "locationId"
                        ]
                    }
                },
                "reportingEntity": {
                    "name": "reportingEntity",
                    "isArray": false,
                    "type": {
                        "model": "ReportingEntity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "reportingEntityId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ReportingEntityLocations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLocation",
                        "fields": [
                            "locationId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byReportingEntity",
                        "fields": [
                            "reportingEntityId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AssetAssetRegister": {
            "name": "AssetAssetRegister",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "assetId": {
                    "name": "assetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "assetRegisterId": {
                    "name": "assetRegisterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "asset": {
                    "name": "asset",
                    "isArray": false,
                    "type": {
                        "model": "Asset"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "assetId"
                        ]
                    }
                },
                "assetRegister": {
                    "name": "assetRegister",
                    "isArray": false,
                    "type": {
                        "model": "AssetRegister"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "assetRegisterId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AssetAssetRegisters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAsset",
                        "fields": [
                            "assetId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAssetRegister",
                        "fields": [
                            "assetRegisterId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedMetricsAsset": {
            "name": "ClimateRelatedMetricsAsset",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "assetId": {
                    "name": "assetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedMetricsId": {
                    "name": "climateRelatedMetricsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "asset": {
                    "name": "asset",
                    "isArray": false,
                    "type": {
                        "model": "Asset"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "assetId"
                        ]
                    }
                },
                "climateRelatedMetrics": {
                    "name": "climateRelatedMetrics",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedMetrics"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedMetricsId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedMetricsAssets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAsset",
                        "fields": [
                            "assetId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedMetrics",
                        "fields": [
                            "climateRelatedMetricsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "OffsetAccountEntryCarbonAccount": {
            "name": "OffsetAccountEntryCarbonAccount",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "offsetAccountEntryId": {
                    "name": "offsetAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccountId": {
                    "name": "carbonAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "offsetAccountEntry": {
                    "name": "offsetAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "OffsetAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "offsetAccountEntryId"
                        ]
                    }
                },
                "carbonAccount": {
                    "name": "carbonAccount",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "OffsetAccountEntryCarbonAccounts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOffsetAccountEntry",
                        "fields": [
                            "offsetAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccount",
                        "fields": [
                            "carbonAccountId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "OffsetAccountEntryClimateRelatedTargets": {
            "name": "OffsetAccountEntryClimateRelatedTargets",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "offsetAccountEntryId": {
                    "name": "offsetAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedTargetsId": {
                    "name": "climateRelatedTargetsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "offsetAccountEntry": {
                    "name": "offsetAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "OffsetAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "offsetAccountEntryId"
                        ]
                    }
                },
                "climateRelatedTargets": {
                    "name": "climateRelatedTargets",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedTargets"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "OffsetAccountEntryClimateRelatedTargets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOffsetAccountEntry",
                        "fields": [
                            "offsetAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedTargets",
                        "fields": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "EmissionAccountEntryCarbonAccount": {
            "name": "EmissionAccountEntryCarbonAccount",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "emissionAccountEntryId": {
                    "name": "emissionAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccountId": {
                    "name": "carbonAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "emissionAccountEntry": {
                    "name": "emissionAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "EmissionAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "emissionAccountEntryId"
                        ]
                    }
                },
                "carbonAccount": {
                    "name": "carbonAccount",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "EmissionAccountEntryCarbonAccounts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEmissionAccountEntry",
                        "fields": [
                            "emissionAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccount",
                        "fields": [
                            "carbonAccountId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ReductionAccountEntryCarbonAccount": {
            "name": "ReductionAccountEntryCarbonAccount",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "carbonAccountId": {
                    "name": "carbonAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "reductionAccountEntryId": {
                    "name": "reductionAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccount": {
                    "name": "carbonAccount",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountId"
                        ]
                    }
                },
                "reductionAccountEntry": {
                    "name": "reductionAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "ReductionAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "reductionAccountEntryId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ReductionAccountEntryCarbonAccounts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccount",
                        "fields": [
                            "carbonAccountId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byReductionAccountEntry",
                        "fields": [
                            "reductionAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CarbonAccountCarbonAccountingLedger": {
            "name": "CarbonAccountCarbonAccountingLedger",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "carbonAccountId": {
                    "name": "carbonAccountId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccountingLedgerId": {
                    "name": "carbonAccountingLedgerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "carbonAccount": {
                    "name": "carbonAccount",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccount"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountId"
                        ]
                    }
                },
                "carbonAccountingLedger": {
                    "name": "carbonAccountingLedger",
                    "isArray": false,
                    "type": {
                        "model": "CarbonAccountingLedger"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "carbonAccountingLedgerId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CarbonAccountCarbonAccountingLedgers",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccount",
                        "fields": [
                            "carbonAccountId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCarbonAccountingLedger",
                        "fields": [
                            "carbonAccountingLedgerId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedTargetsReductionAccountEntry": {
            "name": "ClimateRelatedTargetsReductionAccountEntry",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "reductionAccountEntryId": {
                    "name": "reductionAccountEntryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedTargetsId": {
                    "name": "climateRelatedTargetsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "reductionAccountEntry": {
                    "name": "reductionAccountEntry",
                    "isArray": false,
                    "type": {
                        "model": "ReductionAccountEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "reductionAccountEntryId"
                        ]
                    }
                },
                "climateRelatedTargets": {
                    "name": "climateRelatedTargets",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedTargets"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedTargetsReductionAccountEntries",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byReductionAccountEntry",
                        "fields": [
                            "reductionAccountEntryId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedTargets",
                        "fields": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedTargetsMetricAndTargetDataCollection": {
            "name": "ClimateRelatedTargetsMetricAndTargetDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateRelatedTargetsId": {
                    "name": "climateRelatedTargetsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metricAndTargetDataCollectionId": {
                    "name": "metricAndTargetDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedTargets": {
                    "name": "climateRelatedTargets",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedTargets"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                "metricAndTargetDataCollection": {
                    "name": "metricAndTargetDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "MetricAndTargetDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "metricAndTargetDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedTargetsMetricAndTargetDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedTargets",
                        "fields": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMetricAndTargetDataCollection",
                        "fields": [
                            "metricAndTargetDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedMetricsClimateRelatedTargets": {
            "name": "ClimateRelatedMetricsClimateRelatedTargets",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateRelatedTargetsId": {
                    "name": "climateRelatedTargetsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedMetricsId": {
                    "name": "climateRelatedMetricsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedTargets": {
                    "name": "climateRelatedTargets",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedTargets"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                "climateRelatedMetrics": {
                    "name": "climateRelatedMetrics",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedMetrics"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedMetricsId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedMetricsClimateRelatedTargets",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedTargets",
                        "fields": [
                            "climateRelatedTargetsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedMetrics",
                        "fields": [
                            "climateRelatedMetricsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedMetricsMetricAndTargetDataCollection": {
            "name": "ClimateRelatedMetricsMetricAndTargetDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateRelatedMetricsId": {
                    "name": "climateRelatedMetricsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metricAndTargetDataCollectionId": {
                    "name": "metricAndTargetDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedMetrics": {
                    "name": "climateRelatedMetrics",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedMetrics"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedMetricsId"
                        ]
                    }
                },
                "metricAndTargetDataCollection": {
                    "name": "metricAndTargetDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "MetricAndTargetDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "metricAndTargetDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedMetricsMetricAndTargetDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedMetrics",
                        "fields": [
                            "climateRelatedMetricsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMetricAndTargetDataCollection",
                        "fields": [
                            "metricAndTargetDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RiskManagementProcessPolicyRiskManagementDataCollection": {
            "name": "RiskManagementProcessPolicyRiskManagementDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "riskManagementProcessPolicyId": {
                    "name": "riskManagementProcessPolicyId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "riskManagementDataCollectionId": {
                    "name": "riskManagementDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "riskManagementProcessPolicy": {
                    "name": "riskManagementProcessPolicy",
                    "isArray": false,
                    "type": {
                        "model": "RiskManagementProcessPolicy"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "riskManagementProcessPolicyId"
                        ]
                    }
                },
                "riskManagementDataCollection": {
                    "name": "riskManagementDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "RiskManagementDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "riskManagementDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RiskManagementProcessPolicyRiskManagementDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRiskManagementProcessPolicy",
                        "fields": [
                            "riskManagementProcessPolicyId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRiskManagementDataCollection",
                        "fields": [
                            "riskManagementDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RiskOpportunityRiskManagementProcessPolicy": {
            "name": "RiskOpportunityRiskManagementProcessPolicy",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "riskManagementProcessPolicyId": {
                    "name": "riskManagementProcessPolicyId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "riskOpportunityId": {
                    "name": "riskOpportunityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "riskManagementProcessPolicy": {
                    "name": "riskManagementProcessPolicy",
                    "isArray": false,
                    "type": {
                        "model": "RiskManagementProcessPolicy"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "riskManagementProcessPolicyId"
                        ]
                    }
                },
                "riskOpportunity": {
                    "name": "riskOpportunity",
                    "isArray": false,
                    "type": {
                        "model": "RiskOpportunity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "riskOpportunityId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RiskOpportunityRiskManagementProcessPolicies",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRiskManagementProcessPolicy",
                        "fields": [
                            "riskManagementProcessPolicyId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRiskOpportunity",
                        "fields": [
                            "riskOpportunityId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ScenarioAnalysisAssumptionsClimateResilience": {
            "name": "ScenarioAnalysisAssumptionsClimateResilience",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "scenarioAnalysisAssumptionsId": {
                    "name": "scenarioAnalysisAssumptionsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateResilienceId": {
                    "name": "climateResilienceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "scenarioAnalysisAssumptions": {
                    "name": "scenarioAnalysisAssumptions",
                    "isArray": false,
                    "type": {
                        "model": "ScenarioAnalysisAssumptions"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "scenarioAnalysisAssumptionsId"
                        ]
                    }
                },
                "climateResilience": {
                    "name": "climateResilience",
                    "isArray": false,
                    "type": {
                        "model": "ClimateResilience"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateResilienceId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ScenarioAnalysisAssumptionsClimateResiliences",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byScenarioAnalysisAssumptions",
                        "fields": [
                            "scenarioAnalysisAssumptionsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateResilience",
                        "fields": [
                            "climateResilienceId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ScenarioAnalysisClimateResilience": {
            "name": "ScenarioAnalysisClimateResilience",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "scenarioAnalysisId": {
                    "name": "scenarioAnalysisId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateResilienceId": {
                    "name": "climateResilienceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "scenarioAnalysis": {
                    "name": "scenarioAnalysis",
                    "isArray": false,
                    "type": {
                        "model": "ScenarioAnalysis"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "scenarioAnalysisId"
                        ]
                    }
                },
                "climateResilience": {
                    "name": "climateResilience",
                    "isArray": false,
                    "type": {
                        "model": "ClimateResilience"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateResilienceId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ScenarioAnalysisClimateResiliences",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byScenarioAnalysis",
                        "fields": [
                            "scenarioAnalysisId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateResilience",
                        "fields": [
                            "climateResilienceId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ReportingPeriodScenarioAnalysis": {
            "name": "ReportingPeriodScenarioAnalysis",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "scenarioAnalysisId": {
                    "name": "scenarioAnalysisId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "reportingPeriodId": {
                    "name": "reportingPeriodId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "scenarioAnalysis": {
                    "name": "scenarioAnalysis",
                    "isArray": false,
                    "type": {
                        "model": "ScenarioAnalysis"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "scenarioAnalysisId"
                        ]
                    }
                },
                "reportingPeriod": {
                    "name": "reportingPeriod",
                    "isArray": false,
                    "type": {
                        "model": "ReportingPeriod"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "reportingPeriodId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ReportingPeriodScenarioAnalyses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byScenarioAnalysis",
                        "fields": [
                            "scenarioAnalysisId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byReportingPeriod",
                        "fields": [
                            "reportingPeriodId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateResilienceClimateResilienceAssessment": {
            "name": "ClimateResilienceClimateResilienceAssessment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateResilienceAssessmentId": {
                    "name": "climateResilienceAssessmentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateResilienceId": {
                    "name": "climateResilienceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateResilienceAssessment": {
                    "name": "climateResilienceAssessment",
                    "isArray": false,
                    "type": {
                        "model": "ClimateResilienceAssessment"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateResilienceAssessmentId"
                        ]
                    }
                },
                "climateResilience": {
                    "name": "climateResilience",
                    "isArray": false,
                    "type": {
                        "model": "ClimateResilience"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateResilienceId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateResilienceClimateResilienceAssessments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateResilienceAssessment",
                        "fields": [
                            "climateResilienceAssessmentId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateResilience",
                        "fields": [
                            "climateResilienceId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateResilienceStrategyDataCollection": {
            "name": "ClimateResilienceStrategyDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateResilienceId": {
                    "name": "climateResilienceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDataCollectionId": {
                    "name": "strategyDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateResilience": {
                    "name": "climateResilience",
                    "isArray": false,
                    "type": {
                        "model": "ClimateResilience"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateResilienceId"
                        ]
                    }
                },
                "strategyDataCollection": {
                    "name": "strategyDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateResilienceStrategyDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateResilience",
                        "fields": [
                            "climateResilienceId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDataCollection",
                        "fields": [
                            "strategyDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StrategyDecisionMakingDataStrategyDecisionMaking": {
            "name": "StrategyDecisionMakingDataStrategyDecisionMaking",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "strategyDecisionMakingDataId": {
                    "name": "strategyDecisionMakingDataId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDecisionMakingId": {
                    "name": "strategyDecisionMakingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDecisionMakingData": {
                    "name": "strategyDecisionMakingData",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDecisionMakingData"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDecisionMakingDataId"
                        ]
                    }
                },
                "strategyDecisionMaking": {
                    "name": "strategyDecisionMaking",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDecisionMaking"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StrategyDecisionMakingDataStrategyDecisionMakings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDecisionMakingData",
                        "fields": [
                            "strategyDecisionMakingDataId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDecisionMaking",
                        "fields": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MitigationAdaptationChangeStrategyDecisionMaking": {
            "name": "MitigationAdaptationChangeStrategyDecisionMaking",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "mitigationAdaptationChangeId": {
                    "name": "mitigationAdaptationChangeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDecisionMakingId": {
                    "name": "strategyDecisionMakingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "mitigationAdaptationChange": {
                    "name": "mitigationAdaptationChange",
                    "isArray": false,
                    "type": {
                        "model": "MitigationAdaptationChange"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "mitigationAdaptationChangeId"
                        ]
                    }
                },
                "strategyDecisionMaking": {
                    "name": "strategyDecisionMaking",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDecisionMaking"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "MitigationAdaptationChangeStrategyDecisionMakings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMitigationAdaptationChange",
                        "fields": [
                            "mitigationAdaptationChangeId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDecisionMaking",
                        "fields": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BusinessModelChangeStrategyDecisionMaking": {
            "name": "BusinessModelChangeStrategyDecisionMaking",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "businessModelChangeId": {
                    "name": "businessModelChangeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDecisionMakingId": {
                    "name": "strategyDecisionMakingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "businessModelChange": {
                    "name": "businessModelChange",
                    "isArray": false,
                    "type": {
                        "model": "BusinessModelChange"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "businessModelChangeId"
                        ]
                    }
                },
                "strategyDecisionMaking": {
                    "name": "strategyDecisionMaking",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDecisionMaking"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "BusinessModelChangeStrategyDecisionMakings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBusinessModelChange",
                        "fields": [
                            "businessModelChangeId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDecisionMaking",
                        "fields": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StrategyDecisionMakingStrategyDataCollection": {
            "name": "StrategyDecisionMakingStrategyDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "strategyDecisionMakingId": {
                    "name": "strategyDecisionMakingId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDataCollectionId": {
                    "name": "strategyDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDecisionMaking": {
                    "name": "strategyDecisionMaking",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDecisionMaking"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                "strategyDataCollection": {
                    "name": "strategyDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StrategyDecisionMakingStrategyDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDecisionMaking",
                        "fields": [
                            "strategyDecisionMakingId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDataCollection",
                        "fields": [
                            "strategyDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AggregatedFinancialEffectClimateRelatedRiskOpportunity": {
            "name": "AggregatedFinancialEffectClimateRelatedRiskOpportunity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "aggregatedFinancialEffectId": {
                    "name": "aggregatedFinancialEffectId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedRiskOpportunityId": {
                    "name": "climateRelatedRiskOpportunityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "aggregatedFinancialEffect": {
                    "name": "aggregatedFinancialEffect",
                    "isArray": false,
                    "type": {
                        "model": "AggregatedFinancialEffect"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "aggregatedFinancialEffectId"
                        ]
                    }
                },
                "climateRelatedRiskOpportunity": {
                    "name": "climateRelatedRiskOpportunity",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedRiskOpportunity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "AggregatedFinancialEffectClimateRelatedRiskOpportunities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAggregatedFinancialEffect",
                        "fields": [
                            "aggregatedFinancialEffectId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedRiskOpportunity",
                        "fields": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RiskOpportunityClimateRelatedRiskOpportunity": {
            "name": "RiskOpportunityClimateRelatedRiskOpportunity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "riskOpportunityId": {
                    "name": "riskOpportunityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedRiskOpportunityId": {
                    "name": "climateRelatedRiskOpportunityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "riskOpportunity": {
                    "name": "riskOpportunity",
                    "isArray": false,
                    "type": {
                        "model": "RiskOpportunity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "riskOpportunityId"
                        ]
                    }
                },
                "climateRelatedRiskOpportunity": {
                    "name": "climateRelatedRiskOpportunity",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedRiskOpportunity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RiskOpportunityClimateRelatedRiskOpportunities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRiskOpportunity",
                        "fields": [
                            "riskOpportunityId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedRiskOpportunity",
                        "fields": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateRelatedRiskOpportunityStrategyDataCollection": {
            "name": "ClimateRelatedRiskOpportunityStrategyDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateRelatedRiskOpportunityId": {
                    "name": "climateRelatedRiskOpportunityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "strategyDataCollectionId": {
                    "name": "strategyDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedRiskOpportunity": {
                    "name": "climateRelatedRiskOpportunity",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedRiskOpportunity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                "strategyDataCollection": {
                    "name": "strategyDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "StrategyDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "strategyDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateRelatedRiskOpportunityStrategyDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedRiskOpportunity",
                        "fields": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStrategyDataCollection",
                        "fields": [
                            "strategyDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PlanningHorizonClimateRelatedRiskOpportunity": {
            "name": "PlanningHorizonClimateRelatedRiskOpportunity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateRelatedRiskOpportunityId": {
                    "name": "climateRelatedRiskOpportunityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "planningHorizonId": {
                    "name": "planningHorizonId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateRelatedRiskOpportunity": {
                    "name": "climateRelatedRiskOpportunity",
                    "isArray": false,
                    "type": {
                        "model": "ClimateRelatedRiskOpportunity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                "planningHorizon": {
                    "name": "planningHorizon",
                    "isArray": false,
                    "type": {
                        "model": "PlanningHorizon"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "planningHorizonId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PlanningHorizonClimateRelatedRiskOpportunities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateRelatedRiskOpportunity",
                        "fields": [
                            "climateRelatedRiskOpportunityId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPlanningHorizon",
                        "fields": [
                            "planningHorizonId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimateGovernanceProcessGovernanceData": {
            "name": "ClimateGovernanceProcessGovernanceData",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climateGovernanceProcessId": {
                    "name": "climateGovernanceProcessId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "governanceDataId": {
                    "name": "governanceDataId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climateGovernanceProcess": {
                    "name": "climateGovernanceProcess",
                    "isArray": false,
                    "type": {
                        "model": "ClimateGovernanceProcess"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climateGovernanceProcessId"
                        ]
                    }
                },
                "governanceData": {
                    "name": "governanceData",
                    "isArray": false,
                    "type": {
                        "model": "GovernanceData"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "governanceDataId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimateGovernanceProcessGovernanceData",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimateGovernanceProcess",
                        "fields": [
                            "climateGovernanceProcessId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGovernanceData",
                        "fields": [
                            "governanceDataId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GovernanceDataGovernanceDataCollection": {
            "name": "GovernanceDataGovernanceDataCollection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "governanceDataId": {
                    "name": "governanceDataId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "governanceDataCollectionId": {
                    "name": "governanceDataCollectionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "governanceData": {
                    "name": "governanceData",
                    "isArray": false,
                    "type": {
                        "model": "GovernanceData"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "governanceDataId"
                        ]
                    }
                },
                "governanceDataCollection": {
                    "name": "governanceDataCollection",
                    "isArray": false,
                    "type": {
                        "model": "GovernanceDataCollection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "governanceDataCollectionId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GovernanceDataGovernanceDataCollections",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGovernanceData",
                        "fields": [
                            "governanceDataId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGovernanceDataCollection",
                        "fields": [
                            "governanceDataCollectionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BusinessPlanningHorizonClimatePlanningHorizon": {
            "name": "BusinessPlanningHorizonClimatePlanningHorizon",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "businessPlanningHorizonId": {
                    "name": "businessPlanningHorizonId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climatePlanningHorizonId": {
                    "name": "climatePlanningHorizonId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "businessPlanningHorizon": {
                    "name": "businessPlanningHorizon",
                    "isArray": false,
                    "type": {
                        "model": "BusinessPlanningHorizon"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "businessPlanningHorizonId"
                        ]
                    }
                },
                "climatePlanningHorizon": {
                    "name": "climatePlanningHorizon",
                    "isArray": false,
                    "type": {
                        "model": "ClimatePlanningHorizon"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climatePlanningHorizonId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "BusinessPlanningHorizonClimatePlanningHorizons",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBusinessPlanningHorizon",
                        "fields": [
                            "businessPlanningHorizonId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimatePlanningHorizon",
                        "fields": [
                            "climatePlanningHorizonId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ClimatePlanningHorizonPlanningHorizon": {
            "name": "ClimatePlanningHorizonPlanningHorizon",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "climatePlanningHorizonId": {
                    "name": "climatePlanningHorizonId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "planningHorizonId": {
                    "name": "planningHorizonId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "climatePlanningHorizon": {
                    "name": "climatePlanningHorizon",
                    "isArray": false,
                    "type": {
                        "model": "ClimatePlanningHorizon"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "climatePlanningHorizonId"
                        ]
                    }
                },
                "planningHorizon": {
                    "name": "planningHorizon",
                    "isArray": false,
                    "type": {
                        "model": "PlanningHorizon"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "planningHorizonId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ClimatePlanningHorizonPlanningHorizons",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byClimatePlanningHorizon",
                        "fields": [
                            "climatePlanningHorizonId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPlanningHorizon",
                        "fields": [
                            "planningHorizonId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Administrator",
                                    "Editor"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Contributor"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Auditor"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "PartyRoleTypeEnum": {
            "name": "PartyRoleTypeEnum",
            "values": [
                "ParentEntity",
                "Subsidiary",
                "Supplier",
                "Target",
                "Milestone",
                "InterimTarget"
            ]
        },
        "PartyRelationshipTypeEnum": {
            "name": "PartyRelationshipTypeEnum",
            "values": [
                "OrganisationRollup",
                "TargetRollup"
            ]
        },
        "RiskManagementProcessPolicySelectTypeEnum": {
            "name": "RiskManagementProcessPolicySelectTypeEnum",
            "values": [
                "Processes",
                "Supplementary"
            ]
        },
        "ClimateRelatedTargetsSelectTypeEnum": {
            "name": "ClimateRelatedTargetsSelectTypeEnum",
            "values": [
                "ClimateRelatedTarget",
                "TargetSettingReviewApproach",
                "PerformanceAgainstTarget",
                "GreenhouseGasEmissionTargetData"
            ]
        },
        "ClimateRelatedMetricsSelectTypeEnum": {
            "name": "ClimateRelatedMetricsSelectTypeEnum",
            "values": [
                "CrossIndustryMetrics",
                "RiskOpportunityMetrics",
                "IndustryMetrics"
            ]
        },
        "FacilityOrSiteEnum": {
            "name": "FacilityOrSiteEnum",
            "values": [
                "Facility",
                "Site"
            ]
        },
        "ClimateRelatedRiskOpportunitySelectTypeEnum": {
            "name": "ClimateRelatedRiskOpportunitySelectTypeEnum",
            "values": [
                "ClimateRelatedRiskOpportunity",
                "BusinessModelValueChain",
                "FinancialEffect"
            ]
        },
        "AppGroup": {
            "name": "AppGroup",
            "values": [
                "Administrator",
                "Auditor",
                "Contributor",
                "Editor"
            ]
        },
        "TransitionPhysicalRiskEnum": {
            "name": "TransitionPhysicalRiskEnum",
            "values": [
                "PhysicalRisk",
                "TransitionRisk"
            ]
        },
        "StatusEnum": {
            "name": "StatusEnum",
            "values": [
                "Draft",
                "Published",
                "Inactive"
            ]
        },
        "EmissionCategoryEnum": {
            "name": "EmissionCategoryEnum",
            "values": [
                "Category1",
                "Category2",
                "Category3",
                "Category4",
                "Category5",
                "Category6",
                "Category7",
                "Category8",
                "Category9",
                "Category10",
                "Category11",
                "Category12",
                "Category13",
                "Category14",
                "Category15"
            ]
        },
        "AssetActivityType": {
            "name": "AssetActivityType",
            "values": [
                "BusinessActivity",
                "Asset"
            ]
        },
        "CarbonAccountSelectTypeEnum": {
            "name": "CarbonAccountSelectTypeEnum",
            "values": [
                "EmissionAccount",
                "ReductionAccount",
                "OffsetAccount",
                "BiogenicAccount",
                "AvoidedEmissionAccount"
            ]
        },
        "ReductionInitiativeStatusEnum": {
            "name": "ReductionInitiativeStatusEnum",
            "values": [
                "Investigating",
                "Planning",
                "Implementing",
                "Implemented"
            ]
        },
        "OffsetAchievementTypeEnum": {
            "name": "OffsetAchievementTypeEnum",
            "values": [
                "CarbonReduction",
                "CarbonRemoval"
            ]
        },
        "CarbonCreditTypeEnum": {
            "name": "CarbonCreditTypeEnum",
            "values": [
                "NatureBased",
                "TechnologicalBased"
            ]
        },
        "OffsetRelianceEnum": {
            "name": "OffsetRelianceEnum",
            "values": [
                "Percentage",
                "Value",
                "None"
            ]
        },
        "SectoralDecarbonisationApproachEnum": {
            "name": "SectoralDecarbonisationApproachEnum",
            "values": [
                "UsesSectoralDecarbonisationApproach",
                "DoesntUseSectoralDecarbonisationApproach"
            ]
        },
        "TargetEmissionTypeEnum": {
            "name": "TargetEmissionTypeEnum",
            "values": [
                "GrossGhgEmissionTarget",
                "NetGhgEmissionTarget"
            ]
        },
        "EmissionScopeEnum": {
            "name": "EmissionScopeEnum",
            "values": [
                "Scope_1",
                "Scope_2",
                "Scope_3"
            ]
        },
        "GhgCoveredByTarget": {
            "name": "GhgCoveredByTarget",
            "values": [
                "CarbonDioxide",
                "Methane",
                "NitrousOxide",
                "Hydrofluorocarbons",
                "Perfluorocarbons",
                "SulphurHexafluoride"
            ]
        },
        "TargetMethodologyValidationEnum": {
            "name": "TargetMethodologyValidationEnum",
            "values": [
                "ValidatedByThirdParty",
                "NotValidated"
            ]
        },
        "EmissionUnitEnum": {
            "name": "EmissionUnitEnum",
            "values": [
                "Employees",
                "Kg",
                "Kw",
                "Kwh",
                "Litres",
                "MetresCubed",
                "MillionDollarsRevenue",
                "Nights",
                "PassengerPerKm",
                "PerCapita",
                "Percentage",
                "Quantity",
                "Tonnes",
                "TonnesPerKm"
            ]
        },
        "TargetTypeEnum": {
            "name": "TargetTypeEnum",
            "values": [
                "AbsoluteTarget",
                "IntensityTarget"
            ]
        },
        "TargetObjectiveEnum": {
            "name": "TargetObjectiveEnum",
            "values": [
                "Mitigation",
                "Adaptation",
                "ConformanceWithScienceBasedInitiatives"
            ]
        },
        "AmountPercentageEnum": {
            "name": "AmountPercentageEnum",
            "values": [
                "Amount",
                "Percentage"
            ]
        },
        "ScopeOfOperationsEnum": {
            "name": "ScopeOfOperationsEnum",
            "values": [
                "CompanyWide",
                "ValueChainSegment",
                "BusinessDivision",
                "Facility",
                "CountryOrArea",
                "ProductLevel",
                "PortfolioLevel",
                "Other"
            ]
        },
        "ScenarioTemperatureAlignmentEnum": {
            "name": "ScenarioTemperatureAlignmentEnum",
            "values": [
                "OnePointFiveDegreesCelsius",
                "OnePointSixToTwoDegreesCelsius",
                "TwoPointOneToThreeDegreesCelsius",
                "ThreePointOneToFourDegreesCelsius",
                "FourPointOneDegreesAndAbove"
            ]
        },
        "ScenarioAnalysisMethodEnum": {
            "name": "ScenarioAnalysisMethodEnum",
            "values": [
                "Qualitative",
                "Quantitative",
                "Both"
            ]
        },
        "ClimateResilienceAssessmentTypeEnum": {
            "name": "ClimateResilienceAssessmentTypeEnum",
            "values": [
                "ResilienceAssessmentOfStrategyAndBusinessModel",
                "ImplicationsOfResilienceAssessment",
                "ResponseToIdentifiedEffectsFromClimateRelatedScenarioAnalysis",
                "AreaOfSignificantUncertainty",
                "AvailabilityOfFinancialResources",
                "FlexibilityOfFinancialResources",
                "AbilityToModifyExistingAssetUsage",
                "EffectOfCurrentInvestments",
                "EffectOfPlannedInvestments"
            ]
        },
        "StrategyDecisionMakingDataSelectTypeEnum": {
            "name": "StrategyDecisionMakingDataSelectTypeEnum",
            "values": [
                "TransitionPlan",
                "TargetAchievementPlan",
                "StrategyResourcing",
                "StrategyPlanProgress"
            ]
        },
        "FinancialPositionChangeTypeEnum": {
            "name": "FinancialPositionChangeTypeEnum",
            "values": [
                "InvestmentPlan",
                "DisposalPlan",
                "FundingSource",
                "FinancialPerformanceChange",
                "CashflowChange"
            ]
        },
        "FinancialEffectTypeEnum": {
            "name": "FinancialEffectTypeEnum",
            "values": [
                "FinancialPosition",
                "FinancialPerformance",
                "Cashflows",
                "CarryingAmountOfAssets",
                "CarryingAmountOfLiabilities"
            ]
        },
        "PriorityEnum": {
            "name": "PriorityEnum",
            "values": [
                "Lowest",
                "Low",
                "Medium",
                "High",
                "Highest"
            ]
        },
        "LikelihoodOfOccurringEnum": {
            "name": "LikelihoodOfOccurringEnum",
            "values": [
                "None",
                "Low",
                "Medium",
                "High",
                "VeryHigh"
            ]
        },
        "RiskOpportunityGroupEnum": {
            "name": "RiskOpportunityGroupEnum",
            "values": [
                "PhysicalRisk",
                "AcutePhysical",
                "ChronicPhysical",
                "TransitionRisk",
                "MarketRisk",
                "PolicyAndLegalRisk",
                "ReputationRisk",
                "TechnologyRisk",
                "ResourceEfficiencyOpportunity",
                "EnergySourceOpportunity",
                "ProductsServicesOpportunity",
                "MarketsOpportunity",
                "ResilienceOpportunity"
            ]
        },
        "RiskOpportunityTypeEnum": {
            "name": "RiskOpportunityTypeEnum",
            "values": [
                "Risk",
                "Opportunity"
            ]
        },
        "ClimateOversightProcessTypeEnum": {
            "name": "ClimateOversightProcessTypeEnum",
            "values": [
                "StrategyOversight",
                "MajorTransactionDecisionMaking",
                "RiskManagementProcesses",
                "Policies"
            ]
        },
        "ProcessTypeEnum": {
            "name": "ProcessTypeEnum",
            "values": [
                "Assess",
                "Decide",
                "Identify",
                "Manage",
                "Monitor",
                "Oversee",
                "Prioritise",
                "Report",
                "Integrated"
            ]
        },
        "ProcessFrequencyEnum": {
            "name": "ProcessFrequencyEnum",
            "values": [
                "Daily",
                "Weekly",
                "Fortnightly",
                "Monthly",
                "Quarterly",
                "HalfYearly",
                "Yearly",
                "EveryTwoYears",
                "EveryThreeYears",
                "Adhoc",
                "Never"
            ]
        },
        "GovernanceProcessSelectTypeEnum": {
            "name": "GovernanceProcessSelectTypeEnum",
            "values": [
                "InformGovernance",
                "ClimateStrategyOversight",
                "GovernanceMetricsTargets",
                "ManagementOversight",
                "GovernanceArtifact"
            ]
        },
        "GovernanceDataSelectTypeEnum": {
            "name": "GovernanceDataSelectTypeEnum",
            "values": [
                "GovernanceBody",
                "ManagementGovernanceRole"
            ]
        },
        "ClimatePlanningHorizonsEnum": {
            "name": "ClimatePlanningHorizonsEnum",
            "values": [
                "CurrentReportingPeriod",
                "NextReportingPeriod",
                "ShortTerm",
                "MediumTerm",
                "LongTerm"
            ]
        },
        "OrgIdTypeEnum": {
            "name": "OrgIdTypeEnum",
            "values": [
                "ABN",
                "BusinessNumber",
                "CompanyNumber",
                "DunsNumber",
                "IATI",
                "NZBN",
                "RegistrationNumber",
                "StockSymbol"
            ]
        },
        "CurrencyEnum": {
            "name": "CurrencyEnum",
            "values": [
                "AUD",
                "CAD",
                "CNY",
                "HKD",
                "NZD",
                "SGD",
                "GBP",
                "USD"
            ]
        },
        "CountryEnum": {
            "name": "CountryEnum",
            "values": [
                "Australia",
                "Canada",
                "China",
                "HongKong",
                "NewZealand",
                "Singapore",
                "UnitedKingdom",
                "UnitedStates"
            ]
        },
        "PublishedToEnum": {
            "name": "PublishedToEnum",
            "values": [
                "Public",
                "Stakeholder",
                "Assurance",
                "Regulator"
            ]
        }
    },
    "nonModels": {},
    "codegenVersion": "3.4.4",
    "version": "839d1e2e7a7ac6525c752f7381339bc3"
};