/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { FinancialEffect } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function FinancialEffectCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    FinancialEffectName: "",
    HorizonPeriodType: "",
    FinancialEffectType: "",
    FinancialPositionChangeType: "",
    FinancialEffectValue: "",
    FinancialEffectUnit: "",
    FinancialEffectValueMin: "",
    FinancialEffectValueMax: "",
    FinancialEffectQuantitativeDescription: "",
    WhyNoQuanitativeFinancialData: "",
    FinancialEffectQualitativeDescription: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [FinancialEffectName, setFinancialEffectName] = React.useState(
    initialValues.FinancialEffectName
  );
  const [HorizonPeriodType, setHorizonPeriodType] = React.useState(
    initialValues.HorizonPeriodType
  );
  const [FinancialEffectType, setFinancialEffectType] = React.useState(
    initialValues.FinancialEffectType
  );
  const [FinancialPositionChangeType, setFinancialPositionChangeType] =
    React.useState(initialValues.FinancialPositionChangeType);
  const [FinancialEffectValue, setFinancialEffectValue] = React.useState(
    initialValues.FinancialEffectValue
  );
  const [FinancialEffectUnit, setFinancialEffectUnit] = React.useState(
    initialValues.FinancialEffectUnit
  );
  const [FinancialEffectValueMin, setFinancialEffectValueMin] = React.useState(
    initialValues.FinancialEffectValueMin
  );
  const [FinancialEffectValueMax, setFinancialEffectValueMax] = React.useState(
    initialValues.FinancialEffectValueMax
  );
  const [
    FinancialEffectQuantitativeDescription,
    setFinancialEffectQuantitativeDescription,
  ] = React.useState(initialValues.FinancialEffectQuantitativeDescription);
  const [WhyNoQuanitativeFinancialData, setWhyNoQuanitativeFinancialData] =
    React.useState(initialValues.WhyNoQuanitativeFinancialData);
  const [
    FinancialEffectQualitativeDescription,
    setFinancialEffectQualitativeDescription,
  ] = React.useState(initialValues.FinancialEffectQualitativeDescription);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFinancialEffectName(initialValues.FinancialEffectName);
    setHorizonPeriodType(initialValues.HorizonPeriodType);
    setFinancialEffectType(initialValues.FinancialEffectType);
    setFinancialPositionChangeType(initialValues.FinancialPositionChangeType);
    setFinancialEffectValue(initialValues.FinancialEffectValue);
    setFinancialEffectUnit(initialValues.FinancialEffectUnit);
    setFinancialEffectValueMin(initialValues.FinancialEffectValueMin);
    setFinancialEffectValueMax(initialValues.FinancialEffectValueMax);
    setFinancialEffectQuantitativeDescription(
      initialValues.FinancialEffectQuantitativeDescription
    );
    setWhyNoQuanitativeFinancialData(
      initialValues.WhyNoQuanitativeFinancialData
    );
    setFinancialEffectQualitativeDescription(
      initialValues.FinancialEffectQualitativeDescription
    );
    setErrors({});
  };
  const validations = {
    FinancialEffectName: [],
    HorizonPeriodType: [],
    FinancialEffectType: [],
    FinancialPositionChangeType: [],
    FinancialEffectValue: [],
    FinancialEffectUnit: [],
    FinancialEffectValueMin: [],
    FinancialEffectValueMax: [],
    FinancialEffectQuantitativeDescription: [],
    WhyNoQuanitativeFinancialData: [],
    FinancialEffectQualitativeDescription: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          FinancialEffectName,
          HorizonPeriodType,
          FinancialEffectType,
          FinancialPositionChangeType,
          FinancialEffectValue,
          FinancialEffectUnit,
          FinancialEffectValueMin,
          FinancialEffectValueMax,
          FinancialEffectQuantitativeDescription,
          WhyNoQuanitativeFinancialData,
          FinancialEffectQualitativeDescription,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new FinancialEffect(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "FinancialEffectCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Effects on Financial position, Financial Performance and Cash Flows"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        level={6}
        children="IFRS S2 paragraph 15-21 b."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Create a record that defines the current and anticipated effects of this climate-related risk or opportunity on your organization's financial position, financial performance and cash flows."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Financial effect name"
        isRequired={false}
        isReadOnly={false}
        value={FinancialEffectName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectName: value,
              HorizonPeriodType,
              FinancialEffectType,
              FinancialPositionChangeType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.FinancialEffectName ?? value;
          }
          if (errors.FinancialEffectName?.hasError) {
            runValidationTasks("FinancialEffectName", value);
          }
          setFinancialEffectName(value);
        }}
        onBlur={() =>
          runValidationTasks("FinancialEffectName", FinancialEffectName)
        }
        errorMessage={errors.FinancialEffectName?.errorMessage}
        hasError={errors.FinancialEffectName?.hasError}
        {...getOverrideProps(overrides, "FinancialEffectName")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <SelectField
          label="Period financial effect applies to"
          placeholder="Please select an option"
          isDisabled={false}
          value={HorizonPeriodType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectName,
                HorizonPeriodType: value,
                FinancialEffectType,
                FinancialPositionChangeType,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialEffectValueMin,
                FinancialEffectValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.HorizonPeriodType ?? value;
            }
            if (errors.HorizonPeriodType?.hasError) {
              runValidationTasks("HorizonPeriodType", value);
            }
            setHorizonPeriodType(value);
          }}
          onBlur={() =>
            runValidationTasks("HorizonPeriodType", HorizonPeriodType)
          }
          errorMessage={errors.HorizonPeriodType?.errorMessage}
          hasError={errors.HorizonPeriodType?.hasError}
          {...getOverrideProps(overrides, "HorizonPeriodType")}
        >
          <option
            children="Current reporting period"
            value="CurrentReportingPeriod"
            {...getOverrideProps(overrides, "HorizonPeriodTypeoption0")}
          ></option>
          <option
            children="Next reporting period"
            value="NextReportingPeriod"
            {...getOverrideProps(overrides, "HorizonPeriodTypeoption1")}
          ></option>
          <option
            children="Short term"
            value="ShortTerm"
            {...getOverrideProps(overrides, "HorizonPeriodTypeoption2")}
          ></option>
          <option
            children="Medium term"
            value="MediumTerm"
            {...getOverrideProps(overrides, "HorizonPeriodTypeoption3")}
          ></option>
          <option
            children="Long term"
            value="LongTerm"
            {...getOverrideProps(overrides, "HorizonPeriodTypeoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Financial effect type"
          placeholder="Please select an option"
          isDisabled={false}
          value={FinancialEffectType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectName,
                HorizonPeriodType,
                FinancialEffectType: value,
                FinancialPositionChangeType,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialEffectValueMin,
                FinancialEffectValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectType ?? value;
            }
            if (errors.FinancialEffectType?.hasError) {
              runValidationTasks("FinancialEffectType", value);
            }
            setFinancialEffectType(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectType", FinancialEffectType)
          }
          errorMessage={errors.FinancialEffectType?.errorMessage}
          hasError={errors.FinancialEffectType?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectType")}
        >
          <option
            children="Financial position"
            value="FinancialPosition"
            {...getOverrideProps(overrides, "FinancialEffectTypeoption0")}
          ></option>
          <option
            children="Financial performance"
            value="FinancialPerformance"
            {...getOverrideProps(overrides, "FinancialEffectTypeoption1")}
          ></option>
          <option
            children="Cashflows"
            value="Cashflows"
            {...getOverrideProps(overrides, "FinancialEffectTypeoption2")}
          ></option>
          <option
            children="Carrying amount of assets"
            value="CarryingAmountOfAssets"
            {...getOverrideProps(overrides, "FinancialEffectTypeoption3")}
          ></option>
          <option
            children="Carrying amount of liabilities"
            value="CarryingAmountOfLiabilities"
            {...getOverrideProps(overrides, "FinancialEffectTypeoption4")}
          ></option>
        </SelectField>
      </Grid>
      <SelectField
        label="Type of change in financial position"
        placeholder="Please select an option"
        isDisabled={false}
        value={FinancialPositionChangeType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectName,
              HorizonPeriodType,
              FinancialEffectType,
              FinancialPositionChangeType: value,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.FinancialPositionChangeType ?? value;
          }
          if (errors.FinancialPositionChangeType?.hasError) {
            runValidationTasks("FinancialPositionChangeType", value);
          }
          setFinancialPositionChangeType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "FinancialPositionChangeType",
            FinancialPositionChangeType
          )
        }
        errorMessage={errors.FinancialPositionChangeType?.errorMessage}
        hasError={errors.FinancialPositionChangeType?.hasError}
        {...getOverrideProps(overrides, "FinancialPositionChangeType")}
      >
        <option
          children="Investment plan"
          value="InvestmentPlan"
          {...getOverrideProps(overrides, "FinancialPositionChangeTypeoption0")}
        ></option>
        <option
          children="Disposal plan"
          value="DisposalPlan"
          {...getOverrideProps(overrides, "FinancialPositionChangeTypeoption1")}
        ></option>
        <option
          children="Funding source"
          value="FundingSource"
          {...getOverrideProps(overrides, "FinancialPositionChangeTypeoption2")}
        ></option>
        <option
          children="Financial performance change"
          value="FinancialPerformanceChange"
          {...getOverrideProps(overrides, "FinancialPositionChangeTypeoption3")}
        ></option>
        <option
          children="Cashflow change"
          value="CashflowChange"
          {...getOverrideProps(overrides, "FinancialPositionChangeTypeoption4")}
        ></option>
      </SelectField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Value of financial effect"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={FinancialEffectValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                FinancialEffectName,
                HorizonPeriodType,
                FinancialEffectType,
                FinancialPositionChangeType,
                FinancialEffectValue: value,
                FinancialEffectUnit,
                FinancialEffectValueMin,
                FinancialEffectValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValue ?? value;
            }
            if (errors.FinancialEffectValue?.hasError) {
              runValidationTasks("FinancialEffectValue", value);
            }
            setFinancialEffectValue(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectValue", FinancialEffectValue)
          }
          errorMessage={errors.FinancialEffectValue?.errorMessage}
          hasError={errors.FinancialEffectValue?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValue")}
        ></TextField>
        <TextField
          label="Unit of measure of financial effect"
          isRequired={false}
          isReadOnly={false}
          value={FinancialEffectUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectName,
                HorizonPeriodType,
                FinancialEffectType,
                FinancialPositionChangeType,
                FinancialEffectValue,
                FinancialEffectUnit: value,
                FinancialEffectValueMin,
                FinancialEffectValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectUnit ?? value;
            }
            if (errors.FinancialEffectUnit?.hasError) {
              runValidationTasks("FinancialEffectUnit", value);
            }
            setFinancialEffectUnit(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectUnit", FinancialEffectUnit)
          }
          errorMessage={errors.FinancialEffectUnit?.errorMessage}
          hasError={errors.FinancialEffectUnit?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectUnit")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Value of financial effect (min)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={FinancialEffectValueMin}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                FinancialEffectName,
                HorizonPeriodType,
                FinancialEffectType,
                FinancialPositionChangeType,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialEffectValueMin: value,
                FinancialEffectValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValueMin ?? value;
            }
            if (errors.FinancialEffectValueMin?.hasError) {
              runValidationTasks("FinancialEffectValueMin", value);
            }
            setFinancialEffectValueMin(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "FinancialEffectValueMin",
              FinancialEffectValueMin
            )
          }
          errorMessage={errors.FinancialEffectValueMin?.errorMessage}
          hasError={errors.FinancialEffectValueMin?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValueMin")}
        ></TextField>
        <TextField
          label="Value of financial effect (max)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={FinancialEffectValueMax}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                FinancialEffectName,
                HorizonPeriodType,
                FinancialEffectType,
                FinancialPositionChangeType,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialEffectValueMin,
                FinancialEffectValueMax: value,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValueMax ?? value;
            }
            if (errors.FinancialEffectValueMax?.hasError) {
              runValidationTasks("FinancialEffectValueMax", value);
            }
            setFinancialEffectValueMax(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "FinancialEffectValueMax",
              FinancialEffectValueMax
            )
          }
          errorMessage={errors.FinancialEffectValueMax?.errorMessage}
          hasError={errors.FinancialEffectValueMax?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValueMax")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Quantitative description of financial effect"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectName,
              HorizonPeriodType,
              FinancialEffectType,
              FinancialPositionChangeType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
              FinancialEffectQuantitativeDescription: value,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.FinancialEffectQuantitativeDescription ?? value;
          }
          if (errors.FinancialEffectQuantitativeDescription?.hasError) {
            runValidationTasks("FinancialEffectQuantitativeDescription", value);
          }
          setFinancialEffectQuantitativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "FinancialEffectQuantitativeDescription",
            FinancialEffectQuantitativeDescription
          )
        }
        errorMessage={
          errors.FinancialEffectQuantitativeDescription?.errorMessage
        }
        hasError={errors.FinancialEffectQuantitativeDescription?.hasError}
        {...getOverrideProps(
          overrides,
          "FinancialEffectQuantitativeDescription"
        )}
      ></TextAreaField>
      <Text
        children="If you cannot provide quantitative information about the current financial effects of your climate-related risk or opportunity, update your explanation why and include a description of relevant qualitative information. If you cannot provide qualitative information about this risk or opportunity, enter aggregated financial effect data separately."
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <TextAreaField
        label="Describe why you cannot provide data on the quantitative financial effects arising from this climate-related risk / opportunity"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectName,
              HorizonPeriodType,
              FinancialEffectType,
              FinancialPositionChangeType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData: value,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.WhyNoQuanitativeFinancialData ?? value;
          }
          if (errors.WhyNoQuanitativeFinancialData?.hasError) {
            runValidationTasks("WhyNoQuanitativeFinancialData", value);
          }
          setWhyNoQuanitativeFinancialData(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "WhyNoQuanitativeFinancialData",
            WhyNoQuanitativeFinancialData
          )
        }
        errorMessage={errors.WhyNoQuanitativeFinancialData?.errorMessage}
        hasError={errors.WhyNoQuanitativeFinancialData?.hasError}
        	value={WhyNoQuanitativeFinancialData} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "WhyNoQuanitativeFinancialData")}
      ></TextAreaField>
      <TextAreaField
        label="Provide a qualitative description of the financial effect of this climate-related risk / opportunity"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectName,
              HorizonPeriodType,
              FinancialEffectType,
              FinancialPositionChangeType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialEffectValueMin,
              FinancialEffectValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription: value,
            };
            const result = onChange(modelFields);
            value = result?.FinancialEffectQualitativeDescription ?? value;
          }
          if (errors.FinancialEffectQualitativeDescription?.hasError) {
            runValidationTasks("FinancialEffectQualitativeDescription", value);
          }
          setFinancialEffectQualitativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "FinancialEffectQualitativeDescription",
            FinancialEffectQualitativeDescription
          )
        }
        errorMessage={
          errors.FinancialEffectQualitativeDescription?.errorMessage
        }
        hasError={errors.FinancialEffectQualitativeDescription?.hasError}
        {...getOverrideProps(
          overrides,
          "FinancialEffectQualitativeDescription"
        )}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
