/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedTargets } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedTargetsUpdateForm(props) {
  const {
    id: idProp,
    climateRelatedTargets: climateRelatedTargetsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    TargetName: "",
    TargetDescription: "",
    TargetUnit: "",
    TargetValue: "",
    TargetObjective: "",
    ApplicableEntityPart: "",
    TargetTimeframeStartDate: "",
    TargetTimeframeEndDate: "",
    TargetBaseYear: "",
    TargetType: "",
    InformedByInternationalAgreement: "",
  };
  const [TargetName, setTargetName] = React.useState(initialValues.TargetName);
  const [TargetDescription, setTargetDescription] = React.useState(
    initialValues.TargetDescription
  );
  const [TargetUnit, setTargetUnit] = React.useState(initialValues.TargetUnit);
  const [TargetValue, setTargetValue] = React.useState(
    initialValues.TargetValue
  );
  const [TargetObjective, setTargetObjective] = React.useState(
    initialValues.TargetObjective
  );
  const [ApplicableEntityPart, setApplicableEntityPart] = React.useState(
    initialValues.ApplicableEntityPart
  );
  const [TargetTimeframeStartDate, setTargetTimeframeStartDate] =
    React.useState(initialValues.TargetTimeframeStartDate);
  const [TargetTimeframeEndDate, setTargetTimeframeEndDate] = React.useState(
    initialValues.TargetTimeframeEndDate
  );
  const [TargetBaseYear, setTargetBaseYear] = React.useState(
    initialValues.TargetBaseYear
  );
  const [TargetType, setTargetType] = React.useState(initialValues.TargetType);
  const [
    InformedByInternationalAgreement,
    setInformedByInternationalAgreement,
  ] = React.useState(initialValues.InformedByInternationalAgreement);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = climateRelatedTargetsRecord
      ? { ...initialValues, ...climateRelatedTargetsRecord }
      : initialValues;
    setTargetName(cleanValues.TargetName);
    setTargetDescription(cleanValues.TargetDescription);
    setTargetUnit(cleanValues.TargetUnit);
    setTargetValue(cleanValues.TargetValue);
    setTargetObjective(cleanValues.TargetObjective);
    setApplicableEntityPart(cleanValues.ApplicableEntityPart);
    setTargetTimeframeStartDate(cleanValues.TargetTimeframeStartDate);
    setTargetTimeframeEndDate(cleanValues.TargetTimeframeEndDate);
    setTargetBaseYear(cleanValues.TargetBaseYear);
    setTargetType(cleanValues.TargetType);
    setInformedByInternationalAgreement(
      cleanValues.InformedByInternationalAgreement
    );
    setErrors({});
  };
  const [climateRelatedTargetsRecord, setClimateRelatedTargetsRecord] =
    React.useState(climateRelatedTargetsModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(ClimateRelatedTargets, idProp)
        : climateRelatedTargetsModelProp;
      setClimateRelatedTargetsRecord(record);
    };
    queryData();
  }, [idProp, climateRelatedTargetsModelProp]);
  React.useEffect(resetStateValues, [climateRelatedTargetsRecord]);
  const validations = {
    TargetName: [],
    TargetDescription: [],
    TargetUnit: [],
    TargetValue: [],
    TargetObjective: [],
    ApplicableEntityPart: [],
    TargetTimeframeStartDate: [],
    TargetTimeframeEndDate: [],
    TargetBaseYear: [],
    TargetType: [],
    InformedByInternationalAgreement: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          TargetName,
          TargetDescription,
          TargetUnit,
          TargetValue,
          TargetObjective,
          ApplicableEntityPart,
          TargetTimeframeStartDate,
          TargetTimeframeEndDate,
          TargetBaseYear,
          TargetType,
          InformedByInternationalAgreement,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            ClimateRelatedTargets.copyOf(
              climateRelatedTargetsRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClimateRelatedTargetsUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate-Related Targets"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 33."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Heading>
      <Text
        children="Update this record about a quantitative and qualitative climate-related target you have set to monitor progress towards achieving your strategic goals, and any target required to meet by law or regulation, including any greenhouse gas emissions target."
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Target name"
        isRequired={false}
        isReadOnly={false}
        value={TargetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName: value,
              TargetDescription,
              TargetUnit,
              TargetValue,
              TargetObjective,
              ApplicableEntityPart,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear,
              TargetType,
              InformedByInternationalAgreement,
            };
            const result = onChange(modelFields);
            value = result?.TargetName ?? value;
          }
          if (errors.TargetName?.hasError) {
            runValidationTasks("TargetName", value);
          }
          setTargetName(value);
        }}
        onBlur={() => runValidationTasks("TargetName", TargetName)}
        errorMessage={errors.TargetName?.errorMessage}
        hasError={errors.TargetName?.hasError}
        {...getOverrideProps(overrides, "TargetName")}
      ></TextField>
      <TextAreaField
        label="Target description"
        isRequired={false}
        isReadOnly={false}
        value={TargetDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetDescription: value,
              TargetUnit,
              TargetValue,
              TargetObjective,
              ApplicableEntityPart,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear,
              TargetType,
              InformedByInternationalAgreement,
            };
            const result = onChange(modelFields);
            value = result?.TargetDescription ?? value;
          }
          if (errors.TargetDescription?.hasError) {
            runValidationTasks("TargetDescription", value);
          }
          setTargetDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("TargetDescription", TargetDescription)
        }
        errorMessage={errors.TargetDescription?.errorMessage}
        hasError={errors.TargetDescription?.hasError}
        {...getOverrideProps(overrides, "TargetDescription")}
      ></TextAreaField>
      <Text
        children="Define the metric used to set the target."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <SelectField
          label="Target unit"
          placeholder="Please select an option"
          isDisabled={false}
          value={TargetUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetDescription,
                TargetUnit: value,
                TargetValue,
                TargetObjective,
                ApplicableEntityPart,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                TargetBaseYear,
                TargetType,
                InformedByInternationalAgreement,
              };
              const result = onChange(modelFields);
              value = result?.TargetUnit ?? value;
            }
            if (errors.TargetUnit?.hasError) {
              runValidationTasks("TargetUnit", value);
            }
            setTargetUnit(value);
          }}
          onBlur={() => runValidationTasks("TargetUnit", TargetUnit)}
          errorMessage={errors.TargetUnit?.errorMessage}
          hasError={errors.TargetUnit?.hasError}
          {...getOverrideProps(overrides, "TargetUnit")}
        >
          <option
            children="Employees"
            value="Employees"
            {...getOverrideProps(overrides, "TargetUnitoption0")}
          ></option>
          <option
            children="Kg"
            value="Kg"
            {...getOverrideProps(overrides, "TargetUnitoption1")}
          ></option>
          <option
            children="Kw"
            value="Kw"
            {...getOverrideProps(overrides, "TargetUnitoption2")}
          ></option>
          <option
            children="Kwh"
            value="Kwh"
            {...getOverrideProps(overrides, "TargetUnitoption3")}
          ></option>
          <option
            children="Litres"
            value="Litres"
            {...getOverrideProps(overrides, "TargetUnitoption4")}
          ></option>
          <option
            children="Metres cubed"
            value="MetresCubed"
            {...getOverrideProps(overrides, "TargetUnitoption5")}
          ></option>
          <option
            children="Million dollars revenue"
            value="MillionDollarsRevenue"
            {...getOverrideProps(overrides, "TargetUnitoption6")}
          ></option>
          <option
            children="Nights"
            value="Nights"
            {...getOverrideProps(overrides, "TargetUnitoption7")}
          ></option>
          <option
            children="Passenger per km"
            value="PassengerPerKm"
            {...getOverrideProps(overrides, "TargetUnitoption8")}
          ></option>
          <option
            children="Per capita"
            value="PerCapita"
            {...getOverrideProps(overrides, "TargetUnitoption9")}
          ></option>
          <option
            children="Percentage"
            value="Percentage"
            {...getOverrideProps(overrides, "TargetUnitoption10")}
          ></option>
          <option
            children="Quantity"
            value="Quantity"
            {...getOverrideProps(overrides, "TargetUnitoption11")}
          ></option>
          <option
            children="Tonnes"
            value="Tonnes"
            {...getOverrideProps(overrides, "TargetUnitoption12")}
          ></option>
          <option
            children="Tonnes per km"
            value="TonnesPerKm"
            {...getOverrideProps(overrides, "TargetUnitoption13")}
          ></option>
        </SelectField>
        <TextField
          label="Target value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={TargetValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetDescription,
                TargetUnit,
                TargetValue: value,
                TargetObjective,
                ApplicableEntityPart,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                TargetBaseYear,
                TargetType,
                InformedByInternationalAgreement,
              };
              const result = onChange(modelFields);
              value = result?.TargetValue ?? value;
            }
            if (errors.TargetValue?.hasError) {
              runValidationTasks("TargetValue", value);
            }
            setTargetValue(value);
          }}
          onBlur={() => runValidationTasks("TargetValue", TargetValue)}
          errorMessage={errors.TargetValue?.errorMessage}
          hasError={errors.TargetValue?.hasError}
          {...getOverrideProps(overrides, "TargetValue")}
        ></TextField>
      </Grid>
      <SelectField
        label="Target objective"
        placeholder="Please select an option"
        isDisabled={false}
        value={TargetObjective}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetDescription,
              TargetUnit,
              TargetValue,
              TargetObjective: value,
              ApplicableEntityPart,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear,
              TargetType,
              InformedByInternationalAgreement,
            };
            const result = onChange(modelFields);
            value = result?.TargetObjective ?? value;
          }
          if (errors.TargetObjective?.hasError) {
            runValidationTasks("TargetObjective", value);
          }
          setTargetObjective(value);
        }}
        onBlur={() => runValidationTasks("TargetObjective", TargetObjective)}
        errorMessage={errors.TargetObjective?.errorMessage}
        hasError={errors.TargetObjective?.hasError}
        {...getOverrideProps(overrides, "TargetObjective")}
      >
        <option
          children="Mitigation"
          value="Mitigation"
          {...getOverrideProps(overrides, "TargetObjectiveoption0")}
        ></option>
        <option
          children="Adaptation"
          value="Adaptation"
          {...getOverrideProps(overrides, "TargetObjectiveoption1")}
        ></option>
        <option
          children="Conformance with science based initiatives"
          value="ConformanceWithScienceBasedInitiatives"
          {...getOverrideProps(overrides, "TargetObjectiveoption2")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Describe which part of your organisation the target applies to (e.g. the whole organisation, or part of the organisation such as a specific business unit or specific geographical region)"
        isRequired={false}
        isReadOnly={false}
        value={ApplicableEntityPart}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetDescription,
              TargetUnit,
              TargetValue,
              TargetObjective,
              ApplicableEntityPart: value,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear,
              TargetType,
              InformedByInternationalAgreement,
            };
            const result = onChange(modelFields);
            value = result?.ApplicableEntityPart ?? value;
          }
          if (errors.ApplicableEntityPart?.hasError) {
            runValidationTasks("ApplicableEntityPart", value);
          }
          setApplicableEntityPart(value);
        }}
        onBlur={() =>
          runValidationTasks("ApplicableEntityPart", ApplicableEntityPart)
        }
        errorMessage={errors.ApplicableEntityPart?.errorMessage}
        hasError={errors.ApplicableEntityPart?.hasError}
        {...getOverrideProps(overrides, "ApplicableEntityPart")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Target timeframe start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={TargetTimeframeStartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetDescription,
                TargetUnit,
                TargetValue,
                TargetObjective,
                ApplicableEntityPart,
                TargetTimeframeStartDate: value,
                TargetTimeframeEndDate,
                TargetBaseYear,
                TargetType,
                InformedByInternationalAgreement,
              };
              const result = onChange(modelFields);
              value = result?.TargetTimeframeStartDate ?? value;
            }
            if (errors.TargetTimeframeStartDate?.hasError) {
              runValidationTasks("TargetTimeframeStartDate", value);
            }
            setTargetTimeframeStartDate(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "TargetTimeframeStartDate",
              TargetTimeframeStartDate
            )
          }
          errorMessage={errors.TargetTimeframeStartDate?.errorMessage}
          hasError={errors.TargetTimeframeStartDate?.hasError}
          {...getOverrideProps(overrides, "TargetTimeframeStartDate")}
        ></TextField>
        <TextField
          label="Target timeframe end date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={TargetTimeframeEndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetDescription,
                TargetUnit,
                TargetValue,
                TargetObjective,
                ApplicableEntityPart,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate: value,
                TargetBaseYear,
                TargetType,
                InformedByInternationalAgreement,
              };
              const result = onChange(modelFields);
              value = result?.TargetTimeframeEndDate ?? value;
            }
            if (errors.TargetTimeframeEndDate?.hasError) {
              runValidationTasks("TargetTimeframeEndDate", value);
            }
            setTargetTimeframeEndDate(value);
          }}
          onBlur={() =>
            runValidationTasks("TargetTimeframeEndDate", TargetTimeframeEndDate)
          }
          errorMessage={errors.TargetTimeframeEndDate?.errorMessage}
          hasError={errors.TargetTimeframeEndDate?.hasError}
          {...getOverrideProps(overrides, "TargetTimeframeEndDate")}
        ></TextField>
      </Grid>
      <TextField
        label="Target base year"
        isRequired={false}
        isReadOnly={false}
        value={TargetBaseYear}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetDescription,
              TargetUnit,
              TargetValue,
              TargetObjective,
              ApplicableEntityPart,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear: value,
              TargetType,
              InformedByInternationalAgreement,
            };
            const result = onChange(modelFields);
            value = result?.TargetBaseYear ?? value;
          }
          if (errors.TargetBaseYear?.hasError) {
            runValidationTasks("TargetBaseYear", value);
          }
          setTargetBaseYear(value);
        }}
        onBlur={() => runValidationTasks("TargetBaseYear", TargetBaseYear)}
        errorMessage={errors.TargetBaseYear?.errorMessage}
        hasError={errors.TargetBaseYear?.hasError}
        {...getOverrideProps(overrides, "TargetBaseYear")}
      ></TextField>
      <SelectField
        label="Target type"
        placeholder="Please select an option"
        isDisabled={false}
        value={TargetType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetDescription,
              TargetUnit,
              TargetValue,
              TargetObjective,
              ApplicableEntityPart,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear,
              TargetType: value,
              InformedByInternationalAgreement,
            };
            const result = onChange(modelFields);
            value = result?.TargetType ?? value;
          }
          if (errors.TargetType?.hasError) {
            runValidationTasks("TargetType", value);
          }
          setTargetType(value);
        }}
        onBlur={() => runValidationTasks("TargetType", TargetType)}
        errorMessage={errors.TargetType?.errorMessage}
        hasError={errors.TargetType?.hasError}
        {...getOverrideProps(overrides, "TargetType")}
      >
        <option
          children="Absolute target"
          value="AbsoluteTarget"
          {...getOverrideProps(overrides, "TargetTypeoption0")}
        ></option>
        <option
          children="Intensity target"
          value="IntensityTarget"
          {...getOverrideProps(overrides, "TargetTypeoption1")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Describe how your target is informed by the latest international agreement on climate change, including any jurisdictional commitments arising from that agreement"
        isRequired={false}
        isReadOnly={false}
        value={InformedByInternationalAgreement}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetDescription,
              TargetUnit,
              TargetValue,
              TargetObjective,
              ApplicableEntityPart,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              TargetBaseYear,
              TargetType,
              InformedByInternationalAgreement: value,
            };
            const result = onChange(modelFields);
            value = result?.InformedByInternationalAgreement ?? value;
          }
          if (errors.InformedByInternationalAgreement?.hasError) {
            runValidationTasks("InformedByInternationalAgreement", value);
          }
          setInformedByInternationalAgreement(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "InformedByInternationalAgreement",
            InformedByInternationalAgreement
          )
        }
        errorMessage={errors.InformedByInternationalAgreement?.errorMessage}
        hasError={errors.InformedByInternationalAgreement?.hasError}
        {...getOverrideProps(overrides, "InformedByInternationalAgreement")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || climateRelatedTargetsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || climateRelatedTargetsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
