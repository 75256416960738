/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { RiskOpportunity } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskOpportunityBusinessModelValueChainEffectUpdateForm(
  props
) {
  const {
    id: idProp,
    riskOpportunity: riskOpportunityModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    CurrentEffectOnBusinessModel: "",
    AnticipatedEffectOnBusinessModel: "",
    CurrentEffectOnValueChain: "",
    AnticipatedEffectOnValueChain: "",
    WhereConcentratedBusinessModel: "",
    WhereConcentratedValueChain: "",
  };
  const [CurrentEffectOnBusinessModel, setCurrentEffectOnBusinessModel] =
    React.useState(initialValues.CurrentEffectOnBusinessModel);
  const [
    AnticipatedEffectOnBusinessModel,
    setAnticipatedEffectOnBusinessModel,
  ] = React.useState(initialValues.AnticipatedEffectOnBusinessModel);
  const [CurrentEffectOnValueChain, setCurrentEffectOnValueChain] =
    React.useState(initialValues.CurrentEffectOnValueChain);
  const [AnticipatedEffectOnValueChain, setAnticipatedEffectOnValueChain] =
    React.useState(initialValues.AnticipatedEffectOnValueChain);
  const [WhereConcentratedBusinessModel, setWhereConcentratedBusinessModel] =
    React.useState(initialValues.WhereConcentratedBusinessModel);
  const [WhereConcentratedValueChain, setWhereConcentratedValueChain] =
    React.useState(initialValues.WhereConcentratedValueChain);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = riskOpportunityRecord
      ? { ...initialValues, ...riskOpportunityRecord }
      : initialValues;
    setCurrentEffectOnBusinessModel(cleanValues.CurrentEffectOnBusinessModel);
    setAnticipatedEffectOnBusinessModel(
      cleanValues.AnticipatedEffectOnBusinessModel
    );
    setCurrentEffectOnValueChain(cleanValues.CurrentEffectOnValueChain);
    setAnticipatedEffectOnValueChain(cleanValues.AnticipatedEffectOnValueChain);
    setWhereConcentratedBusinessModel(
      cleanValues.WhereConcentratedBusinessModel
    );
    setWhereConcentratedValueChain(cleanValues.WhereConcentratedValueChain);
    setErrors({});
  };
  const [riskOpportunityRecord, setRiskOpportunityRecord] = React.useState(
    riskOpportunityModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(RiskOpportunity, idProp)
        : riskOpportunityModelProp;
      setRiskOpportunityRecord(record);
    };
    queryData();
  }, [idProp, riskOpportunityModelProp]);
  React.useEffect(resetStateValues, [riskOpportunityRecord]);
  const validations = {
    CurrentEffectOnBusinessModel: [],
    AnticipatedEffectOnBusinessModel: [],
    CurrentEffectOnValueChain: [],
    AnticipatedEffectOnValueChain: [],
    WhereConcentratedBusinessModel: [],
    WhereConcentratedValueChain: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CurrentEffectOnBusinessModel,
          AnticipatedEffectOnBusinessModel,
          CurrentEffectOnValueChain,
          AnticipatedEffectOnValueChain,
          WhereConcentratedBusinessModel,
          WhereConcentratedValueChain,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            RiskOpportunity.copyOf(riskOpportunityRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "RiskOpportunityBusinessModelValueChainEffectUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Business Model and Value Chain Effect"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 13 a-b."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update this record that describes the current and anticipated effects of climate-related risks and opportunities on your organisation's business model and value chain."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextAreaField
        label="Current effect of this climate-related risk / opportunity on your business model"
        isRequired={false}
        isReadOnly={false}
        value={CurrentEffectOnBusinessModel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentEffectOnBusinessModel: value,
              AnticipatedEffectOnBusinessModel,
              CurrentEffectOnValueChain,
              AnticipatedEffectOnValueChain,
              WhereConcentratedBusinessModel,
              WhereConcentratedValueChain,
            };
            const result = onChange(modelFields);
            value = result?.CurrentEffectOnBusinessModel ?? value;
          }
          if (errors.CurrentEffectOnBusinessModel?.hasError) {
            runValidationTasks("CurrentEffectOnBusinessModel", value);
          }
          setCurrentEffectOnBusinessModel(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "CurrentEffectOnBusinessModel",
            CurrentEffectOnBusinessModel
          )
        }
        errorMessage={errors.CurrentEffectOnBusinessModel?.errorMessage}
        hasError={errors.CurrentEffectOnBusinessModel?.hasError}
        {...getOverrideProps(overrides, "CurrentEffectOnBusinessModel")}
      ></TextAreaField>
      <TextAreaField
        label="Anticipated effect of this climate-related risk / opportunity on your business model"
        isRequired={false}
        isReadOnly={false}
        value={AnticipatedEffectOnBusinessModel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentEffectOnBusinessModel,
              AnticipatedEffectOnBusinessModel: value,
              CurrentEffectOnValueChain,
              AnticipatedEffectOnValueChain,
              WhereConcentratedBusinessModel,
              WhereConcentratedValueChain,
            };
            const result = onChange(modelFields);
            value = result?.AnticipatedEffectOnBusinessModel ?? value;
          }
          if (errors.AnticipatedEffectOnBusinessModel?.hasError) {
            runValidationTasks("AnticipatedEffectOnBusinessModel", value);
          }
          setAnticipatedEffectOnBusinessModel(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AnticipatedEffectOnBusinessModel",
            AnticipatedEffectOnBusinessModel
          )
        }
        errorMessage={errors.AnticipatedEffectOnBusinessModel?.errorMessage}
        hasError={errors.AnticipatedEffectOnBusinessModel?.hasError}
        {...getOverrideProps(overrides, "AnticipatedEffectOnBusinessModel")}
      ></TextAreaField>
      <TextAreaField
        label="Current effect of this climate-related risk / opportunity on your value chain"
        isRequired={false}
        isReadOnly={false}
        value={CurrentEffectOnValueChain}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentEffectOnBusinessModel,
              AnticipatedEffectOnBusinessModel,
              CurrentEffectOnValueChain: value,
              AnticipatedEffectOnValueChain,
              WhereConcentratedBusinessModel,
              WhereConcentratedValueChain,
            };
            const result = onChange(modelFields);
            value = result?.CurrentEffectOnValueChain ?? value;
          }
          if (errors.CurrentEffectOnValueChain?.hasError) {
            runValidationTasks("CurrentEffectOnValueChain", value);
          }
          setCurrentEffectOnValueChain(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "CurrentEffectOnValueChain",
            CurrentEffectOnValueChain
          )
        }
        errorMessage={errors.CurrentEffectOnValueChain?.errorMessage}
        hasError={errors.CurrentEffectOnValueChain?.hasError}
        {...getOverrideProps(overrides, "CurrentEffectOnValueChain")}
      ></TextAreaField>
      <TextAreaField
        label="Anticipated effect of this climate-related risk / opportunity on your value chain"
        isRequired={false}
        isReadOnly={false}
        value={AnticipatedEffectOnValueChain}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentEffectOnBusinessModel,
              AnticipatedEffectOnBusinessModel,
              CurrentEffectOnValueChain,
              AnticipatedEffectOnValueChain: value,
              WhereConcentratedBusinessModel,
              WhereConcentratedValueChain,
            };
            const result = onChange(modelFields);
            value = result?.AnticipatedEffectOnValueChain ?? value;
          }
          if (errors.AnticipatedEffectOnValueChain?.hasError) {
            runValidationTasks("AnticipatedEffectOnValueChain", value);
          }
          setAnticipatedEffectOnValueChain(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AnticipatedEffectOnValueChain",
            AnticipatedEffectOnValueChain
          )
        }
        errorMessage={errors.AnticipatedEffectOnValueChain?.errorMessage}
        hasError={errors.AnticipatedEffectOnValueChain?.hasError}
        {...getOverrideProps(overrides, "AnticipatedEffectOnValueChain")}
      ></TextAreaField>
      <TextAreaField
        label="Where climate-related risks and opportunities are concentrated in your business model"
        isRequired={false}
        isReadOnly={false}
        value={WhereConcentratedBusinessModel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentEffectOnBusinessModel,
              AnticipatedEffectOnBusinessModel,
              CurrentEffectOnValueChain,
              AnticipatedEffectOnValueChain,
              WhereConcentratedBusinessModel: value,
              WhereConcentratedValueChain,
            };
            const result = onChange(modelFields);
            value = result?.WhereConcentratedBusinessModel ?? value;
          }
          if (errors.WhereConcentratedBusinessModel?.hasError) {
            runValidationTasks("WhereConcentratedBusinessModel", value);
          }
          setWhereConcentratedBusinessModel(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "WhereConcentratedBusinessModel",
            WhereConcentratedBusinessModel
          )
        }
        errorMessage={errors.WhereConcentratedBusinessModel?.errorMessage}
        hasError={errors.WhereConcentratedBusinessModel?.hasError}
        {...getOverrideProps(overrides, "WhereConcentratedBusinessModel")}
      ></TextAreaField>
      <TextAreaField
        label="Where climate-related risks and opportunities are concentrated in your value chain"
        isRequired={false}
        isReadOnly={false}
        value={WhereConcentratedValueChain}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CurrentEffectOnBusinessModel,
              AnticipatedEffectOnBusinessModel,
              CurrentEffectOnValueChain,
              AnticipatedEffectOnValueChain,
              WhereConcentratedBusinessModel,
              WhereConcentratedValueChain: value,
            };
            const result = onChange(modelFields);
            value = result?.WhereConcentratedValueChain ?? value;
          }
          if (errors.WhereConcentratedValueChain?.hasError) {
            runValidationTasks("WhereConcentratedValueChain", value);
          }
          setWhereConcentratedValueChain(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "WhereConcentratedValueChain",
            WhereConcentratedValueChain
          )
        }
        errorMessage={errors.WhereConcentratedValueChain?.errorMessage}
        hasError={errors.WhereConcentratedValueChain?.hasError}
        {...getOverrideProps(overrides, "WhereConcentratedValueChain")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || riskOpportunityModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || riskOpportunityModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
