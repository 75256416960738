import { cloneDeepWith, merge } from "lodash";

import { Schema } from "@aws-amplify/datastore";
import S, { ArraySchema } from "fluent-json-schema";
import { ClimateResilienceAssessmentTypeEnum, TargetEmissionTypeEnum } from "../models";
import { schema } from "../models/schema";
import { insertDisplayNames } from "../shared/amplify/schemaDisplayNames";
import { UISchema, UISchemaAggregate } from "../shared/backend";
import AssetHelp from "../shared/content/AssetHelp";
import AssetRegisterHelp from "../shared/content/AssetRegisterHelp";
import AvoidedEmissionAcountEntryHelp from "../shared/content/AvoidedEmissionAcountEntryHelp";
import BiogenicAcountEntryHelp from "../shared/content/BiogenicAcountEntryHelp";
import CarbonAccountManagementHelp from "../shared/content/CarbonAccountManagementHelp";
import CarbonAccountingLedgersHelp from "../shared/content/CarbonAccountingLedgersHelp";
import EmissionAcountEntryHelp from "../shared/content/EmissionAcountEntryHelp";
import GovernanceDataSetHelp from "../shared/content/GovernanceDataSetHelp";
import LocationHelp from "../shared/content/LocationHelp";
import MetricsTargetsDataSetHelp from "../shared/content/MetricsTargetsDataSetHelp";
import OffsetAcountEntryHelp from "../shared/content/OffsetAcountEntryHelp";
import ReductionAcountEntryHelp from "../shared/content/ReductionAcountEntryHelp";
import ReportingEntityHelp from "../shared/content/ReportingEntityHelp";
import StrategyDataSetHelp from "../shared/content/StrategyDataSetHelp";
import uishared from "../shared/uischema-shared";
import { AsOptional, Latitude, Longitude, RequiredFloat, RequiredString, WithFields } from "../shared/validators/ajv";
import EmissionAccountEntryWizard from "../shared/wizard/EmissionAccountEntryWizard";
import withIndustryMetricsWizard from "../shared/wizard/withIndustryMetricsWizard";
import AggregatedFinancialEffectHelp from "./content/AggregatedFinancialEffectHelp";
import BusinessModelChangeHelp from "./content/BusinessModelChangeHelp";
import BusinessPlanningHorizonsHelp from "./content/BusinessPlanningHorizonsHelp";
import ClimateGovernanceArtifactHelp from "./content/ClimateGovernanceArtifactHelp";
import ClimateGovernanceBodyHelp from "./content/ClimateGovernanceBodyHelp";
import ClimateGovernanceTargetsHelp from "./content/ClimateGovernanceTargetsHelp";
import ClimatePlanningHorizonsHelp from "./content/ClimatePlanningHorizonsHelp";
import ClimateRelatedRisksOpportunitiesHelp from "./content/ClimateRelatedRisksOpportunitiesHelp";
import ClimateRelatedTargetsHelp from "./content/ClimateRelatedTargetsHelp";
import ClimateRelatedTransitionPlanHelp from "./content/ClimateRelatedTransitionPlanHelp";
import ClimateResilienceHelp from "./content/ClimateResilienceHelp";
import ClimateScenarioAnalysisHelp from "./content/ClimateScenarioAnalysisHelp";
import ClimateStrategyOversightHelp from "./content/ClimateStrategyOversightHelp";
import CrossIndustryMetricsHelp from "./content/CrossIndustryMetricsHelp";
import DisclosureBuilderHelp from "./content/DisclosureBuilderHelp";
import FinancialEffectHelp from "./content/FinancialEffectHelp";
import IndustryBasedMetricsHelp from "./content/IndustryBasedMetricsHelp";
import InformGovernanceProcessHelp from "./content/InformGovernanceProcessHelp";
import ManagementOversightProcessHelp from "./content/ManagementOversightProcessHelp";
import ManagementRoleInClimateGovernanceHelp from "./content/ManagementRoleInClimateGovernanceHelp";
import MitigationAdaptationEffortsHelp from "./content/MitigationAdaptationEffortsHelp";
import PlanningHorizonsHelp from "./content/PlanningHorizonsHelp";
import ProgressStrategyPlansHelp from "./content/ProgressStrategyPlansHelp";
import ReportingPeriodHelp from "./content/ReportingPeriodHelp";
import ResilienceAssessmentHelp from "./content/ResilienceAssessmentHelp";
import ResourcingStrategyDecisionMakingTargetsHelp from "./content/ResourcingStrategyDecisionMakingTargetsHelp";
import RiskManagementDataSetHelp from "./content/RiskManagementDataSetHelp";
import RiskManagementProcessPolicyHelp from "./content/RiskManagementProcessPolicyHelp";
import RiskManagementProcessPolicySupplementaryHelp from "./content/RiskManagementProcessPolicySupplementaryHelp";
import RiskOpportunityMetricsHelp from "./content/RiskOpportunityMetricsHelp";
import RisksOpportunitiesHelp from "./content/RisksOpportunitiesHelp";
import ScenarioAnalysisAssumptionsHelp from "./content/ScenarioAnalysisAssumptionsHelp";
import StrategyAndDecisionMakingHelp from "./content/StrategyAndDecisionMakingHelp";
import TargetAchievementPlanHelp from "./content/TargetAchievementPlanHelp";

const uiextensions: UISchema = {
  wrappedSchema: schema,
  enums: {
    AmountPercentageEnum: { displayColor: "rgba(128, 108, 59, 0.8)" },
    CarbonCreditTypeEnum: { displayColor: "rgba(100, 155, 133, 1)" },
    ClimatePlanningHorizonsEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    CountryEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    CurrencyEnum: { displayColor: "rgb(218, 108, 85,0.8)" },
    EmissionCategoryEnum: { displayColor: "rgba(49, 66, 111)" },
    EmissionScopeEnum: { displayColor: "rgba(187, 104, 95)" },
    EmissionUnitEnum: { displayColor: "rgba(49, 66, 111)" },
    FacilityOrSiteEnum: { displayColor: "rgba(49, 66, 111)" },
    LikelihoodOfOccurringEnum: { displayColor: "rgba(49, 66, 111)" },
    OrgIdTypeEnum: { displayColor: "rgba(49, 66, 111, 0.8)" },
    PriorityEnum: { displayColor: "rgba(175, 4, 4, 0.64)" },
    ProcessFrequencyEnum: { displayColor: "rgba(49, 66, 111)" },
    ProcessTypeEnum: { displayColor: "rgba(2, 73, 87, 0.8)" },
    PublishedToEnum: { displayColor: "rgba(49, 66, 111,0.8)" },
    ReductionInitiativeStatusEnum: { displayColor: "rgba(49, 66, 111, 0.8)" },
    RiskOpportunityGroupEnum: { displayColor: "rgba(175, 4, 4, 0.64)" },
    RiskOpportunityTypeEnum: { displayColor: "rgba(100, 155, 133, 1)" },
    ScenarioAnalysisMethodEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    ScenarioTemperatureAlignmentEnum: {
      displayColor: "rgba(100, 155, 133, 1)"
    },
    StatusEnum: { displayColor: "rgba(100, 155, 133, 1)" },
    TargetTypeEnum: { displayColor: "rgba(2, 73, 87, 0.8)" }
  },
  models: {
    AggregatedFinancialEffect: {
      fields: {
        WhyNoQuanitativeFinancialData: {
          hideInTable: true
        }
      },
      helpContent: AggregatedFinancialEffectHelp,
      showEvidence: true
    },
    Asset: {
      fields: {
        AssetClass: {
          displayName: "Class",
          hideInTable: true
        },
        AssetComponent: {
          displayName: "Component",
          hideInTable: true
        },
        AssetDescription: {
          displayName: "Description",
          isUnique: true
        },
        AssetID: {
          displayName: "Asset ID",
          isUnique: true
        },
        AssetName: {
          displayName: "Name",
          isUnique: true
        },
        AssetType: {
          displayName: "Type"
        },
        CapitalisationDate: {
          displayName: "Capitalisation Date",
          hideInTable: true
        },
        Condition: {
          displayName: "Condition",
          hideInTable: true
        },
        CurrentValue: {
          displayName: "Current Value",
          hideInTable: true
        },
        Lifespan: {
          displayName: "Lifespan",
          hideInTable: true
        },
        Quantity: {
          displayName: "Quantity",
          hideInTable: true
        },
        ValueAtAcquisition: {
          displayName: "Value at Acquisition",
          isUnique: true
        },
        ValueClimateAffected: {
          displayName: "Value Climate Affected",
          isUnique: true
        },
        ValueClimateAffectedType: {
          displayName: "Value Climate Affected Type",
          hideInTable: true
        }
      },
      helpContent: AssetHelp,
      showEvidence: true
    },
    AssetRegister: {
      fields: {
        Assets: {
          description:
            "Register used to record the organization's assets that are subject to climate-related risks and opportunities.",
          publishSchema: AsOptional
        }
      },
      helpContent: AssetRegisterHelp
    },
    AvoidedEmissionAccountEntry: {
      fields: {
        AvoidedEmissionTransactionDataSource: {
          displayName: "Data Source"
        },
        AvoidedEmissionTransactionDate: {
          displayName: "Date"
        },
        AvoidedEmissionTransactionDescription: {
          displayName: "Description"
        },
        AvoidedEmissionTransactionName: {
          displayName: "Name",
          isUnique: true
        },
        AvoidedEmissionTransactionStatus: {
          hideInTable: true
        },
        GrossAmountCO2Avoided: {
          displayName: "Tonnes CO2 Avoided"
        }
      },
      helpContent: AvoidedEmissionAcountEntryHelp
    },
    BiogenicAccountEntry: {
      fields: {
        BiogenicTransactionDataSource: {
          displayName: "Data Source"
        },
        BiogenicTransactionDescription: {
          displayName: "Description"
        },
        BiogenicTransactionName: {
          displayName: "Name",
          isUnique: true
        },
        BiogenicTransactionStatus: {
          displayName: "Status"
        },
        GrossAmountCO2Sequestered: {
          displayName: "CO2 Sequestered"
        },
        SequestrationDate: {
          displayName: "Date"
        }
      },
      helpContent: BiogenicAcountEntryHelp,
      showEvidence: true
    },
    BusinessModelChange: {
      fields: {
        AnticipatedBusinessModelChange: {
          displayName: "Future change"
        },
        CurrentBusinessModelChange: {
          displayName: "Current change"
        }
      },
      helpContent: BusinessModelChangeHelp,
      showEvidence: true
    },
    BusinessPlanningHorizon: {
      fields: {
        BusinessPlanningHorizonDescription: {
          displayName: "Description"
        },
        BusinessPlanningHorizonName: {
          displayName: "Horizon Name"
        },
        HorizonEnd: {
          displayName: "End"
        },
        HorizonStart: {
          displayName: "Start"
        }
      },
      helpContent: BusinessPlanningHorizonsHelp,
      showEvidence: true
    },
    CarbonAccount: {
      discriminatorField: "CarbonAccountSelectType",
      fields: {
        AccountName: {
          displayName: "Name",
          isUnique: true
        },
        AvoidedEmissionAccountEntries: {
          publishSchema: AsOptional
        },
        BiogenicAccountEntries: {
          publishSchema: AsOptional
        },
        EmissionAccountEntries: {
          publishSchema: AsOptional
        },
        OffsetAccountEntries: {
          publishSchema: AsOptional
        },
        ReductionAccountEntries: {
          publishSchema: AsOptional
        }
      },
      helpContent: CarbonAccountManagementHelp,
      subtypes: {
        AvoidedEmissionAccount: {
          description: "Account for planned and achieved emissions that have been avoided (i.e. not emitted).",
          fields: {
            AccountName: {
              displayName: "Avoided Account Name"
            },
            Description: {
              displayName: "Avoided Account Description"
            }
          },
          includeRelations: /^AvoidedEmissionAccountEntries$/
        },
        BiogenicAccount: {
          description: "Account for planned and achieved emissions reductions using biogenic sequestration.",
          displayName: "Biogenic Sequestration Account",
          fields: {
            AccountName: {
              displayName: "Biogenic Account Name"
            },
            Description: {
              displayName: "Biogenic Account Description"
            }
          },
          includeRelations: /^BiogenicAccountEntries$/
        },
        EmissionAccount: {
          description: "Account for greenhouse gas emissions.",
          fields: {
            AccountName: {
              displayName: "Emission Account Name"
            },
            Description: {
              displayName: "Emission Account Description"
            }
          },
          includeRelations: /^EmissionAccountEntries$/
        },
        OffsetAccount: {
          description: "Account for emission offset purchases / carbon credits.",
          displayName: "Carbon Credits / Offsets Account",
          fields: {
            AccountName: {
              displayName: "Carbon Credit / Offset Account Name"
            },
            Description: {
              displayName: "Carbon Credit / Offset Account Description"
            }
          },
          includeRelations: /^OffsetAccountEntries$/
        },
        ReductionAccount: {
          description: "Account for planned and achieved emissions reductions (scope 1-3).",
          fields: {
            AccountName: {
              displayName: "Reduction Account Name"
            },
            Description: {
              displayName: "Reduction Account Description"
            }
          },
          includeRelations: /^ReductionAccountEntries$/
        }
      }
    },
    CarbonAccountingLedger: {
      fields: {
        CarbonAccounts: {
          targetSubtypes: schema.enums.CarbonAccountSelectTypeEnum.values,
          publishSchema: AsOptional
        }
      },
      helpContent: CarbonAccountingLedgersHelp
    },
    ClimateGovernanceProcess: {
      discriminatorField: "GovernanceProcessSelectType",
      fields: {
        GovernanceProcessName: {
          displayName: "Name",
          isUnique: true
        },
        ProcessDescription: {
          displayName: "Description"
        },
        ProcessFrequency: {
          displayName: "Frequency"
        }
      },
      subtypes: {
        ClimateStrategyOversight: {
          description:
            "Enter record(s) to describe how the governance body(s) or individual(s) takes into account climate-related risks and opportunities when overseeing your strategy, decisions on major transactions, and risk management processes and related policies.",
          displayName: "Climate-related Oversight of Organizational Strategy",
          fields: {
            GovernanceProcessName: {
              displayName: "Process Name"
            },
            ProcessDescription: {
              displayName: "Process Description"
            }
          },
          helpContent: ClimateStrategyOversightHelp,
          includeColumns: /GovernanceProcessName|ProcessDescription|ProcessFrequency/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6 a iv.",
          subtypeOrder: 2,
          publishSchema: WithFields("GovernanceProcessName", "ProcessDescription", "ProcessFrequency")
        },
        GovernanceArtifact: {
          description:
            "Enter record(s) to describe how responsibilities for climate-related risks and opportunities are reflected in governance artifacts.",
          displayName: "Governance Artifacts",
          helpContent: ClimateGovernanceArtifactHelp,
          includeColumns: /GovernanceArtifactDescription|GovernanceArtifactName/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6 a i.",
          subtypeOrder: 1,
          publishSchema: WithFields("GovernanceArtifactDescription", "GovernanceArtifactName")
        },
        GovernanceMetricsTargets: {
          description:
            "Enter record(s) to describe how the governance body(s) or individual(s) oversees the setting of targets related to climate-related risks and opportunities, monitors progress towards those targets, and includes performance metrics in remuneration policies.",
          displayName: "Climate-related Governance of Targets",
          fields: {
            GovernanceProcessName: {
              displayName: "Process Name"
            },
            ProcessDescription: {
              displayName: "Process Description"
            }
          },
          helpContent: ClimateGovernanceTargetsHelp,
          includeColumns: /GovernanceProcessName|ProcessDescription|ProcessFrequency/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6 a v.",
          subtypeOrder: 4,
          publishSchema: WithFields("GovernanceProcessName", "ProcessDescription", "ProcessFrequency")
        },
        InformGovernance: {
          description:
            "Enter record(s) to describe how, and how often, the governance body(s) or individual(s) are informed about climate-related risks and opportunities.",
          displayName: "Inform Governance Body about Climate-related Risks and Opportunities",
          fields: {
            GovernanceProcessName: {
              displayName: "Process Name"
            },
            ProcessDescription: {
              displayName: "Process Description"
            }
          },
          helpContent: InformGovernanceProcessHelp,
          includeColumns: /GovernanceProcessName|ProcessDescription|ProcessFrequency/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6 a iii.",
          subtypeOrder: 3,
          publishSchema: WithFields("GovernanceProcessName", "ProcessDescription", "ProcessFrequency")
        },
        ManagementOversight: {
          description:
            "Enter record(s) to describe how oversight is exercised over management's role in climate-related governance.",
          displayName: "Management Oversight of Climate-related Risks and Opportunities",
          fields: {
            GovernanceProcessName: {
              displayName: "Process Name"
            },
            ProcessDescription: {
              displayName: "Process Description"
            }
          },
          helpContent: ManagementOversightProcessHelp,
          includeColumns: /GovernanceProcessName|ProcessDescription|ProcessFrequency/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6 b ii.",
          publishSchema: WithFields("GovernanceProcessName", "ProcessDescription", "ProcessFrequency")
        }
      }
    },
    ClimatePlanningHorizon: {
      fields: {
        BusinessPlanningHorizons: {
          description: "Enter record(s) to describe the planning horizons used by the organization for strategic decision-making.",
          displayName: "Business Strategic Planning Horizons",
          standardReference: "IFRS S2 paragraph 10d."
        },
        HorizonEnd: {
          displayName: "End"
        },
        HorizonPeriodType: {
          displayName: "Horizon Period"
        },
        HorizonStart: {
          displayName: "Start"
        }
      },
      helpContent: ClimatePlanningHorizonsHelp,
      showEvidence: true
    },
    ClimateRelatedMetrics: {
      discriminatorField: "ClimateRelatedMetricsSelectType",
      fields: {
        AbsoluteGrossScope1Emissions: {
          displayName: "Scope 1 Emissions"
        },
        AbsoluteGrossScope2Emissions: {
          displayName: "Scope 2 Emissions"
        },
        AbsoluteGrossScope3Emissions: {
          displayName: "Scope 3 Emissions"
        },
        AdditionalCategory15Information: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        CapitalDeploymentValue: {
          hideInTable: true
        },
        ChangesInApproachFromPreviousPeriod: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ContracturalInstrumentsInformingScope2: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        DisclosureCode: {
          publishSchema: AsOptional
        },
        GHGMeasurementStandardUsed: {
          hideInTable: true
        },
        IndustryCode: {
          publishSchema: AsOptional
        },

        InternalCarbonPrice: {
          displayName: ""
        },
        InternalCarbonPriceDecisionExplanation: {
          hideInTable: true
        },
        MeasurementApproach: {
          hideInTable: true
        },
        MeasurementAssumptions: {
          hideInTable: true
        },
        MeasurementInputs: {
          hideInTable: true
        },
        MetricCode: {
          publishSchema: AsOptional
        },
        MetricDataSetName: {
          displayName: "Name",
          isUnique: true
        },
        OpportunitiesAmount: {
          displayName: ""
        },
        OpportunitiesAssetActivityType: {
          hideInTable: true
        },
        OpportunitiesPercentage: {
          hideInTable: true
        },
        PhysicalRisksAmount: {
          displayName: ""
        },
        PhysicalRisksAssetActivityType: {
          hideInTable: true
        },
        PhysicalRisksPercentage: {
          hideInTable: true
        },
        ReasonForApproach: {
          hideInTable: true
        },
        RemunerationAlternative: {
          hideInTable: true
        },
        RemunerationDescription: {
          hideInTable: true
        },
        RemunerationPercentageValue: {
          hideInTable: true
        },
        Scope1EmissionsByConsolidatedAcctGrp: {
          hideInTable: true
        },
        Scope1EmissionsOtherInvestees: {
          hideInTable: true
        },
        Scope2EmissionsByConsolidatedAcctGrp: {
          hideInTable: true
        },
        Scope2EmissionsOtherInvestees: {
          hideInTable: true
        },
        Scope2LocationBasedEmissions: {
          hideInTable: true
        },
        Scope3EmissionsCategoriesIncluded: {
          hideInTable: true
        },
        TotalAbsoluteGrossAllScopesEmissions: {
          displayName: "Total Emissions"
        },
        TransitionRisksAmount: {
          displayName: ""
        },
        TransitionRisksAssetActivityType: {
          hideInTable: true
        },
        TransitionRisksPercentage: {
          hideInTable: true
        }
      },
      subtypes: {
        CrossIndustryMetrics: {
          description: "Enter record(s) to describe cross-industry metrics for greenhouse gases.",
          includeColumns:
            /AbsoluteGrossScope1Emissions|AbsoluteGrossScope2Emissions|AbsoluteGrossScope3Emissions|AdditionalCategory15Information|ChangesInApproachFromPreviousPeriod|ContracturalInstrumentsInformingScope2|GHGMeasurementStandardUsed|MeasurementApproach|MeasurementAssumptions|MeasurementInputs|MetricDataSetName|ReasonForApproach|Scope1EmissionsByConsolidatedAcctGrp|Scope1EmissionsOtherInvestees|Scope2EmissionsByConsolidatedAcctGrp|Scope2EmissionsOtherInvestees|Scope2LocationBasedEmissions|Scope3EmissionsCategoriesIncluded|TotalAbsoluteGrossAllScopesEmissions/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 29 a.",
          helpContent: CrossIndustryMetricsHelp,
          fields: {
            Assets: {
              hideInRelations: true
            }
          },
          publishSchema: WithFields(
            "AbsoluteGrossScope1Emissions",
            "AbsoluteGrossScope2Emissions",
            "AbsoluteGrossScope3Emissions",
            "AdditionalCategory15Information|ChangesInApproachFromPreviousPeriod|ContracturalInstrumentsInformingScope2|GHGMeasurementStandardUsed|MeasurementApproach|MeasurementAssumptions|MeasurementInputs|MetricDataSetName|ReasonForApproach|Scope1EmissionsByConsolidatedAcctGrp|Scope1EmissionsOtherInvestees|Scope2EmissionsByConsolidatedAcctGrp",
            "Scope2EmissionsOtherInvestees",
            "Scope2LocationBasedEmissions",
            "Scope3EmissionsCategoriesIncluded",
            "TotalAbsoluteGrossAllScopesEmissions"
          )
        },
        IndustryMetrics: {
          description:
            "Enter record(s) to describe the industry-based metrics associated with business models, activities or other features common to an industry.",
          includeColumns:
            /DisclosureTopic|DisclosureTopicCode|Metric|MetricCode|DiscussionAnalysis|MetricValue|MetricUnitOfMeasure|IndustryCode|MetricDataSetName/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 32.",
          helpContent: IndustryBasedMetricsHelp,
          fields: {
            Assets: {
              hideInRelations: true
            }
          },
          formOptions: {
            Create: {
              formWrappers: withIndustryMetricsWizard
            }
          },
          publishSchema: WithFields(
            "DisclosureTopic",
            "DisclosureTopicCode",
            "Metric",
            "MetricCode",
            "DiscussionAnalysis",
            "MetricValue",
            "MetricUnitOfMeasure",
            "IndustryCode",
            "MetricDataSetName"
          )
        },
        RiskOpportunityMetrics: {
          description:
            "Enter record(s) to describe the cross-industry metrics for climate-related risks and opportunities, capital deployed, carbon price and remuneration.",
          includeColumns:
            /CapitalDeploymentValue|InternalCarbonPrice|InternalCarbonPriceDecisionExplanation|MetricDataSetName|OpportunitiesAmount|OpportunitiesAssetActivityType|OpportunitiesPercentage|PhysicalRisksAmount|PhysicalRisksAssetActivityType|PhysicalRisksPercentage|RemunerationDescription|RemunerationPercentageValue|TransitionRisksAmount|TransitionRisksAssetActivityType|TransitionRisksPercentage/,
          standardReference: "IFRS S2 paragraph 29 b-g.",
          helpContent: RiskOpportunityMetricsHelp,
          fields: {
            Assets: {
              hideInRelations: true
            }
          },
          publishSchema: WithFields(
            "CapitalDeploymentValue",
            "InternalCarbonPrice",
            "InternalCarbonPriceDecisionExplanation",
            "MetricDataSetName",
            "OpportunitiesAmount",
            "OpportunitiesAssetActivityType",
            "OpportunitiesPercentage",
            "PhysicalRisksAmount",
            "PhysicalRisksAssetActivityType",
            "PhysicalRisksPercentage",
            "RemunerationDescription",
            "RemunerationPercentageValue",
            "TransitionRisksAmount",
            "TransitionRisksAssetActivityType",
            "TransitionRisksPercentage"
          )
        }
      }
    },
    ClimateRelatedRiskOpportunity: {
      fields: {
        AggregatedFinancialEffects: {
          description:
            "Enter record(s) to describe the combined or aggregated financial effects of your organization's climate-related risks or opportunities.",
          displayName: "Aggregated Financial Effect from Risks and Opportunities",
          standardReference: "IFRS S2 paragraph 21 c.",
          publishSchema: AsOptional
        },
        DataSetName: {
          displayName: "Record Name"
        },
        PlanningHorizons: {
          description:
            "Enter record(s) to describe the planning horizons where each climate-related risk and opportunity could reasonably be expected to occur, and how these are linked to the planning horizons used for strategic decision-making.",
          displayName: "Planning Horizons",
          standardReference: "IFRS S2 paragraph 10 d."
        },
        RiskOpportunities: {
          description:
            "Enter record(s) to describe the climate-related risks and opportunities that could reasonably be expected to affect your organization.",
          displayName: "Climate-related Risks and Opportunities",
          standardReference: "IFRS S2 paragraph 10."
        }
      },
      helpContent: ClimateRelatedRisksOpportunitiesHelp
    },
    ClimateRelatedTargets: {
      helpContent: ClimateRelatedTargetsHelp,
      showEvidence: true,
      displayComposed: true,
      fields: {
        ClimateRelatedMetrics: {
          order: 1,
          publishSchema: AsOptional
        },
        OffsetAccountEntries: {
          order: 2,
          publishSchema: AsOptional
        },
        AnalysisOfTrendsInPerformance: {
          hideInTable: true
        },
        ApplicableEntityPart: {
          hideInTable: true
        },
        AssociatedGrossGHGEmissionTarget: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ClimateRelatedTargetsSelectType: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ExtentRelianceOnCarbonCredits: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ExtentRelianceOnCarbonCreditsDescription: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        GHGCoveredByTarget: {
          hideInTable: true
        },
        InformedByInternationalAgreement: {
          hideInTable: true
        },
        OtherCarbonCreditFactorsDescription: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ParentTarget: {
          publishSchema: AsOptional
        },
        PerformanceAgainstTarget: {
          hideInTable: true
        },
        ProgressMonitoringMetric: {
          hideInTable: true
        },
        ReductionAccountEntries: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        RelianceonOffsetsType: {
          hideInTable: true
        },
        RevisionExplanation: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        SectoralDecarbonisationApproach: {
          hideInTable: true
        },
        TargetBaseYear: {
          displayName: "Base Year"
        },
        TargetDescription: {
          displayName: "Description"
        },
        TargetEmissionScope: {
          displayName: "Scope",
          hideInTable: true
        },
        TargetEmissionType: {
          displayName: "Type",
          hideInTable: true
        },
        TargetMethodologyValidation: {
          displayName: "Methodology Validation",
          hideInTable: true
        },
        TargetName: {
          displayName: "Name",
          isUnique: true
        },
        TargetObjective: {
          displayName: "Objective",
          hideInTable: true
        },
        TargetReviewProcessDescription: {
          displayName: "Process Description",
          hideInTable: true
        },
        TargetReviewProcessFrequency: {
          displayName: "Process Frequency",
          hideInTable: true
        },
        TargetReviewProcessName: {
          displayName: "Process Name",
          hideInTable: true
        },
        TargetRevision: {
          displayName: "Revision",
          hideInTable: true,
          publishSchema: AsOptional
        },
        TargetTimeframeEndDate: {
          displayName: "End Date",
          hideInTable: true
        },
        TargetTimeframeStartDate: {
          displayName: "Start Date",
          hideInTable: true
        },
        TargetType: {
          displayName: "Target Type"
        },
        TargetUnit: {
          displayName: "Target Unit"
        },
        TargetValue: {
          displayName: "Target Value"
        }
      },
      publishSchema: (s) =>
        s
          .ifThen(
            S.object().prop("ProgressMonitoringMetric", S.null()),
            S.object().prop("ClimateRelatedMetrics", S.array().minItems(1))
          )
          .ifThen(
            S.object().prop("TargetEmissionTypeENUM", S.const(TargetEmissionTypeEnum.NET_GHG_EMISSION_TARGET)),
            S.object().prop("AssociatedGrossGHGEmissionTarget", RequiredString)
          ),
      subtypes: {
        GreenhouseGasEmissionTargetData: {
          includeColumns:
            new RegExp(`AssociatedGrossGHGEmissionTarget|ClimateRelatedTargetsSelectType|ExtentRelianceOnCarbonCredits|TargetReviewProcesses
            ExtentRelianceOnCarbonCreditsDescription|GHGCoveredByTarget|OtherCarbonCreditFactorsDescription|
            RelianceonOffsetsType|SectoralDecarbonisationApproach|TargetDescription|TargetEmissionScope|TargetEmissionType|TargetName`),
          includeRelations: /ClimateRelatedMetrics:(RiskOpportunityMetrics|IndustryMetrics)/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 36 a-e.",
          subtypeOrder: 1
        },
        PerformanceAgainstTarget: {
          includeColumns: /ClimateRelatedTargetsSelectType|TargetDescription|TargetName/,
          includeRelations: /ClimateRelatedMetrics:(RiskOpportunityMetrics|IndustryMetrics)/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 35.",
          subtypeOrder: 3
        },
        TargetSettingReviewApproach: {
          includeColumns:
            /ClimateRelatedTargetsSelectType|ProgressMonitoringMetric|RevisionExplanation|TargetMethodologyValidation|TargetName|TargetRevision/,
          includeRelations: /CrossIndusMetrics:(RiskOpportunityMetrics|IndustryMetrics)/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 34 a-d.",
          subtypeOrder: 2
        }
      }
    },
    ClimateResilience: {
      fields: {
        ClimateResilienceAssessments: {
          description:
            "Enter record(s) to describe your organization's assessment of its climate resilience as at the reporting date.",
          displayName: "Climate-related Resilience Assessment",
          standardReference: "IFRS S2 paragraph 22 a i-iii.",
          publishSchema: (s) =>
            (s as ArraySchema).minItems(Object.keys(ClimateResilienceAssessmentTypeEnum).length).raw({
              uniqueItemProperties: ["ClimateResilienceAssessmentType"],
              errorMessage: { uniqueItemProperties: "Should have one for each Area of Assessment" }
            })
        },
        DataSetName: {
          displayName: "Record Name"
        },
        ScenarioAnalyses: {
          description:
            "Enter record(s) to describe how and when your organization's climate-related scenario analysis was carried out.",
          displayName: "Climate-related Scenario Analysis",
          standardReference: "IFRS S2 paragraph 22 b i."
        },
        ScenarioAnalysisAssumptions: {
          description: "Enter record(s) to describe the key assumptions made in your organization's scenario analysis.",
          displayName: "Scenario Analysis Assumptions",
          standardReference: "IFRS S2 paragraph 22 b ii."
        }
      },
      helpContent: ClimateResilienceHelp
    },
    ClimateResilienceAssessment: {
      fields: {
        ClimateResilienceAssessmentType: {
          displayName: "Type"
        },
        Description: {
          displayName: "Description"
        },
        QuantitativeAssessmentUnit: {
          hideInTable: true
        },
        QuantitativeAssessmentValue: {
          displayName: "Value",
          publishSchema: AsOptional
        },
        QuantitativeAssessmentValueMax: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        QuantitativeAssessmentValueMin: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ResilienceAssessmentName: {
          displayName: "Name",
          isUnique: true
        }
      },
      helpContent: ResilienceAssessmentHelp,
      showEvidence: true,
      publishSchema: (s) =>
        s.ifThen(
          S.object().prop("QuantitativeAssessmentValue", S.oneOf([S.null(), S.const(0)])),
          S.object().prop("QuantitativeAssessmentValueMin", RequiredFloat).prop("QuantitativeAssessmentValueMax", RequiredFloat)
        )
    },
    Disclosure: {
      fields: {
        PublishedTo: {
          valueOnClone: []
        },
        Status: {
          valueOnClone: "Draft"
        },
        GovernanceDataCollection: {
          displayName: "Governance Records",
          description: "The set of Governance records for this Disclosure.",
          order: 3,
          standardReference: "IFRS S2 paragraph 5."
        },
        MetricsAndTargetsDataCollection: {
          displayName: "Metrics and Targets Records",
          description: "The set of Metrics and Targets records for this Disclosure.",
          order: 6,
          standardReference: "IFRS S2 paragraph 27."
        },
        reportingentityID: {
          displayName: "Reporting Entity",
          description: "Information about the reporting entity making this Disclosure.",
          order: 1,
          targetModel: "ReportingEntity"
        },
        ReportingPeriod: {
          displayName: "Disclosure Reporting Period",
          description: "The reporting period for this Disclosure.",
          order: 2
        },
        RiskManagementDataCollection: {
          displayName: "Risk Management Records",
          description: "The set of Risk Management process records and how they relate to this Disclosure.",
          order: 5,
          standardReference: "IFRS S2 paragraph 24."
        },
        StrategyDataCollection: {
          displayName: "Strategy Records",
          description: "The set of Strategy records for this Disclosure.",
          order: 4,
          standardReference: "IFRS S2 paragraph 8."
        },
        Notes: {
          publishSchema: AsOptional
        }
      },
      helpContent: DisclosureBuilderHelp
    },
    EmissionAccountEntry: {
      fields: {
        DataSource: {
          displayName: "Data Source"
        },
        EmissionCategory: {
          displayName: "Category"
        },
        EmissionConversionFactor: {
          displayName: "Conversion Factor",
          hideInTable: true
        },
        EmissionDate: {
          displayName: "Date",
          hideInTable: true
        },
        EmissionDateRangeEnd: {
          displayName: "End",
          hideInTable: true
        },
        EmissionDateRangeStart: {
          displayName: "Start",
          hideInTable: true
        },
        EmissionDescription: {
          displayName: "Description"
        },
        EmissionGroup: {
          displayName: "Group",
          hideInTable: true
        },
        EmissionScope: {
          displayName: "Scope"
        },
        EmissionUnit: {
          displayName: "Unit",
          hideInTable: true
        },
        EmissionUsageValue: {
          displayName: "Value"
        },
        GrossEmission: {
          displayName: "Gross Emission"
        }
      },
      helpContent: EmissionAcountEntryHelp,
      showEvidence: true,
      formOptions: {
        Create: {
          formOverride: EmissionAccountEntryWizard
        }
      }
    },
    FinancialEffect: {
      fields: {
        FinancialEffectName: {
          displayName: "Name",
          isUnique: true
        },
        FinancialEffectQualitativeDescription: {
          displayName: "Qualitative Description",
          hideInTable: true,
          publishSchema: AsOptional
        },
        FinancialEffectQuantitativeDescription: {
          displayName: "Quantitative Description",
          hideInTable: true,
          publishSchema: AsOptional
        },
        FinancialEffectType: {
          displayName: "Type"
        },
        FinancialEffectUnit: {
          displayName: "Unit of Measure",
          hideInTable: true,
          publishSchema: AsOptional
        },
        FinancialEffectValue: {
          displayName: "Value of Effect",
          publishSchema: AsOptional
        },
        FinancialEffectValueMax: {
          displayName: "Value of Effect (max)",
          hideInTable: true,
          publishSchema: AsOptional
        },
        FinancialEffectValueMin: {
          displayName: "Value of Effect (min)",
          hideInTable: true,
          publishSchema: AsOptional
        },
        FinancialPositionChangeType: {
          displayName: "Effect On"
        },
        HorizonPeriodType: {
          displayName: "Period of Effect"
        },
        WhyNoQuanitativeFinancialData: {
          hideInTable: true,
          publishSchema: AsOptional
        }
      },
      publishSchema: (s) =>
        s.ifThen(
          S.object()
            .prop("FinancialEffectValue", S.null())
            .prop("FinancialEffectUnit", S.null())
            .prop("FinancialEffectValueMax", S.null())
            .prop("FinancialEffectValueMin", S.null()),
          S.object()
            .prop("WhyNoQuanitativeFinancialData", RequiredString)
            .prop("FinancialEffectQualitativeDescription", RequiredString)
        ),
      helpContent: FinancialEffectHelp,
      showEvidence: true
    },
    GovernanceData: {
      discriminatorField: "GovernanceDataSelectType",
      fields: {
        GovernanceDescription: {
          displayName: "Description"
        },
        GovernanceName: {
          displayName: "Record Name",
          isUnique: true
        },
        GovernanceProcesses: {
          targetSubtypes: schema.enums.GovernanceProcessSelectTypeEnum.values
        }
      },
      subtypes: {
        GovernanceBody: {
          description:
            "Enter record(s) to describe the governance body(s) or individual(s) responsible for oversight of climate-related risks and opportunities.",
          displayName: "Climate Oversight Governance Body",
          helpContent: ClimateGovernanceBodyHelp,
          includeColumns: /GovernanceDescription|GovernanceName|SkillCompetencyDetermination/,
          includeRelations:
            /^GovernanceProcesses:(InformGovernance|ClimateStrategyOversight|GovernanceMetricsTargets|GovernanceArtifact)$/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6a.",
          publishSchema: WithFields("GovernanceDescription", "GovernanceName", "SkillCompetencyDetermination")
        },
        ManagementGovernanceRole: {
          description:
            "Enter record(s) to describe management’s role in the governance processes, controls and procedures used to monitor, manage and oversee climate-related risks and opportunities.",
          displayName: "Management Role in Climate Governance",
          fields: {
            GovernanceName: { isUnique: true },
            ManagementControlsProceduresIntegration: {
              hideInTable: true
            },
            SkillCompetencyDetermination: {
              hideInTable: true
            }
          },
          helpContent: ManagementRoleInClimateGovernanceHelp,
          includeColumns: /GovernanceDescription|GovernanceName|ManagementDelegation/,
          includeRelations: /^GovernanceProcesses:(ManagementOversight)$/,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 6b.",
          publishSchema: WithFields("GovernanceDescription", "GovernanceName", "ManagementDelegation")
        }
      }
    },
    GovernanceDataCollection: {
      fields: {
        DataSetName: { displayName: "Record Name" },
        GovernanceData: {
          targetSubtypes: schema.enums.GovernanceDataSelectTypeEnum.values
        }
      },
      helpContent: GovernanceDataSetHelp
    },
    Location: {
      description: " Location of assets, business activities, and reporting entities.",
      fields: {
        AddressCity: {
          displayName: "City"
        },
        AddressCountry: {
          displayName: "Country"
        },
        AddressLine1: {
          displayName: "Address Line 1",
          hideInTable: true
        },
        AddressLine2: {
          displayName: "Address Line 2",
          hideInTable: true
        },
        AddressLine3: {
          displayName: "Address Line 3",
          hideInTable: true
        },
        AddressPostCode: {
          displayName: "Post Code",
          hideInTable: true
        },
        Description: {
          displayName: "Description"
        },
        FacilityOrSite: {
          displayName: "Facility or Site"
        },
        Latitude: {
          hideInTable: true,
          validator: Latitude
        },
        LocationID: {
          displayName: "ID",
          hideInTable: true,
          isUnique: true
        },
        LocationName: {
          displayName: "Name",
          isUnique: true
        },
        Longitude: {
          hideInTable: true,
          validator: Longitude
        }
      },
      helpContent: LocationHelp,
      showEvidence: true
    },
    MetricAndTargetDataCollection: {
      fields: {
        DataSetName: { displayName: "Record Name" },
        ClimateRelatedMetrics: {
          targetSubtypes: schema.enums.ClimateRelatedMetricsSelectTypeEnum.values
        },
        ClimateRelatedTargets: {
          description:
            "Enter record(s) to describe the quantitative and qualitative climate-related targets set to monitor progress towards achieving strategic goals, and any targets required by law or regulation.",
          standardReference: "IFRS S2 paragraph 33 a-h."
        }
      },
      helpContent: MetricsTargetsDataSetHelp
    },
    MitigationAdaptationChange: {
      fields: {
        AnticipatedDirectMitigationAdaptationEffort: {
          displayName: "Future direct effort"
        },
        AnticipatedIndirectMitigationAdaptationEffort: {
          displayName: "Future indirect effort"
        },
        CurrentDirectMitigationAdaptationEffort: {
          displayName: "Current direct effort"
        },
        CurrentIndirectMitigationAdaptationEffort: {
          displayName: "Current indirect effort"
        }
      },
      helpContent: MitigationAdaptationEffortsHelp,
      showEvidence: true
    },
    OffsetAccountEntry: {
      fields: {
        OffsetAchievementType: {
          hideInTable: true
        },
        OffsetCertificationSchemeName: {
          displayName: "Scheme Name"
        },
        OffsetDataSource: {
          displayName: "Data Source",
          hideInTable: true
        },
        OffsetDescription: {
          displayName: "Description"
        },
        OffsetName: {
          displayName: "Name",
          isUnique: true
        },
        OffsetPurchaseDate: {
          displayName: "Purchase Date",
          hideInTable: true
        },
        OffsetTransactionStatus: {
          displayName: "Status"
        },
        OffsetValidityEndDate: {
          displayName: "End Date",
          hideInTable: true
        },
        OffsetValidityStartDate: {
          displayName: "Start Date",
          hideInTable: true
        }
      },
      helpContent: OffsetAcountEntryHelp,
      showEvidence: true
    },
    PlanningHorizon: {
      fields: {
        ClimatePlanningHorizons: {
          description:
            "Enter record(s) to describe the time horizons used to understand when the effects of each climate-related risk and opportunity could reasonably be expected to occur.",
          displayName: "Climate Planning Horizons",
          standardReference: "IFRS S2 paragraph 10d.",
          publishSchema: (s) => (s as ArraySchema).minItems(3)
        },
        DataSetName: {
          displayName: "Record Name"
        }
      },
      helpContent: PlanningHorizonsHelp,
      showEvidence: true
    },
    ReductionAccountEntry: {
      fields: {
        AnnualMonetarySaving: {
          displayName: "Saving",
          hideInTable: true
        },
        CO2SavingScope: {
          displayName: "Scope"
        },
        DateAchieved: {
          displayName: "Achieved",
          hideInTable: true
        },
        EmissionReductionUnit: {
          displayName: "Reduction Unit"
        },
        EmissionReductionValue: {
          displayName: "Value"
        },
        EndDate: {
          displayName: "End Datae",
          hideInTable: true
        },
        EstimatedAnnualCO2Saving: {
          displayName: "CO2 Saving"
        },
        EstimatedInitiativeLifetime: {
          displayName: "Lifetime",
          hideInTable: true
        },
        GrossEmissionReductionAchieved: {
          displayName: "Gross Reduction",
          hideInTable: true
        },
        PaybackPeriod: {
          displayName: "Payback Period",
          hideInTable: true
        },
        ReductionInitiativeDescription: {
          displayName: "Description"
        },
        ReductionInitiativeName: {
          displayName: "Name",
          isUnique: true
        },
        ReductionInitiativeStatus: {
          displayName: "Status"
        },
        RequiredInvestment: {
          displayName: "Investment",
          hideInTable: true
        },
        StartDate: {
          displayName: "Start Date",
          hideInTable: true
        }
      },
      helpContent: ReductionAcountEntryHelp,
      showEvidence: true
    },
    ReportingEntity: {
      fields: {
        Currency: {
          hideInTable: true
        },
        DataSetName: {
          displayName: "Entity name",
          isUnique: true
        },
        Disclosures: {
          hideInRelations: true
        },
        OrganisationIdentifier: {
          displayName: "Identifier"
        },
        OrganisationIdentifierType: {
          hideInTable: true
        },
        SecondaryOperatingCountry: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        URL: {
          hideInTable: true,
          publishSchema: AsOptional
        }
      },
      helpContent: ReportingEntityHelp
    },
    ReportingPeriod: {
      fields: {
        ReportingPeriodName: {
          displayName: "Period Name"
        },
        ReportingPeriodYear: {
          displayName: "Period Year"
        }
      },
      helpContent: ReportingPeriodHelp
    },
    RiskManagementDataCollection: {
      fields: {
        DataSetName: {
          displayName: "Record Name"
        },
        RiskManagementProcessesPolicies: {
          description:
            "Enter record(s) to describe the processes and related policies used to identify, assess, prioritise and monitor climate-related risks, and the extent these are integrated into and inform your overall risk management process.",
          displayName: "Risk Management Processes and Policies",
          standardReference: "IFRS S2 paragraph 25 a,c.",
          targetSubtypes: schema.enums.RiskManagementProcessPolicySelectTypeEnum.values
        }
      },
      helpContent: RiskManagementDataSetHelp
    },
    RiskManagementProcessPolicy: {
      helpContent: RiskManagementProcessPolicyHelp,
      discriminatorField: "RiskManagementProcessPolicySelectType",
      showEvidence: true,
      subtypes: {
        Processes: {
          description:
            "Enter record(s) to describe the processes and policies used to identify, assess, prioritise and monitor climate-related risks and opportunities.",
          displayName: "Risk Management Processes and Policies",
          fields: {
            Inputs: {
              hideInTable: true
            },
            Parameters: {
              hideInTable: true
            },
            ProcessDescription: {
              displayName: "Description",
              isUnique: true
            },
            ProcessName: {
              displayName: "Process Name",
              isUnique: true
            },
            ProcessType: {
              displayName: "Type",
              isUnique: true
            },
            RiskOpportunities: {
              hideInRelations: true
            }
          },
          includeColumns: /RiskOpportunityType|ProcessName|ProcessType|ProcessDescription|Inputs|Parameters/,
          includeRelations: undefined,
          standardReference: "IFRS S2 paragraph 25 a - c.",
          publishSchema: WithFields(
            "RiskOpportunityType",
            "ProcessName",
            "ProcessType",
            "ProcessDescription",
            "Inputs",
            "Parameters"
          )
        },
        Supplementary: {
          helpContent: RiskManagementProcessPolicySupplementaryHelp,
          description: "Enter record(s) to describe the supplemental data applied to risk management processes and policies.",
          displayName: "Supplemental Risk Management Data",
          fields: {
            HowAssessRiskEffects: {
              displayName: "Risk Effects Assessment"
            },
            IntegrationWithEnterpriseRiskProcess: {
              displayName: "Integration to Enterprise Risk Process"
            },
            ProcessChangesFromPreviousPeriod: {
              displayName: "Change from Previous Period"
            },
            RelativeRiskPrioritisation: {
              displayName: "Relative Risk Prioritisation"
            },
            UseOfScenariosInOpportunityIdentification: {
              displayName: "Use of Scenarios for Opportunities",
              isUnique: true
            },
            UseOfScenariosInRiskIdentification: {
              displayName: "Use of Scenarios for Risks",
              isUnique: true
            },
            RiskOpportunities: {
              hideInRelations: true
            }
          },
          includeColumns:
            /UseOfScenariosInRiskIdentification|UseOfScenariosInOpportunityIdentification|HowAssessRiskEffects|RelativeRiskPrioritisation|ProcessChangesFromPreviousPeriod|IntegrationWithEnterpriseRiskProcess/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 25 a - c.",
          publishSchema: WithFields(
            "UseOfScenariosInRiskIdentification",
            "UseOfScenariosInOpportunityIdentification",
            "HowAssessRiskEffects",
            "RelativeRiskPrioritisation",
            "ProcessChangesFromPreviousPeriod",
            "IntegrationWithEnterpriseRiskProcess"
          )
        }
      }
    },
    RiskOpportunity: {
      fields: {
        AnticipatedEffectOnBusinessModel: {
          displayName: "Future Business Model Effect",
          hideInTable: true
        },
        AnticipatedEffectOnValueChain: {
          displayName: "Future Value Chain Effect",
          hideInTable: true
        },
        CurrentEffectOnBusinessModel: {
          displayName: "Current Business Model Effect",
          hideInTable: true
        },
        CurrentEffectOnValueChain: {
          displayName: "Current Value Chain Effect",
          hideInTable: true
        },
        FinancialEffects: {
          description: "Enter record(s) to describe the current and anticipated financial effects of this risk/opportunity.",
          displayName: "Current and Anticipated Financial Effects",
          standardReference: "IFRS S2 paragraph 15-21."
        },
        LikelihoodOfOccurring: {
          displayName: "Likelihood"
        },
        Name: {
          displayName: "Name",
          isUnique: true
        },
        Priority: {
          displayName: "Priority"
        },
        RiskOpportunityGroups: {
          displayName: "Subtype"
        },
        RiskOpportunityType: {
          displayName: "Type"
        },
        TimeHorizon: {
          displayName: "Time Horizon"
        },
        WhereConcentratedBusinessModel: {
          displayName: "Concentration in Business Model",
          hideInTable: true
        },
        WhereConcentratedValueChain: {
          displayName: "Concentration in Value Chain",
          hideInTable: true
        }
      },
      helpContent: RisksOpportunitiesHelp,
      showEvidence: true
    },
    ScenarioAnalysis: {
      fields: {
        AlignmentWithInternationalAgreement: {
          hideInTable: true
        },
        AssociationWithRisks: {
          hideInTable: true
        },
        ScenarioAnalysisMethod: {
          hideInTable: true
        },
        ScenarioDataSource: {
          hideInTable: true
        },
        ScenarioDescription: {
          displayName: "Description"
        },
        ScenarioName: {
          displayName: "Name",
          isUnique: true
        },
        ScenarioRelevanceDescription: {
          hideInTable: true
        },
        ScenarioTemperatureAlignment: {
          displayName: "Temp. Alignment"
        },
        ScopeOfOperations: {
          hideInTable: true
        },
        ScopeOfOperationsDetail: {
          hideInTable: true
        },
        ReportingPeriods: {
          hideInRelations: true,
          publishSchema: AsOptional
        }
      },
      helpContent: ClimateScenarioAnalysisHelp,
      showEvidence: true
    },
    ScenarioAnalysisAssumptions: {
      fields: {
        EnergyUsageAndMix: {
          displayName: "Energy Usage"
        },
        MacroeconomicTrends: {
          displayName: "Trends"
        },
        NationalRegionalVariables: {
          displayName: "Variables"
        },
        PolicyAssumptions: {
          displayName: "Policy Assumptions",
          isUnique: true
        },
        TechnologyDevelopments: {
          displayName: "Technology Developments"
        }
      },
      helpContent: ScenarioAnalysisAssumptionsHelp,
      showEvidence: true
    },
    StrategyDataCollection: {
      fields: {
        ClimateRelatedRisksOpportunities: {
          description:
            "Enter record(s) to describe the climate-related risks and opportunities that could reasonably be expected to affect your organization.",
          displayName: "Climate-related Risks and Opportunities",
          standardReference: "IFRS S2 paragraph 10-13, 15-21."
        },
        ClimateResiliences: {
          description: "Enter record(s) to describe the resilience of your organization's strategy and business model.",
          displayName: "Climate-related Resilience",
          standardReference: "IFRS S2 paragraph 22."
        },
        DataSet: {
          displayName: "Record Name"
        },
        StrategyDecisionMakings: {
          description:
            "Enter record(s) to describe the effects of climate-related risks and opportunities on your organization's strategy and decision-making.",
          displayName: "Strategy and Decision Making",
          standardReference: "IFRS S2 paragraph 14."
        }
      },
      helpContent: StrategyDataSetHelp
    },
    StrategyDecisionMaking: {
      fields: {
        BusinessModelChanges: {
          description: "Enter record(s) to describe the current and anticipated changes to your organization’s business model.",
          displayName: "Business Model Changes",
          standardReference: "IFRS S2 paragraph 14 a i."
        },
        DataSetName: {
          displayName: "Record Name",
          isUnique: true
        },
        MitigationAdaptationChanges: {
          description: "Enter record(s) to describe your organization’s mitigation and adaptation efforts.",
          displayName: "Mitigation and Adaptation Efforts",
          standardReference: "IFRS S2 paragraph 14 a ii-iii."
        },
        StrategyDecisionMakingData: {
          targetSubtypes: schema.enums.StrategyDecisionMakingDataSelectTypeEnum.values
        }
      },
      helpContent: StrategyAndDecisionMakingHelp
    },
    StrategyDecisionMakingData: {
      discriminatorField: "StrategyDecisionMakingDataSelectType",
      fields: {
        StrategyPlanProgressValue: {
          publishSchema: AsOptional
        },
        StrategyPlanProgressUnit: {
          publishSchema: AsOptional
        }
      },
      subtypes: {
        StrategyPlanProgress: {
          description: "Enter record(s) to describe progress of your organization’s plans disclosed in previous reporting periods.",
          displayName: "Progress of Climate-related Strategy Plans",
          fields: {
            PlanProgressName: {
              displayName: "Name",
              isUnique: true
            },
            QualitativePlanProgressDescription: {
              displayName: "Qualitative Progress Description"
            },
            QuantitativePlanProgressDescription: {
              displayName: "Quantitative Progress Description"
            }
          },
          helpContent: ProgressStrategyPlansHelp,
          includeColumns: /PlanProgressName|QualitativePlanProgressDescription|QuantitativePlanProgressDescription/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 14 c.",
          publishSchema: WithFields("PlanProgressName", "QualitativePlanProgressDescription", "QuantitativePlanProgressDescription")
        },
        StrategyResourcing: {
          description: "Enter record(s) to describe resourcing of your organization’s strategy and decision making.",
          displayName: "Resourcing of Strategy and Decision Making",
          fields: {
            CurrentResourcingDescription: {
              displayName: "Current Description",
              isUnique: true
            },
            FutureResourcingDescription: {
              displayName: "Future Description"
            },
            ResourcingPlanName: {
              displayName: "Name",
              isUnique: true
            }
          },
          helpContent: ResourcingStrategyDecisionMakingTargetsHelp,
          includeColumns: /ResourcingPlanName|CurrentResourcingDescription|FutureResourcingDescription/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 14 b.",
          publishSchema: WithFields("ResourcingPlanName", "CurrentResourcingDescription", "FutureResourcingDescription")
        },
        TargetAchievementPlan: {
          description: "Enter record(s) to describe your organization’s plans to achieve any climate-related targets.",
          fields: {
            TargetAchievementPlanDescription: {
              displayName: "Description"
            },
            TargetAchievementPlanName: {
              displayName: "Name",
              isUnique: true
            }
          },
          helpContent: TargetAchievementPlanHelp,
          includeColumns: /TargetAchievementPlanName|TargetAchievementPlanDescription/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 14 a v.",
          publishSchema: WithFields("TargetAchievementPlanName", "TargetAchievementPlanDescription")
        },
        TransitionPlan: {
          description: "Enter record(s) to describe your organization’s climate-related transition plan.",
          displayName: "Climate-related Transition Plan",
          fields: {
            TransitionPlanDescription: {
              displayName: "Description"
            },
            TransitionPlanName: {
              displayName: "Name",
              isUnique: true
            }
          },
          helpContent: ClimateRelatedTransitionPlanHelp,
          includeColumns: /TransitionPlanName|TransitionPlanDescription/,
          includeRelations: undefined,
          showEvidence: true,
          standardReference: "IFRS S2 paragraph 14 a iv.",
          publishSchema: WithFields("TransitionPlanName", "TransitionPlanDescription")
        }
      }
    }
  }
};

const withDisplayInfo: UISchemaAggregate = cloneDeepWith(merge({}, schema as Schema, uiextensions, uishared), insertDisplayNames);

console.debug(withDisplayInfo);

export default withDisplayInfo;
