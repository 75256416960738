/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { BiogenicAccountEntry } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function BiogenicAccountEntryUpdateForm(props) {
  const {
    id: idProp,
    biogenicAccountEntry: biogenicAccountEntryModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    BiogenicTransactionName: "",
    BiogenicTransactionStatus: "",
    ReportingPeriod: "",
    BiogenicTransactionDescription: "",
    BiogenicTransactionDataSource: "",
    GrossAmountCO2Sequestered: "",
    SequestrationDate: "",
  };
  const [BiogenicTransactionName, setBiogenicTransactionName] = React.useState(
    initialValues.BiogenicTransactionName
  );
  const [BiogenicTransactionStatus, setBiogenicTransactionStatus] =
    React.useState(initialValues.BiogenicTransactionStatus);
  const [ReportingPeriod, setReportingPeriod] = React.useState(
    initialValues.ReportingPeriod
  );
  const [BiogenicTransactionDescription, setBiogenicTransactionDescription] =
    React.useState(initialValues.BiogenicTransactionDescription);
  const [BiogenicTransactionDataSource, setBiogenicTransactionDataSource] =
    React.useState(initialValues.BiogenicTransactionDataSource);
  const [GrossAmountCO2Sequestered, setGrossAmountCO2Sequestered] =
    React.useState(initialValues.GrossAmountCO2Sequestered);
  const [SequestrationDate, setSequestrationDate] = React.useState(
    initialValues.SequestrationDate
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = biogenicAccountEntryRecord
      ? { ...initialValues, ...biogenicAccountEntryRecord }
      : initialValues;
    setBiogenicTransactionName(cleanValues.BiogenicTransactionName);
    setBiogenicTransactionStatus(cleanValues.BiogenicTransactionStatus);
    setReportingPeriod(cleanValues.ReportingPeriod);
    setBiogenicTransactionDescription(
      cleanValues.BiogenicTransactionDescription
    );
    setBiogenicTransactionDataSource(cleanValues.BiogenicTransactionDataSource);
    setGrossAmountCO2Sequestered(cleanValues.GrossAmountCO2Sequestered);
    setSequestrationDate(cleanValues.SequestrationDate);
    setErrors({});
  };
  const [biogenicAccountEntryRecord, setBiogenicAccountEntryRecord] =
    React.useState(biogenicAccountEntryModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(BiogenicAccountEntry, idProp)
        : biogenicAccountEntryModelProp;
      setBiogenicAccountEntryRecord(record);
    };
    queryData();
  }, [idProp, biogenicAccountEntryModelProp]);
  React.useEffect(resetStateValues, [biogenicAccountEntryRecord]);
  const validations = {
    BiogenicTransactionName: [],
    BiogenicTransactionStatus: [],
    ReportingPeriod: [],
    BiogenicTransactionDescription: [],
    BiogenicTransactionDataSource: [],
    GrossAmountCO2Sequestered: [],
    SequestrationDate: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          BiogenicTransactionName,
          BiogenicTransactionStatus,
          ReportingPeriod,
          BiogenicTransactionDescription,
          BiogenicTransactionDataSource,
          GrossAmountCO2Sequestered,
          SequestrationDate,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            BiogenicAccountEntry.copyOf(
              biogenicAccountEntryRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "BiogenicAccountEntryUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Biogenic Sequestration Transaction"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update a record of a biogenic sequestration event that you are responsible for."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Biogenic transaction name"
        isRequired={false}
        isReadOnly={false}
        value={BiogenicTransactionName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              BiogenicTransactionName: value,
              BiogenicTransactionStatus,
              ReportingPeriod,
              BiogenicTransactionDescription,
              BiogenicTransactionDataSource,
              GrossAmountCO2Sequestered,
              SequestrationDate,
            };
            const result = onChange(modelFields);
            value = result?.BiogenicTransactionName ?? value;
          }
          if (errors.BiogenicTransactionName?.hasError) {
            runValidationTasks("BiogenicTransactionName", value);
          }
          setBiogenicTransactionName(value);
        }}
        onBlur={() =>
          runValidationTasks("BiogenicTransactionName", BiogenicTransactionName)
        }
        errorMessage={errors.BiogenicTransactionName?.errorMessage}
        hasError={errors.BiogenicTransactionName?.hasError}
        {...getOverrideProps(overrides, "BiogenicTransactionName")}
      ></TextField>
      <SelectField
        label="Biogenic transaction status"
        placeholder="Please select an option"
        isDisabled={false}
        value={BiogenicTransactionStatus}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              BiogenicTransactionName,
              BiogenicTransactionStatus: value,
              ReportingPeriod,
              BiogenicTransactionDescription,
              BiogenicTransactionDataSource,
              GrossAmountCO2Sequestered,
              SequestrationDate,
            };
            const result = onChange(modelFields);
            value = result?.BiogenicTransactionStatus ?? value;
          }
          if (errors.BiogenicTransactionStatus?.hasError) {
            runValidationTasks("BiogenicTransactionStatus", value);
          }
          setBiogenicTransactionStatus(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "BiogenicTransactionStatus",
            BiogenicTransactionStatus
          )
        }
        errorMessage={errors.BiogenicTransactionStatus?.errorMessage}
        hasError={errors.BiogenicTransactionStatus?.hasError}
        {...getOverrideProps(overrides, "BiogenicTransactionStatus")}
      >
        <option
          children="Investigating"
          value="Investigating"
          {...getOverrideProps(overrides, "BiogenicTransactionStatusoption0")}
        ></option>
        <option
          children="Planning"
          value="Planning"
          {...getOverrideProps(overrides, "BiogenicTransactionStatusoption1")}
        ></option>
        <option
          children="Implementing"
          value="Implementing"
          {...getOverrideProps(overrides, "BiogenicTransactionStatusoption2")}
        ></option>
        <option
          children="Implemented"
          value="Implemented"
          {...getOverrideProps(overrides, "BiogenicTransactionStatusoption3")}
        ></option>
      </SelectField>
      <TextField
        label="Reporting period"
        isRequired={false}
        isReadOnly={false}
        value={ReportingPeriod}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              BiogenicTransactionName,
              BiogenicTransactionStatus,
              ReportingPeriod: value,
              BiogenicTransactionDescription,
              BiogenicTransactionDataSource,
              GrossAmountCO2Sequestered,
              SequestrationDate,
            };
            const result = onChange(modelFields);
            value = result?.ReportingPeriod ?? value;
          }
          if (errors.ReportingPeriod?.hasError) {
            runValidationTasks("ReportingPeriod", value);
          }
          setReportingPeriod(value);
        }}
        onBlur={() => runValidationTasks("ReportingPeriod", ReportingPeriod)}
        errorMessage={errors.ReportingPeriod?.errorMessage}
        hasError={errors.ReportingPeriod?.hasError}
        {...getOverrideProps(overrides, "ReportingPeriod")}
      ></TextField>
      <TextAreaField
        label="Biogenic transaction description"
        isRequired={false}
        isReadOnly={false}
        value={BiogenicTransactionDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              BiogenicTransactionName,
              BiogenicTransactionStatus,
              ReportingPeriod,
              BiogenicTransactionDescription: value,
              BiogenicTransactionDataSource,
              GrossAmountCO2Sequestered,
              SequestrationDate,
            };
            const result = onChange(modelFields);
            value = result?.BiogenicTransactionDescription ?? value;
          }
          if (errors.BiogenicTransactionDescription?.hasError) {
            runValidationTasks("BiogenicTransactionDescription", value);
          }
          setBiogenicTransactionDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "BiogenicTransactionDescription",
            BiogenicTransactionDescription
          )
        }
        errorMessage={errors.BiogenicTransactionDescription?.errorMessage}
        hasError={errors.BiogenicTransactionDescription?.hasError}
        {...getOverrideProps(overrides, "BiogenicTransactionDescription")}
      ></TextAreaField>
      <TextField
        label="Biogenic transaction data source"
        isRequired={false}
        isReadOnly={false}
        value={BiogenicTransactionDataSource}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              BiogenicTransactionName,
              BiogenicTransactionStatus,
              ReportingPeriod,
              BiogenicTransactionDescription,
              BiogenicTransactionDataSource: value,
              GrossAmountCO2Sequestered,
              SequestrationDate,
            };
            const result = onChange(modelFields);
            value = result?.BiogenicTransactionDataSource ?? value;
          }
          if (errors.BiogenicTransactionDataSource?.hasError) {
            runValidationTasks("BiogenicTransactionDataSource", value);
          }
          setBiogenicTransactionDataSource(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "BiogenicTransactionDataSource",
            BiogenicTransactionDataSource
          )
        }
        errorMessage={errors.BiogenicTransactionDataSource?.errorMessage}
        hasError={errors.BiogenicTransactionDataSource?.hasError}
        {...getOverrideProps(overrides, "BiogenicTransactionDataSource")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Gross amount CO2 sequestered (tonnes CO2e)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={GrossAmountCO2Sequestered}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                BiogenicTransactionName,
                BiogenicTransactionStatus,
                ReportingPeriod,
                BiogenicTransactionDescription,
                BiogenicTransactionDataSource,
                GrossAmountCO2Sequestered: value,
                SequestrationDate,
              };
              const result = onChange(modelFields);
              value = result?.GrossAmountCO2Sequestered ?? value;
            }
            if (errors.GrossAmountCO2Sequestered?.hasError) {
              runValidationTasks("GrossAmountCO2Sequestered", value);
            }
            setGrossAmountCO2Sequestered(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "GrossAmountCO2Sequestered",
              GrossAmountCO2Sequestered
            )
          }
          errorMessage={errors.GrossAmountCO2Sequestered?.errorMessage}
          hasError={errors.GrossAmountCO2Sequestered?.hasError}
          {...getOverrideProps(overrides, "GrossAmountCO2Sequestered")}
        ></TextField>
        <TextField
          label="Sequestration date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={SequestrationDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                BiogenicTransactionName,
                BiogenicTransactionStatus,
                ReportingPeriod,
                BiogenicTransactionDescription,
                BiogenicTransactionDataSource,
                GrossAmountCO2Sequestered,
                SequestrationDate: value,
              };
              const result = onChange(modelFields);
              value = result?.SequestrationDate ?? value;
            }
            if (errors.SequestrationDate?.hasError) {
              runValidationTasks("SequestrationDate", value);
            }
            setSequestrationDate(value);
          }}
          onBlur={() =>
            runValidationTasks("SequestrationDate", SequestrationDate)
          }
          errorMessage={errors.SequestrationDate?.errorMessage}
          hasError={errors.SequestrationDate?.hasError}
          {...getOverrideProps(overrides, "SequestrationDate")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || biogenicAccountEntryModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || biogenicAccountEntryModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
