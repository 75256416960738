import HelpBox from "../../shared/content/HelpBox";

export default function BusinessModelChangeHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Business Model Change Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes the organization's current and anticipated business model changes.
        </p>
        <p>
          Examples may include include plans to manage or decommission carbon, energy or water-intensive operations; resource allocations resulting from demand or supply-chain changes; resource allocations arising from business development through capital expenditure or additional expenditure on research and development; and acquisitions or divestments.
        </p>
        <p>Follow these steps to define your business model changes:</p>
        <ol>
          <li>
            Enter description of the current changes in the organization's business model to address identified climate-related risks and opportunities.
          </li>
          <li>
            Enter a description of the anticipated changes in the organization's business model to address identified climate-related risks and opportunities.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
