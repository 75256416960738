import HelpBox from "../../shared/content/HelpBox";

export default function BusinessPlanningHorizonsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Business Strategic Planning Horizons Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes the Business Strategic Planning Horizons used for strategic decision-making.
        </p>
        <p>Follow these steps to define a business strategic planning horizon:</p>
        <ol>
          <li>
            Enter a name and description for the business planning horizon.
          </li>
          <li>
            In the fields below, enter dates for the <i>Horizon start</i> and <i>Horizon end</i> periods.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
