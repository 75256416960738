import HelpBox from "../../shared/content/HelpBox";

export default function DisclosureBuilderHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Disclosure Builder Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          The Disclosure Builder helps you build Climate Disclosures that conform to the IFRS S2 standard.
        </p>
        <ul>
          <li>
            Disclosures are created by building and linking together 'data sets', which contain your responses to the questions in IFRS S2.
          </li>
          <li>
            Follow these steps to create a disclosure:
          </li>
          <p><b>Step 1</b></p>
          <li>
            To start building a new Disclosure, in the left hand side of the window enter a <i>Disclosure name</i> and <i>Description</i>, enter an <i>Author</i>, choose where you want the Disclosure to appear by choosing options in <i>Published to</i>, and set the <i>Status</i> to <b>Draft</b>. Disclosures can be published to any combination of four portals for different target audiences: Regulator, Assurance, Public and Stakeholder.
          </li>
          <li>
            Next, using the tables in the center of the window, select or create an organisation responsible for the disclosure in the <i>Reporting Entity</i> table, then select or create a <i>Disclosure Reporting Period</i> that the disclosure applies to.
          </li>
          <p><b>Step 2</b></p>
          <li>
            Data sets are used to group related data needed in your disclosure, and can contain other subsets of data e.g. The <i>Governance Data Set</i> contains data about the Climate Governance Body, Governance Body Oversight Activities and Management Role and Accountabilities.
          </li>
          <li>
            Select or create the required data sets in each of the IFRS S2 core content domains: Governance, Strategy, Risk Management, and Metrics and Targets. You can also use the second level of menus at the top of the screen to create/edit data sets and disclosure content.
          </li>
          <p><b>Step 3</b></p>
          <li>
            To add data sets to the Disclosure, select each data set from the tables in the middle of the window, then click the <b>Create</b> or <b>Update</b> button in the top right.
          </li>
          <li>
            If you need to update a Disclosure, click in the <b>Search</b> box and select the disclosure, then click the <b>Edit</b> button, which will then open the Disclosure Builder and allow you to update your selected data sets.
          </li>
          <p><b>Step 4</b></p>
          <li>
            Once your disclosure is ready, select <b>Published</b> in the <i>Disclosure status</i> dropdown menu on the left hand side of the screen. You can also update your selected options in the <i>Published to</i> menu at any time to change where your disclosure will publish to.
            required.
          </li>
        </ul>
        For background information on disclosures, click this link to access the{" "}
        <a href="https://www.xrb.govt.nz/standards/climate-related-disclosures/aotearoa-new-zealand-climate-standards/aotearoa-new-zealand-climate-standard-1/">
          XRB NZCS1 Climate Disclosure standard
        </a>
        {" "}and this link to access the{" "}
        <a href="https://www.xrb.govt.nz/standards/climate-related-disclosures/aotearoa-new-zealand-climate-standards/aotearoa-new-zealand-climate-standard-3/">
          XRB NZCS3 Climate Disclosure standard.
        </a>
      </HelpBox.Content>
    </HelpBox>
  );
}
