import HelpBox from "./HelpBox";

export default function CarbonAccountManagementHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Carbon Account Management</HelpBox.Header>
            <HelpBox.Content>
                <p>This form allows you to create or update an account that will be used to group and manage your emission transactions.</p>
                <p>Follow these steps to create or update an account:</p>
                <ul>
                    <li>Enter a name for the account in the form on the left hand side of the screen</li>
                    <li>Enter a <i>Description</i> for the account</li>
                    <li>Finally, select or create the appropriate records from the table in the center of the screen.</li>
                    <li>You also have the option of directly importing and exporting records into the table to bulk-upload/download account transaction records.</li>
                    <li>Once selected, choose <b>Create</b> or <b>Update</b>.</li>
                </ul>
            </HelpBox.Content>
        </HelpBox>
    );
}
