import HelpBox from "../../shared/content/HelpBox";

export default function ProgressStrategyPlansHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Progress of Climate-related Strategy Plans Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes your organization's progress of climate-related Strategy and Decisin Making Plans, and Target Achievement Plans.
        </p>
        <p>Follow these steps to define your plan progress:</p>
        <ol>
          <li>
            Enter a name for the plan progress data plan.
          </li>
          <li>
            Enter a qualitative description of progress towards strategy and decision making, and target achievement plans disclosed in the previous reporting period.
          </li>
          <li>
            Enter a quantitative description of progress towards strategy and decision making, and target achievement plans disclosed in the previous reporting period.
          </li>
          <li>
            Enter a quantitative measure of progress and unit of measure.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
