/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { RiskManagementProcessPolicy } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskManagementProcessPolicySupplementaryUpdateForm(
  props
) {
  const {
    id: idProp,
    riskManagementProcessPolicy: riskManagementProcessPolicyModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    UseOfScenariosInRiskIdentification: "",
    UseOfScenariosInOpportunityIdentification: "",
    HowAssessRiskEffects: "",
    RelativeRiskPrioritisation: "",
    ProcessChangesFromPreviousPeriod: "",
    IntegrationWithEnterpriseRiskProcess: "",
  };
  const [
    UseOfScenariosInRiskIdentification,
    setUseOfScenariosInRiskIdentification,
  ] = React.useState(initialValues.UseOfScenariosInRiskIdentification);
  const [
    UseOfScenariosInOpportunityIdentification,
    setUseOfScenariosInOpportunityIdentification,
  ] = React.useState(initialValues.UseOfScenariosInOpportunityIdentification);
  const [HowAssessRiskEffects, setHowAssessRiskEffects] = React.useState(
    initialValues.HowAssessRiskEffects
  );
  const [RelativeRiskPrioritisation, setRelativeRiskPrioritisation] =
    React.useState(initialValues.RelativeRiskPrioritisation);
  const [
    ProcessChangesFromPreviousPeriod,
    setProcessChangesFromPreviousPeriod,
  ] = React.useState(initialValues.ProcessChangesFromPreviousPeriod);
  const [
    IntegrationWithEnterpriseRiskProcess,
    setIntegrationWithEnterpriseRiskProcess,
  ] = React.useState(initialValues.IntegrationWithEnterpriseRiskProcess);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = riskManagementProcessPolicyRecord
      ? { ...initialValues, ...riskManagementProcessPolicyRecord }
      : initialValues;
    setUseOfScenariosInRiskIdentification(
      cleanValues.UseOfScenariosInRiskIdentification
    );
    setUseOfScenariosInOpportunityIdentification(
      cleanValues.UseOfScenariosInOpportunityIdentification
    );
    setHowAssessRiskEffects(cleanValues.HowAssessRiskEffects);
    setRelativeRiskPrioritisation(cleanValues.RelativeRiskPrioritisation);
    setProcessChangesFromPreviousPeriod(
      cleanValues.ProcessChangesFromPreviousPeriod
    );
    setIntegrationWithEnterpriseRiskProcess(
      cleanValues.IntegrationWithEnterpriseRiskProcess
    );
    setErrors({});
  };
  const [
    riskManagementProcessPolicyRecord,
    setRiskManagementProcessPolicyRecord,
  ] = React.useState(riskManagementProcessPolicyModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(RiskManagementProcessPolicy, idProp)
        : riskManagementProcessPolicyModelProp;
      setRiskManagementProcessPolicyRecord(record);
    };
    queryData();
  }, [idProp, riskManagementProcessPolicyModelProp]);
  React.useEffect(resetStateValues, [riskManagementProcessPolicyRecord]);
  const validations = {
    UseOfScenariosInRiskIdentification: [],
    UseOfScenariosInOpportunityIdentification: [],
    HowAssessRiskEffects: [],
    RelativeRiskPrioritisation: [],
    ProcessChangesFromPreviousPeriod: [],
    IntegrationWithEnterpriseRiskProcess: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          UseOfScenariosInRiskIdentification,
          UseOfScenariosInOpportunityIdentification,
          HowAssessRiskEffects,
          RelativeRiskPrioritisation,
          ProcessChangesFromPreviousPeriod,
          IntegrationWithEnterpriseRiskProcess,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            RiskManagementProcessPolicy.copyOf(
              riskManagementProcessPolicyRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "RiskManagementProcessPolicySupplementaryUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Additional data on Risk and Opportunity Management Processes and Policies"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 25 a - c."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update this record that contains additional information about your risk management process."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextAreaField
        label="Whether and how your organization uses climate-related scenario analysis to inform identification of climate-related risks"
        isRequired={false}
        isReadOnly={false}
        value={UseOfScenariosInRiskIdentification}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UseOfScenariosInRiskIdentification: value,
              UseOfScenariosInOpportunityIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ProcessChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
            };
            const result = onChange(modelFields);
            value = result?.UseOfScenariosInRiskIdentification ?? value;
          }
          if (errors.UseOfScenariosInRiskIdentification?.hasError) {
            runValidationTasks("UseOfScenariosInRiskIdentification", value);
          }
          setUseOfScenariosInRiskIdentification(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "UseOfScenariosInRiskIdentification",
            UseOfScenariosInRiskIdentification
          )
        }
        errorMessage={errors.UseOfScenariosInRiskIdentification?.errorMessage}
        hasError={errors.UseOfScenariosInRiskIdentification?.hasError}
        {...getOverrideProps(overrides, "UseOfScenariosInRiskIdentification")}
      ></TextAreaField>
      <TextAreaField
        label="Whether and how your organization uses climate-related scenario analysis to inform identification of climate-related opportunities"
        isRequired={false}
        isReadOnly={false}
        value={UseOfScenariosInOpportunityIdentification}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UseOfScenariosInRiskIdentification,
              UseOfScenariosInOpportunityIdentification: value,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ProcessChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
            };
            const result = onChange(modelFields);
            value = result?.UseOfScenariosInOpportunityIdentification ?? value;
          }
          if (errors.UseOfScenariosInOpportunityIdentification?.hasError) {
            runValidationTasks(
              "UseOfScenariosInOpportunityIdentification",
              value
            );
          }
          setUseOfScenariosInOpportunityIdentification(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "UseOfScenariosInOpportunityIdentification",
            UseOfScenariosInOpportunityIdentification
          )
        }
        errorMessage={
          errors.UseOfScenariosInOpportunityIdentification?.errorMessage
        }
        hasError={errors.UseOfScenariosInOpportunityIdentification?.hasError}
        {...getOverrideProps(
          overrides,
          "UseOfScenariosInOpportunityIdentification"
        )}
      ></TextAreaField>
      <TextAreaField
        label="How your organization assesses the nature, likelihood and magnitude of the effects of climate-related risks (e.g. qualitative factors, quantitative thresholds or other criteria)"
        isRequired={false}
        isReadOnly={false}
        value={HowAssessRiskEffects}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UseOfScenariosInRiskIdentification,
              UseOfScenariosInOpportunityIdentification,
              HowAssessRiskEffects: value,
              RelativeRiskPrioritisation,
              ProcessChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
            };
            const result = onChange(modelFields);
            value = result?.HowAssessRiskEffects ?? value;
          }
          if (errors.HowAssessRiskEffects?.hasError) {
            runValidationTasks("HowAssessRiskEffects", value);
          }
          setHowAssessRiskEffects(value);
        }}
        onBlur={() =>
          runValidationTasks("HowAssessRiskEffects", HowAssessRiskEffects)
        }
        errorMessage={errors.HowAssessRiskEffects?.errorMessage}
        hasError={errors.HowAssessRiskEffects?.hasError}
        {...getOverrideProps(overrides, "HowAssessRiskEffects")}
      ></TextAreaField>
      <TextAreaField
        label="Whether and how your organization prioritises climate-related risks relative to other types of risk"
        isRequired={false}
        isReadOnly={false}
        value={RelativeRiskPrioritisation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UseOfScenariosInRiskIdentification,
              UseOfScenariosInOpportunityIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation: value,
              ProcessChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
            };
            const result = onChange(modelFields);
            value = result?.RelativeRiskPrioritisation ?? value;
          }
          if (errors.RelativeRiskPrioritisation?.hasError) {
            runValidationTasks("RelativeRiskPrioritisation", value);
          }
          setRelativeRiskPrioritisation(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "RelativeRiskPrioritisation",
            RelativeRiskPrioritisation
          )
        }
        errorMessage={errors.RelativeRiskPrioritisation?.errorMessage}
        hasError={errors.RelativeRiskPrioritisation?.hasError}
        {...getOverrideProps(overrides, "RelativeRiskPrioritisation")}
      ></TextAreaField>
      <TextAreaField
        label="Whether and how your organization has changed these Risk Management processes, compared to the previous reporting period"
        isRequired={false}
        isReadOnly={false}
        value={ProcessChangesFromPreviousPeriod}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UseOfScenariosInRiskIdentification,
              UseOfScenariosInOpportunityIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ProcessChangesFromPreviousPeriod: value,
              IntegrationWithEnterpriseRiskProcess,
            };
            const result = onChange(modelFields);
            value = result?.ProcessChangesFromPreviousPeriod ?? value;
          }
          if (errors.ProcessChangesFromPreviousPeriod?.hasError) {
            runValidationTasks("ProcessChangesFromPreviousPeriod", value);
          }
          setProcessChangesFromPreviousPeriod(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ProcessChangesFromPreviousPeriod",
            ProcessChangesFromPreviousPeriod
          )
        }
        errorMessage={errors.ProcessChangesFromPreviousPeriod?.errorMessage}
        hasError={errors.ProcessChangesFromPreviousPeriod?.hasError}
        {...getOverrideProps(overrides, "ProcessChangesFromPreviousPeriod")}
      ></TextAreaField>
      <TextAreaField
        label="The extent to which, and how, these climate-related risk and opportunity processes are integrated into and inform your overall risk management process"
        isRequired={false}
        isReadOnly={false}
        value={IntegrationWithEnterpriseRiskProcess}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UseOfScenariosInRiskIdentification,
              UseOfScenariosInOpportunityIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ProcessChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess: value,
            };
            const result = onChange(modelFields);
            value = result?.IntegrationWithEnterpriseRiskProcess ?? value;
          }
          if (errors.IntegrationWithEnterpriseRiskProcess?.hasError) {
            runValidationTasks("IntegrationWithEnterpriseRiskProcess", value);
          }
          setIntegrationWithEnterpriseRiskProcess(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "IntegrationWithEnterpriseRiskProcess",
            IntegrationWithEnterpriseRiskProcess
          )
        }
        errorMessage={errors.IntegrationWithEnterpriseRiskProcess?.errorMessage}
        hasError={errors.IntegrationWithEnterpriseRiskProcess?.hasError}
        {...getOverrideProps(overrides, "IntegrationWithEnterpriseRiskProcess")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || riskManagementProcessPolicyModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || riskManagementProcessPolicyModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
