/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { GovernanceData } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function GovernanceDataGovernanceBodyUpdateForm(props) {
  const {
    id: idProp,
    governanceData: governanceDataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GovernanceName: "",
    GovernanceDescription: "",
    SkillCompetencyDetermination: "",
  };
  const [GovernanceName, setGovernanceName] = React.useState(
    initialValues.GovernanceName
  );
  const [GovernanceDescription, setGovernanceDescription] = React.useState(
    initialValues.GovernanceDescription
  );
  const [SkillCompetencyDetermination, setSkillCompetencyDetermination] =
    React.useState(initialValues.SkillCompetencyDetermination);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = governanceDataRecord
      ? { ...initialValues, ...governanceDataRecord }
      : initialValues;
    setGovernanceName(cleanValues.GovernanceName);
    setGovernanceDescription(cleanValues.GovernanceDescription);
    setSkillCompetencyDetermination(cleanValues.SkillCompetencyDetermination);
    setErrors({});
  };
  const [governanceDataRecord, setGovernanceDataRecord] = React.useState(
    governanceDataModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(GovernanceData, idProp)
        : governanceDataModelProp;
      setGovernanceDataRecord(record);
    };
    queryData();
  }, [idProp, governanceDataModelProp]);
  React.useEffect(resetStateValues, [governanceDataRecord]);
  const validations = {
    GovernanceName: [],
    GovernanceDescription: [],
    SkillCompetencyDetermination: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GovernanceName,
          GovernanceDescription,
          SkillCompetencyDetermination,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            GovernanceData.copyOf(governanceDataRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "GovernanceDataGovernanceBodyUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Governance Body"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 6 a."
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Heading>
      <Text
        children="Update this record that describes the governance body(s) (which can include a board, committee or equivalent body charged with governance) or individual(s) responsible for oversight of climate-related risks and opportunities."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Governance body name (e.g. Climate Governance Committee)"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName: value,
              GovernanceDescription,
              SkillCompetencyDetermination,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceName ?? value;
          }
          if (errors.GovernanceName?.hasError) {
            runValidationTasks("GovernanceName", value);
          }
          setGovernanceName(value);
        }}
        onBlur={() => runValidationTasks("GovernanceName", GovernanceName)}
        errorMessage={errors.GovernanceName?.errorMessage}
        hasError={errors.GovernanceName?.hasError}
        {...getOverrideProps(overrides, "GovernanceName")}
      ></TextField>
      <TextAreaField
        label="Governance body description  (e.g. Board-delegated sub-committee responsible for oversight of all climate related activities.)"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName,
              GovernanceDescription: value,
              SkillCompetencyDetermination,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceDescription ?? value;
          }
          if (errors.GovernanceDescription?.hasError) {
            runValidationTasks("GovernanceDescription", value);
          }
          setGovernanceDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceDescription", GovernanceDescription)
        }
        errorMessage={errors.GovernanceDescription?.errorMessage}
        hasError={errors.GovernanceDescription?.hasError}
        {...getOverrideProps(overrides, "GovernanceDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Describe how the governance body determines whether appropriate skills and competencies are available or will be developed to oversee strategies designed to respond to climate- related risks and opportunities"
        isRequired={false}
        isReadOnly={false}
        value={SkillCompetencyDetermination}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName,
              GovernanceDescription,
              SkillCompetencyDetermination: value,
            };
            const result = onChange(modelFields);
            value = result?.SkillCompetencyDetermination ?? value;
          }
          if (errors.SkillCompetencyDetermination?.hasError) {
            runValidationTasks("SkillCompetencyDetermination", value);
          }
          setSkillCompetencyDetermination(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "SkillCompetencyDetermination",
            SkillCompetencyDetermination
          )
        }
        errorMessage={errors.SkillCompetencyDetermination?.errorMessage}
        hasError={errors.SkillCompetencyDetermination?.hasError}
        {...getOverrideProps(overrides, "SkillCompetencyDetermination")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || governanceDataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || governanceDataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
