import HelpBox from "../../shared/content/HelpBox";

export default function StrategyAndDecisionMakingHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Strategy and Decision Making Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes different aspects of Strategy and Decision Making, which are used to describe the effects of climate-related risks and opportunities on your strategy and decision-making.
        </p>
        <p><p>Follow these steps to define your strategy and decision making data:</p></p>
        <ol>
          <li>
            Enter a name and description for the Strategy and Decision Making data.
          </li>
          <li>
            Select or create and select records for each of the tables in the middle of the window covering: <i>Business Model Changes</i>, <i>Mitigation and Adaptation Efforts</i>, <i>Climate-related Transition Plan</i>, <i>Target Achievement Plan</i>, <i>Resourcing of Climate-related Strategy</i>, and <i>Progress of Climate-related Strategy Plans</i>.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
