/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { RiskManagementProcessPolicy } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskManagementProcessPolicyUpdateForm(props) {
  const {
    id: idProp,
    riskManagementProcessPolicy: riskManagementProcessPolicyModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ProcessName: "",
    ProcessType: "",
    ProcessDescription: "",
    RiskOpportunityType: "",
    Inputs: "",
    Parameters: "",
    UseOfScenariosInRiskIdentification: "",
    HowAssessRiskEffects: "",
    RelativeRiskPrioritisation: "",
    ChangesFromPreviousPeriod: "",
    IntegrationWithEnterpriseRiskProcess: "",
    UseOfScenariosInOpportunityIdentification: "",
    ProcessChangesFromPreviousPeriod: "",
    RiskManagementProcessPolicySelectType: "",
  };
  const [ProcessName, setProcessName] = React.useState(
    initialValues.ProcessName
  );
  const [ProcessType, setProcessType] = React.useState(
    initialValues.ProcessType
  );
  const [ProcessDescription, setProcessDescription] = React.useState(
    initialValues.ProcessDescription
  );
  const [RiskOpportunityType, setRiskOpportunityType] = React.useState(
    initialValues.RiskOpportunityType
  );
  const [Inputs, setInputs] = React.useState(initialValues.Inputs);
  const [Parameters, setParameters] = React.useState(initialValues.Parameters);
  const [
    UseOfScenariosInRiskIdentification,
    setUseOfScenariosInRiskIdentification,
  ] = React.useState(initialValues.UseOfScenariosInRiskIdentification);
  const [HowAssessRiskEffects, setHowAssessRiskEffects] = React.useState(
    initialValues.HowAssessRiskEffects
  );
  const [RelativeRiskPrioritisation, setRelativeRiskPrioritisation] =
    React.useState(initialValues.RelativeRiskPrioritisation);
  const [ChangesFromPreviousPeriod, setChangesFromPreviousPeriod] =
    React.useState(initialValues.ChangesFromPreviousPeriod);
  const [
    IntegrationWithEnterpriseRiskProcess,
    setIntegrationWithEnterpriseRiskProcess,
  ] = React.useState(initialValues.IntegrationWithEnterpriseRiskProcess);
  const [
    UseOfScenariosInOpportunityIdentification,
    setUseOfScenariosInOpportunityIdentification,
  ] = React.useState(initialValues.UseOfScenariosInOpportunityIdentification);
  const [
    ProcessChangesFromPreviousPeriod,
    setProcessChangesFromPreviousPeriod,
  ] = React.useState(initialValues.ProcessChangesFromPreviousPeriod);
  const [
    RiskManagementProcessPolicySelectType,
    setRiskManagementProcessPolicySelectType,
  ] = React.useState(initialValues.RiskManagementProcessPolicySelectType);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = riskManagementProcessPolicyRecord
      ? { ...initialValues, ...riskManagementProcessPolicyRecord }
      : initialValues;
    setProcessName(cleanValues.ProcessName);
    setProcessType(cleanValues.ProcessType);
    setProcessDescription(cleanValues.ProcessDescription);
    setRiskOpportunityType(cleanValues.RiskOpportunityType);
    setInputs(cleanValues.Inputs);
    setParameters(cleanValues.Parameters);
    setUseOfScenariosInRiskIdentification(
      cleanValues.UseOfScenariosInRiskIdentification
    );
    setHowAssessRiskEffects(cleanValues.HowAssessRiskEffects);
    setRelativeRiskPrioritisation(cleanValues.RelativeRiskPrioritisation);
    setChangesFromPreviousPeriod(cleanValues.ChangesFromPreviousPeriod);
    setIntegrationWithEnterpriseRiskProcess(
      cleanValues.IntegrationWithEnterpriseRiskProcess
    );
    setUseOfScenariosInOpportunityIdentification(
      cleanValues.UseOfScenariosInOpportunityIdentification
    );
    setProcessChangesFromPreviousPeriod(
      cleanValues.ProcessChangesFromPreviousPeriod
    );
    setRiskManagementProcessPolicySelectType(
      cleanValues.RiskManagementProcessPolicySelectType
    );
    setErrors({});
  };
  const [
    riskManagementProcessPolicyRecord,
    setRiskManagementProcessPolicyRecord,
  ] = React.useState(riskManagementProcessPolicyModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(RiskManagementProcessPolicy, idProp)
        : riskManagementProcessPolicyModelProp;
      setRiskManagementProcessPolicyRecord(record);
    };
    queryData();
  }, [idProp, riskManagementProcessPolicyModelProp]);
  React.useEffect(resetStateValues, [riskManagementProcessPolicyRecord]);
  const validations = {
    ProcessName: [],
    ProcessType: [],
    ProcessDescription: [],
    RiskOpportunityType: [],
    Inputs: [],
    Parameters: [],
    UseOfScenariosInRiskIdentification: [],
    HowAssessRiskEffects: [],
    RelativeRiskPrioritisation: [],
    ChangesFromPreviousPeriod: [],
    IntegrationWithEnterpriseRiskProcess: [],
    UseOfScenariosInOpportunityIdentification: [],
    ProcessChangesFromPreviousPeriod: [],
    RiskManagementProcessPolicySelectType: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ProcessName,
          ProcessType,
          ProcessDescription,
          RiskOpportunityType,
          Inputs,
          Parameters,
          UseOfScenariosInRiskIdentification,
          HowAssessRiskEffects,
          RelativeRiskPrioritisation,
          ChangesFromPreviousPeriod,
          IntegrationWithEnterpriseRiskProcess,
          UseOfScenariosInOpportunityIdentification,
          ProcessChangesFromPreviousPeriod,
          RiskManagementProcessPolicySelectType,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            ProcessName: modelFields.ProcessName,
            ProcessType: modelFields.ProcessType,
            ProcessDescription: modelFields.ProcessDescription,
            RiskOpportunityType: modelFields.RiskOpportunityType,
            Inputs: modelFields.Inputs,
            Parameters: modelFields.Parameters,
            UseOfScenariosInRiskIdentification:
              modelFields.UseOfScenariosInRiskIdentification,
            HowAssessRiskEffects: modelFields.HowAssessRiskEffects,
            RelativeRiskPrioritisation: modelFields.RelativeRiskPrioritisation,
            IntegrationWithEnterpriseRiskProcess:
              modelFields.IntegrationWithEnterpriseRiskProcess,
            UseOfScenariosInOpportunityIdentification:
              modelFields.UseOfScenariosInOpportunityIdentification,
            ProcessChangesFromPreviousPeriod:
              modelFields.ProcessChangesFromPreviousPeriod,
            RiskManagementProcessPolicySelectType:
              modelFields.RiskManagementProcessPolicySelectType,
          };
          await DataStore.save(
            RiskManagementProcessPolicy.copyOf(
              riskManagementProcessPolicyRecord,
              (updated) => {
                Object.assign(updated, modelFieldsToSave);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "RiskManagementProcessPolicyUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Risk Management Process and Policy"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 25 a i-vi, c."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Update data about the processes and related policies you use to identify, assess, prioritise and monitor climate-related risks."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Text
        children="If you manage risks on an integrated basis, you should enter data for all risks by selecting the ‘Integrated’ option in the ‘Process type’ dropdown menu, and complete the required fields. Do not select the linked risks from the table on the right hand side."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Text
        children="If you manage these processes on an individual risk basis, enter the data below for each risk management process (identify, assess, prioritise and monitor) and select the relevant risk from the table on the right hand side."
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Process name"
        isRequired={false}
        isReadOnly={false}
        value={ProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName: value,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.ProcessName ?? value;
          }
          if (errors.ProcessName?.hasError) {
            runValidationTasks("ProcessName", value);
          }
          setProcessName(value);
        }}
        onBlur={() => runValidationTasks("ProcessName", ProcessName)}
        errorMessage={errors.ProcessName?.errorMessage}
        hasError={errors.ProcessName?.hasError}
        {...getOverrideProps(overrides, "ProcessName")}
      ></TextField>
      <SelectField
        label="Process type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ProcessType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType: value,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.ProcessType ?? value;
          }
          if (errors.ProcessType?.hasError) {
            runValidationTasks("ProcessType", value);
          }
          setProcessType(value);
        }}
        onBlur={() => runValidationTasks("ProcessType", ProcessType)}
        errorMessage={errors.ProcessType?.errorMessage}
        hasError={errors.ProcessType?.hasError}
        {...getOverrideProps(overrides, "ProcessType")}
      >
        <option
          children="Assess"
          value="Assess"
          {...getOverrideProps(overrides, "ProcessTypeoption0")}
        ></option>
        <option
          children="Decide"
          value="Decide"
          {...getOverrideProps(overrides, "ProcessTypeoption1")}
        ></option>
        <option
          children="Identify"
          value="Identify"
          {...getOverrideProps(overrides, "ProcessTypeoption2")}
        ></option>
        <option
          children="Manage"
          value="Manage"
          {...getOverrideProps(overrides, "ProcessTypeoption3")}
        ></option>
        <option
          children="Monitor"
          value="Monitor"
          {...getOverrideProps(overrides, "ProcessTypeoption4")}
        ></option>
        <option
          children="Oversee"
          value="Oversee"
          {...getOverrideProps(overrides, "ProcessTypeoption5")}
        ></option>
        <option
          children="Prioritise"
          value="Prioritise"
          {...getOverrideProps(overrides, "ProcessTypeoption6")}
        ></option>
        <option
          children="Report"
          value="Report"
          {...getOverrideProps(overrides, "ProcessTypeoption7")}
        ></option>
        <option
          children="Integrated"
          value="Integrated"
          {...getOverrideProps(overrides, "ProcessTypeoption8")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Process description"
        isRequired={false}
        isReadOnly={false}
        value={ProcessDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription: value,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.ProcessDescription ?? value;
          }
          if (errors.ProcessDescription?.hasError) {
            runValidationTasks("ProcessDescription", value);
          }
          setProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ProcessDescription", ProcessDescription)
        }
        errorMessage={errors.ProcessDescription?.errorMessage}
        hasError={errors.ProcessDescription?.hasError}
        {...getOverrideProps(overrides, "ProcessDescription")}
      ></TextAreaField>
      <SelectField
        label="Risk opportunity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={RiskOpportunityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType: value,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.RiskOpportunityType ?? value;
          }
          if (errors.RiskOpportunityType?.hasError) {
            runValidationTasks("RiskOpportunityType", value);
          }
          setRiskOpportunityType(value);
        }}
        onBlur={() =>
          runValidationTasks("RiskOpportunityType", RiskOpportunityType)
        }
        errorMessage={errors.RiskOpportunityType?.errorMessage}
        hasError={errors.RiskOpportunityType?.hasError}
        {...getOverrideProps(overrides, "RiskOpportunityType")}
      >
        <option
          children="Risk"
          value="Risk"
          {...getOverrideProps(overrides, "RiskOpportunityTypeoption0")}
        ></option>
        <option
          children="Opportunity"
          value="Opportunity"
          {...getOverrideProps(overrides, "RiskOpportunityTypeoption1")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Inputs used in this process"
        isRequired={false}
        isReadOnly={false}
        value={Inputs}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs: value,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.Inputs ?? value;
          }
          if (errors.Inputs?.hasError) {
            runValidationTasks("Inputs", value);
          }
          setInputs(value);
        }}
        onBlur={() => runValidationTasks("Inputs", Inputs)}
        errorMessage={errors.Inputs?.errorMessage}
        hasError={errors.Inputs?.hasError}
        {...getOverrideProps(overrides, "Inputs")}
      ></TextAreaField>
      <TextAreaField
        label="Parameters used in this process"
        isRequired={false}
        isReadOnly={false}
        value={Parameters}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters: value,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.Parameters ?? value;
          }
          if (errors.Parameters?.hasError) {
            runValidationTasks("Parameters", value);
          }
          setParameters(value);
        }}
        onBlur={() => runValidationTasks("Parameters", Parameters)}
        errorMessage={errors.Parameters?.errorMessage}
        hasError={errors.Parameters?.hasError}
        {...getOverrideProps(overrides, "Parameters")}
      ></TextAreaField>
      <Heading
        level={5}
        children="Additional data on risk management policies"
        {...getOverrideProps(overrides, "SectionalElement7")}
      ></Heading>
      <Text
        children="Please enter the following additional information about your risk management process."
        {...getOverrideProps(overrides, "SectionalElement8")}
      ></Text>
      <TextAreaField
        label="Inform process - whether and how you use climate-related scenarios to inform climate-related risk identification"
        isRequired={false}
        isReadOnly={false}
        value={UseOfScenariosInRiskIdentification}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification: value,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.UseOfScenariosInRiskIdentification ?? value;
          }
          if (errors.UseOfScenariosInRiskIdentification?.hasError) {
            runValidationTasks("UseOfScenariosInRiskIdentification", value);
          }
          setUseOfScenariosInRiskIdentification(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "UseOfScenariosInRiskIdentification",
            UseOfScenariosInRiskIdentification
          )
        }
        errorMessage={errors.UseOfScenariosInRiskIdentification?.errorMessage}
        hasError={errors.UseOfScenariosInRiskIdentification?.hasError}
        {...getOverrideProps(overrides, "UseOfScenariosInRiskIdentification")}
      ></TextAreaField>
      <TextAreaField
        label="Assess process - how you assess the nature, likelihood and magnitude of risk effects (e.g. qualitative factors, quantitative thresholds etc)"
        isRequired={false}
        isReadOnly={false}
        value={HowAssessRiskEffects}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects: value,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.HowAssessRiskEffects ?? value;
          }
          if (errors.HowAssessRiskEffects?.hasError) {
            runValidationTasks("HowAssessRiskEffects", value);
          }
          setHowAssessRiskEffects(value);
        }}
        onBlur={() =>
          runValidationTasks("HowAssessRiskEffects", HowAssessRiskEffects)
        }
        errorMessage={errors.HowAssessRiskEffects?.errorMessage}
        hasError={errors.HowAssessRiskEffects?.hasError}
        {...getOverrideProps(overrides, "HowAssessRiskEffects")}
      ></TextAreaField>
      <TextAreaField
        label="Prioritise process - whether and how you prioritise climate-related risks relative to other types of risks"
        isRequired={false}
        isReadOnly={false}
        value={RelativeRiskPrioritisation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation: value,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.RelativeRiskPrioritisation ?? value;
          }
          if (errors.RelativeRiskPrioritisation?.hasError) {
            runValidationTasks("RelativeRiskPrioritisation", value);
          }
          setRelativeRiskPrioritisation(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "RelativeRiskPrioritisation",
            RelativeRiskPrioritisation
          )
        }
        errorMessage={errors.RelativeRiskPrioritisation?.errorMessage}
        hasError={errors.RelativeRiskPrioritisation?.hasError}
        {...getOverrideProps(overrides, "RelativeRiskPrioritisation")}
      ></TextAreaField>
      <TextAreaField
        label="Whether and how you have changed this process, compared to the previous reporting period"
        value={ChangesFromPreviousPeriod}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod: value,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.ChangesFromPreviousPeriod ?? value;
          }
          if (errors.ChangesFromPreviousPeriod?.hasError) {
            runValidationTasks("ChangesFromPreviousPeriod", value);
          }
          setChangesFromPreviousPeriod(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ChangesFromPreviousPeriod",
            ChangesFromPreviousPeriod
          )
        }
        errorMessage={errors.ChangesFromPreviousPeriod?.errorMessage}
        hasError={errors.ChangesFromPreviousPeriod?.hasError}
        {...getOverrideProps(overrides, "ChangesFromPreviousPeriod")}
      ></TextAreaField>
      <TextAreaField
        label="The extent to which, and how, this climate-related risk process is integrated into and informs your overall risk management process"
        isRequired={false}
        isReadOnly={false}
        value={IntegrationWithEnterpriseRiskProcess}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess: value,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.IntegrationWithEnterpriseRiskProcess ?? value;
          }
          if (errors.IntegrationWithEnterpriseRiskProcess?.hasError) {
            runValidationTasks("IntegrationWithEnterpriseRiskProcess", value);
          }
          setIntegrationWithEnterpriseRiskProcess(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "IntegrationWithEnterpriseRiskProcess",
            IntegrationWithEnterpriseRiskProcess
          )
        }
        errorMessage={errors.IntegrationWithEnterpriseRiskProcess?.errorMessage}
        hasError={errors.IntegrationWithEnterpriseRiskProcess?.hasError}
        {...getOverrideProps(overrides, "IntegrationWithEnterpriseRiskProcess")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <TextField
        label="Use of scenarios in opportunity identification"
        isRequired={false}
        isReadOnly={false}
        value={UseOfScenariosInOpportunityIdentification}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification: value,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.UseOfScenariosInOpportunityIdentification ?? value;
          }
          if (errors.UseOfScenariosInOpportunityIdentification?.hasError) {
            runValidationTasks(
              "UseOfScenariosInOpportunityIdentification",
              value
            );
          }
          setUseOfScenariosInOpportunityIdentification(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "UseOfScenariosInOpportunityIdentification",
            UseOfScenariosInOpportunityIdentification
          )
        }
        errorMessage={
          errors.UseOfScenariosInOpportunityIdentification?.errorMessage
        }
        hasError={errors.UseOfScenariosInOpportunityIdentification?.hasError}
        {...getOverrideProps(
          overrides,
          "UseOfScenariosInOpportunityIdentification"
        )}
      ></TextField>
      <TextField
        label="Process changes from previous period"
        isRequired={false}
        isReadOnly={false}
        value={ProcessChangesFromPreviousPeriod}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod: value,
              RiskManagementProcessPolicySelectType,
            };
            const result = onChange(modelFields);
            value = result?.ProcessChangesFromPreviousPeriod ?? value;
          }
          if (errors.ProcessChangesFromPreviousPeriod?.hasError) {
            runValidationTasks("ProcessChangesFromPreviousPeriod", value);
          }
          setProcessChangesFromPreviousPeriod(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ProcessChangesFromPreviousPeriod",
            ProcessChangesFromPreviousPeriod
          )
        }
        errorMessage={errors.ProcessChangesFromPreviousPeriod?.errorMessage}
        hasError={errors.ProcessChangesFromPreviousPeriod?.hasError}
        {...getOverrideProps(overrides, "ProcessChangesFromPreviousPeriod")}
      ></TextField>
      <SelectField
        label="Risk management process policy select type"
        placeholder="Please select an option"
        isDisabled={false}
        value={RiskManagementProcessPolicySelectType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              ProcessType,
              ProcessDescription,
              RiskOpportunityType,
              Inputs,
              Parameters,
              UseOfScenariosInRiskIdentification,
              HowAssessRiskEffects,
              RelativeRiskPrioritisation,
              ChangesFromPreviousPeriod,
              IntegrationWithEnterpriseRiskProcess,
              UseOfScenariosInOpportunityIdentification,
              ProcessChangesFromPreviousPeriod,
              RiskManagementProcessPolicySelectType: value,
            };
            const result = onChange(modelFields);
            value = result?.RiskManagementProcessPolicySelectType ?? value;
          }
          if (errors.RiskManagementProcessPolicySelectType?.hasError) {
            runValidationTasks("RiskManagementProcessPolicySelectType", value);
          }
          setRiskManagementProcessPolicySelectType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "RiskManagementProcessPolicySelectType",
            RiskManagementProcessPolicySelectType
          )
        }
        errorMessage={
          errors.RiskManagementProcessPolicySelectType?.errorMessage
        }
        hasError={errors.RiskManagementProcessPolicySelectType?.hasError}
        {...getOverrideProps(
          overrides,
          "RiskManagementProcessPolicySelectType"
        )}
      >
        <option
          children="Processes"
          value="Processes"
          {...getOverrideProps(
            overrides,
            "RiskManagementProcessPolicySelectTypeoption0"
          )}
        ></option>
        <option
          children="Supplementary"
          value="Supplementary"
          {...getOverrideProps(
            overrides,
            "RiskManagementProcessPolicySelectTypeoption1"
          )}
        ></option>
      </SelectField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || riskManagementProcessPolicyModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || riskManagementProcessPolicyModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
