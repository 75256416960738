/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { GovernanceData } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function GovernanceDataManagementGovernanceRoleCreateForm(
  props
) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GovernanceName: "",
    GovernanceDescription: "",
    ManagementDelegation: "",
    ManagementControlsProcedures: "",
    ManagementControlsProceduresIntegration: "",
  };
  const [GovernanceName, setGovernanceName] = React.useState(
    initialValues.GovernanceName
  );
  const [GovernanceDescription, setGovernanceDescription] = React.useState(
    initialValues.GovernanceDescription
  );
  const [ManagementDelegation, setManagementDelegation] = React.useState(
    initialValues.ManagementDelegation
  );
  const [ManagementControlsProcedures, setManagementControlsProcedures] =
    React.useState(initialValues.ManagementControlsProcedures);
  const [
    ManagementControlsProceduresIntegration,
    setManagementControlsProceduresIntegration,
  ] = React.useState(initialValues.ManagementControlsProceduresIntegration);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setGovernanceName(initialValues.GovernanceName);
    setGovernanceDescription(initialValues.GovernanceDescription);
    setManagementDelegation(initialValues.ManagementDelegation);
    setManagementControlsProcedures(initialValues.ManagementControlsProcedures);
    setManagementControlsProceduresIntegration(
      initialValues.ManagementControlsProceduresIntegration
    );
    setErrors({});
  };
  const validations = {
    GovernanceName: [],
    GovernanceDescription: [],
    ManagementDelegation: [],
    ManagementControlsProcedures: [],
    ManagementControlsProceduresIntegration: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GovernanceName,
          GovernanceDescription,
          ManagementDelegation,
          ManagementControlsProcedures,
          ManagementControlsProceduresIntegration,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            GovernanceName: modelFields.GovernanceName,
            GovernanceDescription: modelFields.GovernanceDescription,
            ManagementDelegation: modelFields.ManagementDelegation,
            ManagementControlsProceduresIntegration:
              modelFields.ManagementControlsProceduresIntegration,
          };
          await DataStore.save(new GovernanceData(modelFieldsToSave));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "GovernanceDataManagementGovernanceRoleCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Management Role in Climate-Related Governance"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 6 b i-ii."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record to describe management’s role in climate governance."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Name of management role record (e.g. 2025 management role in climate governance)"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName: value,
              GovernanceDescription,
              ManagementDelegation,
              ManagementControlsProcedures,
              ManagementControlsProceduresIntegration,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceName ?? value;
          }
          if (errors.GovernanceName?.hasError) {
            runValidationTasks("GovernanceName", value);
          }
          setGovernanceName(value);
        }}
        onBlur={() => runValidationTasks("GovernanceName", GovernanceName)}
        errorMessage={errors.GovernanceName?.errorMessage}
        hasError={errors.GovernanceName?.hasError}
        {...getOverrideProps(overrides, "GovernanceName")}
      ></TextField>
      <TextAreaField
        label="Description (e.g. Description of our management role in climate governance for 2025 reporting period)"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName,
              GovernanceDescription: value,
              ManagementDelegation,
              ManagementControlsProcedures,
              ManagementControlsProceduresIntegration,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceDescription ?? value;
          }
          if (errors.GovernanceDescription?.hasError) {
            runValidationTasks("GovernanceDescription", value);
          }
          setGovernanceDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceDescription", GovernanceDescription)
        }
        errorMessage={errors.GovernanceDescription?.errorMessage}
        hasError={errors.GovernanceDescription?.hasError}
        	value={GovernanceDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "GovernanceDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Describe how management's role in climate-related governance is delegated to a specific management-level position or management-level committee"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName,
              GovernanceDescription,
              ManagementDelegation: value,
              ManagementControlsProcedures,
              ManagementControlsProceduresIntegration,
            };
            const result = onChange(modelFields);
            value = result?.ManagementDelegation ?? value;
          }
          if (errors.ManagementDelegation?.hasError) {
            runValidationTasks("ManagementDelegation", value);
          }
          setManagementDelegation(value);
        }}
        onBlur={() =>
          runValidationTasks("ManagementDelegation", ManagementDelegation)
        }
        errorMessage={errors.ManagementDelegation?.errorMessage}
        hasError={errors.ManagementDelegation?.hasError}
        	value={ManagementDelegation} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "ManagementDelegation")}
      ></TextAreaField>
      <TextAreaField
        label="Management use of controls and procedures to support oversight of climate-related risks and opportunities"
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName,
              GovernanceDescription,
              ManagementDelegation,
              ManagementControlsProcedures: value,
              ManagementControlsProceduresIntegration,
            };
            const result = onChange(modelFields);
            value = result?.ManagementControlsProcedures ?? value;
          }
          if (errors.ManagementControlsProcedures?.hasError) {
            runValidationTasks("ManagementControlsProcedures", value);
          }
          setManagementControlsProcedures(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ManagementControlsProcedures",
            ManagementControlsProcedures
          )
        }
        errorMessage={errors.ManagementControlsProcedures?.errorMessage}
        hasError={errors.ManagementControlsProcedures?.hasError}
        	value={ManagementControlsProcedures} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "ManagementControlsProcedures")}
      ></TextAreaField>
      <TextAreaField
        label="Describe how management controls and procedures are integrated with other internal functions"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceName,
              GovernanceDescription,
              ManagementDelegation,
              ManagementControlsProcedures,
              ManagementControlsProceduresIntegration: value,
            };
            const result = onChange(modelFields);
            value = result?.ManagementControlsProceduresIntegration ?? value;
          }
          if (errors.ManagementControlsProceduresIntegration?.hasError) {
            runValidationTasks(
              "ManagementControlsProceduresIntegration",
              value
            );
          }
          setManagementControlsProceduresIntegration(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ManagementControlsProceduresIntegration",
            ManagementControlsProceduresIntegration
          )
        }
        errorMessage={
          errors.ManagementControlsProceduresIntegration?.errorMessage
        }
        hasError={errors.ManagementControlsProceduresIntegration?.hasError}
        {...getOverrideProps(
          overrides,
          "ManagementControlsProceduresIntegration"
        )}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
