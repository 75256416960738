import "bootstrap/dist/css/bootstrap.min.css";


import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from "react-router-dom";

import {Authenticator, Image, useTheme, View} from "@aws-amplify/ui-react";
import {signIn} from "aws-amplify/auth";
import amplifyConfiguration from "./amplifyconfiguration.json";
import styles from "./App.module.css";
import AdminLayout from "./shared/adminComponents/AdminLayout";
import AppUsers from "./shared/adminComponents/AppUsers";
import AuditLog from "./shared/adminComponents/AuditLog";
import { CustomerSessionProvider } from "./shared/customer/CustomerSessionProvider";
import EvidencePage from "./shared/evidence/EvidencePage";
import DataItemBase from "./shared/form/DataItemBase";
import AppError from "./shared/logger/AppError";
import { LayoutProvider } from "./shared/modal/LayoutContext";
import Search from "./shared/page/Search";
import ChangePassword from "./shared/user/ChangePassword";
import { ToastProvider } from "./shared/util/Toast";
import { HelpProvider, useHelpContext } from "./shared/help/HelpContext";
import SharedLayout from "./shared/page/SharedLayout";
import RootNavBar from "./localComponents/RootNavBar";
import DisclosureNavbar from "./localComponents/disclosure/DisclosureNavbar";
import Disclosures from "./shared/disclosure/Disclosures";
import DisclosureLayout from "./shared/disclosure/DisclosureLayout";
import PublishDisclosure from "./shared/page/PublishDisclosure";
import ObjectGraph from "./shared/page/ObjectGraph";
import ExportCustomerData from "./shared/adminComponents/ExportCustomerData";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<SharedLayout navBar={<RootNavBar />} />} errorElement={<AppError />}>
        <Route index element={<Navigate to="/disclosures" />} />
        <Route path="/model/:modelSubtype/:id?" element={<DataItemBase />} />
        <Route path="/graph/:model/:id" element={<ObjectGraph />} />
        <Route path="/disclosures" element={<DisclosureLayout navBar={<DisclosureNavbar />} />}>
          <Route index element={<Disclosures />} />
          <Route path=":modelSubtype/:id?" element={<DataItemBase />} />
        </Route>
        <Route path="/search" element={<Search />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="/admin/AppUsers" replace />} />
          <Route index path="AppUsers" element={<AppUsers />} />
          <Route path="AuditLog" element={<AuditLog />} />
          <Route path="Evidence" element={<EvidencePage />} />
          <Route path="Export" element={<ExportCustomerData />} />
        </Route>
        <Route path="/user/changePassword" element={<ChangePassword />} />
        <Route path="/publish/:id" element={<PublishDisclosure />} />
      </Route>
    )
  );
  const services = {
    async handleSignIn(formData) {
      return signIn({
        ...formData,
        options: {
          clientMetadata: {
            graphqlUrl: amplifyConfiguration.aws_appsync_graphqlEndpoint,
            customerId: window.localStorage.getItem("LastCustomerId")
          }
        }
      });
    }
  };
  return (
    <LayoutProvider>
      <HelpProvider version="IFRSS2">
        <ToastProvider>
          <Authenticator
            className={styles.authentication}
            services={services}
            components={{
              Header() {
                const { tokens } = useTheme();
                const { version } = useHelpContext();
                return (
                  <View data-amplify-router textAlign="center" padding={tokens.space.large}>
                    <Image alt="ClimateTracker logo" src="/ClimateTrackerLogoGreen.svg" />
                    {version} version
                  </View>
                );
              }
            }}
          >
            <CustomerSessionProvider>
              <RouterProvider router={router} />
            </CustomerSessionProvider>
          </Authenticator>
        </ToastProvider>
      </HelpProvider>
    </LayoutProvider>
  );
}

export default App;