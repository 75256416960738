import HelpBox from "../../shared/content/HelpBox";

export default function AggregatedFinancialEffectHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Aggregated Financial Effect Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that collates aggregated financial effects that arise from a climate-related risk or opportunity, for situations where financial effects were not provided separately for individual risks or opportunities.
        </p>
        <p>Follow these steps to define your aggregated financial effects:</p>
        <ol>
          <li>
            Enter a description for why you have not provided quantitative data for individual climate-related risks or opportunities, in the left hand side of the window.
          </li>
          <li>
            Enter a qualitative description of these aggregated effects. It should cover areas such as line items, totals, and subtotals within financial statements (currently affected, or will be affected), or have been affected, by that climate-related risk or opportunity.
          </li>
          <li>
            Enter a description of quantitative information about the combined financial effects of climate-related risks or opportunities.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, enter a value or range of values for the financial effects, and the unit of measure used.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
