/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { RiskManagementProcessPolicy } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskManagementProcessPolicyProcessesUpdateForm(props) {
  const {
    id: idProp,
    riskManagementProcessPolicy: riskManagementProcessPolicyModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    RiskOpportunityType: "",
    ProcessType: "",
    ProcessName: "",
    ProcessDescription: "",
    Inputs: "",
    Parameters: "",
  };
  const [RiskOpportunityType, setRiskOpportunityType] = React.useState(
    initialValues.RiskOpportunityType
  );
  const [ProcessType, setProcessType] = React.useState(
    initialValues.ProcessType
  );
  const [ProcessName, setProcessName] = React.useState(
    initialValues.ProcessName
  );
  const [ProcessDescription, setProcessDescription] = React.useState(
    initialValues.ProcessDescription
  );
  const [Inputs, setInputs] = React.useState(initialValues.Inputs);
  const [Parameters, setParameters] = React.useState(initialValues.Parameters);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = riskManagementProcessPolicyRecord
      ? { ...initialValues, ...riskManagementProcessPolicyRecord }
      : initialValues;
    setRiskOpportunityType(cleanValues.RiskOpportunityType);
    setProcessType(cleanValues.ProcessType);
    setProcessName(cleanValues.ProcessName);
    setProcessDescription(cleanValues.ProcessDescription);
    setInputs(cleanValues.Inputs);
    setParameters(cleanValues.Parameters);
    setErrors({});
  };
  const [
    riskManagementProcessPolicyRecord,
    setRiskManagementProcessPolicyRecord,
  ] = React.useState(riskManagementProcessPolicyModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(RiskManagementProcessPolicy, idProp)
        : riskManagementProcessPolicyModelProp;
      setRiskManagementProcessPolicyRecord(record);
    };
    queryData();
  }, [idProp, riskManagementProcessPolicyModelProp]);
  React.useEffect(resetStateValues, [riskManagementProcessPolicyRecord]);
  const validations = {
    RiskOpportunityType: [],
    ProcessType: [],
    ProcessName: [],
    ProcessDescription: [],
    Inputs: [],
    Parameters: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          RiskOpportunityType,
          ProcessType,
          ProcessName,
          ProcessDescription,
          Inputs,
          Parameters,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            RiskManagementProcessPolicy.copyOf(
              riskManagementProcessPolicyRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "RiskManagementProcessPolicyProcessesUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Risk Management Process and Policy"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 25 a-c."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Heading>
      <Text
        children="Update this record that defines the processes and related policies you use to identify, assess, prioritise and monitor climate-related risks."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <SelectField
        label="Select if this process applies to a Risk or Opportunity"
        placeholder="Please select an option"
        isDisabled={false}
        value={RiskOpportunityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskOpportunityType: value,
              ProcessType,
              ProcessName,
              ProcessDescription,
              Inputs,
              Parameters,
            };
            const result = onChange(modelFields);
            value = result?.RiskOpportunityType ?? value;
          }
          if (errors.RiskOpportunityType?.hasError) {
            runValidationTasks("RiskOpportunityType", value);
          }
          setRiskOpportunityType(value);
        }}
        onBlur={() =>
          runValidationTasks("RiskOpportunityType", RiskOpportunityType)
        }
        errorMessage={errors.RiskOpportunityType?.errorMessage}
        hasError={errors.RiskOpportunityType?.hasError}
        {...getOverrideProps(overrides, "RiskOpportunityType")}
      >
        <option
          children="Risk"
          value="Risk"
          {...getOverrideProps(overrides, "RiskOpportunityTypeoption0")}
        ></option>
        <option
          children="Opportunity"
          value="Opportunity"
          {...getOverrideProps(overrides, "RiskOpportunityTypeoption1")}
        ></option>
      </SelectField>
      <SelectField
        label="Process type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ProcessType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskOpportunityType,
              ProcessType: value,
              ProcessName,
              ProcessDescription,
              Inputs,
              Parameters,
            };
            const result = onChange(modelFields);
            value = result?.ProcessType ?? value;
          }
          if (errors.ProcessType?.hasError) {
            runValidationTasks("ProcessType", value);
          }
          setProcessType(value);
        }}
        onBlur={() => runValidationTasks("ProcessType", ProcessType)}
        errorMessage={errors.ProcessType?.errorMessage}
        hasError={errors.ProcessType?.hasError}
        {...getOverrideProps(overrides, "ProcessType")}
      >
        <option
          children="Assess"
          value="Assess"
          {...getOverrideProps(overrides, "ProcessTypeoption0")}
        ></option>
        <option
          children="Decide"
          value="Decide"
          {...getOverrideProps(overrides, "ProcessTypeoption1")}
        ></option>
        <option
          children="Identify"
          value="Identify"
          {...getOverrideProps(overrides, "ProcessTypeoption2")}
        ></option>
        <option
          children="Manage"
          value="Manage"
          {...getOverrideProps(overrides, "ProcessTypeoption3")}
        ></option>
        <option
          children="Monitor"
          value="Monitor"
          {...getOverrideProps(overrides, "ProcessTypeoption4")}
        ></option>
        <option
          children="Oversee"
          value="Oversee"
          {...getOverrideProps(overrides, "ProcessTypeoption5")}
        ></option>
        <option
          children="Prioritise"
          value="Prioritise"
          {...getOverrideProps(overrides, "ProcessTypeoption6")}
        ></option>
        <option
          children="Report"
          value="Report"
          {...getOverrideProps(overrides, "ProcessTypeoption7")}
        ></option>
        <option
          children="Integrated"
          value="Integrated"
          {...getOverrideProps(overrides, "ProcessTypeoption8")}
        ></option>
      </SelectField>
      <TextField
        label="Process name"
        isRequired={false}
        isReadOnly={false}
        value={ProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskOpportunityType,
              ProcessType,
              ProcessName: value,
              ProcessDescription,
              Inputs,
              Parameters,
            };
            const result = onChange(modelFields);
            value = result?.ProcessName ?? value;
          }
          if (errors.ProcessName?.hasError) {
            runValidationTasks("ProcessName", value);
          }
          setProcessName(value);
        }}
        onBlur={() => runValidationTasks("ProcessName", ProcessName)}
        errorMessage={errors.ProcessName?.errorMessage}
        hasError={errors.ProcessName?.hasError}
        {...getOverrideProps(overrides, "ProcessName")}
      ></TextField>
      <TextAreaField
        label="Process description"
        isRequired={false}
        isReadOnly={false}
        value={ProcessDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskOpportunityType,
              ProcessType,
              ProcessName,
              ProcessDescription: value,
              Inputs,
              Parameters,
            };
            const result = onChange(modelFields);
            value = result?.ProcessDescription ?? value;
          }
          if (errors.ProcessDescription?.hasError) {
            runValidationTasks("ProcessDescription", value);
          }
          setProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ProcessDescription", ProcessDescription)
        }
        errorMessage={errors.ProcessDescription?.errorMessage}
        hasError={errors.ProcessDescription?.hasError}
        {...getOverrideProps(overrides, "ProcessDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Inputs"
        isRequired={false}
        isReadOnly={false}
        value={Inputs}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskOpportunityType,
              ProcessType,
              ProcessName,
              ProcessDescription,
              Inputs: value,
              Parameters,
            };
            const result = onChange(modelFields);
            value = result?.Inputs ?? value;
          }
          if (errors.Inputs?.hasError) {
            runValidationTasks("Inputs", value);
          }
          setInputs(value);
        }}
        onBlur={() => runValidationTasks("Inputs", Inputs)}
        errorMessage={errors.Inputs?.errorMessage}
        hasError={errors.Inputs?.hasError}
        {...getOverrideProps(overrides, "Inputs")}
      ></TextAreaField>
      <TextAreaField
        label="Parameters"
        isRequired={false}
        isReadOnly={false}
        value={Parameters}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskOpportunityType,
              ProcessType,
              ProcessName,
              ProcessDescription,
              Inputs,
              Parameters: value,
            };
            const result = onChange(modelFields);
            value = result?.Parameters ?? value;
          }
          if (errors.Parameters?.hasError) {
            runValidationTasks("Parameters", value);
          }
          setParameters(value);
        }}
        onBlur={() => runValidationTasks("Parameters", Parameters)}
        errorMessage={errors.Parameters?.errorMessage}
        hasError={errors.Parameters?.hasError}
        {...getOverrideProps(overrides, "Parameters")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || riskManagementProcessPolicyModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || riskManagementProcessPolicyModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
