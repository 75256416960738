/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { RiskOpportunity } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskOpportunityUpdateForm(props) {
  const {
    id: idProp,
    riskOpportunity: riskOpportunityModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    Name: "",
    RiskOpportunityType: "",
    Description: "",
    LikelihoodOfOccurring: "",
    Priority: "",
    RiskOpportunityGroups: "",
    TimeHorizon: "",
  };
  const [Name, setName] = React.useState(initialValues.Name);
  const [RiskOpportunityType, setRiskOpportunityType] = React.useState(
    initialValues.RiskOpportunityType
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [LikelihoodOfOccurring, setLikelihoodOfOccurring] = React.useState(
    initialValues.LikelihoodOfOccurring
  );
  const [Priority, setPriority] = React.useState(initialValues.Priority);
  const [RiskOpportunityGroups, setRiskOpportunityGroups] = React.useState(
    initialValues.RiskOpportunityGroups
  );
  const [TimeHorizon, setTimeHorizon] = React.useState(
    initialValues.TimeHorizon
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = riskOpportunityRecord
      ? { ...initialValues, ...riskOpportunityRecord }
      : initialValues;
    setName(cleanValues.Name);
    setRiskOpportunityType(cleanValues.RiskOpportunityType);
    setDescription(cleanValues.Description);
    setLikelihoodOfOccurring(cleanValues.LikelihoodOfOccurring);
    setPriority(cleanValues.Priority);
    setRiskOpportunityGroups(cleanValues.RiskOpportunityGroups);
    setTimeHorizon(cleanValues.TimeHorizon);
    setErrors({});
  };
  const [riskOpportunityRecord, setRiskOpportunityRecord] = React.useState(
    riskOpportunityModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(RiskOpportunity, idProp)
        : riskOpportunityModelProp;
      setRiskOpportunityRecord(record);
    };
    queryData();
  }, [idProp, riskOpportunityModelProp]);
  React.useEffect(resetStateValues, [riskOpportunityRecord]);
  const validations = {
    Name: [],
    RiskOpportunityType: [],
    Description: [],
    LikelihoodOfOccurring: [],
    Priority: [],
    RiskOpportunityGroups: [],
    TimeHorizon: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          Name,
          RiskOpportunityType,
          Description,
          LikelihoodOfOccurring,
          Priority,
          RiskOpportunityGroups,
          TimeHorizon,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            RiskOpportunity.copyOf(riskOpportunityRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "RiskOpportunityUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate-related Risk or Opportunity"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 10 a-c."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Update this record that defines a climate-related risk or opportunity that could reasonably be expected to affect your organisation."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Risk or opportunity name"
        isRequired={false}
        isReadOnly={false}
        value={Name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              Name: value,
              RiskOpportunityType,
              Description,
              LikelihoodOfOccurring,
              Priority,
              RiskOpportunityGroups,
              TimeHorizon,
            };
            const result = onChange(modelFields);
            value = result?.Name ?? value;
          }
          if (errors.Name?.hasError) {
            runValidationTasks("Name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("Name", Name)}
        errorMessage={errors.Name?.errorMessage}
        hasError={errors.Name?.hasError}
        {...getOverrideProps(overrides, "Name")}
      ></TextField>
      <SelectField
        label="Risk or opportunity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={RiskOpportunityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              Name,
              RiskOpportunityType: value,
              Description,
              LikelihoodOfOccurring,
              Priority,
              RiskOpportunityGroups,
              TimeHorizon,
            };
            const result = onChange(modelFields);
            value = result?.RiskOpportunityType ?? value;
          }
          if (errors.RiskOpportunityType?.hasError) {
            runValidationTasks("RiskOpportunityType", value);
          }
          setRiskOpportunityType(value);
        }}
        onBlur={() =>
          runValidationTasks("RiskOpportunityType", RiskOpportunityType)
        }
        errorMessage={errors.RiskOpportunityType?.errorMessage}
        hasError={errors.RiskOpportunityType?.hasError}
        {...getOverrideProps(overrides, "RiskOpportunityType")}
      >
        <option
          children="Risk"
          value="Risk"
          {...getOverrideProps(overrides, "RiskOpportunityTypeoption0")}
        ></option>
        <option
          children="Opportunity"
          value="Opportunity"
          {...getOverrideProps(overrides, "RiskOpportunityTypeoption1")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Description of risk or opportunity"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              Name,
              RiskOpportunityType,
              Description: value,
              LikelihoodOfOccurring,
              Priority,
              RiskOpportunityGroups,
              TimeHorizon,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <SelectField
          label="Likelihood of occurring"
          placeholder="Please select an option"
          isDisabled={false}
          value={LikelihoodOfOccurring}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                Name,
                RiskOpportunityType,
                Description,
                LikelihoodOfOccurring: value,
                Priority,
                RiskOpportunityGroups,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.LikelihoodOfOccurring ?? value;
            }
            if (errors.LikelihoodOfOccurring?.hasError) {
              runValidationTasks("LikelihoodOfOccurring", value);
            }
            setLikelihoodOfOccurring(value);
          }}
          onBlur={() =>
            runValidationTasks("LikelihoodOfOccurring", LikelihoodOfOccurring)
          }
          errorMessage={errors.LikelihoodOfOccurring?.errorMessage}
          hasError={errors.LikelihoodOfOccurring?.hasError}
          {...getOverrideProps(overrides, "LikelihoodOfOccurring")}
        >
          <option
            children="None"
            value="None"
            {...getOverrideProps(overrides, "LikelihoodOfOccurringoption0")}
          ></option>
          <option
            children="Low"
            value="Low"
            {...getOverrideProps(overrides, "LikelihoodOfOccurringoption1")}
          ></option>
          <option
            children="Medium"
            value="Medium"
            {...getOverrideProps(overrides, "LikelihoodOfOccurringoption2")}
          ></option>
          <option
            children="High"
            value="High"
            {...getOverrideProps(overrides, "LikelihoodOfOccurringoption3")}
          ></option>
          <option
            children="Very high"
            value="VeryHigh"
            {...getOverrideProps(overrides, "LikelihoodOfOccurringoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Priority"
          placeholder="Please select an option"
          isDisabled={false}
          value={Priority}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                Name,
                RiskOpportunityType,
                Description,
                LikelihoodOfOccurring,
                Priority: value,
                RiskOpportunityGroups,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.Priority ?? value;
            }
            if (errors.Priority?.hasError) {
              runValidationTasks("Priority", value);
            }
            setPriority(value);
          }}
          onBlur={() => runValidationTasks("Priority", Priority)}
          errorMessage={errors.Priority?.errorMessage}
          hasError={errors.Priority?.hasError}
          {...getOverrideProps(overrides, "Priority")}
        >
          <option
            children="Lowest"
            value="Lowest"
            {...getOverrideProps(overrides, "Priorityoption0")}
          ></option>
          <option
            children="Low"
            value="Low"
            {...getOverrideProps(overrides, "Priorityoption1")}
          ></option>
          <option
            children="Medium"
            value="Medium"
            {...getOverrideProps(overrides, "Priorityoption2")}
          ></option>
          <option
            children="High"
            value="High"
            {...getOverrideProps(overrides, "Priorityoption3")}
          ></option>
          <option
            children="Highest"
            value="Highest"
            {...getOverrideProps(overrides, "Priorityoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <SelectField
          label="Risk or opportunity category"
          placeholder="Please select an option"
          isDisabled={false}
          value={RiskOpportunityGroups}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                Name,
                RiskOpportunityType,
                Description,
                LikelihoodOfOccurring,
                Priority,
                RiskOpportunityGroups: value,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.RiskOpportunityGroups ?? value;
            }
            if (errors.RiskOpportunityGroups?.hasError) {
              runValidationTasks("RiskOpportunityGroups", value);
            }
            setRiskOpportunityGroups(value);
          }}
          onBlur={() =>
            runValidationTasks("RiskOpportunityGroups", RiskOpportunityGroups)
          }
          errorMessage={errors.RiskOpportunityGroups?.errorMessage}
          hasError={errors.RiskOpportunityGroups?.hasError}
          {...getOverrideProps(overrides, "RiskOpportunityGroups")}
        >
          <option
            children="Physical risk"
            value="PhysicalRisk"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption0")}
          ></option>
          <option
            children="Acute physical"
            value="AcutePhysical"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption1")}
          ></option>
          <option
            children="Chronic physical"
            value="ChronicPhysical"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption2")}
          ></option>
          <option
            children="Transition risk"
            value="TransitionRisk"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption3")}
          ></option>
          <option
            children="Market risk"
            value="MarketRisk"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption4")}
          ></option>
          <option
            children="Policy and legal risk"
            value="PolicyAndLegalRisk"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption5")}
          ></option>
          <option
            children="Reputation risk"
            value="ReputationRisk"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption6")}
          ></option>
          <option
            children="Technology risk"
            value="TechnologyRisk"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption7")}
          ></option>
          <option
            children="Resource efficiency opportunity"
            value="ResourceEfficiencyOpportunity"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption8")}
          ></option>
          <option
            children="Energy source opportunity"
            value="EnergySourceOpportunity"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption9")}
          ></option>
          <option
            children="Products services opportunity"
            value="ProductsServicesOpportunity"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption10")}
          ></option>
          <option
            children="Markets opportunity"
            value="MarketsOpportunity"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption11")}
          ></option>
          <option
            children="Resilience opportunity"
            value="ResilienceOpportunity"
            {...getOverrideProps(overrides, "RiskOpportunityGroupsoption12")}
          ></option>
        </SelectField>
        <SelectField
          label="Time horizon"
          placeholder="Please select an option"
          isDisabled={false}
          value={TimeHorizon}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                Name,
                RiskOpportunityType,
                Description,
                LikelihoodOfOccurring,
                Priority,
                RiskOpportunityGroups,
                TimeHorizon: value,
              };
              const result = onChange(modelFields);
              value = result?.TimeHorizon ?? value;
            }
            if (errors.TimeHorizon?.hasError) {
              runValidationTasks("TimeHorizon", value);
            }
            setTimeHorizon(value);
          }}
          onBlur={() => runValidationTasks("TimeHorizon", TimeHorizon)}
          errorMessage={errors.TimeHorizon?.errorMessage}
          hasError={errors.TimeHorizon?.hasError}
          {...getOverrideProps(overrides, "TimeHorizon")}
        >
          <option
            children="Current reporting period"
            value="CurrentReportingPeriod"
            {...getOverrideProps(overrides, "TimeHorizonoption0")}
          ></option>
          <option
            children="Next reporting period"
            value="NextReportingPeriod"
            {...getOverrideProps(overrides, "TimeHorizonoption1")}
          ></option>
          <option
            children="Short term"
            value="ShortTerm"
            {...getOverrideProps(overrides, "TimeHorizonoption2")}
          ></option>
          <option
            children="Medium term"
            value="MediumTerm"
            {...getOverrideProps(overrides, "TimeHorizonoption3")}
          ></option>
          <option
            children="Long term"
            value="LongTerm"
            {...getOverrideProps(overrides, "TimeHorizonoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || riskOpportunityModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || riskOpportunityModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
