import HelpBox from "../../shared/content/HelpBox";

export default function MitigationAdaptationEffortsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Mitigation and Adaptation Efforts Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes the current and anticipated direct and indirect Mitigation and Adaptation efforts.
        </p>
        <p>
          Examples of direct mitigation and adaptation efforts may include changes in production processes or equipment, relocation of facilities, workforce adjustments, and changes in product specifications.
        </p>
        <p>
          Examples of indirect mitigation and adptation efforts may include through working with customers and supply chains.
        </p>
        <p>Follow these steps to define your mitigation and adaptation efforts:</p>
        <ol>
          <li>
            Enter descriptions of the organzation's current and anticipated direct mitigation and adaptation efforts.
          </li>
          <li>
            Enter descriptions of the organzation's current and anticipated indirect mitigation and adaptation efforts.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
