import HelpBox from "../../shared/content/HelpBox";

export default function CrossIndustryMetricsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Cross Industry Metrics Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that contains data about greenhouse gas metrics relevant to all industries.
        </p>
        <p>Follow these steps to create a Cross Industry Metrics record:</p>
        <ol>
          <li>
            Create a name for the record in the <i>Greenhouse gas metrics record name</i> field.
          </li>
          <li>Next, complete each required data field on the form.</li>
          <li>
            Once completed, choose <b>Create</b> or <b>Update</b>.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
