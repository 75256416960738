/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ReductionAccountEntry } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ReductionAccountEntryUpdateForm(props) {
  const {
    id: idProp,
    reductionAccountEntry: reductionAccountEntryModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ReductionInitiativeName: "",
    ReductionInitiativeStatus: "",
    ReductionInitiativeDescription: "",
    EmissionReductionUnit: "",
    EmissionReductionValue: "",
    EstimatedAnnualCO2Saving: "",
    CO2SavingScope: "",
    RequiredInvestment: "",
    EstimatedInitiativeLifetime: "",
    StartDate: "",
    EndDate: "",
    AnnualMonetarySaving: "",
    PaybackPeriod: "",
    GrossEmissionReductionAchieved: "",
    DateAchieved: "",
  };
  const [ReductionInitiativeName, setReductionInitiativeName] = React.useState(
    initialValues.ReductionInitiativeName
  );
  const [ReductionInitiativeStatus, setReductionInitiativeStatus] =
    React.useState(initialValues.ReductionInitiativeStatus);
  const [ReductionInitiativeDescription, setReductionInitiativeDescription] =
    React.useState(initialValues.ReductionInitiativeDescription);
  const [EmissionReductionUnit, setEmissionReductionUnit] = React.useState(
    initialValues.EmissionReductionUnit
  );
  const [EmissionReductionValue, setEmissionReductionValue] = React.useState(
    initialValues.EmissionReductionValue
  );
  const [EstimatedAnnualCO2Saving, setEstimatedAnnualCO2Saving] =
    React.useState(initialValues.EstimatedAnnualCO2Saving);
  const [CO2SavingScope, setCO2SavingScope] = React.useState(
    initialValues.CO2SavingScope
  );
  const [RequiredInvestment, setRequiredInvestment] = React.useState(
    initialValues.RequiredInvestment
  );
  const [EstimatedInitiativeLifetime, setEstimatedInitiativeLifetime] =
    React.useState(initialValues.EstimatedInitiativeLifetime);
  const [StartDate, setStartDate] = React.useState(initialValues.StartDate);
  const [EndDate, setEndDate] = React.useState(initialValues.EndDate);
  const [AnnualMonetarySaving, setAnnualMonetarySaving] = React.useState(
    initialValues.AnnualMonetarySaving
  );
  const [PaybackPeriod, setPaybackPeriod] = React.useState(
    initialValues.PaybackPeriod
  );
  const [GrossEmissionReductionAchieved, setGrossEmissionReductionAchieved] =
    React.useState(initialValues.GrossEmissionReductionAchieved);
  const [DateAchieved, setDateAchieved] = React.useState(
    initialValues.DateAchieved
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = reductionAccountEntryRecord
      ? { ...initialValues, ...reductionAccountEntryRecord }
      : initialValues;
    setReductionInitiativeName(cleanValues.ReductionInitiativeName);
    setReductionInitiativeStatus(cleanValues.ReductionInitiativeStatus);
    setReductionInitiativeDescription(
      cleanValues.ReductionInitiativeDescription
    );
    setEmissionReductionUnit(cleanValues.EmissionReductionUnit);
    setEmissionReductionValue(cleanValues.EmissionReductionValue);
    setEstimatedAnnualCO2Saving(cleanValues.EstimatedAnnualCO2Saving);
    setCO2SavingScope(cleanValues.CO2SavingScope);
    setRequiredInvestment(cleanValues.RequiredInvestment);
    setEstimatedInitiativeLifetime(cleanValues.EstimatedInitiativeLifetime);
    setStartDate(cleanValues.StartDate);
    setEndDate(cleanValues.EndDate);
    setAnnualMonetarySaving(cleanValues.AnnualMonetarySaving);
    setPaybackPeriod(cleanValues.PaybackPeriod);
    setGrossEmissionReductionAchieved(
      cleanValues.GrossEmissionReductionAchieved
    );
    setDateAchieved(cleanValues.DateAchieved);
    setErrors({});
  };
  const [reductionAccountEntryRecord, setReductionAccountEntryRecord] =
    React.useState(reductionAccountEntryModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(ReductionAccountEntry, idProp)
        : reductionAccountEntryModelProp;
      setReductionAccountEntryRecord(record);
    };
    queryData();
  }, [idProp, reductionAccountEntryModelProp]);
  React.useEffect(resetStateValues, [reductionAccountEntryRecord]);
  const validations = {
    ReductionInitiativeName: [],
    ReductionInitiativeStatus: [],
    ReductionInitiativeDescription: [],
    EmissionReductionUnit: [],
    EmissionReductionValue: [],
    EstimatedAnnualCO2Saving: [],
    CO2SavingScope: [],
    RequiredInvestment: [],
    EstimatedInitiativeLifetime: [],
    StartDate: [],
    EndDate: [],
    AnnualMonetarySaving: [],
    PaybackPeriod: [],
    GrossEmissionReductionAchieved: [],
    DateAchieved: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ReductionInitiativeName,
          ReductionInitiativeStatus,
          ReductionInitiativeDescription,
          EmissionReductionUnit,
          EmissionReductionValue,
          EstimatedAnnualCO2Saving,
          CO2SavingScope,
          RequiredInvestment,
          EstimatedInitiativeLifetime,
          StartDate,
          EndDate,
          AnnualMonetarySaving,
          PaybackPeriod,
          GrossEmissionReductionAchieved,
          DateAchieved,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            ReductionAccountEntry.copyOf(
              reductionAccountEntryRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ReductionAccountEntryUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Greenhouse Gas Emission Reduction Transaction"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update this record of a planned or current initiative to reduce CO2 emissions."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Text
        children="Once a reduction initiative completes, you should update this transaction to have a status 'Implemented', and record the gross emission reduction and date at the bottom of this form."
        {...getOverrideProps(overrides, "SectionalElement10")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Reduction initiative name"
          isRequired={false}
          isReadOnly={false}
          value={ReductionInitiativeName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName: value,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.ReductionInitiativeName ?? value;
            }
            if (errors.ReductionInitiativeName?.hasError) {
              runValidationTasks("ReductionInitiativeName", value);
            }
            setReductionInitiativeName(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ReductionInitiativeName",
              ReductionInitiativeName
            )
          }
          errorMessage={errors.ReductionInitiativeName?.errorMessage}
          hasError={errors.ReductionInitiativeName?.hasError}
          {...getOverrideProps(overrides, "ReductionInitiativeName")}
        ></TextField>
        <SelectField
          label="Reduction initiative status"
          placeholder="Please select an option"
          isDisabled={false}
          value={ReductionInitiativeStatus}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus: value,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.ReductionInitiativeStatus ?? value;
            }
            if (errors.ReductionInitiativeStatus?.hasError) {
              runValidationTasks("ReductionInitiativeStatus", value);
            }
            setReductionInitiativeStatus(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ReductionInitiativeStatus",
              ReductionInitiativeStatus
            )
          }
          errorMessage={errors.ReductionInitiativeStatus?.errorMessage}
          hasError={errors.ReductionInitiativeStatus?.hasError}
          {...getOverrideProps(overrides, "ReductionInitiativeStatus")}
        >
          <option
            children="Investigating"
            value="Investigating"
            {...getOverrideProps(overrides, "ReductionInitiativeStatusoption0")}
          ></option>
          <option
            children="Planning"
            value="Planning"
            {...getOverrideProps(overrides, "ReductionInitiativeStatusoption1")}
          ></option>
          <option
            children="Implementing"
            value="Implementing"
            {...getOverrideProps(overrides, "ReductionInitiativeStatusoption2")}
          ></option>
          <option
            children="Implemented"
            value="Implemented"
            {...getOverrideProps(overrides, "ReductionInitiativeStatusoption3")}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Reduction initiative description"
        isRequired={false}
        isReadOnly={false}
        value={ReductionInitiativeDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ReductionInitiativeName,
              ReductionInitiativeStatus,
              ReductionInitiativeDescription: value,
              EmissionReductionUnit,
              EmissionReductionValue,
              EstimatedAnnualCO2Saving,
              CO2SavingScope,
              RequiredInvestment,
              EstimatedInitiativeLifetime,
              StartDate,
              EndDate,
              AnnualMonetarySaving,
              PaybackPeriod,
              GrossEmissionReductionAchieved,
              DateAchieved,
            };
            const result = onChange(modelFields);
            value = result?.ReductionInitiativeDescription ?? value;
          }
          if (errors.ReductionInitiativeDescription?.hasError) {
            runValidationTasks("ReductionInitiativeDescription", value);
          }
          setReductionInitiativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ReductionInitiativeDescription",
            ReductionInitiativeDescription
          )
        }
        errorMessage={errors.ReductionInitiativeDescription?.errorMessage}
        hasError={errors.ReductionInitiativeDescription?.hasError}
        {...getOverrideProps(overrides, "ReductionInitiativeDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Emission reduction unit of measure"
          placeholder="Please select an option"
          isDisabled={false}
          value={EmissionReductionUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit: value,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.EmissionReductionUnit ?? value;
            }
            if (errors.EmissionReductionUnit?.hasError) {
              runValidationTasks("EmissionReductionUnit", value);
            }
            setEmissionReductionUnit(value);
          }}
          onBlur={() =>
            runValidationTasks("EmissionReductionUnit", EmissionReductionUnit)
          }
          errorMessage={errors.EmissionReductionUnit?.errorMessage}
          hasError={errors.EmissionReductionUnit?.hasError}
          {...getOverrideProps(overrides, "EmissionReductionUnit")}
        >
          <option
            children="Employees"
            value="Employees"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption0")}
          ></option>
          <option
            children="Kg"
            value="Kg"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption1")}
          ></option>
          <option
            children="Kw"
            value="Kw"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption2")}
          ></option>
          <option
            children="Kwh"
            value="Kwh"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption3")}
          ></option>
          <option
            children="Litres"
            value="Litres"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption4")}
          ></option>
          <option
            children="Metres cubed"
            value="MetresCubed"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption5")}
          ></option>
          <option
            children="Million dollars revenue"
            value="MillionDollarsRevenue"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption6")}
          ></option>
          <option
            children="Nights"
            value="Nights"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption7")}
          ></option>
          <option
            children="Passenger per km"
            value="PassengerPerKm"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption8")}
          ></option>
          <option
            children="Per capita"
            value="PerCapita"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption9")}
          ></option>
          <option
            children="Percentage"
            value="Percentage"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption10")}
          ></option>
          <option
            children="Quantity"
            value="Quantity"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption11")}
          ></option>
          <option
            children="Tonnes"
            value="Tonnes"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption12")}
          ></option>
          <option
            children="Tonnes per km"
            value="TonnesPerKm"
            {...getOverrideProps(overrides, "EmissionReductionUnitoption13")}
          ></option>
        </SelectField>
        <TextField
          label="Emission reduction value (e.g. litres of fuel)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={EmissionReductionValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue: value,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.EmissionReductionValue ?? value;
            }
            if (errors.EmissionReductionValue?.hasError) {
              runValidationTasks("EmissionReductionValue", value);
            }
            setEmissionReductionValue(value);
          }}
          onBlur={() =>
            runValidationTasks("EmissionReductionValue", EmissionReductionValue)
          }
          errorMessage={errors.EmissionReductionValue?.errorMessage}
          hasError={errors.EmissionReductionValue?.hasError}
          {...getOverrideProps(overrides, "EmissionReductionValue")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Estimated annual CO2 saving (tonnes CO2e)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={EstimatedAnnualCO2Saving}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving: value,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.EstimatedAnnualCO2Saving ?? value;
            }
            if (errors.EstimatedAnnualCO2Saving?.hasError) {
              runValidationTasks("EstimatedAnnualCO2Saving", value);
            }
            setEstimatedAnnualCO2Saving(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "EstimatedAnnualCO2Saving",
              EstimatedAnnualCO2Saving
            )
          }
          errorMessage={errors.EstimatedAnnualCO2Saving?.errorMessage}
          hasError={errors.EstimatedAnnualCO2Saving?.hasError}
          {...getOverrideProps(overrides, "EstimatedAnnualCO2Saving")}
        ></TextField>
        <SelectField
          label="CO2 saving scope"
          placeholder="Please select an option"
          isDisabled={false}
          value={CO2SavingScope}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope: value,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.CO2SavingScope ?? value;
            }
            if (errors.CO2SavingScope?.hasError) {
              runValidationTasks("CO2SavingScope", value);
            }
            setCO2SavingScope(value);
          }}
          onBlur={() => runValidationTasks("CO2SavingScope", CO2SavingScope)}
          errorMessage={errors.CO2SavingScope?.errorMessage}
          hasError={errors.CO2SavingScope?.hasError}
          {...getOverrideProps(overrides, "CO2SavingScope")}
        >
          <option
            children="Scope 1"
            value="Scope_1"
            {...getOverrideProps(overrides, "CO2SavingScopeoption0")}
          ></option>
          <option
            children="Scope 2"
            value="Scope_2"
            {...getOverrideProps(overrides, "CO2SavingScopeoption1")}
          ></option>
          <option
            children="Scope 3"
            value="Scope_3"
            {...getOverrideProps(overrides, "CO2SavingScopeoption2")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Required investment"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={RequiredInvestment}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment: value,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.RequiredInvestment ?? value;
            }
            if (errors.RequiredInvestment?.hasError) {
              runValidationTasks("RequiredInvestment", value);
            }
            setRequiredInvestment(value);
          }}
          onBlur={() =>
            runValidationTasks("RequiredInvestment", RequiredInvestment)
          }
          errorMessage={errors.RequiredInvestment?.errorMessage}
          hasError={errors.RequiredInvestment?.hasError}
          {...getOverrideProps(overrides, "RequiredInvestment")}
        ></TextField>
        <TextField
          label="Estimated initiative lifetime"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={EstimatedInitiativeLifetime}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime: value,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.EstimatedInitiativeLifetime ?? value;
            }
            if (errors.EstimatedInitiativeLifetime?.hasError) {
              runValidationTasks("EstimatedInitiativeLifetime", value);
            }
            setEstimatedInitiativeLifetime(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "EstimatedInitiativeLifetime",
              EstimatedInitiativeLifetime
            )
          }
          errorMessage={errors.EstimatedInitiativeLifetime?.errorMessage}
          hasError={errors.EstimatedInitiativeLifetime?.hasError}
          {...getOverrideProps(overrides, "EstimatedInitiativeLifetime")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={StartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate: value,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.StartDate ?? value;
            }
            if (errors.StartDate?.hasError) {
              runValidationTasks("StartDate", value);
            }
            setStartDate(value);
          }}
          onBlur={() => runValidationTasks("StartDate", StartDate)}
          errorMessage={errors.StartDate?.errorMessage}
          hasError={errors.StartDate?.hasError}
          {...getOverrideProps(overrides, "StartDate")}
        ></TextField>
        <TextField
          label="End date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={EndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate: value,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.EndDate ?? value;
            }
            if (errors.EndDate?.hasError) {
              runValidationTasks("EndDate", value);
            }
            setEndDate(value);
          }}
          onBlur={() => runValidationTasks("EndDate", EndDate)}
          errorMessage={errors.EndDate?.errorMessage}
          hasError={errors.EndDate?.hasError}
          {...getOverrideProps(overrides, "EndDate")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <TextField
          label="Annual monetary saving"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={AnnualMonetarySaving}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving: value,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.AnnualMonetarySaving ?? value;
            }
            if (errors.AnnualMonetarySaving?.hasError) {
              runValidationTasks("AnnualMonetarySaving", value);
            }
            setAnnualMonetarySaving(value);
          }}
          onBlur={() =>
            runValidationTasks("AnnualMonetarySaving", AnnualMonetarySaving)
          }
          errorMessage={errors.AnnualMonetarySaving?.errorMessage}
          hasError={errors.AnnualMonetarySaving?.hasError}
          {...getOverrideProps(overrides, "AnnualMonetarySaving")}
        ></TextField>
        <TextField
          label="Payback period"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={PaybackPeriod}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod: value,
                GrossEmissionReductionAchieved,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.PaybackPeriod ?? value;
            }
            if (errors.PaybackPeriod?.hasError) {
              runValidationTasks("PaybackPeriod", value);
            }
            setPaybackPeriod(value);
          }}
          onBlur={() => runValidationTasks("PaybackPeriod", PaybackPeriod)}
          errorMessage={errors.PaybackPeriod?.errorMessage}
          hasError={errors.PaybackPeriod?.hasError}
          {...getOverrideProps(overrides, "PaybackPeriod")}
        ></TextField>
      </Grid>
      <Text
        children="If your reduction initiative has been implemented, enter the gross reduction achieved and the date it was achieved on."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid12")}
      >
        <TextField
          label="Gross emission reduction achieved (tonnes CO2e)"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={GrossEmissionReductionAchieved}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved: value,
                DateAchieved,
              };
              const result = onChange(modelFields);
              value = result?.GrossEmissionReductionAchieved ?? value;
            }
            if (errors.GrossEmissionReductionAchieved?.hasError) {
              runValidationTasks("GrossEmissionReductionAchieved", value);
            }
            setGrossEmissionReductionAchieved(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "GrossEmissionReductionAchieved",
              GrossEmissionReductionAchieved
            )
          }
          errorMessage={errors.GrossEmissionReductionAchieved?.errorMessage}
          hasError={errors.GrossEmissionReductionAchieved?.hasError}
          {...getOverrideProps(overrides, "GrossEmissionReductionAchieved")}
        ></TextField>
        <TextField
          label="Date achieved"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={DateAchieved}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ReductionInitiativeName,
                ReductionInitiativeStatus,
                ReductionInitiativeDescription,
                EmissionReductionUnit,
                EmissionReductionValue,
                EstimatedAnnualCO2Saving,
                CO2SavingScope,
                RequiredInvestment,
                EstimatedInitiativeLifetime,
                StartDate,
                EndDate,
                AnnualMonetarySaving,
                PaybackPeriod,
                GrossEmissionReductionAchieved,
                DateAchieved: value,
              };
              const result = onChange(modelFields);
              value = result?.DateAchieved ?? value;
            }
            if (errors.DateAchieved?.hasError) {
              runValidationTasks("DateAchieved", value);
            }
            setDateAchieved(value);
          }}
          onBlur={() => runValidationTasks("DateAchieved", DateAchieved)}
          errorMessage={errors.DateAchieved?.errorMessage}
          hasError={errors.DateAchieved?.hasError}
          {...getOverrideProps(overrides, "DateAchieved")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || reductionAccountEntryModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || reductionAccountEntryModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
