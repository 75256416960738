import HelpBox from "./HelpBox";

export default function MetricsTargetsDataSetHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Metrics and Targets Records Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to collate records on Metrics and Targets that measure and
          manage your climate-related risks and opportunities. Metrics and
          targets also provide a basis to compare entities within a sector or
          industry.
        </p>
        <p>Follow these steps to create a Metrics and Targets record:</p>
        <ol>
          <li>
            Create a name for the record in the <i>Metrics and Targets data set name</i> field.
          </li>
          <li>Next, enter a <i>Description</i> for this record.</li>
          <li>
            Finally, select the appropriate records from the tables in the center of the screen.
          </li>
          <li>
            Once selected, choose <b>Create</b> or <b>Update</b>.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
