/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ScenarioAnalysis } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ScenarioAnalysisUpdateForm(props) {
  const {
    id: idProp,
    scenarioAnalysis: scenarioAnalysisModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ScenarioName: "",
    ScenarioDescription: "",
    ScenarioAnalysisMethod: "",
    ScenarioTemperatureAlignment: "",
    ScenarioDataSource: "",
    AssociationWithRisks: "",
    AlignmentWithInternationalAgreement: "",
    ScenarioRelevanceDescription: "",
    TimeHorizon: "",
    ScopeOfOperations: "",
    ScopeOfOperationsDetail: "",
  };
  const [ScenarioName, setScenarioName] = React.useState(
    initialValues.ScenarioName
  );
  const [ScenarioDescription, setScenarioDescription] = React.useState(
    initialValues.ScenarioDescription
  );
  const [ScenarioAnalysisMethod, setScenarioAnalysisMethod] = React.useState(
    initialValues.ScenarioAnalysisMethod
  );
  const [ScenarioTemperatureAlignment, setScenarioTemperatureAlignment] =
    React.useState(initialValues.ScenarioTemperatureAlignment);
  const [ScenarioDataSource, setScenarioDataSource] = React.useState(
    initialValues.ScenarioDataSource
  );
  const [AssociationWithRisks, setAssociationWithRisks] = React.useState(
    initialValues.AssociationWithRisks
  );
  const [
    AlignmentWithInternationalAgreement,
    setAlignmentWithInternationalAgreement,
  ] = React.useState(initialValues.AlignmentWithInternationalAgreement);
  const [ScenarioRelevanceDescription, setScenarioRelevanceDescription] =
    React.useState(initialValues.ScenarioRelevanceDescription);
  const [TimeHorizon, setTimeHorizon] = React.useState(
    initialValues.TimeHorizon
  );
  const [ScopeOfOperations, setScopeOfOperations] = React.useState(
    initialValues.ScopeOfOperations
  );
  const [ScopeOfOperationsDetail, setScopeOfOperationsDetail] = React.useState(
    initialValues.ScopeOfOperationsDetail
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = scenarioAnalysisRecord
      ? { ...initialValues, ...scenarioAnalysisRecord }
      : initialValues;
    setScenarioName(cleanValues.ScenarioName);
    setScenarioDescription(cleanValues.ScenarioDescription);
    setScenarioAnalysisMethod(cleanValues.ScenarioAnalysisMethod);
    setScenarioTemperatureAlignment(cleanValues.ScenarioTemperatureAlignment);
    setScenarioDataSource(cleanValues.ScenarioDataSource);
    setAssociationWithRisks(cleanValues.AssociationWithRisks);
    setAlignmentWithInternationalAgreement(
      cleanValues.AlignmentWithInternationalAgreement
    );
    setScenarioRelevanceDescription(cleanValues.ScenarioRelevanceDescription);
    setTimeHorizon(cleanValues.TimeHorizon);
    setScopeOfOperations(cleanValues.ScopeOfOperations);
    setScopeOfOperationsDetail(cleanValues.ScopeOfOperationsDetail);
    setErrors({});
  };
  const [scenarioAnalysisRecord, setScenarioAnalysisRecord] = React.useState(
    scenarioAnalysisModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(ScenarioAnalysis, idProp)
        : scenarioAnalysisModelProp;
      setScenarioAnalysisRecord(record);
    };
    queryData();
  }, [idProp, scenarioAnalysisModelProp]);
  React.useEffect(resetStateValues, [scenarioAnalysisRecord]);
  const validations = {
    ScenarioName: [],
    ScenarioDescription: [],
    ScenarioAnalysisMethod: [],
    ScenarioTemperatureAlignment: [],
    ScenarioDataSource: [],
    AssociationWithRisks: [],
    AlignmentWithInternationalAgreement: [],
    ScenarioRelevanceDescription: [],
    TimeHorizon: [],
    ScopeOfOperations: [],
    ScopeOfOperationsDetail: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ScenarioName,
          ScenarioDescription,
          ScenarioAnalysisMethod,
          ScenarioTemperatureAlignment,
          ScenarioDataSource,
          AssociationWithRisks,
          AlignmentWithInternationalAgreement,
          ScenarioRelevanceDescription,
          TimeHorizon,
          ScopeOfOperations,
          ScopeOfOperationsDetail,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            ScenarioAnalysis.copyOf(scenarioAnalysisRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ScenarioAnalysisUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate-related Scenario"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 22 b i 1-7."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Update this record that defines a climate-related scenario used for your scenario analysis."
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Scenario name (e.g. 2027 low warming scenario)"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName: value,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource,
              AssociationWithRisks,
              AlignmentWithInternationalAgreement,
              ScenarioRelevanceDescription,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioName ?? value;
          }
          if (errors.ScenarioName?.hasError) {
            runValidationTasks("ScenarioName", value);
          }
          setScenarioName(value);
        }}
        onBlur={() => runValidationTasks("ScenarioName", ScenarioName)}
        errorMessage={errors.ScenarioName?.errorMessage}
        hasError={errors.ScenarioName?.hasError}
        {...getOverrideProps(overrides, "ScenarioName")}
      ></TextField>
      <TextAreaField
        label="Scenario description"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription: value,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource,
              AssociationWithRisks,
              AlignmentWithInternationalAgreement,
              ScenarioRelevanceDescription,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioDescription ?? value;
          }
          if (errors.ScenarioDescription?.hasError) {
            runValidationTasks("ScenarioDescription", value);
          }
          setScenarioDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ScenarioDescription", ScenarioDescription)
        }
        errorMessage={errors.ScenarioDescription?.errorMessage}
        hasError={errors.ScenarioDescription?.hasError}
        {...getOverrideProps(overrides, "ScenarioDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Scenario analysis method"
          placeholder="Please select an option"
          isDisabled={false}
          value={ScenarioAnalysisMethod}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod: value,
                ScenarioTemperatureAlignment,
                ScenarioDataSource,
                AssociationWithRisks,
                AlignmentWithInternationalAgreement,
                ScenarioRelevanceDescription,
                TimeHorizon,
                ScopeOfOperations,
                ScopeOfOperationsDetail,
              };
              const result = onChange(modelFields);
              value = result?.ScenarioAnalysisMethod ?? value;
            }
            if (errors.ScenarioAnalysisMethod?.hasError) {
              runValidationTasks("ScenarioAnalysisMethod", value);
            }
            setScenarioAnalysisMethod(value);
          }}
          onBlur={() =>
            runValidationTasks("ScenarioAnalysisMethod", ScenarioAnalysisMethod)
          }
          errorMessage={errors.ScenarioAnalysisMethod?.errorMessage}
          hasError={errors.ScenarioAnalysisMethod?.hasError}
          {...getOverrideProps(overrides, "ScenarioAnalysisMethod")}
        >
          <option
            children="Qualitative"
            value="Qualitative"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption0")}
          ></option>
          <option
            children="Quantitative"
            value="Quantitative"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption1")}
          ></option>
          <option
            children="Both"
            value="Both"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption2")}
          ></option>
        </SelectField>
        <SelectField
          label="Temperature alignment"
          placeholder="Please select an option"
          isDisabled={false}
          value={ScenarioTemperatureAlignment}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod,
                ScenarioTemperatureAlignment: value,
                ScenarioDataSource,
                AssociationWithRisks,
                AlignmentWithInternationalAgreement,
                ScenarioRelevanceDescription,
                TimeHorizon,
                ScopeOfOperations,
                ScopeOfOperationsDetail,
              };
              const result = onChange(modelFields);
              value = result?.ScenarioTemperatureAlignment ?? value;
            }
            if (errors.ScenarioTemperatureAlignment?.hasError) {
              runValidationTasks("ScenarioTemperatureAlignment", value);
            }
            setScenarioTemperatureAlignment(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ScenarioTemperatureAlignment",
              ScenarioTemperatureAlignment
            )
          }
          errorMessage={errors.ScenarioTemperatureAlignment?.errorMessage}
          hasError={errors.ScenarioTemperatureAlignment?.hasError}
          {...getOverrideProps(overrides, "ScenarioTemperatureAlignment")}
        >
          <option
            children="One point five degrees celsius"
            value="OnePointFiveDegreesCelsius"
            {...getOverrideProps(
              overrides,
              "ScenarioTemperatureAlignmentoption0"
            )}
          ></option>
          <option
            children="One point six to two degrees celsius"
            value="OnePointSixToTwoDegreesCelsius"
            {...getOverrideProps(
              overrides,
              "ScenarioTemperatureAlignmentoption1"
            )}
          ></option>
          <option
            children="Two point one to three degrees celsius"
            value="TwoPointOneToThreeDegreesCelsius"
            {...getOverrideProps(
              overrides,
              "ScenarioTemperatureAlignmentoption2"
            )}
          ></option>
          <option
            children="Three point one to four degrees celsius"
            value="ThreePointOneToFourDegreesCelsius"
            {...getOverrideProps(
              overrides,
              "ScenarioTemperatureAlignmentoption3"
            )}
          ></option>
          <option
            children="Four point one degrees and above"
            value="FourPointOneDegreesAndAbove"
            {...getOverrideProps(
              overrides,
              "ScenarioTemperatureAlignmentoption4"
            )}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Scenario data source"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioDataSource}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource: value,
              AssociationWithRisks,
              AlignmentWithInternationalAgreement,
              ScenarioRelevanceDescription,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioDataSource ?? value;
          }
          if (errors.ScenarioDataSource?.hasError) {
            runValidationTasks("ScenarioDataSource", value);
          }
          setScenarioDataSource(value);
        }}
        onBlur={() =>
          runValidationTasks("ScenarioDataSource", ScenarioDataSource)
        }
        errorMessage={errors.ScenarioDataSource?.errorMessage}
        hasError={errors.ScenarioDataSource?.hasError}
        {...getOverrideProps(overrides, "ScenarioDataSource")}
      ></TextAreaField>
      <SelectField
        label="Association with risks"
        placeholder="Please select an option"
        isDisabled={false}
        value={AssociationWithRisks}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource,
              AssociationWithRisks: value,
              AlignmentWithInternationalAgreement,
              ScenarioRelevanceDescription,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail,
            };
            const result = onChange(modelFields);
            value = result?.AssociationWithRisks ?? value;
          }
          if (errors.AssociationWithRisks?.hasError) {
            runValidationTasks("AssociationWithRisks", value);
          }
          setAssociationWithRisks(value);
        }}
        onBlur={() =>
          runValidationTasks("AssociationWithRisks", AssociationWithRisks)
        }
        errorMessage={errors.AssociationWithRisks?.errorMessage}
        hasError={errors.AssociationWithRisks?.hasError}
        {...getOverrideProps(overrides, "AssociationWithRisks")}
      >
        <option
          children="Physical risk"
          value="PhysicalRisk"
          {...getOverrideProps(overrides, "AssociationWithRisksoption0")}
        ></option>
        <option
          children="Transition risk"
          value="TransitionRisk"
          {...getOverrideProps(overrides, "AssociationWithRisksoption1")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Alignment with international agreement"
        isRequired={false}
        isReadOnly={false}
        value={AlignmentWithInternationalAgreement}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource,
              AssociationWithRisks,
              AlignmentWithInternationalAgreement: value,
              ScenarioRelevanceDescription,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail,
            };
            const result = onChange(modelFields);
            value = result?.AlignmentWithInternationalAgreement ?? value;
          }
          if (errors.AlignmentWithInternationalAgreement?.hasError) {
            runValidationTasks("AlignmentWithInternationalAgreement", value);
          }
          setAlignmentWithInternationalAgreement(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AlignmentWithInternationalAgreement",
            AlignmentWithInternationalAgreement
          )
        }
        errorMessage={errors.AlignmentWithInternationalAgreement?.errorMessage}
        hasError={errors.AlignmentWithInternationalAgreement?.hasError}
        {...getOverrideProps(overrides, "AlignmentWithInternationalAgreement")}
      ></TextAreaField>
      <TextAreaField
        label="Scenario relevance description to assessing resilience to climate-related changes, developments or uncertainties"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioRelevanceDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource,
              AssociationWithRisks,
              AlignmentWithInternationalAgreement,
              ScenarioRelevanceDescription: value,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioRelevanceDescription ?? value;
          }
          if (errors.ScenarioRelevanceDescription?.hasError) {
            runValidationTasks("ScenarioRelevanceDescription", value);
          }
          setScenarioRelevanceDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ScenarioRelevanceDescription",
            ScenarioRelevanceDescription
          )
        }
        errorMessage={errors.ScenarioRelevanceDescription?.errorMessage}
        hasError={errors.ScenarioRelevanceDescription?.hasError}
        {...getOverrideProps(overrides, "ScenarioRelevanceDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <SelectField
          label="Climate planning horizon used in analysis"
          placeholder="Please select an option"
          isDisabled={false}
          value={TimeHorizon}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod,
                ScenarioTemperatureAlignment,
                ScenarioDataSource,
                AssociationWithRisks,
                AlignmentWithInternationalAgreement,
                ScenarioRelevanceDescription,
                TimeHorizon: value,
                ScopeOfOperations,
                ScopeOfOperationsDetail,
              };
              const result = onChange(modelFields);
              value = result?.TimeHorizon ?? value;
            }
            if (errors.TimeHorizon?.hasError) {
              runValidationTasks("TimeHorizon", value);
            }
            setTimeHorizon(value);
          }}
          onBlur={() => runValidationTasks("TimeHorizon", TimeHorizon)}
          errorMessage={errors.TimeHorizon?.errorMessage}
          hasError={errors.TimeHorizon?.hasError}
          {...getOverrideProps(overrides, "TimeHorizon")}
        >
          <option
            children="Current reporting period"
            value="CurrentReportingPeriod"
            {...getOverrideProps(overrides, "TimeHorizonoption0")}
          ></option>
          <option
            children="Next reporting period"
            value="NextReportingPeriod"
            {...getOverrideProps(overrides, "TimeHorizonoption1")}
          ></option>
          <option
            children="Short term"
            value="ShortTerm"
            {...getOverrideProps(overrides, "TimeHorizonoption2")}
          ></option>
          <option
            children="Medium term"
            value="MediumTerm"
            {...getOverrideProps(overrides, "TimeHorizonoption3")}
          ></option>
          <option
            children="Long term"
            value="LongTerm"
            {...getOverrideProps(overrides, "TimeHorizonoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Scope of operations"
          placeholder="Please select an option"
          isDisabled={false}
          value={ScopeOfOperations}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod,
                ScenarioTemperatureAlignment,
                ScenarioDataSource,
                AssociationWithRisks,
                AlignmentWithInternationalAgreement,
                ScenarioRelevanceDescription,
                TimeHorizon,
                ScopeOfOperations: value,
                ScopeOfOperationsDetail,
              };
              const result = onChange(modelFields);
              value = result?.ScopeOfOperations ?? value;
            }
            if (errors.ScopeOfOperations?.hasError) {
              runValidationTasks("ScopeOfOperations", value);
            }
            setScopeOfOperations(value);
          }}
          onBlur={() =>
            runValidationTasks("ScopeOfOperations", ScopeOfOperations)
          }
          errorMessage={errors.ScopeOfOperations?.errorMessage}
          hasError={errors.ScopeOfOperations?.hasError}
          {...getOverrideProps(overrides, "ScopeOfOperations")}
        >
          <option
            children="Company wide"
            value="CompanyWide"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption0")}
          ></option>
          <option
            children="Value chain segment"
            value="ValueChainSegment"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption1")}
          ></option>
          <option
            children="Business division"
            value="BusinessDivision"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption2")}
          ></option>
          <option
            children="Facility"
            value="Facility"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption3")}
          ></option>
          <option
            children="Country or area"
            value="CountryOrArea"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption4")}
          ></option>
          <option
            children="Product level"
            value="ProductLevel"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption5")}
          ></option>
          <option
            children="Portfolio level"
            value="PortfolioLevel"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption6")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "ScopeOfOperationsoption7")}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Scope of operations detail"
        isRequired={false}
        isReadOnly={false}
        value={ScopeOfOperationsDetail}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioTemperatureAlignment,
              ScenarioDataSource,
              AssociationWithRisks,
              AlignmentWithInternationalAgreement,
              ScenarioRelevanceDescription,
              TimeHorizon,
              ScopeOfOperations,
              ScopeOfOperationsDetail: value,
            };
            const result = onChange(modelFields);
            value = result?.ScopeOfOperationsDetail ?? value;
          }
          if (errors.ScopeOfOperationsDetail?.hasError) {
            runValidationTasks("ScopeOfOperationsDetail", value);
          }
          setScopeOfOperationsDetail(value);
        }}
        onBlur={() =>
          runValidationTasks("ScopeOfOperationsDetail", ScopeOfOperationsDetail)
        }
        errorMessage={errors.ScopeOfOperationsDetail?.errorMessage}
        hasError={errors.ScopeOfOperationsDetail?.hasError}
        {...getOverrideProps(overrides, "ScopeOfOperationsDetail")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || scenarioAnalysisModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || scenarioAnalysisModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
