import HelpBox from "./HelpBox";

export default function ReductionAcountEntryHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Greenhouse Gas Emission Reduction Transaction Help</HelpBox.Header>
            <HelpBox.Content>
                <p>This form allows you to create a record of a transaction that reduces greenhouse gases (e.g. installing solar power, reducing refrigerant leaks).</p>
                <p>A reduction transaction can follow a lifecycle, starting with investigating, to planning, then implementing, then finally to implemented, as follows:</p>
                <ul>
                    <li><b>Investigating</b> - currently looking at options for a reduction transaction, for example conducting feasibility studies into installing rooftop solar on buildings.</li>
                    <li><b>Planning</b> - planning has started for implementing the reduction transaction. Data captured at this point can be used in transistion planning tradeoff analysis, to determine cost-effective ways to avoid emissions, and reduce overall scoped emissions over time.</li>
                    <li><b>Implementing</b> - the reduction transaction is now implementing but not yet complete, and some preliminary data may be available on how many emissions it avoids. This can help fine tune emission reduction analyses and transition planning.</li>
                    <li><b>Implemented</b> - the reduction transaction has been implemented, and full data can be recorded on the emissions it reduced. The reduced emissions should be factored in to the scoped emissions recorded as part of a climate-related disclosure.</li>
                </ul>
                <p>It is recommended you record a complete set of data for all reduction initiatives across these lifecycles, as this data can be used in to create business value as follows:</p>
                <ul>
                    <li>related reduction initiatives can be grouped into specific ledgers for planning purposes (e.g. create a ledger with all solar reduction intiatives)</li>
                    <li>reduction initiatives can be linked to Targets in your disclosure</li>
                    <li>this allows for tracking and analysis of reduction initiatives (e.g. create different ledgers with different reduction initiatives for tradeoff analysis of return on investment per kg CO2e reduced; analyse performance against reduction plans and cost per initiative)</li>
                </ul>
                <p>Follow these steps to create an <i>Greenhouse Gas Emission Reduction Transaction</i>:</p>
                <ol>
                    <li>Complete all fields in the form on the left hand side of the screen</li>
                    <li>Once the record is created you can edit it, and upload any evidence documenting the record</li>
                    <li>Once all data is entered, click the Create or Update button at the bottom left</li>
                    <li>You can also click Reset to clear any data entered into the form.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
