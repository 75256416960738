import HelpBox from "../../shared/content/HelpBox";

export default function FinancialEffectHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Current and Anticipated Financial Effects Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes the financial effects that arise from your selected climate-related risk / opportunity.
        </p>
        <p>
          If you have sufficient data, IFRS S2 requires disclosure of several different types of financial effect for each risk or opportunity, over different timeframes and reporting periods. This may require you to create more than one <b>Financial Effect</b> record per risk or opportunity.
        </p>
        <p>Follow these steps to define your current and anticipated financial effects:</p>
        <ol type="1">
          <li>
            Enter a name for the financial effect that is linked to your risk or opportunity
          </li>
          <li>
            Select <b>Current reporting period</b> from the <i>Period financial effect applies to</i> dropdown menu, then select <b>Financial position</b> from the <i>Financial effect type</i> dropdown menu.
          </li>
          <li>
            Enter data on the financial effect by specifying a value in the <i>Value of financial effect</i> field, and the units used to measure this in the <i>Unit of measure of financial effect</i> field (e.g. 1000000 USD). You can also specify a range using the <i>Value of financial effect (min)</i> and <i>Value of financial effect (max)</i> fields.
          </li>
          <p>Depending on whether you have enough data, you may need to repeat steps 1-3 with separate entries for: <b>Financial performance</b> and <b>Cashflows</b> (IFRS S2 paragraph 16 a.)</p>
          <li>
            If you have financial effects that will impact assets and liabilities, create a new <b>Financial Effect</b> record and select <b>Next reporting period</b> from the <i>Period financial effect applies to</i> dropdown menu then <b>Carrying amount of assets</b> from the <i>Financial effect type</i> dropdown menu, and record the values or range of financial effect. Repeat this for the <b>Next reporting period</b> and <b>Carrying amount of liabilities</b> <i>Financial effect type</i> (IFRS S2 paragraph 16 b.)
          </li>
          <li>
            Record anticipated effects for the risk/opportunity. Create additional records for <b>Short term</b>, <b>Medium term</b> and <b>Long term</b> from the <i>Period financial effect applies to</i> dropdown menu, and <b>Financial postion</b> from the <i>Financial effect type</i> menu, then fill in the value of the anticipated financial effects in the <i>Value of financial effect</i> field. Your answer should take into account your strategy to manage climate-related risks and opportunities, and specifically consider your investment and disposal plans (including plans that aren't contractual committments), and any planned sources of funding to implement your strategy (IFRS S2 paragraph 16 c.)
          </li>
          <li>
            If you have sufficient data, repeat the previous step for <b>Financial performance</b> from the <i>Period financial effect applies to</i> dropdown menu. Your answer should take into account your strategy to manage climate-related risks and opportunities (IFRS S2 paragraph 16 d.)
          </li>
          <li>
            Create separate records for the anticipated effects on <b>cashflows</b> by selecting <b>Short term</b>, <b>Medium term</b> and <b>Long term</b> from the <i>Period financial effect applies to</i> dropdown menu, and select <b>Cashflows</b> from the <i>Financial effect type</i> dropdown menu, then fill in the value(s) for the anticipated financial effects on cashflows. Your answer should take into account your strategy to manage climate-related risks and opportunities (IFRS S2 paragraph 16 d.)
          </li>
          <li>
            You also have space to provide additional descriptive data about the quantitative financial effects arising from this climate-related risk and opportunity.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
        <p>
          Note that if you cannot provide quantitative data about these current and anticipated future effects from your climate-related risks and opportunities, you need to provide an explanation for why you are not using quantitative data, and include a qualitative description of the current and anticipated financial effects in the bottom field (e.g. line items, totals and subtotals within the related financial statements that are likely to be affected, or have been affected, by that climate-related risk or opportunity).
        </p>
        <p>
          Note also that if you cannot provide data <i>separately</i> for each climate-related risk or opportunity, you can exit this form and enter aggregated financial effects data in the <b>Aggregated Financial Effect from Risks and Opportunities</b> table.
        </p>
      </HelpBox.Content>
    </HelpBox >
  );
}
