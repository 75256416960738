import HelpBox from "../../shared/content/HelpBox";

export default function RiskManagementProcessesBaseHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Risk Management Process and Policy Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to collate records on Risk Management Process and Policy, which are used to identify, assess, prioritise and monitor climate-related risks and opportunities, and how these processes are integrated into existing organisational risk
          management processes.
        </p>
        <p>You need to create four separate process records for climate-related Risks covering four process types: <i>Identify</i>, <i>Assess</i>, <i>Prioritise</i> and <i>Monitor</i>, then repeat this for climate-related Opportunities. You should create eight records in total.</p>
        <ol type="1">
          <li>
            Select <i>Risk</i> or <i>Opportunity</i> from the first drop down menu entitled <i>Select if this process applies to a Risk or Opportunity</i>.
          </li>
          <li>
            Select the type of process from the <i>Process type</i> menu. You will need to create records for the four process types required - <i>Identify</i>, <i>Assess</i>, <i>Prioritise</i>.
          </li>
          <li>
            Add a description of each process for each record.
          </li>
          <li>
            For each of the four records applied to the <i>Risk</i> selection, you will also need to add data in the Inputs and Parameters fields. This is not required in the four records applied to the <i>Opportunity</i> selection.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally click the <b>Submit</b> or <b>Update</b> buttons.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
