import HelpBox from "../../shared/content/HelpBox";

export default function ReportingPeriodHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Reporting Period</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    The Reporting Period defines the timeframe the climate disclosure is valid for. Each jurisdication defines a Reporting Period differently, usually in relation to the Reporting Entity's financial year and annual reporting periods.
                </p>
                <p>Follow these steps to define your climate disclosure reporting period:</p>
                <ol>
                    <li>
                        Enter a name and description for the reporting period.
                    </li>
                    <li>
                        Select a <i>Start date</i> and <i>End date</i> to define the period.
                    </li>
                    <li>
                        Enter the year the reporting period applies to.
                    </li>
                    <li>
                        Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
                    </li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
