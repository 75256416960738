/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { StrategyDecisionMakingData } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function StrategyDecisionMakingDataStrategyPlanProgressUpdateForm(
  props
) {
  const {
    id: idProp,
    strategyDecisionMakingData: strategyDecisionMakingDataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    PlanProgressName: "",
    QualitativePlanProgressDescription: "",
    QuantitativePlanProgressDescription: "",
    StrategyPlanProgressValue: "",
    StrategyPlanProgressUnit: "",
  };
  const [PlanProgressName, setPlanProgressName] = React.useState(
    initialValues.PlanProgressName
  );
  const [
    QualitativePlanProgressDescription,
    setQualitativePlanProgressDescription,
  ] = React.useState(initialValues.QualitativePlanProgressDescription);
  const [
    QuantitativePlanProgressDescription,
    setQuantitativePlanProgressDescription,
  ] = React.useState(initialValues.QuantitativePlanProgressDescription);
  const [StrategyPlanProgressValue, setStrategyPlanProgressValue] =
    React.useState(initialValues.StrategyPlanProgressValue);
  const [StrategyPlanProgressUnit, setStrategyPlanProgressUnit] =
    React.useState(initialValues.StrategyPlanProgressUnit);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = strategyDecisionMakingDataRecord
      ? { ...initialValues, ...strategyDecisionMakingDataRecord }
      : initialValues;
    setPlanProgressName(cleanValues.PlanProgressName);
    setQualitativePlanProgressDescription(
      cleanValues.QualitativePlanProgressDescription
    );
    setQuantitativePlanProgressDescription(
      cleanValues.QuantitativePlanProgressDescription
    );
    setStrategyPlanProgressValue(cleanValues.StrategyPlanProgressValue);
    setStrategyPlanProgressUnit(cleanValues.StrategyPlanProgressUnit);
    setErrors({});
  };
  const [
    strategyDecisionMakingDataRecord,
    setStrategyDecisionMakingDataRecord,
  ] = React.useState(strategyDecisionMakingDataModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(StrategyDecisionMakingData, idProp)
        : strategyDecisionMakingDataModelProp;
      setStrategyDecisionMakingDataRecord(record);
    };
    queryData();
  }, [idProp, strategyDecisionMakingDataModelProp]);
  React.useEffect(resetStateValues, [strategyDecisionMakingDataRecord]);
  const validations = {
    PlanProgressName: [],
    QualitativePlanProgressDescription: [],
    QuantitativePlanProgressDescription: [],
    StrategyPlanProgressValue: [],
    StrategyPlanProgressUnit: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          PlanProgressName,
          QualitativePlanProgressDescription,
          QuantitativePlanProgressDescription,
          StrategyPlanProgressValue,
          StrategyPlanProgressUnit,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            StrategyDecisionMakingData.copyOf(
              strategyDecisionMakingDataRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "StrategyDecisionMakingDataStrategyPlanProgressUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Progress of Plans for Strategy, Decision Making and Targets"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 14 c."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update this record that defines quantitative and qualitative data about the progress of plans disclosed in previous reporting periods."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Name of progress towards plan (e.g. 2027 progress towards transition plan)"
        isRequired={false}
        isReadOnly={false}
        value={PlanProgressName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PlanProgressName: value,
              QualitativePlanProgressDescription,
              QuantitativePlanProgressDescription,
              StrategyPlanProgressValue,
              StrategyPlanProgressUnit,
            };
            const result = onChange(modelFields);
            value = result?.PlanProgressName ?? value;
          }
          if (errors.PlanProgressName?.hasError) {
            runValidationTasks("PlanProgressName", value);
          }
          setPlanProgressName(value);
        }}
        onBlur={() => runValidationTasks("PlanProgressName", PlanProgressName)}
        errorMessage={errors.PlanProgressName?.errorMessage}
        hasError={errors.PlanProgressName?.hasError}
        {...getOverrideProps(overrides, "PlanProgressName")}
      ></TextField>
      <TextAreaField
        label="Qualitative description of progress of plans disclosed in the previous reporting period, for how you planned to respond to climate-related risks and opportunities in your strategy, decision making, and climate-related targets"
        isRequired={false}
        isReadOnly={false}
        value={QualitativePlanProgressDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PlanProgressName,
              QualitativePlanProgressDescription: value,
              QuantitativePlanProgressDescription,
              StrategyPlanProgressValue,
              StrategyPlanProgressUnit,
            };
            const result = onChange(modelFields);
            value = result?.QualitativePlanProgressDescription ?? value;
          }
          if (errors.QualitativePlanProgressDescription?.hasError) {
            runValidationTasks("QualitativePlanProgressDescription", value);
          }
          setQualitativePlanProgressDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "QualitativePlanProgressDescription",
            QualitativePlanProgressDescription
          )
        }
        errorMessage={errors.QualitativePlanProgressDescription?.errorMessage}
        hasError={errors.QualitativePlanProgressDescription?.hasError}
        {...getOverrideProps(overrides, "QualitativePlanProgressDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Quantitative description of progress of plans disclosed in the previous reporting period, for how you planned to respond to climate-related risks and opportunities in your strategy, decision making, and climate-related targets"
        isRequired={false}
        isReadOnly={false}
        value={QuantitativePlanProgressDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PlanProgressName,
              QualitativePlanProgressDescription,
              QuantitativePlanProgressDescription: value,
              StrategyPlanProgressValue,
              StrategyPlanProgressUnit,
            };
            const result = onChange(modelFields);
            value = result?.QuantitativePlanProgressDescription ?? value;
          }
          if (errors.QuantitativePlanProgressDescription?.hasError) {
            runValidationTasks("QuantitativePlanProgressDescription", value);
          }
          setQuantitativePlanProgressDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "QuantitativePlanProgressDescription",
            QuantitativePlanProgressDescription
          )
        }
        errorMessage={errors.QuantitativePlanProgressDescription?.errorMessage}
        hasError={errors.QuantitativePlanProgressDescription?.hasError}
        {...getOverrideProps(overrides, "QuantitativePlanProgressDescription")}
      ></TextAreaField>
      <Text
        children="Please provide additional quantitative measure of progress if possible."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Quantitative value measuring progress"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={StrategyPlanProgressValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                PlanProgressName,
                QualitativePlanProgressDescription,
                QuantitativePlanProgressDescription,
                StrategyPlanProgressValue: value,
                StrategyPlanProgressUnit,
              };
              const result = onChange(modelFields);
              value = result?.StrategyPlanProgressValue ?? value;
            }
            if (errors.StrategyPlanProgressValue?.hasError) {
              runValidationTasks("StrategyPlanProgressValue", value);
            }
            setStrategyPlanProgressValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "StrategyPlanProgressValue",
              StrategyPlanProgressValue
            )
          }
          errorMessage={errors.StrategyPlanProgressValue?.errorMessage}
          hasError={errors.StrategyPlanProgressValue?.hasError}
          {...getOverrideProps(overrides, "StrategyPlanProgressValue")}
        ></TextField>
        <TextField
          label="Unit of measure of progress towards plan"
          isRequired={false}
          isReadOnly={false}
          value={StrategyPlanProgressUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                PlanProgressName,
                QualitativePlanProgressDescription,
                QuantitativePlanProgressDescription,
                StrategyPlanProgressValue,
                StrategyPlanProgressUnit: value,
              };
              const result = onChange(modelFields);
              value = result?.StrategyPlanProgressUnit ?? value;
            }
            if (errors.StrategyPlanProgressUnit?.hasError) {
              runValidationTasks("StrategyPlanProgressUnit", value);
            }
            setStrategyPlanProgressUnit(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "StrategyPlanProgressUnit",
              StrategyPlanProgressUnit
            )
          }
          errorMessage={errors.StrategyPlanProgressUnit?.errorMessage}
          hasError={errors.StrategyPlanProgressUnit?.hasError}
          {...getOverrideProps(overrides, "StrategyPlanProgressUnit")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || strategyDecisionMakingDataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || strategyDecisionMakingDataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
