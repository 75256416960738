/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedMetrics } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedMetricsIndustryMetricsCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    MetricDataSetName: "",
    DisclosureCode: "",
    DisclosureTopic: "",
    MetricCode: "",
    Metric: "",
    DiscussionAnalysis: "",
    MetricUnitOfMeasure: "",
    MetricValue: "",
    IndustryCode: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [MetricDataSetName, setMetricDataSetName] = React.useState(
    initialValues.MetricDataSetName
  );
  const [DisclosureCode, setDisclosureCode] = React.useState(
    initialValues.DisclosureCode
  );
  const [DisclosureTopic, setDisclosureTopic] = React.useState(
    initialValues.DisclosureTopic
  );
  const [MetricCode, setMetricCode] = React.useState(initialValues.MetricCode);
  const [Metric, setMetric] = React.useState(initialValues.Metric);
  const [DiscussionAnalysis, setDiscussionAnalysis] = React.useState(
    initialValues.DiscussionAnalysis
  );
  const [MetricUnitOfMeasure, setMetricUnitOfMeasure] = React.useState(
    initialValues.MetricUnitOfMeasure
  );
  const [MetricValue, setMetricValue] = React.useState(
    initialValues.MetricValue
  );
  const [IndustryCode, setIndustryCode] = React.useState(
    initialValues.IndustryCode
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setMetricDataSetName(initialValues.MetricDataSetName);
    setDisclosureCode(initialValues.DisclosureCode);
    setDisclosureTopic(initialValues.DisclosureTopic);
    setMetricCode(initialValues.MetricCode);
    setMetric(initialValues.Metric);
    setDiscussionAnalysis(initialValues.DiscussionAnalysis);
    setMetricUnitOfMeasure(initialValues.MetricUnitOfMeasure);
    setMetricValue(initialValues.MetricValue);
    setIndustryCode(initialValues.IndustryCode);
    setErrors({});
  };
  const validations = {
    MetricDataSetName: [],
    DisclosureCode: [],
    DisclosureTopic: [],
    MetricCode: [],
    Metric: [],
    DiscussionAnalysis: [],
    MetricUnitOfMeasure: [],
    MetricValue: [],
    IndustryCode: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          MetricDataSetName,
          DisclosureCode,
          DisclosureTopic,
          MetricCode,
          Metric,
          DiscussionAnalysis,
          MetricUnitOfMeasure,
          MetricValue,
          IndustryCode,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateRelatedMetrics(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedMetricsIndustryMetricsCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Metrics - Industry-based Metric"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 32."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record describing an industry-based metric associated with one or more particular business models, activities or other common features that characterise participation in your industry."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Industry-based metric record name"
        isRequired={false}
        isReadOnly={false}
        value={MetricDataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName: value,
              DisclosureCode,
              DisclosureTopic,
              MetricCode,
              Metric,
              DiscussionAnalysis,
              MetricUnitOfMeasure,
              MetricValue,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MetricDataSetName ?? value;
          }
          if (errors.MetricDataSetName?.hasError) {
            runValidationTasks("MetricDataSetName", value);
          }
          setMetricDataSetName(value);
        }}
        onBlur={() =>
          runValidationTasks("MetricDataSetName", MetricDataSetName)
        }
        errorMessage={errors.MetricDataSetName?.errorMessage}
        hasError={errors.MetricDataSetName?.hasError}
        {...getOverrideProps(overrides, "MetricDataSetName")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Disclosure code"
          isRequired={false}
          isReadOnly={false}
          value={DisclosureCode}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                DisclosureCode: value,
                DisclosureTopic,
                MetricCode,
                Metric,
                DiscussionAnalysis,
                MetricUnitOfMeasure,
                MetricValue,
                IndustryCode,
              };
              const result = onChange(modelFields);
              value = result?.DisclosureCode ?? value;
            }
            if (errors.DisclosureCode?.hasError) {
              runValidationTasks("DisclosureCode", value);
            }
            setDisclosureCode(value);
          }}
          onBlur={() => runValidationTasks("DisclosureCode", DisclosureCode)}
          errorMessage={errors.DisclosureCode?.errorMessage}
          hasError={errors.DisclosureCode?.hasError}
          {...getOverrideProps(overrides, "DisclosureCode")}
        ></TextField>
        <TextField
          label="Disclosure topic"
          isRequired={false}
          isReadOnly={false}
          value={DisclosureTopic}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                DisclosureCode,
                DisclosureTopic: value,
                MetricCode,
                Metric,
                DiscussionAnalysis,
                MetricUnitOfMeasure,
                MetricValue,
                IndustryCode,
              };
              const result = onChange(modelFields);
              value = result?.DisclosureTopic ?? value;
            }
            if (errors.DisclosureTopic?.hasError) {
              runValidationTasks("DisclosureTopic", value);
            }
            setDisclosureTopic(value);
          }}
          onBlur={() => runValidationTasks("DisclosureTopic", DisclosureTopic)}
          errorMessage={errors.DisclosureTopic?.errorMessage}
          hasError={errors.DisclosureTopic?.hasError}
          {...getOverrideProps(overrides, "DisclosureTopic")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Metric code"
          isRequired={false}
          isReadOnly={false}
          value={MetricCode}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                DisclosureCode,
                DisclosureTopic,
                MetricCode: value,
                Metric,
                DiscussionAnalysis,
                MetricUnitOfMeasure,
                MetricValue,
                IndustryCode,
              };
              const result = onChange(modelFields);
              value = result?.MetricCode ?? value;
            }
            if (errors.MetricCode?.hasError) {
              runValidationTasks("MetricCode", value);
            }
            setMetricCode(value);
          }}
          onBlur={() => runValidationTasks("MetricCode", MetricCode)}
          errorMessage={errors.MetricCode?.errorMessage}
          hasError={errors.MetricCode?.hasError}
          {...getOverrideProps(overrides, "MetricCode")}
        ></TextField>
        <TextField
          label="Metric"
          isRequired={false}
          isReadOnly={false}
          value={Metric}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                DisclosureCode,
                DisclosureTopic,
                MetricCode,
                Metric: value,
                DiscussionAnalysis,
                MetricUnitOfMeasure,
                MetricValue,
                IndustryCode,
              };
              const result = onChange(modelFields);
              value = result?.Metric ?? value;
            }
            if (errors.Metric?.hasError) {
              runValidationTasks("Metric", value);
            }
            setMetric(value);
          }}
          onBlur={() => runValidationTasks("Metric", Metric)}
          errorMessage={errors.Metric?.errorMessage}
          hasError={errors.Metric?.hasError}
          {...getOverrideProps(overrides, "Metric")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Discussion analysis"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              DisclosureCode,
              DisclosureTopic,
              MetricCode,
              Metric,
              DiscussionAnalysis: value,
              MetricUnitOfMeasure,
              MetricValue,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.DiscussionAnalysis ?? value;
          }
          if (errors.DiscussionAnalysis?.hasError) {
            runValidationTasks("DiscussionAnalysis", value);
          }
          setDiscussionAnalysis(value);
        }}
        onBlur={() =>
          runValidationTasks("DiscussionAnalysis", DiscussionAnalysis)
        }
        errorMessage={errors.DiscussionAnalysis?.errorMessage}
        hasError={errors.DiscussionAnalysis?.hasError}
        	value={DiscussionAnalysis} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "DiscussionAnalysis")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Metric unit of measure"
          isRequired={false}
          isReadOnly={false}
          value={MetricUnitOfMeasure}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                DisclosureCode,
                DisclosureTopic,
                MetricCode,
                Metric,
                DiscussionAnalysis,
                MetricUnitOfMeasure: value,
                MetricValue,
                IndustryCode,
              };
              const result = onChange(modelFields);
              value = result?.MetricUnitOfMeasure ?? value;
            }
            if (errors.MetricUnitOfMeasure?.hasError) {
              runValidationTasks("MetricUnitOfMeasure", value);
            }
            setMetricUnitOfMeasure(value);
          }}
          onBlur={() =>
            runValidationTasks("MetricUnitOfMeasure", MetricUnitOfMeasure)
          }
          errorMessage={errors.MetricUnitOfMeasure?.errorMessage}
          hasError={errors.MetricUnitOfMeasure?.hasError}
          {...getOverrideProps(overrides, "MetricUnitOfMeasure")}
        ></TextField>
        <TextField
          label="Metric value"
          isRequired={false}
          isReadOnly={false}
          value={MetricValue}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                MetricDataSetName,
                DisclosureCode,
                DisclosureTopic,
                MetricCode,
                Metric,
                DiscussionAnalysis,
                MetricUnitOfMeasure,
                MetricValue: value,
                IndustryCode,
              };
              const result = onChange(modelFields);
              value = result?.MetricValue ?? value;
            }
            if (errors.MetricValue?.hasError) {
              runValidationTasks("MetricValue", value);
            }
            setMetricValue(value);
          }}
          onBlur={() => runValidationTasks("MetricValue", MetricValue)}
          errorMessage={errors.MetricValue?.errorMessage}
          hasError={errors.MetricValue?.hasError}
          {...getOverrideProps(overrides, "MetricValue")}
        ></TextField>
      </Grid>
      <TextField
        label="Industry code"
        isRequired={false}
        isReadOnly={false}
        value={IndustryCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              DisclosureCode,
              DisclosureTopic,
              MetricCode,
              Metric,
              DiscussionAnalysis,
              MetricUnitOfMeasure,
              MetricValue,
              IndustryCode: value,
            };
            const result = onChange(modelFields);
            value = result?.IndustryCode ?? value;
          }
          if (errors.IndustryCode?.hasError) {
            runValidationTasks("IndustryCode", value);
          }
          setIndustryCode(value);
        }}
        onBlur={() => runValidationTasks("IndustryCode", IndustryCode)}
        errorMessage={errors.IndustryCode?.errorMessage}
        hasError={errors.IndustryCode?.hasError}
        {...getOverrideProps(overrides, "IndustryCode")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
