/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ReportingEntity } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ReportingEntityCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    DataSetName: "",
    Description: "",
    PrimaryOperatingCountry: "",
    SecondaryOperatingCountry: "",
    Currency: "",
    OrganisationIdentifierType: "",
    OrganisationIdentifier: "",
    URL: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [DataSetName, setDataSetName] = React.useState(
    initialValues.DataSetName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [PrimaryOperatingCountry, setPrimaryOperatingCountry] = React.useState(
    initialValues.PrimaryOperatingCountry
  );
  const [SecondaryOperatingCountry, setSecondaryOperatingCountry] =
    React.useState(initialValues.SecondaryOperatingCountry);
  const [Currency, setCurrency] = React.useState(initialValues.Currency);
  const [OrganisationIdentifierType, setOrganisationIdentifierType] =
    React.useState(initialValues.OrganisationIdentifierType);
  const [OrganisationIdentifier, setOrganisationIdentifier] = React.useState(
    initialValues.OrganisationIdentifier
  );
  const [URL, setURL] = React.useState(initialValues.URL);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDataSetName(initialValues.DataSetName);
    setDescription(initialValues.Description);
    setPrimaryOperatingCountry(initialValues.PrimaryOperatingCountry);
    setSecondaryOperatingCountry(initialValues.SecondaryOperatingCountry);
    setCurrency(initialValues.Currency);
    setOrganisationIdentifierType(initialValues.OrganisationIdentifierType);
    setOrganisationIdentifier(initialValues.OrganisationIdentifier);
    setURL(initialValues.URL);
    setErrors({});
  };
  const validations = {
    DataSetName: [],
    Description: [],
    PrimaryOperatingCountry: [],
    SecondaryOperatingCountry: [],
    Currency: [],
    OrganisationIdentifierType: [],
    OrganisationIdentifier: [],
    URL: [{ type: "URL" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DataSetName,
          Description,
          PrimaryOperatingCountry,
          SecondaryOperatingCountry,
          Currency,
          OrganisationIdentifierType,
          OrganisationIdentifier,
          URL,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ReportingEntity(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ReportingEntityCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Reporting Entity"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Create a record describing your organisation in the fields below."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Reporting entity name"
        isRequired={false}
        isReadOnly={false}
        value={DataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName: value,
              Description,
              PrimaryOperatingCountry,
              SecondaryOperatingCountry,
              Currency,
              OrganisationIdentifierType,
              OrganisationIdentifier,
              URL,
            };
            const result = onChange(modelFields);
            value = result?.DataSetName ?? value;
          }
          if (errors.DataSetName?.hasError) {
            runValidationTasks("DataSetName", value);
          }
          setDataSetName(value);
        }}
        onBlur={() => runValidationTasks("DataSetName", DataSetName)}
        errorMessage={errors.DataSetName?.errorMessage}
        hasError={errors.DataSetName?.hasError}
        {...getOverrideProps(overrides, "DataSetName")}
      ></TextField>
      <TextAreaField
        label="Describe your organization"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description: value,
              PrimaryOperatingCountry,
              SecondaryOperatingCountry,
              Currency,
              OrganisationIdentifierType,
              OrganisationIdentifier,
              URL,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        	value={Description} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <SelectField
          label="Primary operating country"
          placeholder="Please select an option"
          isDisabled={false}
          value={PrimaryOperatingCountry}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Description,
                PrimaryOperatingCountry: value,
                SecondaryOperatingCountry,
                Currency,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                URL,
              };
              const result = onChange(modelFields);
              value = result?.PrimaryOperatingCountry ?? value;
            }
            if (errors.PrimaryOperatingCountry?.hasError) {
              runValidationTasks("PrimaryOperatingCountry", value);
            }
            setPrimaryOperatingCountry(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "PrimaryOperatingCountry",
              PrimaryOperatingCountry
            )
          }
          errorMessage={errors.PrimaryOperatingCountry?.errorMessage}
          hasError={errors.PrimaryOperatingCountry?.hasError}
          {...getOverrideProps(overrides, "PrimaryOperatingCountry")}
        >
          <option
            children="Australia"
            value="Australia"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption0")}
          ></option>
          <option
            children="Canada"
            value="Canada"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption1")}
          ></option>
          <option
            children="China"
            value="China"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption2")}
          ></option>
          <option
            children="Hong kong"
            value="HongKong"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption3")}
          ></option>
          <option
            children="New zealand"
            value="NewZealand"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption4")}
          ></option>
          <option
            children="Singapore"
            value="Singapore"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption5")}
          ></option>
          <option
            children="United kingdom"
            value="UnitedKingdom"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption6")}
          ></option>
          <option
            children="United states"
            value="UnitedStates"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption7")}
          ></option>
        </SelectField>
        <SelectField
          label="Secondary operating country"
          placeholder="Please select an option"
          isDisabled={false}
          value={SecondaryOperatingCountry}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry: value,
                Currency,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                URL,
              };
              const result = onChange(modelFields);
              value = result?.SecondaryOperatingCountry ?? value;
            }
            if (errors.SecondaryOperatingCountry?.hasError) {
              runValidationTasks("SecondaryOperatingCountry", value);
            }
            setSecondaryOperatingCountry(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "SecondaryOperatingCountry",
              SecondaryOperatingCountry
            )
          }
          errorMessage={errors.SecondaryOperatingCountry?.errorMessage}
          hasError={errors.SecondaryOperatingCountry?.hasError}
          {...getOverrideProps(overrides, "SecondaryOperatingCountry")}
        >
          <option
            children="Australia"
            value="Australia"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption0")}
          ></option>
          <option
            children="Canada"
            value="Canada"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption1")}
          ></option>
          <option
            children="China"
            value="China"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption2")}
          ></option>
          <option
            children="Hong kong"
            value="HongKong"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption3")}
          ></option>
          <option
            children="New zealand"
            value="NewZealand"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption4")}
          ></option>
          <option
            children="Singapore"
            value="Singapore"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption5")}
          ></option>
          <option
            children="United kingdom"
            value="UnitedKingdom"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption6")}
          ></option>
          <option
            children="United states"
            value="UnitedStates"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption7")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Currency"
          placeholder="Please select an option"
          isDisabled={false}
          value={Currency}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry,
                Currency: value,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                URL,
              };
              const result = onChange(modelFields);
              value = result?.Currency ?? value;
            }
            if (errors.Currency?.hasError) {
              runValidationTasks("Currency", value);
            }
            setCurrency(value);
          }}
          onBlur={() => runValidationTasks("Currency", Currency)}
          errorMessage={errors.Currency?.errorMessage}
          hasError={errors.Currency?.hasError}
          {...getOverrideProps(overrides, "Currency")}
        >
          <option
            children="Aud"
            value="AUD"
            {...getOverrideProps(overrides, "Currencyoption0")}
          ></option>
          <option
            children="Cad"
            value="CAD"
            {...getOverrideProps(overrides, "Currencyoption1")}
          ></option>
          <option
            children="Cny"
            value="CNY"
            {...getOverrideProps(overrides, "Currencyoption2")}
          ></option>
          <option
            children="Hkd"
            value="HKD"
            {...getOverrideProps(overrides, "Currencyoption3")}
          ></option>
          <option
            children="Nzd"
            value="NZD"
            {...getOverrideProps(overrides, "Currencyoption4")}
          ></option>
          <option
            children="Sgd"
            value="SGD"
            {...getOverrideProps(overrides, "Currencyoption5")}
          ></option>
          <option
            children="Gbp"
            value="GBP"
            {...getOverrideProps(overrides, "Currencyoption6")}
          ></option>
          <option
            children="Usd"
            value="USD"
            {...getOverrideProps(overrides, "Currencyoption7")}
          ></option>
        </SelectField>
        <SelectField
          label="Organisation identifier type"
          placeholder="Please select an option"
          isDisabled={false}
          value={OrganisationIdentifierType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry,
                Currency,
                OrganisationIdentifierType: value,
                OrganisationIdentifier,
                URL,
              };
              const result = onChange(modelFields);
              value = result?.OrganisationIdentifierType ?? value;
            }
            if (errors.OrganisationIdentifierType?.hasError) {
              runValidationTasks("OrganisationIdentifierType", value);
            }
            setOrganisationIdentifierType(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OrganisationIdentifierType",
              OrganisationIdentifierType
            )
          }
          errorMessage={errors.OrganisationIdentifierType?.errorMessage}
          hasError={errors.OrganisationIdentifierType?.hasError}
          {...getOverrideProps(overrides, "OrganisationIdentifierType")}
        >
          <option
            children="Abn"
            value="ABN"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption0"
            )}
          ></option>
          <option
            children="Business number"
            value="BusinessNumber"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption1"
            )}
          ></option>
          <option
            children="Company number"
            value="CompanyNumber"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption2"
            )}
          ></option>
          <option
            children="Duns number"
            value="DunsNumber"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption3"
            )}
          ></option>
          <option
            children="Iati"
            value="IATI"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption4"
            )}
          ></option>
          <option
            children="Nzbn"
            value="NZBN"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption5"
            )}
          ></option>
          <option
            children="Registration number"
            value="RegistrationNumber"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption6"
            )}
          ></option>
          <option
            children="Stock symbol"
            value="StockSymbol"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption7"
            )}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Organization identifier"
          isRequired={false}
          isReadOnly={false}
          value={OrganisationIdentifier}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry,
                Currency,
                OrganisationIdentifierType,
                OrganisationIdentifier: value,
                URL,
              };
              const result = onChange(modelFields);
              value = result?.OrganisationIdentifier ?? value;
            }
            if (errors.OrganisationIdentifier?.hasError) {
              runValidationTasks("OrganisationIdentifier", value);
            }
            setOrganisationIdentifier(value);
          }}
          onBlur={() =>
            runValidationTasks("OrganisationIdentifier", OrganisationIdentifier)
          }
          errorMessage={errors.OrganisationIdentifier?.errorMessage}
          hasError={errors.OrganisationIdentifier?.hasError}
          {...getOverrideProps(overrides, "OrganisationIdentifier")}
        ></TextField>
        <TextField
          label="Website URL"
          isRequired={false}
          isReadOnly={false}
          value={URL}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry,
                Currency,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                URL: value,
              };
              const result = onChange(modelFields);
              value = result?.URL ?? value;
            }
            if (errors.URL?.hasError) {
              runValidationTasks("URL", value);
            }
            setURL(value);
          }}
          onBlur={() => runValidationTasks("URL", URL)}
          errorMessage={errors.URL?.errorMessage}
          hasError={errors.URL?.hasError}
          {...getOverrideProps(overrides, "URL")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
