/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { ClimateRelatedMetrics } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ClimateRelatedMetricsCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    MetricDataSetName: "",
    AbsoluteGrossScope1Emissions: "",
    AbsoluteGrossScope2Emissions: "",
    AbsoluteGrossScope3Emissions: "",
    TotalAbsoluteGrossAllScopesEmissions: "",
    GHGMeasurementStandardUsed: "",
    MeasurementApproach: "",
    MeasurementInputs: "",
    MeasurementAssumptions: "",
    ReasonForApproach: "",
    ChangesInApproachFromPreviousPeriod: "",
    Scope1EmissionsByConsolidatedAcctGrp: "",
    Scope2EmissionsByConsolidatedAcctGrp: "",
    Scope1EmissionsOtherInvestees: "",
    Scope2EmissionsOtherInvestees: "",
    Scope2LocationBasedEmissions: "",
    ContracturalInstrumentsInformingScope2: "",
    Scope3EmissionsCategoriesIncluded: [],
    AdditionalCategory15Information: "",
    TransitionRisksAssetActivityType: "",
    TransitionRisksAmount: "",
    PhysicalRisksAssetActivityType: "",
    PhysicalRisksAmount: "",
    OpportunitiesAssetActivityType: "",
    OpportunitiesAmount: "",
    CapitalDeploymentValue: "",
    InternalCarbonPriceDecisionExplanation: "",
    InternalCarbonPrice: "",
    RemunerationDescription: "",
    RemunerationPercentageValue: "",
    RemunerationAlternative: "",
    IndustryMetricDescription: "",
    IndustryMetricValue: "",
    IndustryMetricUnit: "",
    TransitionRisksPercentage: "",
    PhysicalRisksPercentage: "",
    OpportunitiesPercentage: "",
    ClimateRelatedMetricsSelectType: "",
    DisclosureTopic: "",
    DisclosureCode: "",
    Metric: "",
    MetricCode: "",
    DiscussionAnalysis: "",
    MetricValue: "",
    MetricUnitOfMeasure: "",
    IndustryCode: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [MetricDataSetName, setMetricDataSetName] = React.useState(
    initialValues.MetricDataSetName
  );
  const [AbsoluteGrossScope1Emissions, setAbsoluteGrossScope1Emissions] =
    React.useState(initialValues.AbsoluteGrossScope1Emissions);
  const [AbsoluteGrossScope2Emissions, setAbsoluteGrossScope2Emissions] =
    React.useState(initialValues.AbsoluteGrossScope2Emissions);
  const [AbsoluteGrossScope3Emissions, setAbsoluteGrossScope3Emissions] =
    React.useState(initialValues.AbsoluteGrossScope3Emissions);
  const [
    TotalAbsoluteGrossAllScopesEmissions,
    setTotalAbsoluteGrossAllScopesEmissions,
  ] = React.useState(initialValues.TotalAbsoluteGrossAllScopesEmissions);
  const [GHGMeasurementStandardUsed, setGHGMeasurementStandardUsed] =
    React.useState(initialValues.GHGMeasurementStandardUsed);
  const [MeasurementApproach, setMeasurementApproach] = React.useState(
    initialValues.MeasurementApproach
  );
  const [MeasurementInputs, setMeasurementInputs] = React.useState(
    initialValues.MeasurementInputs
  );
  const [MeasurementAssumptions, setMeasurementAssumptions] = React.useState(
    initialValues.MeasurementAssumptions
  );
  const [ReasonForApproach, setReasonForApproach] = React.useState(
    initialValues.ReasonForApproach
  );
  const [
    ChangesInApproachFromPreviousPeriod,
    setChangesInApproachFromPreviousPeriod,
  ] = React.useState(initialValues.ChangesInApproachFromPreviousPeriod);
  const [
    Scope1EmissionsByConsolidatedAcctGrp,
    setScope1EmissionsByConsolidatedAcctGrp,
  ] = React.useState(initialValues.Scope1EmissionsByConsolidatedAcctGrp);
  const [
    Scope2EmissionsByConsolidatedAcctGrp,
    setScope2EmissionsByConsolidatedAcctGrp,
  ] = React.useState(initialValues.Scope2EmissionsByConsolidatedAcctGrp);
  const [Scope1EmissionsOtherInvestees, setScope1EmissionsOtherInvestees] =
    React.useState(initialValues.Scope1EmissionsOtherInvestees);
  const [Scope2EmissionsOtherInvestees, setScope2EmissionsOtherInvestees] =
    React.useState(initialValues.Scope2EmissionsOtherInvestees);
  const [Scope2LocationBasedEmissions, setScope2LocationBasedEmissions] =
    React.useState(initialValues.Scope2LocationBasedEmissions);
  const [
    ContracturalInstrumentsInformingScope2,
    setContracturalInstrumentsInformingScope2,
  ] = React.useState(initialValues.ContracturalInstrumentsInformingScope2);
  const [
    Scope3EmissionsCategoriesIncluded,
    setScope3EmissionsCategoriesIncluded,
  ] = React.useState(initialValues.Scope3EmissionsCategoriesIncluded);
  const [AdditionalCategory15Information, setAdditionalCategory15Information] =
    React.useState(initialValues.AdditionalCategory15Information);
  const [
    TransitionRisksAssetActivityType,
    setTransitionRisksAssetActivityType,
  ] = React.useState(initialValues.TransitionRisksAssetActivityType);
  const [TransitionRisksAmount, setTransitionRisksAmount] = React.useState(
    initialValues.TransitionRisksAmount
  );
  const [PhysicalRisksAssetActivityType, setPhysicalRisksAssetActivityType] =
    React.useState(initialValues.PhysicalRisksAssetActivityType);
  const [PhysicalRisksAmount, setPhysicalRisksAmount] = React.useState(
    initialValues.PhysicalRisksAmount
  );
  const [OpportunitiesAssetActivityType, setOpportunitiesAssetActivityType] =
    React.useState(initialValues.OpportunitiesAssetActivityType);
  const [OpportunitiesAmount, setOpportunitiesAmount] = React.useState(
    initialValues.OpportunitiesAmount
  );
  const [CapitalDeploymentValue, setCapitalDeploymentValue] = React.useState(
    initialValues.CapitalDeploymentValue
  );
  const [
    InternalCarbonPriceDecisionExplanation,
    setInternalCarbonPriceDecisionExplanation,
  ] = React.useState(initialValues.InternalCarbonPriceDecisionExplanation);
  const [InternalCarbonPrice, setInternalCarbonPrice] = React.useState(
    initialValues.InternalCarbonPrice
  );
  const [RemunerationDescription, setRemunerationDescription] = React.useState(
    initialValues.RemunerationDescription
  );
  const [RemunerationPercentageValue, setRemunerationPercentageValue] =
    React.useState(initialValues.RemunerationPercentageValue);
  const [RemunerationAlternative, setRemunerationAlternative] = React.useState(
    initialValues.RemunerationAlternative
  );
  const [IndustryMetricDescription, setIndustryMetricDescription] =
    React.useState(initialValues.IndustryMetricDescription);
  const [IndustryMetricValue, setIndustryMetricValue] = React.useState(
    initialValues.IndustryMetricValue
  );
  const [IndustryMetricUnit, setIndustryMetricUnit] = React.useState(
    initialValues.IndustryMetricUnit
  );
  const [TransitionRisksPercentage, setTransitionRisksPercentage] =
    React.useState(initialValues.TransitionRisksPercentage);
  const [PhysicalRisksPercentage, setPhysicalRisksPercentage] = React.useState(
    initialValues.PhysicalRisksPercentage
  );
  const [OpportunitiesPercentage, setOpportunitiesPercentage] = React.useState(
    initialValues.OpportunitiesPercentage
  );
  const [ClimateRelatedMetricsSelectType, setClimateRelatedMetricsSelectType] =
    React.useState(initialValues.ClimateRelatedMetricsSelectType);
  const [DisclosureTopic, setDisclosureTopic] = React.useState(
    initialValues.DisclosureTopic
  );
  const [DisclosureCode, setDisclosureCode] = React.useState(
    initialValues.DisclosureCode
  );
  const [Metric, setMetric] = React.useState(initialValues.Metric);
  const [MetricCode, setMetricCode] = React.useState(initialValues.MetricCode);
  const [DiscussionAnalysis, setDiscussionAnalysis] = React.useState(
    initialValues.DiscussionAnalysis
  );
  const [MetricValue, setMetricValue] = React.useState(
    initialValues.MetricValue
  );
  const [MetricUnitOfMeasure, setMetricUnitOfMeasure] = React.useState(
    initialValues.MetricUnitOfMeasure
  );
  const [IndustryCode, setIndustryCode] = React.useState(
    initialValues.IndustryCode
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setMetricDataSetName(initialValues.MetricDataSetName);
    setAbsoluteGrossScope1Emissions(initialValues.AbsoluteGrossScope1Emissions);
    setAbsoluteGrossScope2Emissions(initialValues.AbsoluteGrossScope2Emissions);
    setAbsoluteGrossScope3Emissions(initialValues.AbsoluteGrossScope3Emissions);
    setTotalAbsoluteGrossAllScopesEmissions(
      initialValues.TotalAbsoluteGrossAllScopesEmissions
    );
    setGHGMeasurementStandardUsed(initialValues.GHGMeasurementStandardUsed);
    setMeasurementApproach(initialValues.MeasurementApproach);
    setMeasurementInputs(initialValues.MeasurementInputs);
    setMeasurementAssumptions(initialValues.MeasurementAssumptions);
    setReasonForApproach(initialValues.ReasonForApproach);
    setChangesInApproachFromPreviousPeriod(
      initialValues.ChangesInApproachFromPreviousPeriod
    );
    setScope1EmissionsByConsolidatedAcctGrp(
      initialValues.Scope1EmissionsByConsolidatedAcctGrp
    );
    setScope2EmissionsByConsolidatedAcctGrp(
      initialValues.Scope2EmissionsByConsolidatedAcctGrp
    );
    setScope1EmissionsOtherInvestees(
      initialValues.Scope1EmissionsOtherInvestees
    );
    setScope2EmissionsOtherInvestees(
      initialValues.Scope2EmissionsOtherInvestees
    );
    setScope2LocationBasedEmissions(initialValues.Scope2LocationBasedEmissions);
    setContracturalInstrumentsInformingScope2(
      initialValues.ContracturalInstrumentsInformingScope2
    );
    setScope3EmissionsCategoriesIncluded(
      initialValues.Scope3EmissionsCategoriesIncluded
    );
    setCurrentScope3EmissionsCategoriesIncludedValue("");
    setAdditionalCategory15Information(
      initialValues.AdditionalCategory15Information
    );
    setTransitionRisksAssetActivityType(
      initialValues.TransitionRisksAssetActivityType
    );
    setTransitionRisksAmount(initialValues.TransitionRisksAmount);
    setPhysicalRisksAssetActivityType(
      initialValues.PhysicalRisksAssetActivityType
    );
    setPhysicalRisksAmount(initialValues.PhysicalRisksAmount);
    setOpportunitiesAssetActivityType(
      initialValues.OpportunitiesAssetActivityType
    );
    setOpportunitiesAmount(initialValues.OpportunitiesAmount);
    setCapitalDeploymentValue(initialValues.CapitalDeploymentValue);
    setInternalCarbonPriceDecisionExplanation(
      initialValues.InternalCarbonPriceDecisionExplanation
    );
    setInternalCarbonPrice(initialValues.InternalCarbonPrice);
    setRemunerationDescription(initialValues.RemunerationDescription);
    setRemunerationPercentageValue(initialValues.RemunerationPercentageValue);
    setRemunerationAlternative(initialValues.RemunerationAlternative);
    setIndustryMetricDescription(initialValues.IndustryMetricDescription);
    setIndustryMetricValue(initialValues.IndustryMetricValue);
    setIndustryMetricUnit(initialValues.IndustryMetricUnit);
    setTransitionRisksPercentage(initialValues.TransitionRisksPercentage);
    setPhysicalRisksPercentage(initialValues.PhysicalRisksPercentage);
    setOpportunitiesPercentage(initialValues.OpportunitiesPercentage);
    setClimateRelatedMetricsSelectType(
      initialValues.ClimateRelatedMetricsSelectType
    );
    setDisclosureTopic(initialValues.DisclosureTopic);
    setDisclosureCode(initialValues.DisclosureCode);
    setMetric(initialValues.Metric);
    setMetricCode(initialValues.MetricCode);
    setDiscussionAnalysis(initialValues.DiscussionAnalysis);
    setMetricValue(initialValues.MetricValue);
    setMetricUnitOfMeasure(initialValues.MetricUnitOfMeasure);
    setIndustryCode(initialValues.IndustryCode);
    setErrors({});
  };
  const [
    currentScope3EmissionsCategoriesIncludedValue,
    setCurrentScope3EmissionsCategoriesIncludedValue,
  ] = React.useState("");
  const Scope3EmissionsCategoriesIncludedRef = React.createRef();
  const getDisplayValue = {
    Scope3EmissionsCategoriesIncluded: (r) => {
      const enumDisplayValueMap = {
        Category1: "Category1",
        Category2: "Category2",
        Category3: "Category3",
        Category4: "Category4",
        Category5: "Category5",
        Category6: "Category6",
        Category7: "Category7",
        Category8: "Category8",
        Category9: "Category9",
        Category10: "Category10",
        Category11: "Category11",
        Category12: "Category12",
        Category13: "Category13",
        Category14: "Category14",
        Category15: "Category15",
      };
      return enumDisplayValueMap[r];
    },
  };
  const validations = {
    MetricDataSetName: [],
    AbsoluteGrossScope1Emissions: [],
    AbsoluteGrossScope2Emissions: [],
    AbsoluteGrossScope3Emissions: [],
    TotalAbsoluteGrossAllScopesEmissions: [],
    GHGMeasurementStandardUsed: [],
    MeasurementApproach: [],
    MeasurementInputs: [],
    MeasurementAssumptions: [],
    ReasonForApproach: [],
    ChangesInApproachFromPreviousPeriod: [],
    Scope1EmissionsByConsolidatedAcctGrp: [],
    Scope2EmissionsByConsolidatedAcctGrp: [],
    Scope1EmissionsOtherInvestees: [],
    Scope2EmissionsOtherInvestees: [],
    Scope2LocationBasedEmissions: [],
    ContracturalInstrumentsInformingScope2: [],
    Scope3EmissionsCategoriesIncluded: [],
    AdditionalCategory15Information: [],
    TransitionRisksAssetActivityType: [],
    TransitionRisksAmount: [],
    PhysicalRisksAssetActivityType: [],
    PhysicalRisksAmount: [],
    OpportunitiesAssetActivityType: [],
    OpportunitiesAmount: [],
    CapitalDeploymentValue: [],
    InternalCarbonPriceDecisionExplanation: [],
    InternalCarbonPrice: [],
    RemunerationDescription: [],
    RemunerationPercentageValue: [],
    RemunerationAlternative: [],
    IndustryMetricDescription: [],
    IndustryMetricValue: [],
    IndustryMetricUnit: [],
    TransitionRisksPercentage: [],
    PhysicalRisksPercentage: [],
    OpportunitiesPercentage: [],
    ClimateRelatedMetricsSelectType: [],
    DisclosureTopic: [],
    DisclosureCode: [],
    Metric: [],
    MetricCode: [],
    DiscussionAnalysis: [],
    MetricValue: [],
    MetricUnitOfMeasure: [],
    IndustryCode: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          MetricDataSetName,
          AbsoluteGrossScope1Emissions,
          AbsoluteGrossScope2Emissions,
          AbsoluteGrossScope3Emissions,
          TotalAbsoluteGrossAllScopesEmissions,
          GHGMeasurementStandardUsed,
          MeasurementApproach,
          MeasurementInputs,
          MeasurementAssumptions,
          ReasonForApproach,
          ChangesInApproachFromPreviousPeriod,
          Scope1EmissionsByConsolidatedAcctGrp,
          Scope2EmissionsByConsolidatedAcctGrp,
          Scope1EmissionsOtherInvestees,
          Scope2EmissionsOtherInvestees,
          Scope2LocationBasedEmissions,
          ContracturalInstrumentsInformingScope2,
          Scope3EmissionsCategoriesIncluded,
          AdditionalCategory15Information,
          TransitionRisksAssetActivityType,
          TransitionRisksAmount,
          PhysicalRisksAssetActivityType,
          PhysicalRisksAmount,
          OpportunitiesAssetActivityType,
          OpportunitiesAmount,
          CapitalDeploymentValue,
          InternalCarbonPriceDecisionExplanation,
          InternalCarbonPrice,
          RemunerationDescription,
          RemunerationPercentageValue,
          RemunerationAlternative,
          IndustryMetricDescription,
          IndustryMetricValue,
          IndustryMetricUnit,
          TransitionRisksPercentage,
          PhysicalRisksPercentage,
          OpportunitiesPercentage,
          ClimateRelatedMetricsSelectType,
          DisclosureTopic,
          DisclosureCode,
          Metric,
          MetricCode,
          DiscussionAnalysis,
          MetricValue,
          MetricUnitOfMeasure,
          IndustryCode,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            MetricDataSetName: modelFields.MetricDataSetName,
            AbsoluteGrossScope1Emissions:
              modelFields.AbsoluteGrossScope1Emissions,
            AbsoluteGrossScope2Emissions:
              modelFields.AbsoluteGrossScope2Emissions,
            AbsoluteGrossScope3Emissions:
              modelFields.AbsoluteGrossScope3Emissions,
            TotalAbsoluteGrossAllScopesEmissions:
              modelFields.TotalAbsoluteGrossAllScopesEmissions,
            GHGMeasurementStandardUsed: modelFields.GHGMeasurementStandardUsed,
            MeasurementApproach: modelFields.MeasurementApproach,
            MeasurementInputs: modelFields.MeasurementInputs,
            MeasurementAssumptions: modelFields.MeasurementAssumptions,
            ReasonForApproach: modelFields.ReasonForApproach,
            ChangesInApproachFromPreviousPeriod:
              modelFields.ChangesInApproachFromPreviousPeriod,
            Scope1EmissionsByConsolidatedAcctGrp:
              modelFields.Scope1EmissionsByConsolidatedAcctGrp,
            Scope2EmissionsByConsolidatedAcctGrp:
              modelFields.Scope2EmissionsByConsolidatedAcctGrp,
            Scope1EmissionsOtherInvestees:
              modelFields.Scope1EmissionsOtherInvestees,
            Scope2EmissionsOtherInvestees:
              modelFields.Scope2EmissionsOtherInvestees,
            Scope2LocationBasedEmissions:
              modelFields.Scope2LocationBasedEmissions,
            ContracturalInstrumentsInformingScope2:
              modelFields.ContracturalInstrumentsInformingScope2,
            Scope3EmissionsCategoriesIncluded:
              modelFields.Scope3EmissionsCategoriesIncluded,
            AdditionalCategory15Information:
              modelFields.AdditionalCategory15Information,
            TransitionRisksAssetActivityType:
              modelFields.TransitionRisksAssetActivityType,
            TransitionRisksAmount: modelFields.TransitionRisksAmount,
            PhysicalRisksAssetActivityType:
              modelFields.PhysicalRisksAssetActivityType,
            PhysicalRisksAmount: modelFields.PhysicalRisksAmount,
            OpportunitiesAssetActivityType:
              modelFields.OpportunitiesAssetActivityType,
            OpportunitiesAmount: modelFields.OpportunitiesAmount,
            CapitalDeploymentValue: modelFields.CapitalDeploymentValue,
            InternalCarbonPriceDecisionExplanation:
              modelFields.InternalCarbonPriceDecisionExplanation,
            InternalCarbonPrice: modelFields.InternalCarbonPrice,
            RemunerationDescription: modelFields.RemunerationDescription,
            RemunerationPercentageValue:
              modelFields.RemunerationPercentageValue,
            RemunerationAlternative: modelFields.RemunerationAlternative,
            TransitionRisksPercentage: modelFields.TransitionRisksPercentage,
            PhysicalRisksPercentage: modelFields.PhysicalRisksPercentage,
            OpportunitiesPercentage: modelFields.OpportunitiesPercentage,
            ClimateRelatedMetricsSelectType:
              modelFields.ClimateRelatedMetricsSelectType,
            DisclosureTopic: modelFields.DisclosureTopic,
            DisclosureCode: modelFields.DisclosureCode,
            Metric: modelFields.Metric,
            MetricCode: modelFields.MetricCode,
            DiscussionAnalysis: modelFields.DiscussionAnalysis,
            MetricValue: modelFields.MetricValue,
            MetricUnitOfMeasure: modelFields.MetricUnitOfMeasure,
            IndustryCode: modelFields.IndustryCode,
          };
          await DataStore.save(new ClimateRelatedMetrics(modelFieldsToSave));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClimateRelatedMetricsCreateForm")}
      {...rest}
    >
      <TextField
        label="Metric data set name"
        isRequired={false}
        isReadOnly={false}
        value={MetricDataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName: value,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MetricDataSetName ?? value;
          }
          if (errors.MetricDataSetName?.hasError) {
            runValidationTasks("MetricDataSetName", value);
          }
          setMetricDataSetName(value);
        }}
        onBlur={() =>
          runValidationTasks("MetricDataSetName", MetricDataSetName)
        }
        errorMessage={errors.MetricDataSetName?.errorMessage}
        hasError={errors.MetricDataSetName?.hasError}
        {...getOverrideProps(overrides, "MetricDataSetName")}
      ></TextField>
      <TextField
        label="Absolute gross scope1 emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={AbsoluteGrossScope1Emissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions: value,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.AbsoluteGrossScope1Emissions ?? value;
          }
          if (errors.AbsoluteGrossScope1Emissions?.hasError) {
            runValidationTasks("AbsoluteGrossScope1Emissions", value);
          }
          setAbsoluteGrossScope1Emissions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AbsoluteGrossScope1Emissions",
            AbsoluteGrossScope1Emissions
          )
        }
        errorMessage={errors.AbsoluteGrossScope1Emissions?.errorMessage}
        hasError={errors.AbsoluteGrossScope1Emissions?.hasError}
        {...getOverrideProps(overrides, "AbsoluteGrossScope1Emissions")}
      ></TextField>
      <TextField
        label="Absolute gross scope2 emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={AbsoluteGrossScope2Emissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions: value,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.AbsoluteGrossScope2Emissions ?? value;
          }
          if (errors.AbsoluteGrossScope2Emissions?.hasError) {
            runValidationTasks("AbsoluteGrossScope2Emissions", value);
          }
          setAbsoluteGrossScope2Emissions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AbsoluteGrossScope2Emissions",
            AbsoluteGrossScope2Emissions
          )
        }
        errorMessage={errors.AbsoluteGrossScope2Emissions?.errorMessage}
        hasError={errors.AbsoluteGrossScope2Emissions?.hasError}
        {...getOverrideProps(overrides, "AbsoluteGrossScope2Emissions")}
      ></TextField>
      <TextField
        label="Absolute gross scope3 emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={AbsoluteGrossScope3Emissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions: value,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.AbsoluteGrossScope3Emissions ?? value;
          }
          if (errors.AbsoluteGrossScope3Emissions?.hasError) {
            runValidationTasks("AbsoluteGrossScope3Emissions", value);
          }
          setAbsoluteGrossScope3Emissions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AbsoluteGrossScope3Emissions",
            AbsoluteGrossScope3Emissions
          )
        }
        errorMessage={errors.AbsoluteGrossScope3Emissions?.errorMessage}
        hasError={errors.AbsoluteGrossScope3Emissions?.hasError}
        {...getOverrideProps(overrides, "AbsoluteGrossScope3Emissions")}
      ></TextField>
      <TextField
        label="Total absolute gross all scopes emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={TotalAbsoluteGrossAllScopesEmissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions: value,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.TotalAbsoluteGrossAllScopesEmissions ?? value;
          }
          if (errors.TotalAbsoluteGrossAllScopesEmissions?.hasError) {
            runValidationTasks("TotalAbsoluteGrossAllScopesEmissions", value);
          }
          setTotalAbsoluteGrossAllScopesEmissions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TotalAbsoluteGrossAllScopesEmissions",
            TotalAbsoluteGrossAllScopesEmissions
          )
        }
        errorMessage={errors.TotalAbsoluteGrossAllScopesEmissions?.errorMessage}
        hasError={errors.TotalAbsoluteGrossAllScopesEmissions?.hasError}
        {...getOverrideProps(overrides, "TotalAbsoluteGrossAllScopesEmissions")}
      ></TextField>
      <TextField
        label="Ghg measurement standard used"
        isRequired={false}
        isReadOnly={false}
        value={GHGMeasurementStandardUsed}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed: value,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.GHGMeasurementStandardUsed ?? value;
          }
          if (errors.GHGMeasurementStandardUsed?.hasError) {
            runValidationTasks("GHGMeasurementStandardUsed", value);
          }
          setGHGMeasurementStandardUsed(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GHGMeasurementStandardUsed",
            GHGMeasurementStandardUsed
          )
        }
        errorMessage={errors.GHGMeasurementStandardUsed?.errorMessage}
        hasError={errors.GHGMeasurementStandardUsed?.hasError}
        {...getOverrideProps(overrides, "GHGMeasurementStandardUsed")}
      ></TextField>
      <TextField
        label="Measurement approach"
        isRequired={false}
        isReadOnly={false}
        value={MeasurementApproach}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach: value,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MeasurementApproach ?? value;
          }
          if (errors.MeasurementApproach?.hasError) {
            runValidationTasks("MeasurementApproach", value);
          }
          setMeasurementApproach(value);
        }}
        onBlur={() =>
          runValidationTasks("MeasurementApproach", MeasurementApproach)
        }
        errorMessage={errors.MeasurementApproach?.errorMessage}
        hasError={errors.MeasurementApproach?.hasError}
        {...getOverrideProps(overrides, "MeasurementApproach")}
      ></TextField>
      <TextField
        label="Measurement inputs"
        isRequired={false}
        isReadOnly={false}
        value={MeasurementInputs}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs: value,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MeasurementInputs ?? value;
          }
          if (errors.MeasurementInputs?.hasError) {
            runValidationTasks("MeasurementInputs", value);
          }
          setMeasurementInputs(value);
        }}
        onBlur={() =>
          runValidationTasks("MeasurementInputs", MeasurementInputs)
        }
        errorMessage={errors.MeasurementInputs?.errorMessage}
        hasError={errors.MeasurementInputs?.hasError}
        {...getOverrideProps(overrides, "MeasurementInputs")}
      ></TextField>
      <TextField
        label="Measurement assumptions"
        isRequired={false}
        isReadOnly={false}
        value={MeasurementAssumptions}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions: value,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MeasurementAssumptions ?? value;
          }
          if (errors.MeasurementAssumptions?.hasError) {
            runValidationTasks("MeasurementAssumptions", value);
          }
          setMeasurementAssumptions(value);
        }}
        onBlur={() =>
          runValidationTasks("MeasurementAssumptions", MeasurementAssumptions)
        }
        errorMessage={errors.MeasurementAssumptions?.errorMessage}
        hasError={errors.MeasurementAssumptions?.hasError}
        {...getOverrideProps(overrides, "MeasurementAssumptions")}
      ></TextField>
      <TextField
        label="Reason for approach"
        isRequired={false}
        isReadOnly={false}
        value={ReasonForApproach}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach: value,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.ReasonForApproach ?? value;
          }
          if (errors.ReasonForApproach?.hasError) {
            runValidationTasks("ReasonForApproach", value);
          }
          setReasonForApproach(value);
        }}
        onBlur={() =>
          runValidationTasks("ReasonForApproach", ReasonForApproach)
        }
        errorMessage={errors.ReasonForApproach?.errorMessage}
        hasError={errors.ReasonForApproach?.hasError}
        {...getOverrideProps(overrides, "ReasonForApproach")}
      ></TextField>
      <TextField
        label="Changes in approach from previous period"
        isRequired={false}
        isReadOnly={false}
        value={ChangesInApproachFromPreviousPeriod}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod: value,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.ChangesInApproachFromPreviousPeriod ?? value;
          }
          if (errors.ChangesInApproachFromPreviousPeriod?.hasError) {
            runValidationTasks("ChangesInApproachFromPreviousPeriod", value);
          }
          setChangesInApproachFromPreviousPeriod(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ChangesInApproachFromPreviousPeriod",
            ChangesInApproachFromPreviousPeriod
          )
        }
        errorMessage={errors.ChangesInApproachFromPreviousPeriod?.errorMessage}
        hasError={errors.ChangesInApproachFromPreviousPeriod?.hasError}
        {...getOverrideProps(overrides, "ChangesInApproachFromPreviousPeriod")}
      ></TextField>
      <TextField
        label="Scope1 emissions by consolidated acct grp"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Scope1EmissionsByConsolidatedAcctGrp}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp: value,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.Scope1EmissionsByConsolidatedAcctGrp ?? value;
          }
          if (errors.Scope1EmissionsByConsolidatedAcctGrp?.hasError) {
            runValidationTasks("Scope1EmissionsByConsolidatedAcctGrp", value);
          }
          setScope1EmissionsByConsolidatedAcctGrp(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "Scope1EmissionsByConsolidatedAcctGrp",
            Scope1EmissionsByConsolidatedAcctGrp
          )
        }
        errorMessage={errors.Scope1EmissionsByConsolidatedAcctGrp?.errorMessage}
        hasError={errors.Scope1EmissionsByConsolidatedAcctGrp?.hasError}
        {...getOverrideProps(overrides, "Scope1EmissionsByConsolidatedAcctGrp")}
      ></TextField>
      <TextField
        label="Scope2 emissions by consolidated acct grp"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Scope2EmissionsByConsolidatedAcctGrp}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp: value,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.Scope2EmissionsByConsolidatedAcctGrp ?? value;
          }
          if (errors.Scope2EmissionsByConsolidatedAcctGrp?.hasError) {
            runValidationTasks("Scope2EmissionsByConsolidatedAcctGrp", value);
          }
          setScope2EmissionsByConsolidatedAcctGrp(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "Scope2EmissionsByConsolidatedAcctGrp",
            Scope2EmissionsByConsolidatedAcctGrp
          )
        }
        errorMessage={errors.Scope2EmissionsByConsolidatedAcctGrp?.errorMessage}
        hasError={errors.Scope2EmissionsByConsolidatedAcctGrp?.hasError}
        {...getOverrideProps(overrides, "Scope2EmissionsByConsolidatedAcctGrp")}
      ></TextField>
      <TextField
        label="Scope1 emissions other investees"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Scope1EmissionsOtherInvestees}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees: value,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.Scope1EmissionsOtherInvestees ?? value;
          }
          if (errors.Scope1EmissionsOtherInvestees?.hasError) {
            runValidationTasks("Scope1EmissionsOtherInvestees", value);
          }
          setScope1EmissionsOtherInvestees(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "Scope1EmissionsOtherInvestees",
            Scope1EmissionsOtherInvestees
          )
        }
        errorMessage={errors.Scope1EmissionsOtherInvestees?.errorMessage}
        hasError={errors.Scope1EmissionsOtherInvestees?.hasError}
        {...getOverrideProps(overrides, "Scope1EmissionsOtherInvestees")}
      ></TextField>
      <TextField
        label="Scope2 emissions other investees"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Scope2EmissionsOtherInvestees}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees: value,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.Scope2EmissionsOtherInvestees ?? value;
          }
          if (errors.Scope2EmissionsOtherInvestees?.hasError) {
            runValidationTasks("Scope2EmissionsOtherInvestees", value);
          }
          setScope2EmissionsOtherInvestees(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "Scope2EmissionsOtherInvestees",
            Scope2EmissionsOtherInvestees
          )
        }
        errorMessage={errors.Scope2EmissionsOtherInvestees?.errorMessage}
        hasError={errors.Scope2EmissionsOtherInvestees?.hasError}
        {...getOverrideProps(overrides, "Scope2EmissionsOtherInvestees")}
      ></TextField>
      <TextField
        label="Scope2 location based emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Scope2LocationBasedEmissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions: value,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.Scope2LocationBasedEmissions ?? value;
          }
          if (errors.Scope2LocationBasedEmissions?.hasError) {
            runValidationTasks("Scope2LocationBasedEmissions", value);
          }
          setScope2LocationBasedEmissions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "Scope2LocationBasedEmissions",
            Scope2LocationBasedEmissions
          )
        }
        errorMessage={errors.Scope2LocationBasedEmissions?.errorMessage}
        hasError={errors.Scope2LocationBasedEmissions?.hasError}
        {...getOverrideProps(overrides, "Scope2LocationBasedEmissions")}
      ></TextField>
      <TextField
        label="Contractural instruments informing scope2"
        isRequired={false}
        isReadOnly={false}
        value={ContracturalInstrumentsInformingScope2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2: value,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.ContracturalInstrumentsInformingScope2 ?? value;
          }
          if (errors.ContracturalInstrumentsInformingScope2?.hasError) {
            runValidationTasks("ContracturalInstrumentsInformingScope2", value);
          }
          setContracturalInstrumentsInformingScope2(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ContracturalInstrumentsInformingScope2",
            ContracturalInstrumentsInformingScope2
          )
        }
        errorMessage={
          errors.ContracturalInstrumentsInformingScope2?.errorMessage
        }
        hasError={errors.ContracturalInstrumentsInformingScope2?.hasError}
        {...getOverrideProps(
          overrides,
          "ContracturalInstrumentsInformingScope2"
        )}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded: values,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            values = result?.Scope3EmissionsCategoriesIncluded ?? values;
          }
          setScope3EmissionsCategoriesIncluded(values);
          setCurrentScope3EmissionsCategoriesIncludedValue("");
        }}
        currentFieldValue={currentScope3EmissionsCategoriesIncludedValue}
        label={"Scope3 emissions categories included"}
        items={Scope3EmissionsCategoriesIncluded}
        hasError={errors?.Scope3EmissionsCategoriesIncluded?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "Scope3EmissionsCategoriesIncluded",
            currentScope3EmissionsCategoriesIncludedValue
          )
        }
        errorMessage={errors?.Scope3EmissionsCategoriesIncluded?.errorMessage}
        getBadgeText={getDisplayValue.Scope3EmissionsCategoriesIncluded}
        setFieldValue={setCurrentScope3EmissionsCategoriesIncludedValue}
        inputFieldRef={Scope3EmissionsCategoriesIncludedRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Scope3 emissions categories included"
          placeholder="Please select an option"
          isDisabled={false}
          value={currentScope3EmissionsCategoriesIncludedValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.Scope3EmissionsCategoriesIncluded?.hasError) {
              runValidationTasks("Scope3EmissionsCategoriesIncluded", value);
            }
            setCurrentScope3EmissionsCategoriesIncludedValue(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "Scope3EmissionsCategoriesIncluded",
              currentScope3EmissionsCategoriesIncludedValue
            )
          }
          errorMessage={errors.Scope3EmissionsCategoriesIncluded?.errorMessage}
          hasError={errors.Scope3EmissionsCategoriesIncluded?.hasError}
          ref={Scope3EmissionsCategoriesIncludedRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "Scope3EmissionsCategoriesIncluded")}
        >
          <option
            children="Category1"
            value="Category1"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption0"
            )}
          ></option>
          <option
            children="Category2"
            value="Category2"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption1"
            )}
          ></option>
          <option
            children="Category3"
            value="Category3"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption2"
            )}
          ></option>
          <option
            children="Category4"
            value="Category4"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption3"
            )}
          ></option>
          <option
            children="Category5"
            value="Category5"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption4"
            )}
          ></option>
          <option
            children="Category6"
            value="Category6"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption5"
            )}
          ></option>
          <option
            children="Category7"
            value="Category7"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption6"
            )}
          ></option>
          <option
            children="Category8"
            value="Category8"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption7"
            )}
          ></option>
          <option
            children="Category9"
            value="Category9"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption8"
            )}
          ></option>
          <option
            children="Category10"
            value="Category10"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption9"
            )}
          ></option>
          <option
            children="Category11"
            value="Category11"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption10"
            )}
          ></option>
          <option
            children="Category12"
            value="Category12"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption11"
            )}
          ></option>
          <option
            children="Category13"
            value="Category13"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption12"
            )}
          ></option>
          <option
            children="Category14"
            value="Category14"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption13"
            )}
          ></option>
          <option
            children="Category15"
            value="Category15"
            {...getOverrideProps(
              overrides,
              "Scope3EmissionsCategoriesIncludedoption14"
            )}
          ></option>
        </SelectField>
      </ArrayField>
      <TextField
        label="Additional category15 information"
        isRequired={false}
        isReadOnly={false}
        value={AdditionalCategory15Information}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information: value,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.AdditionalCategory15Information ?? value;
          }
          if (errors.AdditionalCategory15Information?.hasError) {
            runValidationTasks("AdditionalCategory15Information", value);
          }
          setAdditionalCategory15Information(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AdditionalCategory15Information",
            AdditionalCategory15Information
          )
        }
        errorMessage={errors.AdditionalCategory15Information?.errorMessage}
        hasError={errors.AdditionalCategory15Information?.hasError}
        {...getOverrideProps(overrides, "AdditionalCategory15Information")}
      ></TextField>
      <SelectField
        label="Transition risks asset activity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={TransitionRisksAssetActivityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType: value,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.TransitionRisksAssetActivityType ?? value;
          }
          if (errors.TransitionRisksAssetActivityType?.hasError) {
            runValidationTasks("TransitionRisksAssetActivityType", value);
          }
          setTransitionRisksAssetActivityType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TransitionRisksAssetActivityType",
            TransitionRisksAssetActivityType
          )
        }
        errorMessage={errors.TransitionRisksAssetActivityType?.errorMessage}
        hasError={errors.TransitionRisksAssetActivityType?.hasError}
        {...getOverrideProps(overrides, "TransitionRisksAssetActivityType")}
      >
        <option
          children="Business activity"
          value="BusinessActivity"
          {...getOverrideProps(
            overrides,
            "TransitionRisksAssetActivityTypeoption0"
          )}
        ></option>
        <option
          children="Asset"
          value="Asset"
          {...getOverrideProps(
            overrides,
            "TransitionRisksAssetActivityTypeoption1"
          )}
        ></option>
      </SelectField>
      <TextField
        label="Transition risks amount"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={TransitionRisksAmount}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount: value,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.TransitionRisksAmount ?? value;
          }
          if (errors.TransitionRisksAmount?.hasError) {
            runValidationTasks("TransitionRisksAmount", value);
          }
          setTransitionRisksAmount(value);
        }}
        onBlur={() =>
          runValidationTasks("TransitionRisksAmount", TransitionRisksAmount)
        }
        errorMessage={errors.TransitionRisksAmount?.errorMessage}
        hasError={errors.TransitionRisksAmount?.hasError}
        {...getOverrideProps(overrides, "TransitionRisksAmount")}
      ></TextField>
      <SelectField
        label="Physical risks asset activity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={PhysicalRisksAssetActivityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType: value,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.PhysicalRisksAssetActivityType ?? value;
          }
          if (errors.PhysicalRisksAssetActivityType?.hasError) {
            runValidationTasks("PhysicalRisksAssetActivityType", value);
          }
          setPhysicalRisksAssetActivityType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "PhysicalRisksAssetActivityType",
            PhysicalRisksAssetActivityType
          )
        }
        errorMessage={errors.PhysicalRisksAssetActivityType?.errorMessage}
        hasError={errors.PhysicalRisksAssetActivityType?.hasError}
        {...getOverrideProps(overrides, "PhysicalRisksAssetActivityType")}
      >
        <option
          children="Business activity"
          value="BusinessActivity"
          {...getOverrideProps(
            overrides,
            "PhysicalRisksAssetActivityTypeoption0"
          )}
        ></option>
        <option
          children="Asset"
          value="Asset"
          {...getOverrideProps(
            overrides,
            "PhysicalRisksAssetActivityTypeoption1"
          )}
        ></option>
      </SelectField>
      <TextField
        label="Physical risks amount"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={PhysicalRisksAmount}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount: value,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.PhysicalRisksAmount ?? value;
          }
          if (errors.PhysicalRisksAmount?.hasError) {
            runValidationTasks("PhysicalRisksAmount", value);
          }
          setPhysicalRisksAmount(value);
        }}
        onBlur={() =>
          runValidationTasks("PhysicalRisksAmount", PhysicalRisksAmount)
        }
        errorMessage={errors.PhysicalRisksAmount?.errorMessage}
        hasError={errors.PhysicalRisksAmount?.hasError}
        {...getOverrideProps(overrides, "PhysicalRisksAmount")}
      ></TextField>
      <SelectField
        label="Opportunities asset activity type"
        placeholder="Please select an option"
        isDisabled={false}
        value={OpportunitiesAssetActivityType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType: value,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.OpportunitiesAssetActivityType ?? value;
          }
          if (errors.OpportunitiesAssetActivityType?.hasError) {
            runValidationTasks("OpportunitiesAssetActivityType", value);
          }
          setOpportunitiesAssetActivityType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "OpportunitiesAssetActivityType",
            OpportunitiesAssetActivityType
          )
        }
        errorMessage={errors.OpportunitiesAssetActivityType?.errorMessage}
        hasError={errors.OpportunitiesAssetActivityType?.hasError}
        {...getOverrideProps(overrides, "OpportunitiesAssetActivityType")}
      >
        <option
          children="Business activity"
          value="BusinessActivity"
          {...getOverrideProps(
            overrides,
            "OpportunitiesAssetActivityTypeoption0"
          )}
        ></option>
        <option
          children="Asset"
          value="Asset"
          {...getOverrideProps(
            overrides,
            "OpportunitiesAssetActivityTypeoption1"
          )}
        ></option>
      </SelectField>
      <TextField
        label="Opportunities amount"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={OpportunitiesAmount}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount: value,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.OpportunitiesAmount ?? value;
          }
          if (errors.OpportunitiesAmount?.hasError) {
            runValidationTasks("OpportunitiesAmount", value);
          }
          setOpportunitiesAmount(value);
        }}
        onBlur={() =>
          runValidationTasks("OpportunitiesAmount", OpportunitiesAmount)
        }
        errorMessage={errors.OpportunitiesAmount?.errorMessage}
        hasError={errors.OpportunitiesAmount?.hasError}
        {...getOverrideProps(overrides, "OpportunitiesAmount")}
      ></TextField>
      <TextField
        label="Capital deployment value"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={CapitalDeploymentValue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue: value,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.CapitalDeploymentValue ?? value;
          }
          if (errors.CapitalDeploymentValue?.hasError) {
            runValidationTasks("CapitalDeploymentValue", value);
          }
          setCapitalDeploymentValue(value);
        }}
        onBlur={() =>
          runValidationTasks("CapitalDeploymentValue", CapitalDeploymentValue)
        }
        errorMessage={errors.CapitalDeploymentValue?.errorMessage}
        hasError={errors.CapitalDeploymentValue?.hasError}
        {...getOverrideProps(overrides, "CapitalDeploymentValue")}
      ></TextField>
      <TextField
        label="Internal carbon price decision explanation"
        isRequired={false}
        isReadOnly={false}
        value={InternalCarbonPriceDecisionExplanation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation: value,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.InternalCarbonPriceDecisionExplanation ?? value;
          }
          if (errors.InternalCarbonPriceDecisionExplanation?.hasError) {
            runValidationTasks("InternalCarbonPriceDecisionExplanation", value);
          }
          setInternalCarbonPriceDecisionExplanation(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "InternalCarbonPriceDecisionExplanation",
            InternalCarbonPriceDecisionExplanation
          )
        }
        errorMessage={
          errors.InternalCarbonPriceDecisionExplanation?.errorMessage
        }
        hasError={errors.InternalCarbonPriceDecisionExplanation?.hasError}
        {...getOverrideProps(
          overrides,
          "InternalCarbonPriceDecisionExplanation"
        )}
      ></TextField>
      <TextField
        label="Internal carbon price"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={InternalCarbonPrice}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice: value,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.InternalCarbonPrice ?? value;
          }
          if (errors.InternalCarbonPrice?.hasError) {
            runValidationTasks("InternalCarbonPrice", value);
          }
          setInternalCarbonPrice(value);
        }}
        onBlur={() =>
          runValidationTasks("InternalCarbonPrice", InternalCarbonPrice)
        }
        errorMessage={errors.InternalCarbonPrice?.errorMessage}
        hasError={errors.InternalCarbonPrice?.hasError}
        {...getOverrideProps(overrides, "InternalCarbonPrice")}
      ></TextField>
      <TextField
        label="Remuneration description"
        isRequired={false}
        isReadOnly={false}
        value={RemunerationDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription: value,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.RemunerationDescription ?? value;
          }
          if (errors.RemunerationDescription?.hasError) {
            runValidationTasks("RemunerationDescription", value);
          }
          setRemunerationDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("RemunerationDescription", RemunerationDescription)
        }
        errorMessage={errors.RemunerationDescription?.errorMessage}
        hasError={errors.RemunerationDescription?.hasError}
        {...getOverrideProps(overrides, "RemunerationDescription")}
      ></TextField>
      <TextField
        label="Remuneration percentage value"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={RemunerationPercentageValue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue: value,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.RemunerationPercentageValue ?? value;
          }
          if (errors.RemunerationPercentageValue?.hasError) {
            runValidationTasks("RemunerationPercentageValue", value);
          }
          setRemunerationPercentageValue(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "RemunerationPercentageValue",
            RemunerationPercentageValue
          )
        }
        errorMessage={errors.RemunerationPercentageValue?.errorMessage}
        hasError={errors.RemunerationPercentageValue?.hasError}
        {...getOverrideProps(overrides, "RemunerationPercentageValue")}
      ></TextField>
      <TextField
        label="Remuneration alternative"
        isRequired={false}
        isReadOnly={false}
        value={RemunerationAlternative}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative: value,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.RemunerationAlternative ?? value;
          }
          if (errors.RemunerationAlternative?.hasError) {
            runValidationTasks("RemunerationAlternative", value);
          }
          setRemunerationAlternative(value);
        }}
        onBlur={() =>
          runValidationTasks("RemunerationAlternative", RemunerationAlternative)
        }
        errorMessage={errors.RemunerationAlternative?.errorMessage}
        hasError={errors.RemunerationAlternative?.hasError}
        {...getOverrideProps(overrides, "RemunerationAlternative")}
      ></TextField>
      <TextField
        label="Label"
        value={IndustryMetricDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription: value,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.IndustryMetricDescription ?? value;
          }
          if (errors.IndustryMetricDescription?.hasError) {
            runValidationTasks("IndustryMetricDescription", value);
          }
          setIndustryMetricDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "IndustryMetricDescription",
            IndustryMetricDescription
          )
        }
        errorMessage={errors.IndustryMetricDescription?.errorMessage}
        hasError={errors.IndustryMetricDescription?.hasError}
        {...getOverrideProps(overrides, "IndustryMetricDescription")}
      ></TextField>
      <TextField
        label="Label"
        value={IndustryMetricValue}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue: value,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.IndustryMetricValue ?? value;
          }
          if (errors.IndustryMetricValue?.hasError) {
            runValidationTasks("IndustryMetricValue", value);
          }
          setIndustryMetricValue(value);
        }}
        onBlur={() =>
          runValidationTasks("IndustryMetricValue", IndustryMetricValue)
        }
        errorMessage={errors.IndustryMetricValue?.errorMessage}
        hasError={errors.IndustryMetricValue?.hasError}
        {...getOverrideProps(overrides, "IndustryMetricValue")}
      ></TextField>
      <TextField
        label="Label"
        value={IndustryMetricUnit}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit: value,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.IndustryMetricUnit ?? value;
          }
          if (errors.IndustryMetricUnit?.hasError) {
            runValidationTasks("IndustryMetricUnit", value);
          }
          setIndustryMetricUnit(value);
        }}
        onBlur={() =>
          runValidationTasks("IndustryMetricUnit", IndustryMetricUnit)
        }
        errorMessage={errors.IndustryMetricUnit?.errorMessage}
        hasError={errors.IndustryMetricUnit?.hasError}
        {...getOverrideProps(overrides, "IndustryMetricUnit")}
      ></TextField>
      <TextField
        label="Transition risks percentage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={TransitionRisksPercentage}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage: value,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.TransitionRisksPercentage ?? value;
          }
          if (errors.TransitionRisksPercentage?.hasError) {
            runValidationTasks("TransitionRisksPercentage", value);
          }
          setTransitionRisksPercentage(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "TransitionRisksPercentage",
            TransitionRisksPercentage
          )
        }
        errorMessage={errors.TransitionRisksPercentage?.errorMessage}
        hasError={errors.TransitionRisksPercentage?.hasError}
        {...getOverrideProps(overrides, "TransitionRisksPercentage")}
      ></TextField>
      <TextField
        label="Physical risks percentage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={PhysicalRisksPercentage}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage: value,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.PhysicalRisksPercentage ?? value;
          }
          if (errors.PhysicalRisksPercentage?.hasError) {
            runValidationTasks("PhysicalRisksPercentage", value);
          }
          setPhysicalRisksPercentage(value);
        }}
        onBlur={() =>
          runValidationTasks("PhysicalRisksPercentage", PhysicalRisksPercentage)
        }
        errorMessage={errors.PhysicalRisksPercentage?.errorMessage}
        hasError={errors.PhysicalRisksPercentage?.hasError}
        {...getOverrideProps(overrides, "PhysicalRisksPercentage")}
      ></TextField>
      <TextField
        label="Opportunities percentage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={OpportunitiesPercentage}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage: value,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.OpportunitiesPercentage ?? value;
          }
          if (errors.OpportunitiesPercentage?.hasError) {
            runValidationTasks("OpportunitiesPercentage", value);
          }
          setOpportunitiesPercentage(value);
        }}
        onBlur={() =>
          runValidationTasks("OpportunitiesPercentage", OpportunitiesPercentage)
        }
        errorMessage={errors.OpportunitiesPercentage?.errorMessage}
        hasError={errors.OpportunitiesPercentage?.hasError}
        {...getOverrideProps(overrides, "OpportunitiesPercentage")}
      ></TextField>
      <SelectField
        label="Climate related metrics select type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ClimateRelatedMetricsSelectType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType: value,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.ClimateRelatedMetricsSelectType ?? value;
          }
          if (errors.ClimateRelatedMetricsSelectType?.hasError) {
            runValidationTasks("ClimateRelatedMetricsSelectType", value);
          }
          setClimateRelatedMetricsSelectType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ClimateRelatedMetricsSelectType",
            ClimateRelatedMetricsSelectType
          )
        }
        errorMessage={errors.ClimateRelatedMetricsSelectType?.errorMessage}
        hasError={errors.ClimateRelatedMetricsSelectType?.hasError}
        {...getOverrideProps(overrides, "ClimateRelatedMetricsSelectType")}
      >
        <option
          children="Cross industry metrics"
          value="CrossIndustryMetrics"
          {...getOverrideProps(
            overrides,
            "ClimateRelatedMetricsSelectTypeoption0"
          )}
        ></option>
        <option
          children="Risk opportunity metrics"
          value="RiskOpportunityMetrics"
          {...getOverrideProps(
            overrides,
            "ClimateRelatedMetricsSelectTypeoption1"
          )}
        ></option>
        <option
          children="Industry metrics"
          value="IndustryMetrics"
          {...getOverrideProps(
            overrides,
            "ClimateRelatedMetricsSelectTypeoption2"
          )}
        ></option>
      </SelectField>
      <TextField
        label="Disclosure topic"
        isRequired={false}
        isReadOnly={false}
        value={DisclosureTopic}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic: value,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.DisclosureTopic ?? value;
          }
          if (errors.DisclosureTopic?.hasError) {
            runValidationTasks("DisclosureTopic", value);
          }
          setDisclosureTopic(value);
        }}
        onBlur={() => runValidationTasks("DisclosureTopic", DisclosureTopic)}
        errorMessage={errors.DisclosureTopic?.errorMessage}
        hasError={errors.DisclosureTopic?.hasError}
        {...getOverrideProps(overrides, "DisclosureTopic")}
      ></TextField>
      <TextField
        label="Disclosure code"
        isRequired={false}
        isReadOnly={false}
        value={DisclosureCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode: value,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.DisclosureCode ?? value;
          }
          if (errors.DisclosureCode?.hasError) {
            runValidationTasks("DisclosureCode", value);
          }
          setDisclosureCode(value);
        }}
        onBlur={() => runValidationTasks("DisclosureCode", DisclosureCode)}
        errorMessage={errors.DisclosureCode?.errorMessage}
        hasError={errors.DisclosureCode?.hasError}
        {...getOverrideProps(overrides, "DisclosureCode")}
      ></TextField>
      <TextField
        label="Metric"
        isRequired={false}
        isReadOnly={false}
        value={Metric}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric: value,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.Metric ?? value;
          }
          if (errors.Metric?.hasError) {
            runValidationTasks("Metric", value);
          }
          setMetric(value);
        }}
        onBlur={() => runValidationTasks("Metric", Metric)}
        errorMessage={errors.Metric?.errorMessage}
        hasError={errors.Metric?.hasError}
        {...getOverrideProps(overrides, "Metric")}
      ></TextField>
      <TextField
        label="Metric code"
        isRequired={false}
        isReadOnly={false}
        value={MetricCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode: value,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MetricCode ?? value;
          }
          if (errors.MetricCode?.hasError) {
            runValidationTasks("MetricCode", value);
          }
          setMetricCode(value);
        }}
        onBlur={() => runValidationTasks("MetricCode", MetricCode)}
        errorMessage={errors.MetricCode?.errorMessage}
        hasError={errors.MetricCode?.hasError}
        {...getOverrideProps(overrides, "MetricCode")}
      ></TextField>
      <TextField
        label="Discussion analysis"
        isRequired={false}
        isReadOnly={false}
        value={DiscussionAnalysis}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis: value,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.DiscussionAnalysis ?? value;
          }
          if (errors.DiscussionAnalysis?.hasError) {
            runValidationTasks("DiscussionAnalysis", value);
          }
          setDiscussionAnalysis(value);
        }}
        onBlur={() =>
          runValidationTasks("DiscussionAnalysis", DiscussionAnalysis)
        }
        errorMessage={errors.DiscussionAnalysis?.errorMessage}
        hasError={errors.DiscussionAnalysis?.hasError}
        {...getOverrideProps(overrides, "DiscussionAnalysis")}
      ></TextField>
      <TextField
        label="Metric value"
        isRequired={false}
        isReadOnly={false}
        value={MetricValue}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue: value,
              MetricUnitOfMeasure,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MetricValue ?? value;
          }
          if (errors.MetricValue?.hasError) {
            runValidationTasks("MetricValue", value);
          }
          setMetricValue(value);
        }}
        onBlur={() => runValidationTasks("MetricValue", MetricValue)}
        errorMessage={errors.MetricValue?.errorMessage}
        hasError={errors.MetricValue?.hasError}
        {...getOverrideProps(overrides, "MetricValue")}
      ></TextField>
      <TextField
        label="Metric unit of measure"
        isRequired={false}
        isReadOnly={false}
        value={MetricUnitOfMeasure}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure: value,
              IndustryCode,
            };
            const result = onChange(modelFields);
            value = result?.MetricUnitOfMeasure ?? value;
          }
          if (errors.MetricUnitOfMeasure?.hasError) {
            runValidationTasks("MetricUnitOfMeasure", value);
          }
          setMetricUnitOfMeasure(value);
        }}
        onBlur={() =>
          runValidationTasks("MetricUnitOfMeasure", MetricUnitOfMeasure)
        }
        errorMessage={errors.MetricUnitOfMeasure?.errorMessage}
        hasError={errors.MetricUnitOfMeasure?.hasError}
        {...getOverrideProps(overrides, "MetricUnitOfMeasure")}
      ></TextField>
      <TextField
        label="Industry code"
        isRequired={false}
        isReadOnly={false}
        value={IndustryCode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              MetricDataSetName,
              AbsoluteGrossScope1Emissions,
              AbsoluteGrossScope2Emissions,
              AbsoluteGrossScope3Emissions,
              TotalAbsoluteGrossAllScopesEmissions,
              GHGMeasurementStandardUsed,
              MeasurementApproach,
              MeasurementInputs,
              MeasurementAssumptions,
              ReasonForApproach,
              ChangesInApproachFromPreviousPeriod,
              Scope1EmissionsByConsolidatedAcctGrp,
              Scope2EmissionsByConsolidatedAcctGrp,
              Scope1EmissionsOtherInvestees,
              Scope2EmissionsOtherInvestees,
              Scope2LocationBasedEmissions,
              ContracturalInstrumentsInformingScope2,
              Scope3EmissionsCategoriesIncluded,
              AdditionalCategory15Information,
              TransitionRisksAssetActivityType,
              TransitionRisksAmount,
              PhysicalRisksAssetActivityType,
              PhysicalRisksAmount,
              OpportunitiesAssetActivityType,
              OpportunitiesAmount,
              CapitalDeploymentValue,
              InternalCarbonPriceDecisionExplanation,
              InternalCarbonPrice,
              RemunerationDescription,
              RemunerationPercentageValue,
              RemunerationAlternative,
              IndustryMetricDescription,
              IndustryMetricValue,
              IndustryMetricUnit,
              TransitionRisksPercentage,
              PhysicalRisksPercentage,
              OpportunitiesPercentage,
              ClimateRelatedMetricsSelectType,
              DisclosureTopic,
              DisclosureCode,
              Metric,
              MetricCode,
              DiscussionAnalysis,
              MetricValue,
              MetricUnitOfMeasure,
              IndustryCode: value,
            };
            const result = onChange(modelFields);
            value = result?.IndustryCode ?? value;
          }
          if (errors.IndustryCode?.hasError) {
            runValidationTasks("IndustryCode", value);
          }
          setIndustryCode(value);
        }}
        onBlur={() => runValidationTasks("IndustryCode", IndustryCode)}
        errorMessage={errors.IndustryCode?.errorMessage}
        hasError={errors.IndustryCode?.hasError}
        {...getOverrideProps(overrides, "IndustryCode")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
