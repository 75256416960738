/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateGovernanceProcess } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateGovernanceProcessCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    CustomerID: "",
    GovernanceProcessSelectType: "",
    GovernanceProcessName: "",
    ProcessDescription: "",
    ProcessFrequency: "",
    ProcessType: "",
    ProcessTradeoffDescription: "",
    UseRemunerationPerformanceMetrics: "",
    ClimateOversightProcessType: "",
    GovernanceArtifactName: "",
    GovernanceArtifactDescription: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [CustomerID, setCustomerID] = React.useState(initialValues.CustomerID);
  const [GovernanceProcessSelectType, setGovernanceProcessSelectType] =
    React.useState(initialValues.GovernanceProcessSelectType);
  const [GovernanceProcessName, setGovernanceProcessName] = React.useState(
    initialValues.GovernanceProcessName
  );
  const [ProcessDescription, setProcessDescription] = React.useState(
    initialValues.ProcessDescription
  );
  const [ProcessFrequency, setProcessFrequency] = React.useState(
    initialValues.ProcessFrequency
  );
  const [ProcessType, setProcessType] = React.useState(
    initialValues.ProcessType
  );
  const [ProcessTradeoffDescription, setProcessTradeoffDescription] =
    React.useState(initialValues.ProcessTradeoffDescription);
  const [
    UseRemunerationPerformanceMetrics,
    setUseRemunerationPerformanceMetrics,
  ] = React.useState(initialValues.UseRemunerationPerformanceMetrics);
  const [ClimateOversightProcessType, setClimateOversightProcessType] =
    React.useState(initialValues.ClimateOversightProcessType);
  const [GovernanceArtifactName, setGovernanceArtifactName] = React.useState(
    initialValues.GovernanceArtifactName
  );
  const [GovernanceArtifactDescription, setGovernanceArtifactDescription] =
    React.useState(initialValues.GovernanceArtifactDescription);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCustomerID(initialValues.CustomerID);
    setGovernanceProcessSelectType(initialValues.GovernanceProcessSelectType);
    setGovernanceProcessName(initialValues.GovernanceProcessName);
    setProcessDescription(initialValues.ProcessDescription);
    setProcessFrequency(initialValues.ProcessFrequency);
    setProcessType(initialValues.ProcessType);
    setProcessTradeoffDescription(initialValues.ProcessTradeoffDescription);
    setUseRemunerationPerformanceMetrics(
      initialValues.UseRemunerationPerformanceMetrics
    );
    setClimateOversightProcessType(initialValues.ClimateOversightProcessType);
    setGovernanceArtifactName(initialValues.GovernanceArtifactName);
    setGovernanceArtifactDescription(
      initialValues.GovernanceArtifactDescription
    );
    setErrors({});
  };
  const validations = {
    CustomerID: [],
    GovernanceProcessSelectType: [],
    GovernanceProcessName: [],
    ProcessDescription: [],
    ProcessFrequency: [],
    ProcessType: [],
    ProcessTradeoffDescription: [],
    UseRemunerationPerformanceMetrics: [],
    ClimateOversightProcessType: [],
    GovernanceArtifactName: [],
    GovernanceArtifactDescription: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CustomerID,
          GovernanceProcessSelectType,
          GovernanceProcessName,
          ProcessDescription,
          ProcessFrequency,
          ProcessType,
          ProcessTradeoffDescription,
          UseRemunerationPerformanceMetrics,
          ClimateOversightProcessType,
          GovernanceArtifactName,
          GovernanceArtifactDescription,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateGovernanceProcess(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClimateGovernanceProcessCreateForm")}
      {...rest}
    >
      <TextField
        label="Customer id"
        isRequired={false}
        isReadOnly={false}
        value={CustomerID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID: value,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.CustomerID ?? value;
          }
          if (errors.CustomerID?.hasError) {
            runValidationTasks("CustomerID", value);
          }
          setCustomerID(value);
        }}
        onBlur={() => runValidationTasks("CustomerID", CustomerID)}
        errorMessage={errors.CustomerID?.errorMessage}
        hasError={errors.CustomerID?.hasError}
        {...getOverrideProps(overrides, "CustomerID")}
      ></TextField>
      <SelectField
        label="Governance process select type"
        placeholder="Please select an option"
        isDisabled={false}
        value={GovernanceProcessSelectType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType: value,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceProcessSelectType ?? value;
          }
          if (errors.GovernanceProcessSelectType?.hasError) {
            runValidationTasks("GovernanceProcessSelectType", value);
          }
          setGovernanceProcessSelectType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GovernanceProcessSelectType",
            GovernanceProcessSelectType
          )
        }
        errorMessage={errors.GovernanceProcessSelectType?.errorMessage}
        hasError={errors.GovernanceProcessSelectType?.hasError}
        {...getOverrideProps(overrides, "GovernanceProcessSelectType")}
      >
        <option
          children="Inform governance"
          value="InformGovernance"
          {...getOverrideProps(overrides, "GovernanceProcessSelectTypeoption0")}
        ></option>
        <option
          children="Climate strategy oversight"
          value="ClimateStrategyOversight"
          {...getOverrideProps(overrides, "GovernanceProcessSelectTypeoption1")}
        ></option>
        <option
          children="Governance metrics targets"
          value="GovernanceMetricsTargets"
          {...getOverrideProps(overrides, "GovernanceProcessSelectTypeoption2")}
        ></option>
        <option
          children="Management oversight"
          value="ManagementOversight"
          {...getOverrideProps(overrides, "GovernanceProcessSelectTypeoption3")}
        ></option>
        <option
          children="Governance artifact"
          value="GovernanceArtifact"
          {...getOverrideProps(overrides, "GovernanceProcessSelectTypeoption4")}
        ></option>
      </SelectField>
      <TextField
        label="Governance process name"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName: value,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceProcessName ?? value;
          }
          if (errors.GovernanceProcessName?.hasError) {
            runValidationTasks("GovernanceProcessName", value);
          }
          setGovernanceProcessName(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceProcessName", GovernanceProcessName)
        }
        errorMessage={errors.GovernanceProcessName?.errorMessage}
        hasError={errors.GovernanceProcessName?.hasError}
        {...getOverrideProps(overrides, "GovernanceProcessName")}
      ></TextField>
      <TextField
        label="Process description"
        isRequired={false}
        isReadOnly={false}
        value={ProcessDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription: value,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.ProcessDescription ?? value;
          }
          if (errors.ProcessDescription?.hasError) {
            runValidationTasks("ProcessDescription", value);
          }
          setProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ProcessDescription", ProcessDescription)
        }
        errorMessage={errors.ProcessDescription?.errorMessage}
        hasError={errors.ProcessDescription?.hasError}
        {...getOverrideProps(overrides, "ProcessDescription")}
      ></TextField>
      <SelectField
        label="Process frequency"
        placeholder="Please select an option"
        isDisabled={false}
        value={ProcessFrequency}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency: value,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.ProcessFrequency ?? value;
          }
          if (errors.ProcessFrequency?.hasError) {
            runValidationTasks("ProcessFrequency", value);
          }
          setProcessFrequency(value);
        }}
        onBlur={() => runValidationTasks("ProcessFrequency", ProcessFrequency)}
        errorMessage={errors.ProcessFrequency?.errorMessage}
        hasError={errors.ProcessFrequency?.hasError}
        {...getOverrideProps(overrides, "ProcessFrequency")}
      >
        <option
          children="Daily"
          value="Daily"
          {...getOverrideProps(overrides, "ProcessFrequencyoption0")}
        ></option>
        <option
          children="Weekly"
          value="Weekly"
          {...getOverrideProps(overrides, "ProcessFrequencyoption1")}
        ></option>
        <option
          children="Fortnightly"
          value="Fortnightly"
          {...getOverrideProps(overrides, "ProcessFrequencyoption2")}
        ></option>
        <option
          children="Monthly"
          value="Monthly"
          {...getOverrideProps(overrides, "ProcessFrequencyoption3")}
        ></option>
        <option
          children="Quarterly"
          value="Quarterly"
          {...getOverrideProps(overrides, "ProcessFrequencyoption4")}
        ></option>
        <option
          children="Half yearly"
          value="HalfYearly"
          {...getOverrideProps(overrides, "ProcessFrequencyoption5")}
        ></option>
        <option
          children="Yearly"
          value="Yearly"
          {...getOverrideProps(overrides, "ProcessFrequencyoption6")}
        ></option>
        <option
          children="Every two years"
          value="EveryTwoYears"
          {...getOverrideProps(overrides, "ProcessFrequencyoption7")}
        ></option>
        <option
          children="Every three years"
          value="EveryThreeYears"
          {...getOverrideProps(overrides, "ProcessFrequencyoption8")}
        ></option>
        <option
          children="Adhoc"
          value="Adhoc"
          {...getOverrideProps(overrides, "ProcessFrequencyoption9")}
        ></option>
        <option
          children="Never"
          value="Never"
          {...getOverrideProps(overrides, "ProcessFrequencyoption10")}
        ></option>
      </SelectField>
      <SelectField
        label="Process type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ProcessType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType: value,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.ProcessType ?? value;
          }
          if (errors.ProcessType?.hasError) {
            runValidationTasks("ProcessType", value);
          }
          setProcessType(value);
        }}
        onBlur={() => runValidationTasks("ProcessType", ProcessType)}
        errorMessage={errors.ProcessType?.errorMessage}
        hasError={errors.ProcessType?.hasError}
        {...getOverrideProps(overrides, "ProcessType")}
      >
        <option
          children="Assess"
          value="Assess"
          {...getOverrideProps(overrides, "ProcessTypeoption0")}
        ></option>
        <option
          children="Decide"
          value="Decide"
          {...getOverrideProps(overrides, "ProcessTypeoption1")}
        ></option>
        <option
          children="Identify"
          value="Identify"
          {...getOverrideProps(overrides, "ProcessTypeoption2")}
        ></option>
        <option
          children="Manage"
          value="Manage"
          {...getOverrideProps(overrides, "ProcessTypeoption3")}
        ></option>
        <option
          children="Monitor"
          value="Monitor"
          {...getOverrideProps(overrides, "ProcessTypeoption4")}
        ></option>
        <option
          children="Oversee"
          value="Oversee"
          {...getOverrideProps(overrides, "ProcessTypeoption5")}
        ></option>
        <option
          children="Prioritise"
          value="Prioritise"
          {...getOverrideProps(overrides, "ProcessTypeoption6")}
        ></option>
        <option
          children="Report"
          value="Report"
          {...getOverrideProps(overrides, "ProcessTypeoption7")}
        ></option>
        <option
          children="Integrated"
          value="Integrated"
          {...getOverrideProps(overrides, "ProcessTypeoption8")}
        ></option>
      </SelectField>
      <TextField
        label="Process tradeoff description"
        isRequired={false}
        isReadOnly={false}
        value={ProcessTradeoffDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription: value,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.ProcessTradeoffDescription ?? value;
          }
          if (errors.ProcessTradeoffDescription?.hasError) {
            runValidationTasks("ProcessTradeoffDescription", value);
          }
          setProcessTradeoffDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ProcessTradeoffDescription",
            ProcessTradeoffDescription
          )
        }
        errorMessage={errors.ProcessTradeoffDescription?.errorMessage}
        hasError={errors.ProcessTradeoffDescription?.hasError}
        {...getOverrideProps(overrides, "ProcessTradeoffDescription")}
      ></TextField>
      <TextField
        label="Use remuneration performance metrics"
        isRequired={false}
        isReadOnly={false}
        value={UseRemunerationPerformanceMetrics}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics: value,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.UseRemunerationPerformanceMetrics ?? value;
          }
          if (errors.UseRemunerationPerformanceMetrics?.hasError) {
            runValidationTasks("UseRemunerationPerformanceMetrics", value);
          }
          setUseRemunerationPerformanceMetrics(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "UseRemunerationPerformanceMetrics",
            UseRemunerationPerformanceMetrics
          )
        }
        errorMessage={errors.UseRemunerationPerformanceMetrics?.errorMessage}
        hasError={errors.UseRemunerationPerformanceMetrics?.hasError}
        {...getOverrideProps(overrides, "UseRemunerationPerformanceMetrics")}
      ></TextField>
      <SelectField
        label="Climate oversight process type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ClimateOversightProcessType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType: value,
              GovernanceArtifactName,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.ClimateOversightProcessType ?? value;
          }
          if (errors.ClimateOversightProcessType?.hasError) {
            runValidationTasks("ClimateOversightProcessType", value);
          }
          setClimateOversightProcessType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ClimateOversightProcessType",
            ClimateOversightProcessType
          )
        }
        errorMessage={errors.ClimateOversightProcessType?.errorMessage}
        hasError={errors.ClimateOversightProcessType?.hasError}
        {...getOverrideProps(overrides, "ClimateOversightProcessType")}
      >
        <option
          children="Strategy oversight"
          value="StrategyOversight"
          {...getOverrideProps(overrides, "ClimateOversightProcessTypeoption0")}
        ></option>
        <option
          children="Major transaction decision making"
          value="MajorTransactionDecisionMaking"
          {...getOverrideProps(overrides, "ClimateOversightProcessTypeoption1")}
        ></option>
        <option
          children="Risk management processes"
          value="RiskManagementProcesses"
          {...getOverrideProps(overrides, "ClimateOversightProcessTypeoption2")}
        ></option>
        <option
          children="Policies"
          value="Policies"
          {...getOverrideProps(overrides, "ClimateOversightProcessTypeoption3")}
        ></option>
      </SelectField>
      <TextField
        label="Governance artifact name"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceArtifactName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName: value,
              GovernanceArtifactDescription,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceArtifactName ?? value;
          }
          if (errors.GovernanceArtifactName?.hasError) {
            runValidationTasks("GovernanceArtifactName", value);
          }
          setGovernanceArtifactName(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceArtifactName", GovernanceArtifactName)
        }
        errorMessage={errors.GovernanceArtifactName?.errorMessage}
        hasError={errors.GovernanceArtifactName?.hasError}
        {...getOverrideProps(overrides, "GovernanceArtifactName")}
      ></TextField>
      <TextField
        label="Governance artifact description"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceArtifactDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              GovernanceProcessSelectType,
              GovernanceProcessName,
              ProcessDescription,
              ProcessFrequency,
              ProcessType,
              ProcessTradeoffDescription,
              UseRemunerationPerformanceMetrics,
              ClimateOversightProcessType,
              GovernanceArtifactName,
              GovernanceArtifactDescription: value,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceArtifactDescription ?? value;
          }
          if (errors.GovernanceArtifactDescription?.hasError) {
            runValidationTasks("GovernanceArtifactDescription", value);
          }
          setGovernanceArtifactDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GovernanceArtifactDescription",
            GovernanceArtifactDescription
          )
        }
        errorMessage={errors.GovernanceArtifactDescription?.errorMessage}
        hasError={errors.GovernanceArtifactDescription?.hasError}
        {...getOverrideProps(overrides, "GovernanceArtifactDescription")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
