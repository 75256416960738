import HelpBox from "./HelpBox";

export default function EmissionAccountEntryHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Greenhouse Gas Emission Transaction Help</HelpBox.Header>
            <HelpBox.Content>
                <p>This form allows you to create a record of a transaction that emits greenhouse gases.</p>
                <p>Follow these steps to create an <i>Greenhouse Gas Emission Transaction</i>:</p>
                <ol>
                    <li>Complete all fields in the form on the left hand side of the screen</li>
                    <li>Once the record is created you can edit it, and upload any evidence documenting the record</li>
                    <li>Once all data is entered, click the <b>Create</b> or <b>Update</b> button at the bottom left</li>
                    <li>You can also click <b>Reset</b> to clear any data entered into the form.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
