import HelpBox from "../../shared/content/HelpBox";

export default function RiskOpportunityMetricsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Risk, Opportunities, Capital Deployed, Carbon Price and Remuneration Metrics Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that contains data about metrics covering:
        </p>
        <p>
          <ul>
            <li>the amount and percentage of assets or business activities vulnerable to climate-related transition and physical risks</li>
            <li>the amount and percentage of assets or business activities aligned with climate-related opportunities</li>
            <li>the amount of capital expenditure, financing or investment deployed towards climate-related risks and opportunities, internal carbon prices</li>
            <li>remuneration</li>
          </ul>
        </p>
        <p>Follow these steps to create a Risk Opportunity Metrics record:</p>
        <ol>
          <li>
            Create a name for the record in the <i>Metrics record name</i> field.
          </li>
          <li>Next, complete each required data field on the form.</li>
          <li>
            Once completed, choose <b>Create</b> or <b>Update</b>.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
