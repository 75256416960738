import HelpBox from "../../shared/content/HelpBox";

export default function ClimateRelatedTransitionPlanHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Related Transition Plan Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes your organization's Climate Related Transition Plan, in response to the effects of climate-related risks and opportunities on strategy and decision-making.
        </p>
        <p>Follow these steps to define your climate-related transition plan:</p>
        <ol>
          <li>
            Enter a name and description for the transition plan.
          </li>
          <li>
            Enter key assumptions used in developing the transition plan.
          </li>
          <li>
            Enter any dependencies that the transition plan relies on.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
