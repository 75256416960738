/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { StrategyDecisionMakingData } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function StrategyDecisionMakingDataStrategyResourcingUpdateForm(
  props
) {
  const {
    id: idProp,
    strategyDecisionMakingData: strategyDecisionMakingDataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ResourcingPlanName: "",
    CurrentResourcingDescription: "",
    FutureResourcingDescription: "",
  };
  const [ResourcingPlanName, setResourcingPlanName] = React.useState(
    initialValues.ResourcingPlanName
  );
  const [CurrentResourcingDescription, setCurrentResourcingDescription] =
    React.useState(initialValues.CurrentResourcingDescription);
  const [FutureResourcingDescription, setFutureResourcingDescription] =
    React.useState(initialValues.FutureResourcingDescription);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = strategyDecisionMakingDataRecord
      ? { ...initialValues, ...strategyDecisionMakingDataRecord }
      : initialValues;
    setResourcingPlanName(cleanValues.ResourcingPlanName);
    setCurrentResourcingDescription(cleanValues.CurrentResourcingDescription);
    setFutureResourcingDescription(cleanValues.FutureResourcingDescription);
    setErrors({});
  };
  const [
    strategyDecisionMakingDataRecord,
    setStrategyDecisionMakingDataRecord,
  ] = React.useState(strategyDecisionMakingDataModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(StrategyDecisionMakingData, idProp)
        : strategyDecisionMakingDataModelProp;
      setStrategyDecisionMakingDataRecord(record);
    };
    queryData();
  }, [idProp, strategyDecisionMakingDataModelProp]);
  React.useEffect(resetStateValues, [strategyDecisionMakingDataRecord]);
  const validations = {
    ResourcingPlanName: [],
    CurrentResourcingDescription: [],
    FutureResourcingDescription: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ResourcingPlanName,
          CurrentResourcingDescription,
          FutureResourcingDescription,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            StrategyDecisionMakingData.copyOf(
              strategyDecisionMakingDataRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "StrategyDecisionMakingDataStrategyResourcingUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Resourcing of Strategy, Decision Making and Targets"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 14 b."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update this record that defines how you are resourcing, and plan to resource in the future, your strategy, decision making and targets."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Name of resourcing plan (e.g. 2027 strategy resourcing plan)"
        isRequired={false}
        isReadOnly={false}
        value={ResourcingPlanName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ResourcingPlanName: value,
              CurrentResourcingDescription,
              FutureResourcingDescription,
            };
            const result = onChange(modelFields);
            value = result?.ResourcingPlanName ?? value;
          }
          if (errors.ResourcingPlanName?.hasError) {
            runValidationTasks("ResourcingPlanName", value);
          }
          setResourcingPlanName(value);
        }}
        onBlur={() =>
          runValidationTasks("ResourcingPlanName", ResourcingPlanName)
        }
        errorMessage={errors.ResourcingPlanName?.errorMessage}
        hasError={errors.ResourcingPlanName?.hasError}
        {...getOverrideProps(overrides, "ResourcingPlanName")}
      ></TextField>
      <TextAreaField
        label="Description of current resourcing "
        isRequired={false}
        isReadOnly={false}
        value={CurrentResourcingDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ResourcingPlanName,
              CurrentResourcingDescription: value,
              FutureResourcingDescription,
            };
            const result = onChange(modelFields);
            value = result?.CurrentResourcingDescription ?? value;
          }
          if (errors.CurrentResourcingDescription?.hasError) {
            runValidationTasks("CurrentResourcingDescription", value);
          }
          setCurrentResourcingDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "CurrentResourcingDescription",
            CurrentResourcingDescription
          )
        }
        errorMessage={errors.CurrentResourcingDescription?.errorMessage}
        hasError={errors.CurrentResourcingDescription?.hasError}
        {...getOverrideProps(overrides, "CurrentResourcingDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Description of future resourcing plan"
        isRequired={false}
        isReadOnly={false}
        value={FutureResourcingDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ResourcingPlanName,
              CurrentResourcingDescription,
              FutureResourcingDescription: value,
            };
            const result = onChange(modelFields);
            value = result?.FutureResourcingDescription ?? value;
          }
          if (errors.FutureResourcingDescription?.hasError) {
            runValidationTasks("FutureResourcingDescription", value);
          }
          setFutureResourcingDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "FutureResourcingDescription",
            FutureResourcingDescription
          )
        }
        errorMessage={errors.FutureResourcingDescription?.errorMessage}
        hasError={errors.FutureResourcingDescription?.hasError}
        {...getOverrideProps(overrides, "FutureResourcingDescription")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || strategyDecisionMakingDataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || strategyDecisionMakingDataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
