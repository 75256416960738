/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { RiskOpportunity } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskOpportunityCurrentFinancialEffectCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    FinancialEffectType: "",
    FinancialEffectValue: "",
    FinancialEffectUnit: "",
    FinancialPositionValueMin: "",
    FinancialPositionValueMax: "",
    FinancialEffectQuantitativeDescription: "",
    WhyNoQuanitativeFinancialData: "",
    FinancialEffectQualitativeDescription: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [FinancialEffectType, setFinancialEffectType] = React.useState(
    initialValues.FinancialEffectType
  );
  const [FinancialEffectValue, setFinancialEffectValue] = React.useState(
    initialValues.FinancialEffectValue
  );
  const [FinancialEffectUnit, setFinancialEffectUnit] = React.useState(
    initialValues.FinancialEffectUnit
  );
  const [FinancialPositionValueMin, setFinancialPositionValueMin] =
    React.useState(initialValues.FinancialPositionValueMin);
  const [FinancialPositionValueMax, setFinancialPositionValueMax] =
    React.useState(initialValues.FinancialPositionValueMax);
  const [
    FinancialEffectQuantitativeDescription,
    setFinancialEffectQuantitativeDescription,
  ] = React.useState(initialValues.FinancialEffectQuantitativeDescription);
  const [WhyNoQuanitativeFinancialData, setWhyNoQuanitativeFinancialData] =
    React.useState(initialValues.WhyNoQuanitativeFinancialData);
  const [
    FinancialEffectQualitativeDescription,
    setFinancialEffectQualitativeDescription,
  ] = React.useState(initialValues.FinancialEffectQualitativeDescription);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFinancialEffectType(initialValues.FinancialEffectType);
    setFinancialEffectValue(initialValues.FinancialEffectValue);
    setFinancialEffectUnit(initialValues.FinancialEffectUnit);
    setFinancialPositionValueMin(initialValues.FinancialPositionValueMin);
    setFinancialPositionValueMax(initialValues.FinancialPositionValueMax);
    setFinancialEffectQuantitativeDescription(
      initialValues.FinancialEffectQuantitativeDescription
    );
    setWhyNoQuanitativeFinancialData(
      initialValues.WhyNoQuanitativeFinancialData
    );
    setFinancialEffectQualitativeDescription(
      initialValues.FinancialEffectQualitativeDescription
    );
    setErrors({});
  };
  const validations = {
    FinancialEffectType: [],
    FinancialEffectValue: [],
    FinancialEffectUnit: [],
    FinancialPositionValueMin: [],
    FinancialPositionValueMax: [],
    FinancialEffectQuantitativeDescription: [],
    WhyNoQuanitativeFinancialData: [],
    FinancialEffectQualitativeDescription: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          FinancialEffectType,
          FinancialEffectValue,
          FinancialEffectUnit,
          FinancialPositionValueMin,
          FinancialPositionValueMax,
          FinancialEffectQuantitativeDescription,
          WhyNoQuanitativeFinancialData,
          FinancialEffectQualitativeDescription,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {};
          await DataStore.save(new RiskOpportunity(modelFieldsToSave));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "RiskOpportunityCurrentFinancialEffectCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Current Effects on Financial Position, Financial Performance and Cash Flows"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 16 a-b, 17, 19-21."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Heading>
      <Text
        children="Enter data about the current effects of climate-related risks and opportunities on your organisation’s financial position, financial performance and cash flows, and where there is a significant risk of a material adjustment within the next annual reporting period to the carrying amounts of assets and liabilities reported in the related financial statements."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Heading
        children="IFRS S2 paragraph 16 a-b, 17."
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Heading>
      <Text
        children="Enter quantitative information about the current financial effects of your climate-related risk / opportunity. You can enter a single aggregated amount, or a range."
        {...getOverrideProps(overrides, "SectionalElement7")}
      ></Text>
      <SelectField
        label="Type of current financial effect"
        placeholder="Please select the type of financial effect you have currently experienced"
        value={FinancialEffectType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectType: value,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialPositionValueMin,
              FinancialPositionValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.FinancialEffectType ?? value;
          }
          if (errors.FinancialEffectType?.hasError) {
            runValidationTasks("FinancialEffectType", value);
          }
          setFinancialEffectType(value);
        }}
        onBlur={() =>
          runValidationTasks("FinancialEffectType", FinancialEffectType)
        }
        errorMessage={errors.FinancialEffectType?.errorMessage}
        hasError={errors.FinancialEffectType?.hasError}
        {...getOverrideProps(overrides, "FinancialEffectType")}
      ></SelectField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Amount of financial effect"
          type="number"
          step="any"
          value={FinancialEffectValue}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectType,
                FinancialEffectValue: value,
                FinancialEffectUnit,
                FinancialPositionValueMin,
                FinancialPositionValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectValue ?? value;
            }
            if (errors.FinancialEffectValue?.hasError) {
              runValidationTasks("FinancialEffectValue", value);
            }
            setFinancialEffectValue(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectValue", FinancialEffectValue)
          }
          errorMessage={errors.FinancialEffectValue?.errorMessage}
          hasError={errors.FinancialEffectValue?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectValue")}
        ></TextField>
        <TextField
          label="Unit of measure of financial effect"
          value={FinancialEffectUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectType,
                FinancialEffectValue,
                FinancialEffectUnit: value,
                FinancialPositionValueMin,
                FinancialPositionValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialEffectUnit ?? value;
            }
            if (errors.FinancialEffectUnit?.hasError) {
              runValidationTasks("FinancialEffectUnit", value);
            }
            setFinancialEffectUnit(value);
          }}
          onBlur={() =>
            runValidationTasks("FinancialEffectUnit", FinancialEffectUnit)
          }
          errorMessage={errors.FinancialEffectUnit?.errorMessage}
          hasError={errors.FinancialEffectUnit?.hasError}
          {...getOverrideProps(overrides, "FinancialEffectUnit")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Minimum amount of financial effect"
          type="number"
          step="any"
          value={FinancialPositionValueMin}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectType,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialPositionValueMin: value,
                FinancialPositionValueMax,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialPositionValueMin ?? value;
            }
            if (errors.FinancialPositionValueMin?.hasError) {
              runValidationTasks("FinancialPositionValueMin", value);
            }
            setFinancialPositionValueMin(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "FinancialPositionValueMin",
              FinancialPositionValueMin
            )
          }
          errorMessage={errors.FinancialPositionValueMin?.errorMessage}
          hasError={errors.FinancialPositionValueMin?.hasError}
          {...getOverrideProps(overrides, "FinancialPositionValueMin")}
        ></TextField>
        <TextField
          label="Maximum amount of financial effect"
          type="number"
          step="any"
          value={FinancialPositionValueMax}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                FinancialEffectType,
                FinancialEffectValue,
                FinancialEffectUnit,
                FinancialPositionValueMin,
                FinancialPositionValueMax: value,
                FinancialEffectQuantitativeDescription,
                WhyNoQuanitativeFinancialData,
                FinancialEffectQualitativeDescription,
              };
              const result = onChange(modelFields);
              value = result?.FinancialPositionValueMax ?? value;
            }
            if (errors.FinancialPositionValueMax?.hasError) {
              runValidationTasks("FinancialPositionValueMax", value);
            }
            setFinancialPositionValueMax(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "FinancialPositionValueMax",
              FinancialPositionValueMax
            )
          }
          errorMessage={errors.FinancialPositionValueMax?.errorMessage}
          hasError={errors.FinancialPositionValueMax?.hasError}
          {...getOverrideProps(overrides, "FinancialPositionValueMax")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Description of quantitative financial effect"
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialPositionValueMin,
              FinancialPositionValueMax,
              FinancialEffectQuantitativeDescription: value,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.FinancialEffectQuantitativeDescription ?? value;
          }
          if (errors.FinancialEffectQuantitativeDescription?.hasError) {
            runValidationTasks("FinancialEffectQuantitativeDescription", value);
          }
          setFinancialEffectQuantitativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "FinancialEffectQuantitativeDescription",
            FinancialEffectQuantitativeDescription
          )
        }
        errorMessage={
          errors.FinancialEffectQuantitativeDescription?.errorMessage
        }
        hasError={errors.FinancialEffectQuantitativeDescription?.hasError}
        {...getOverrideProps(
          overrides,
          "FinancialEffectQuantitativeDescription"
        )}
      ></TextAreaField>
      <Heading
        children="IFRS S2 paragraph 19-21."
        {...getOverrideProps(overrides, "SectionalElement20")}
      ></Heading>
      <Text
        children=" If you cannot provide quantitative information about the current financial effects of your climate-related risk or opportunity, explain why and include a description of relevant qualitative information. If you cannot provide qualitative information about this risk or opportunity, enter aggregated financial effect data separately."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <TextAreaField
        label="Describe why you cannot provide data on the quantitative financial effects arising from this climate-related risk / opportunity"
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialPositionValueMin,
              FinancialPositionValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData: value,
              FinancialEffectQualitativeDescription,
            };
            const result = onChange(modelFields);
            value = result?.WhyNoQuanitativeFinancialData ?? value;
          }
          if (errors.WhyNoQuanitativeFinancialData?.hasError) {
            runValidationTasks("WhyNoQuanitativeFinancialData", value);
          }
          setWhyNoQuanitativeFinancialData(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "WhyNoQuanitativeFinancialData",
            WhyNoQuanitativeFinancialData
          )
        }
        errorMessage={errors.WhyNoQuanitativeFinancialData?.errorMessage}
        hasError={errors.WhyNoQuanitativeFinancialData?.hasError}
        	value={WhyNoQuanitativeFinancialData} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "WhyNoQuanitativeFinancialData")}
      ></TextAreaField>
      <TextAreaField
        label="Provide a qualitative description of the financial effect of this climate-related risk / opportunity"
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              FinancialEffectType,
              FinancialEffectValue,
              FinancialEffectUnit,
              FinancialPositionValueMin,
              FinancialPositionValueMax,
              FinancialEffectQuantitativeDescription,
              WhyNoQuanitativeFinancialData,
              FinancialEffectQualitativeDescription: value,
            };
            const result = onChange(modelFields);
            value = result?.FinancialEffectQualitativeDescription ?? value;
          }
          if (errors.FinancialEffectQualitativeDescription?.hasError) {
            runValidationTasks("FinancialEffectQualitativeDescription", value);
          }
          setFinancialEffectQualitativeDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "FinancialEffectQualitativeDescription",
            FinancialEffectQualitativeDescription
          )
        }
        errorMessage={
          errors.FinancialEffectQualitativeDescription?.errorMessage
        }
        hasError={errors.FinancialEffectQualitativeDescription?.hasError}
        {...getOverrideProps(
          overrides,
          "FinancialEffectQualitativeDescription"
        )}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
