import HelpBox from "./HelpBox";

export default function LocationHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Locations</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Location are used to provide context for <b>Assets</b> and <b>Reporting Entities</b> and can be used in analysis in the Stakeholder Portal.
                </p>
                <p>
                    To create or edit a Location, fill in the fields on the left hand side of the window. Locations must have a unique <b>Name</b>. Adding more information in the fields provided will result in better analysis of your climate disclosure data.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
