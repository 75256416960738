import HelpBox from "./HelpBox";

export default function AssetHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Assets</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    To create or edit an Asset, fill in the fields on the left hand side of the window.
                </p>
                <p>
                    Assets must have a unique 'Asset name' and 'Asset id', and adding more information in the fields provided will result in better analysis of your climate disclosure data.
                </p>
                <p>
                    You can also link an Asset to a location where the asset is physically located. Select a location from the 'Locations' table and select 'Create' if you have just created a new asset, or 'Update' if you are editing an existing asset.
                </p>
                <p>
                    If the required location is not available from the table, click 'New' at the bottom of the table and enter details for a new location.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
