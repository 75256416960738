import HelpBox from "../../shared/content/HelpBox";

export default function InformGovernanceProcessHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Inform Governance Process Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes a process used to inform the Climate Governance Body about climate-related risks and opportunities.
        </p>
        <p>Follow these steps to define your Inform Governance process:</p>
        <ol>
          <li>Enter a name and description for the inform process.</li>
          <li>Select the frequency with which it is executed.</li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.</li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
