import HelpBox from "../../shared/content/HelpBox";

export default function ScenarioAnalysisAssumptionsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Scenario Analysis Assumotions Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes the Scenario Analysis Assumptions, used in the construction of your climate-related scenarios.
        </p>
        <p>Follow these steps to describe your climate-related scenario analysis assumptions:</p>
        <ol>
          <li>
            Describe any jurisdictional policies used in constructing the scenarios.
          </li>
          <li>
            Describe any assumptions made about macroeconomic trends
          </li>
          <li>
            Describe any assumptions made about specific national or regional variables.
          </li>
          <li>
            Describe any assumptions made about energy use / energy mix.
          </li>
          <li>
            Describe any assumptions made about technology developments.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
