import HelpBox from "../../shared/content/HelpBox";

export default function ClimateGovernanceTargetsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Governance Targets Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes a Governance of Targets process used to oversee the setting of targets related to climate-related risks and opportunities, and monitor progress towards those targets.
        </p>
        <p>Follow these steps to define your Governance of Targets Process:</p>
        <ol>
          <li>
            Enter a name for each process, and select the type of process from the <i>Process type</i> dropdown menu.
          </li>
          <li>You should create records for the two types of relevant process: Monitor, and Oversee.</li>
          <li>
            Select the frequency with which the processes is executed.
          </li>
          <li>
            Enter a description for the process.
          </li>
          <li>
            Enter a description of whether and how related performance metrics are included in remuneration policies.
          </li>
          <li>You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
