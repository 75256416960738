/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedRiskOpportunity } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedRiskOpportunityClimateRelatedRiskOpportunityCreateForm(
  props
) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    DataSetName: "",
    Description: "",
  };
  const [DataSetName, setDataSetName] = React.useState(
    initialValues.DataSetName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDataSetName(initialValues.DataSetName);
    setDescription(initialValues.Description);
    setErrors({});
  };
  const validations = {
    DataSetName: [],
    Description: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DataSetName,
          Description,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateRelatedRiskOpportunity(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedRiskOpportunityClimateRelatedRiskOpportunityCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate-related Risks and Opportunities"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 10 a-d."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Enter data to collate the following sub-sections on climate-related risks and opportunities:"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Text
        children="a) climate-related risks and opportunities that could reasonably be expected to affect your business"
        {...getOverrideProps(overrides, "SectionalElement10")}
      ></Text>
      <Text
        children="b) for each climate-related risk you have identified, whether you consider the risk to be a climate-related physical risk or climate-related transition risk"
        {...getOverrideProps(overrides, "SectionalElement100")}
      ></Text>
      <Text
        children="c) specify, for each climate-related risk and opportunity identified, over which time horizons their effects could reasonably be expected to occur"
        {...getOverrideProps(overrides, "SectionalElement1000")}
      ></Text>
      <Text
        children="d) explain how you define ‘short term’, ‘medium term’ and ‘long term’ and how these definitions are linked to the planning horizons used  for strategic decision-making"
        {...getOverrideProps(overrides, "SectionalElement10000")}
      ></Text>
      <Text
        children="For each sub-section, enter data using the tables and links on the right."
        {...getOverrideProps(overrides, "SectionalElement100000")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Climate-related risks and opportunities data set name (e.g. 2025 Risks and Opportunities)"
        isRequired={false}
        isReadOnly={false}
        value={DataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName: value,
              Description,
            };
            const result = onChange(modelFields);
            value = result?.DataSetName ?? value;
          }
          if (errors.DataSetName?.hasError) {
            runValidationTasks("DataSetName", value);
          }
          setDataSetName(value);
        }}
        onBlur={() => runValidationTasks("DataSetName", DataSetName)}
        errorMessage={errors.DataSetName?.errorMessage}
        hasError={errors.DataSetName?.hasError}
        {...getOverrideProps(overrides, "DataSetName")}
      ></TextField>
      <TextAreaField
        label="Description (e.g. Set of climate-related Risks and Opportunities for the 2025 disclosure period)"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description: value,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        	value={Description} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
