import HelpBox from "../../shared/content/HelpBox";

export default function ClimateRelatedTargetsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Related Targets Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that contains data about a Climate Related Target.
        </p>
        <p>IFRS S2 requires you to disclose several sets of data for each target record, including:</p>
        <ol>
          <li>the base quantitative and qualitative climate-related target</li>
          <li>the greenhouse gas emission data for this target</li>
          <li>the approach to setting and reviewing this target, and monitoring progress against this target</li>
          <li>performance in achieving this target</li>
        </ol>
        <p>Follow these steps to create a complete Climate Related Target record:</p>
        <ol>
          <li>Create a name for the record in the <i>Target name</i> field.</li>
          <li>Complete each required data field on the form on the left.</li>
          <li>Fill in the required fields in the <i>Greenhouse Gas Emission Target Data</i> form in the set of tabs in the middle of the screen</li>
          <li>Fill in the required fields in the <i>Target Setting Review Approach</i> form in the set of tabs in the middle of the screen</li>
          <li>Fill in the required fields in the <i>Performance Against Target</i> form in the set of tabs in the middle of the screen</li>
          <li>Once these tabs have been completed, you need to link this Target to a Climate Related Metric. Expand the <i>Climate Related Metric</i> table in the middle below the three tabs, and select a metric</li>
          <li>If you are using offsets to achieve this target, you will need to enter additional data about the offsets you plan to use. Expand the <i>Offset Account Entries</i> table in the middle below the three tabs, and create an offset account transaction entry</li>
          <li>You can also record additional data about performance against this target by entering additional emission reduction data. Expand the <i>Reduction Account Entries</i> table in the middle below the three tabs, and link to or create a reduction account transaction entry</li>
          <li>Finally, you can add evidence supporting this target by clicking <b>New</b> or <b>Import</b> in the <i>Evidence Attachments</i> table at the bottom of the page.</li>
          <li>Once all forms have been completed, and required records linked, choose <b>Create</b> or <b>Update</b>.</li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
