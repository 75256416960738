import HelpBox from "../../shared/content/HelpBox";

export default function PlanningHorizonsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Planning Horizons Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that defines the Planning Horizons when climate-related risks and opportunities could reasonably be expected to occur.
        </p>
        <p>Follow these steps to define your planning horizons:</p>
        <ol>
          <li>
            Enter a name and description for the planning horizons record.
          </li>
          <li>
            Select or de-select one or more records from the <i>Climate Planning Horions</i> table in the middle of the window. You need to define three records covering <i>Short Term</i>, <i>Medium Term</i> and <i>Long Term</i>.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
