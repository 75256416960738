/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ClimateGovernanceProcess } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateGovernanceProcessClimateStrategyOversightCreateForm(
  props
) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GovernanceProcessName: "",
    ProcessDescription: "",
    ClimateOversightProcessType: "",
    ProcessFrequency: "",
    ProcessTradeoffDescription: "",
  };
  const [GovernanceProcessName, setGovernanceProcessName] = React.useState(
    initialValues.GovernanceProcessName
  );
  const [ProcessDescription, setProcessDescription] = React.useState(
    initialValues.ProcessDescription
  );
  const [ClimateOversightProcessType, setClimateOversightProcessType] =
    React.useState(initialValues.ClimateOversightProcessType);
  const [ProcessFrequency, setProcessFrequency] = React.useState(
    initialValues.ProcessFrequency
  );
  const [ProcessTradeoffDescription, setProcessTradeoffDescription] =
    React.useState(initialValues.ProcessTradeoffDescription);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setGovernanceProcessName(initialValues.GovernanceProcessName);
    setProcessDescription(initialValues.ProcessDescription);
    setClimateOversightProcessType(initialValues.ClimateOversightProcessType);
    setProcessFrequency(initialValues.ProcessFrequency);
    setProcessTradeoffDescription(initialValues.ProcessTradeoffDescription);
    setErrors({});
  };
  const validations = {
    GovernanceProcessName: [],
    ProcessDescription: [],
    ClimateOversightProcessType: [],
    ProcessFrequency: [],
    ProcessTradeoffDescription: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GovernanceProcessName,
          ProcessDescription,
          ClimateOversightProcessType,
          ProcessFrequency,
          ProcessTradeoffDescription,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ClimateGovernanceProcess(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateGovernanceProcessClimateStrategyOversightCreateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Strategy Oversight Process"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 6 a iv."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record that defines how the governance body takes into account climate-related risks and opportunities when overseeing the organization's strategy, decisions on major transactions and risk management processes and related policies, including whether the governance body has considered trade-offs associated with those risks and opportunities."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Strategy oversight process name (e.g. 2025 climate strategy oversight process)"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceProcessName: value,
              ProcessDescription,
              ClimateOversightProcessType,
              ProcessFrequency,
              ProcessTradeoffDescription,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceProcessName ?? value;
          }
          if (errors.GovernanceProcessName?.hasError) {
            runValidationTasks("GovernanceProcessName", value);
          }
          setGovernanceProcessName(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceProcessName", GovernanceProcessName)
        }
        errorMessage={errors.GovernanceProcessName?.errorMessage}
        hasError={errors.GovernanceProcessName?.hasError}
        {...getOverrideProps(overrides, "GovernanceProcessName")}
      ></TextField>
      <TextAreaField
        label="Strategy oversight process description (e.g. Corporate climate strategy oversight process used to provide oversight over how our climate strategy is developed)"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceProcessName,
              ProcessDescription: value,
              ClimateOversightProcessType,
              ProcessFrequency,
              ProcessTradeoffDescription,
            };
            const result = onChange(modelFields);
            value = result?.ProcessDescription ?? value;
          }
          if (errors.ProcessDescription?.hasError) {
            runValidationTasks("ProcessDescription", value);
          }
          setProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ProcessDescription", ProcessDescription)
        }
        errorMessage={errors.ProcessDescription?.errorMessage}
        hasError={errors.ProcessDescription?.hasError}
        	value={ProcessDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "ProcessDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Climate oversight process type"
          placeholder="Please select an option"
          isDisabled={false}
          value={ClimateOversightProcessType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                GovernanceProcessName,
                ProcessDescription,
                ClimateOversightProcessType: value,
                ProcessFrequency,
                ProcessTradeoffDescription,
              };
              const result = onChange(modelFields);
              value = result?.ClimateOversightProcessType ?? value;
            }
            if (errors.ClimateOversightProcessType?.hasError) {
              runValidationTasks("ClimateOversightProcessType", value);
            }
            setClimateOversightProcessType(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ClimateOversightProcessType",
              ClimateOversightProcessType
            )
          }
          errorMessage={errors.ClimateOversightProcessType?.errorMessage}
          hasError={errors.ClimateOversightProcessType?.hasError}
          {...getOverrideProps(overrides, "ClimateOversightProcessType")}
        >
          <option
            children="Strategy oversight"
            value="StrategyOversight"
            {...getOverrideProps(
              overrides,
              "ClimateOversightProcessTypeoption0"
            )}
          ></option>
          <option
            children="Major transaction decision making"
            value="MajorTransactionDecisionMaking"
            {...getOverrideProps(
              overrides,
              "ClimateOversightProcessTypeoption1"
            )}
          ></option>
          <option
            children="Risk management processes"
            value="RiskManagementProcesses"
            {...getOverrideProps(
              overrides,
              "ClimateOversightProcessTypeoption2"
            )}
          ></option>
          <option
            children="Policies"
            value="Policies"
            {...getOverrideProps(
              overrides,
              "ClimateOversightProcessTypeoption3"
            )}
          ></option>
        </SelectField>
        <SelectField
          label="Process frequency"
          placeholder="Please select an option"
          isDisabled={false}
          value={ProcessFrequency}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                GovernanceProcessName,
                ProcessDescription,
                ClimateOversightProcessType,
                ProcessFrequency: value,
                ProcessTradeoffDescription,
              };
              const result = onChange(modelFields);
              value = result?.ProcessFrequency ?? value;
            }
            if (errors.ProcessFrequency?.hasError) {
              runValidationTasks("ProcessFrequency", value);
            }
            setProcessFrequency(value);
          }}
          onBlur={() =>
            runValidationTasks("ProcessFrequency", ProcessFrequency)
          }
          errorMessage={errors.ProcessFrequency?.errorMessage}
          hasError={errors.ProcessFrequency?.hasError}
          {...getOverrideProps(overrides, "ProcessFrequency")}
        >
          <option
            children="Daily"
            value="Daily"
            {...getOverrideProps(overrides, "ProcessFrequencyoption0")}
          ></option>
          <option
            children="Weekly"
            value="Weekly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption1")}
          ></option>
          <option
            children="Fortnightly"
            value="Fortnightly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption2")}
          ></option>
          <option
            children="Monthly"
            value="Monthly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption3")}
          ></option>
          <option
            children="Quarterly"
            value="Quarterly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption4")}
          ></option>
          <option
            children="Half yearly"
            value="HalfYearly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption5")}
          ></option>
          <option
            children="Yearly"
            value="Yearly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption6")}
          ></option>
          <option
            children="Every two years"
            value="EveryTwoYears"
            {...getOverrideProps(overrides, "ProcessFrequencyoption7")}
          ></option>
          <option
            children="Every three years"
            value="EveryThreeYears"
            {...getOverrideProps(overrides, "ProcessFrequencyoption8")}
          ></option>
          <option
            children="Adhoc"
            value="Adhoc"
            {...getOverrideProps(overrides, "ProcessFrequencyoption9")}
          ></option>
          <option
            children="Never"
            value="Never"
            {...getOverrideProps(overrides, "ProcessFrequencyoption10")}
          ></option>
        </SelectField>
      </Grid>
      <TextAreaField
        label="Describe whether the governance body has considered trade-offs associated with those risks and opportunities, as part of this process"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceProcessName,
              ProcessDescription,
              ClimateOversightProcessType,
              ProcessFrequency,
              ProcessTradeoffDescription: value,
            };
            const result = onChange(modelFields);
            value = result?.ProcessTradeoffDescription ?? value;
          }
          if (errors.ProcessTradeoffDescription?.hasError) {
            runValidationTasks("ProcessTradeoffDescription", value);
          }
          setProcessTradeoffDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ProcessTradeoffDescription",
            ProcessTradeoffDescription
          )
        }
        errorMessage={errors.ProcessTradeoffDescription?.errorMessage}
        hasError={errors.ProcessTradeoffDescription?.hasError}
        	value={ProcessTradeoffDescription} // GRL_MODIFY: correct value prop
        {...getOverrideProps(overrides, "ProcessTradeoffDescription")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
