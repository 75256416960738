import HelpBox from "../../shared/content/HelpBox";

export default function ClimateStrategyOversightHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Strategy Oversight Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes a process used by the Climate Governance Body to account for climate-related risks and opportunities when overseeing the organization's strategy, its decisions on major transactions and its risk management processes and related policies, including whether the body(s) or individual(s) has considered trade-offs associated with those risks and opportunities.
        </p>
        <p>Follow these steps to define your Climate Oversight Strategy process:</p>
        <ol>
          <li>Enter a name and description for the strategy oversight process.</li>
          <li>Select the type of process <i>Strategy oversight</i>, <i>Major transaction decision making</i>, <i>Risk management processes</i>, and <i>Policies</i> and select a frequency with which the process is executed.</li>
          <li>For each process you define, enter in the final text field a description whether the governance body has considered trade-offs associated with the climate-related risks and opportunities.</li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.</li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
