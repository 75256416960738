import HelpBox from "../../shared/content/HelpBox";

export default function ClimatePlanningHorizonsHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Planning Horizons Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes a <b>Short Term</b>, <b>Medium Term</b> and <b>Long Term</b> Climate Planning Horizon, and link this to one or more planning horizons used for strategic decision-making.
        </p>
        <p>Follow these steps to define a climate planning horizon:</p>
        <ol>
          <li>
            Select a period from the <i>Horizon period type</i> dropdown menu.
          </li>
          <li>
            In the fields below, enter dates for the <i>Horizon start</i> and <i>Horizon end</i> periods.
          </li>
          <li>
            Select a record from the <b>Business Strategic Planning Horizons</b> table in the middle of the window. If no records are available, create one or more records by selecting the <b>New</b> button in the table.
          </li>
          <li>
            You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
