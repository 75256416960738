import HelpBox from "./HelpBox";

export default function OffsetAcountEntryHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Greenhouse Gas Offset Transaction Help</HelpBox.Header>
            <HelpBox.Content>
                <p>This form allows you to create a record of a transaction that offsets greenhouse gases.</p>
                <p>It is recommended you record a complete set of data for all offset transactions to meet all disclosure requirements.</p>
                <p>Follow these steps to create a <i>Greenhouse Gas Offset Transaction</i>:</p>
                <ol>
                    <li>Complete all fields in the form on the left hand side of the screen</li>
                    <li>Once the record is created you can edit it, and upload any evidence documenting the record</li>
                    <li>Once all data is entered, click the Create or Update button at the bottom left</li>
                    <li>You can also click Reset to clear any data entered into the form.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
