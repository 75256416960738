/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { ClimateRelatedTargets } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ClimateRelatedTargetsPerformanceAgainstTargetUpdateForm(
  props
) {
  const {
    id: idProp,
    climateRelatedTargets: climateRelatedTargetsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    PerformanceAgainstTarget: "",
    AnalysisOfTrendsInPerformance: "",
  };
  const [PerformanceAgainstTarget, setPerformanceAgainstTarget] =
    React.useState(initialValues.PerformanceAgainstTarget);
  const [AnalysisOfTrendsInPerformance, setAnalysisOfTrendsInPerformance] =
    React.useState(initialValues.AnalysisOfTrendsInPerformance);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = climateRelatedTargetsRecord
      ? { ...initialValues, ...climateRelatedTargetsRecord }
      : initialValues;
    setPerformanceAgainstTarget(cleanValues.PerformanceAgainstTarget);
    setAnalysisOfTrendsInPerformance(cleanValues.AnalysisOfTrendsInPerformance);
    setErrors({});
  };
  const [climateRelatedTargetsRecord, setClimateRelatedTargetsRecord] =
    React.useState(climateRelatedTargetsModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(ClimateRelatedTargets, idProp)
        : climateRelatedTargetsModelProp;
      setClimateRelatedTargetsRecord(record);
    };
    queryData();
  }, [idProp, climateRelatedTargetsModelProp]);
  React.useEffect(resetStateValues, [climateRelatedTargetsRecord]);
  const validations = {
    PerformanceAgainstTarget: [],
    AnalysisOfTrendsInPerformance: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          PerformanceAgainstTarget,
          AnalysisOfTrendsInPerformance,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            ClimateRelatedTargets.copyOf(
              climateRelatedTargetsRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "ClimateRelatedTargetsPerformanceAgainstTargetUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Targets - Performance Against Target and Trends"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="IFRS S2 paragraph 35."
        {...getOverrideProps(overrides, "SectionalElement00")}
      ></Heading>
      <Text
        children="Update data about performance against this target and an analysis of trends or changes in your performance."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextAreaField
        label="Describe your performance against this target"
        isRequired={false}
        isReadOnly={false}
        value={PerformanceAgainstTarget}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PerformanceAgainstTarget: value,
              AnalysisOfTrendsInPerformance,
            };
            const result = onChange(modelFields);
            value = result?.PerformanceAgainstTarget ?? value;
          }
          if (errors.PerformanceAgainstTarget?.hasError) {
            runValidationTasks("PerformanceAgainstTarget", value);
          }
          setPerformanceAgainstTarget(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "PerformanceAgainstTarget",
            PerformanceAgainstTarget
          )
        }
        errorMessage={errors.PerformanceAgainstTarget?.errorMessage}
        hasError={errors.PerformanceAgainstTarget?.hasError}
        {...getOverrideProps(overrides, "PerformanceAgainstTarget")}
      ></TextAreaField>
      <TextAreaField
        label="Descibe an analysis of trends or changes in performance against this target"
        isRequired={false}
        isReadOnly={false}
        value={AnalysisOfTrendsInPerformance}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PerformanceAgainstTarget,
              AnalysisOfTrendsInPerformance: value,
            };
            const result = onChange(modelFields);
            value = result?.AnalysisOfTrendsInPerformance ?? value;
          }
          if (errors.AnalysisOfTrendsInPerformance?.hasError) {
            runValidationTasks("AnalysisOfTrendsInPerformance", value);
          }
          setAnalysisOfTrendsInPerformance(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AnalysisOfTrendsInPerformance",
            AnalysisOfTrendsInPerformance
          )
        }
        errorMessage={errors.AnalysisOfTrendsInPerformance?.errorMessage}
        hasError={errors.AnalysisOfTrendsInPerformance?.hasError}
        {...getOverrideProps(overrides, "AnalysisOfTrendsInPerformance")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || climateRelatedTargetsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || climateRelatedTargetsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
