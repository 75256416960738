import HelpBox from "../../shared/content/HelpBox";

export default function ClimateGovernanceArtifactHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate Governance Artifact Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that describes an artifacts that the organization uses to define the Goverance Body's responsibilities for climate-related risks and opportunities.
        </p>
        <p>Follow these steps to define your Climate Governance Artifact:</p>
        <ol>
          <li>
            Enter a name and description for the climate governance artifact.
          </li>
          <li>You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form at the bottom. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
