import {useEffect, useId, useRef, useState} from "react";

import {DataStore, Predicates, SortDirection} from "aws-amplify/datastore";
import clsx from "clsx";
import {isEmpty, omit} from "lodash";
import PropTypes from "prop-types";
import {Dropdown, Table} from "react-bootstrap";

import {Button} from "@aws-amplify/ui-react";
import {Portal} from "@restart/ui";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  BsChevronDoubleDown,
  BsThreeDotsVertical,
  BsXCircle
} from "react-icons/bs";
import {
  combinedSchemaFor,
  defaultFilter,
  toFieldKey
} from "../../shared/amplify/schemaHelpers";
import DeleteItemButton from "../action/DeleteItemButton";
import DownloadButton from "../action/DownloadButton";
import UploadButton from "../action/UploadButton";
import {useCustomerDataStore} from "../customer/useCustomerDataStore";
import {renderField} from "../field/renderField";
import {LayoutProvider} from "../modal/LayoutContext";
import useLocalStorage from "../storage/useLocalStorage";
import {useToast} from "../util/Toast";
import styles from "./DataItemTable.module.css";
import CloneItemButton from "../action/CloneItemButton";

function tableFilter(modelSchema, field) {
  const {includeColumns} = modelSchema;
  return (
    defaultFilter(modelSchema, field) &&
    (!includeColumns || includeColumns.test(field.name)) &&
    !field.hideInTable
  );
}

/**
 * Given a DataStore schema definition generate headers with sensible column names
 * @param {*} param0
 * @param {*} filter
 * @returns
 */
function renderHeaderFromSchema({model, fields, customHeaderRenderers = {}}) {
  return ({
    sort: {sortField = undefined, descending = undefined} = {},
    onSortChange
  } = {}) => (
    <>
      {Object.entries(fields).map(([name, field], i) => (
        <th
          key={i}
          onClick={() =>
            onSortChange &&
            onSortChange({
              sortField: name,
              descending: sortField === name && !descending
            })
          }
        >
          {customHeaderRenderers[name]
            ? customHeaderRenderers[name](field)
            : field.displayName}
          {sortField === name && (descending ? " ▾" : " ▴")}
        </th>
      ))}
    </>
  );
}

async function updateField(dataStore, model, id, name, value) {
  const existing = await DataStore.query(model, id);
  dataStore.save(model.copyOf(existing, (update) => (update[name] = value)));
}

/**
 * Create a renderer that renders each field given the schema definition, applying filter
 * @param {*} param0
 * @param {*} filter
 * @returns
 */

function renderRowFromSchema({model, fields, customFieldRenderers = {}}) {
  const fieldRenderers = Object.fromEntries(
    Object.entries(fields).map(([name, field]) => [
      name,
      typeof customFieldRenderers[name] == "function"
        ? customFieldRenderers[name]
        : renderField(field, customFieldRenderers[name])
    ])
  );
  return (row) => {
    const showToast = useToast();
    const datastore = useCustomerDataStore();
    return (
      <>
        {Object.values(fields).map(({name}, i) => (
          <td key={i}>
            {fieldRenderers[name]({
              value: row[name],
              row,
              onChange: async (value) => {
                await updateField(datastore, model, row.id, name, value);
                showToast({content: `Updated ${model.name}`});
              }
            })}
          </td>
        ))}
      </>
    );
  };
}

function ColumnMenu({
  defaultDisplayFields,
  hideFields,
  onChange,
  onReset,
  containerRef
}) {
  return (
    <Dropdown style={{textAlign: "right"}}>
      <Dropdown.Toggle variant="outline" id="dropdown-toggle">
        <BsThreeDotsVertical />
      </Dropdown.Toggle>

      <Portal containerRef={containerRef}>
        <Dropdown.Menu className={styles.columnMenu}>
          <DropdownItem onClick={onReset}>
            <b>Use Defaults</b>
          </DropdownItem>
          {Object.keys(defaultDisplayFields).map((name, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  onChange(
                    hideFields.includes(name)
                      ? hideFields.filter((f) => f !== name)
                      : [...hideFields, name]
                  );
                }}
                className={!hideFields.includes(name) && "checked"}
              >
                {defaultDisplayFields[name].displayName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Portal>
    </Dropdown>
  );
}

ColumnMenu.propTypes = {
  containerRef: PropTypes.any, // container reference so popup doesn't get clipped
  defaultDisplayFields: PropTypes.any,
  hideFields: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onReset: PropTypes.any
};

export default function DataItemTable({
  model,
  subtype,
  schema = combinedSchemaFor({model, subtype}),
  idField = "id",
  modal: Modal,
  clone: Clone = !schema?.noClone &&
    (({id}) => <CloneItemButton id={id} size="small" model={model} />),
  edit: Edit = Modal && Modal.Edit,
  new: New = Modal && Modal.New,
  delete: Delete = !schema?.noDelete &&
    (({id}) => <DeleteItemButton id={id} size="small" model={model} />),
  download: Download = () => <DownloadButton model={model} subtype={subtype} />,
  upload: Upload = () => <UploadButton model={model} subtype={subtype} />,
  itemActions = [Edit, Clone, Delete].filter(Boolean),
  globalActions = [Upload, Download, New].filter(Boolean),
  label,
  limit = 100,
  customFieldRenderers = {},
  customHeaderRenderers = {},
  onChange,
  multiple = true,
  select = true,
  selection,
  error,
  ariaLabel,
  onClick,
  fullSize,
  predicates
}) {
  const uniqueId = useId();
  const customerDatastore = useCustomerDataStore();
  const storageKeyPrefix = `DataItemTable.${toFieldKey(model.name, subtype)}`;
  const [hideFields, setHideFields] = useLocalStorage(
    `${storageKeyPrefix}.hideFields`,
    []
  );
  const defaultDisplayFields = Object.fromEntries(
    Object.entries(schema.fields).filter(([_, field]) =>
      tableFilter(schema, field)
    )
  );
  const displayFields = Object.fromEntries(
    Object.entries(omit(defaultDisplayFields, Array.from(hideFields))).sort(
      ([_1, {order: order1}], [_2, {order: order2}]) =>
        (order1 || 0) - (order2 || 0)
    )
  );
  const [sort, setSort] = useLocalStorage(`${storageKeyPrefix}.sort`);
  const {sortField, descending} =
    typeof sort === "object" && !!sort ? sort : {};
  const validSortField = sortField in displayFields ? sortField : undefined; // if sort field, valid use it, else ignore
  const [showLimit, setShowLimit] = useState(limit);
  const HeaderRow =
    schema &&
    renderHeaderFromSchema({
      model,
      fields: displayFields,
      customHeaderRenderers
    });
  const ItemRow =
    schema &&
    renderRowFromSchema({model, fields: displayFields, customFieldRenderers});
  const containerRef = useRef();

  const [dataItems, setDataItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  function handleSelectionChange(newOptions) {
    const constrained =
      multiple || !newOptions ? newOptions : newOptions.slice(-1);
    setSelectedOptions(constrained);
    if (onChange) {
      const items = (constrained || [])
        .map((selectedId) =>
          (dataItems || []).find(({[idField]: id}) => id === selectedId)
        )
        .filter((v) => !!v);
      onChange(items);
    }
  }
  const handleSelectAll = ({target: {checked}}) => {
    const newOptions = checked
      ? (dataItems || []).map(({[idField]: id}) => id)
      : [];
    handleSelectionChange(newOptions);
  };
  const handleSelectEvent = ({target: {value, checked}}) => {
    const newOptions = checked
      ? [...selectedOptions, value]
      : selectedOptions.filter((id) => id !== value);
    handleSelectionChange(newOptions);
  };
  function handleClick({target: {value}}) {
    onClick &&
      dataItems &&
      onClick(dataItems.find(({[idField]: id}) => id === value));
  }
  const typeField = schema?.discriminatorField;
  useEffect(() => {
    if (typeof selection === "function")
      setSelectedOptions(
        dataItems.filter(selection).map(({[idField]: id}) => id)
      );
    else if (!!selection) {
      const idSet = new Set([selection].flat());
      setSelectedOptions(
        dataItems
          .filter(({[idField]: id}) => idSet.has(id))
          .map(({[idField]: id}) => id)
      );
    }
  }, [idField, dataItems, selection]);
  useEffect(() => {
    const queryPredicates = [
      subtype && typeField && ((c) => c[typeField].eq(subtype)),
      predicates
    ]
      .flat()
      .filter(Boolean);
    const subscription = customerDatastore
      .observeQuery(
        model,
        !isEmpty(queryPredicates)
          ? (c) => c.and((c1) => queryPredicates.map((p) => p(c1)))
          : Predicates.ALL,
        {
          ...(validSortField && {
            sort: (s) =>
              s[validSortField](
                descending ? SortDirection.DESCENDING : SortDirection.ASCENDING
              )
          })
          // limit: showLimit note observeQuery just ignores limit (not documented by AWS - https://discord.com/channels/705853757799399426/831604049928257556/threads/1050632502722707496)
        }
      )
      .subscribe(({items}) => {
        setDataItems(items.slice(0, showLimit)); // put artificial slice in here, discarding extras!
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [
    model,
    typeField,
    validSortField,
    limit,
    showLimit,
    descending,
    subtype,
    customerDatastore,
    predicates
  ]);
  const uuid = useId();
  return (
    <>
      <div className={clsx(styles.panel)}>
        {label && <label htmlFor={uuid}>{label}</label>}
        <div
          className={clsx(styles.wrapper, fullSize && styles.fullSize)}
          ref={containerRef}
        >
          <Table id={uuid} borderless striped responsive="lg">
            {HeaderRow && (
              <thead className={clsx("sticky-top", "bg-light")}>
                <tr>
                  {select && (
                    <th className={clsx("select", styles.selectCell)}>
                      {multiple ? (
                        <input type="checkbox" onChange={handleSelectAll} />
                      ) : (
                        <Button
                          variation="link"
                          onClick={() =>
                            handleSelectAll({target: {checked: false}})
                          }
                          variant="outline"
                          size="md"
                        >
                          <BsXCircle />
                        </Button>
                      )}
                    </th>
                  )}
                  {
                    <HeaderRow
                      sort={
                        sortField && {sortField: validSortField, descending}
                      }
                      onSortChange={setSort}
                    />
                  }
                  {
                    <th>
                      <ColumnMenu
                        defaultDisplayFields={defaultDisplayFields}
                        hideFields={hideFields}
                        onChange={setHideFields}
                        onReset={() => {
                          setHideFields([]);
                          setSort({});
                        }}
                        containerRef={containerRef}
                      />
                    </th>
                  }
                </tr>
              </thead>
            )}
            {dataItems && (
              <LayoutProvider layout="dense">
                <tbody>
                  {dataItems.map(({[idField]: id, ...row}) => {
                    const selected = id && selectedOptions.includes(id);
                    return (
                      <tr
                        key={id}
                        onClick={handleClick}
                        className={clsx(selected && "selected")}
                      >
                        {select && (
                          <td className={styles.selectCell}>
                            <input
                              type={multiple ? "checkbox" : "radio"}
                              onChange={handleSelectEvent}
                              name={uniqueId}
                              value={id}
                              checked={selectedOptions.includes(id)}
                            />
                          </td>
                        )}
                        {<ItemRow id={id} {...row} />}
                        <td className={styles.itemActions}>
                          {!isEmpty(itemActions) && (
                            <div>
                              {itemActions.map((ItemAction, i) => (
                                <ItemAction
                                  key={i}
                                  id={id}
                                  item={row}
                                  model={model}
                                />
                              ))}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutProvider>
            )}
          </Table>
          {dataItems.length === showLimit && (
            <div className="d-flex justify-content-center">
              <Button
                size="small"
                variation={"link"}
                onClick={() => setShowLimit(showLimit + limit)}
              >
                See more &nbsp;
                <BsChevronDoubleDown />
              </Button>
            </div>
          )}
        </div>
        {!isEmpty(globalActions) && (
          <div className={clsx(styles.globalActions)}>
            {globalActions.map((GlobalAction, i) => (
              <GlobalAction key={i} />
            ))}
          </div>
        )}
      </div>
      {!!error && <div className={styles.errorMessage}>{error}</div>}
    </>
  );
}

DataItemTable.propTypes = {
  new: PropTypes.func,
  ariaLabel: PropTypes.any,
  edit: PropTypes.any,
  header: PropTypes.node,
  idField: PropTypes.string,
  label: PropTypes.node,
  limit: PropTypes.number,
  modal: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      New: PropTypes.any,
      Edit: PropTypes.any
    })
  ]),
  model: PropTypes.any,
  onClick: PropTypes.func,
  row: PropTypes.any,
  schema: PropTypes.object,
  sortField: PropTypes.string,
  selection: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  predicates: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ])
};
