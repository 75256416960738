import HelpBox from "../../shared/content/HelpBox";

export default function ClimateRelatedRisksOpportunitiesHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Climate-related Risks and Opportunities Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to enter a record that collates climate-related risk and opportunity records, their effects, and time and planning horizons they operate over.
        </p>
        <p>
          Because different climate-related risks, opportunities and effects arise over time, this form allows you to group different sets of risk and opportunity data for different disclosures. For example, a 2025 disclosure may require new data from a newly identified risk that wasn't present in a submitted 2024 disclosure.
        </p>
        <p>
          Follow these steps to define your climate-related risk and opportunity records, and the effects of these on your organization:
        </p>
        <ol>
          <li>
            Enter a name and description for this record in the left hand side of the window, to group the climate-related risks and opportunities data required for this disclosure.
          </li>
          <li>
            Select one or more existing rows of data from the <i>Climate-related Risks and Opportunities</i> table in the middle of the window, or create new data entries, to define applicable climate-related risks and opportunities, and the associated financial effects.
          </li>
          <li>
            If you haven't identified financial effects separately for each risk or opportunity, enter data into the <i>Aggregated Financial Effect from Risks and Opportunities</i> table in the middle of the window to define overall financial effects arising from identified climate-related risks and opportunities.
          </li>
          <li>
            Finally, enter data into the <i>Planning Horizons</i> table in the middle of the window to define the time periods and planning horizons used for planning responses to climate-related risks and opportunities.
          </li>
          <li>
            Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox >
  );
}
